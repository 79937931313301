.b-hotels-custom-attachment {
	display: flex;
	align-items: center;

	.b-search-form__param-toggles {
		margin-right: 15px;
	}

	.b-checkbox {
		height: unset;
		line-height: unset;

		&__input:checked + .b-checkbox__view {
			color: #fff;

			&:before {
				border-color: #fff;
			}
		}
		&__view {
			padding-left: 20px;
			color: #A4ABB3;

			&:before {
				height: 13px;
				width: 13px;
				top: calc(~'50% - 6.5px');
				border-color: #fff;
			}

			&:after {
				color: #fff;
				background: #fff;
				width: 9px;
				height: 9px;
				top: 4px;
				left: 2px;
			}
		}
	}

	.b-toggle {
		height: unset;
		line-height: unset;

		.b-toggle__input:checked + .b-toggle__label .b-toggle__view:before {
			left: unset;
			right: 2px;
		}

		&__view {
			width: 23px;
			height: 12px;

			&:before {
				top: 1px;
				left: 2px;
				background: rgba(255, 255, 255, 0.8);
				width: 8px;
				height: 8px;
			}
		}

		&__label {
			display: flex;
			align-items: center;
		}
	}
}

.b-hotels-custom-toggle{
	.b-toggle {
		margin-top: 20px;
	}
}
