@import (reference) "~assets/css/imports";

.b-cabinet {
    background-color: #4A5767;
    padding: 10px 12px;
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    &-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        max-width: 285px;
        /*margin-right: 14px;*/
    }
    &__title {
        margin-bottom: 12px;
        &-text {
            font-weight: bold;
        }
    }

  	&__additional-client,
  	&__additional-order,
  	&__language,
  	&__profile,
	&__info-currency {
		display: none;
		height: unset;
		margin-bottom: unset;
		padding: unset;
  	}
    &__action-button {
        background-color: #2F3842;
        max-width: 262px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        padding: 0;
        display: flex;
        justify-content: center;
        width: 100%;
        cursor: pointer;

	  	&.dn {
		  	display: none;
		}
    }

  	&__action-text {
		color: #fff;
		opacity: .75;
  	}

  	.b-login__rules-container {
	  margin-bottom: 20px;
	}
}

.b-cabinet__action-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .b-cabinet__action-button {
	background-color: #4882bd;
	border: 0;

	&[disabled] {
	  background-color: #2F3842 !important;

	  .b-cabinet__action-text {
		opacity: 0.75;
	  }
	}
	.b-cabinet__action-text {
	  opacity: 1;
	}
  }
}

@import './style.mobile.less';
