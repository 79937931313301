@media @tablet {
  .b-ticket__visible ~ .b-ticket__visible {
	margin: 50px 0 !important;
  }

  .b-flight-ticket {
	min-width: unset;
	padding: 12px;
	flex-direction: column;

	&:after {
	  content: unset;
	}

	&__content-container {
	  width: 100%;
	}

	&__expand {
	  display: none !important;
	}

	&__expand-container {
	  display: none !important;
	}

	&__mobile-company-logos {
	  width: 100%;
	  display: flex;
	  align-items: center;
	  justify-content: flex-start;
	  margin-bottom: 12px;
	  margin-top: 12px;

	  .b-leg__company-logo-container {
		width: 40px;
		height: 40px;
		overflow: hidden;
		.border-radius(50%);

		.b-leg__company-logo {
		  width: 40px !important;
		  height: 40px !important;
		}


		&:not(:last-child) {
		  margin-right: 20px;
		}
	  }
	}

	&__extra-info-container {
      flex-direction: column-reverse;
      align-items: flex-end;
      gap: 10px;
	  right: 20px;
	  top: 12px;
	}

	&__legs {
	  margin-bottom: 10px;
	  width: 100%;
	}

	&__leg {
	  width: 100%;

	  & > .b-leg__company-logo {
		display: none;
	  }

	  .b-leg__segments {
		display: none;
	  }
	}

	&__price {
	  position: absolute;
	  bottom: -37px;
	  left: -1px;
	  width: calc(~'100% + 2px');
	  flex-direction: row;
	  display: flex;

	  .b-flight-price__bottom {
		display: none;
	  }

	  .b-flight-price__top:first-of-type {
		order: 2;
	  }

	  .b-flight-price__top {
		width: 100%;
	  }

	  .btn {
		height: 38px;
		padding: 9px 10px;
	  }

	  .b-flight-price__button {
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
		background-color: #099C9D;
		color: #FFFFFF;
	  }

	  .b-flight-price__button--offer {
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
		border-right: 1px solid #9ca0a7;
		background-color: #4882BD;
		color: #FFFFFF;

		&:before {
		  left: unset;
		  top: 8px;
		  right: 10%;
		  border-color: #FFFFFF;
		}
	  }

	  .b-ticket-offer__cancel {
		background-color: #D70000;
		color: #FFFFFF;

		&:hover {
		  color: #FFFFFF;

		  &:before {
			border-color: #FFFFFF;
		  }
		}
	  }
	}

	.b-fare-rules__rule-carrier-open:last-of-type {
	  display: none;
	}
  }

  .b-leg {

	&__flight {
	  max-width: unset;
	}

	&__date,
	&__flight-info {
	  display: none;
	}

	&__mobile {
	  display: flex !important;
	  flex-direction: column;
	  width: 100%;
	  align-items: center;

	  & > div:not(:last-child) {
		margin-bottom: 10px;
	  }
	}

	&__mobile-expand, &__mobile-branded-fares {
	  color: rgba(74, 87, 103, 0.8);
	  font-weight: bold;
	  border-bottom: 1px dashed #4A5767;
	}

	&__mobile-controls {
	  width: 100%;
	  font-size: 14px;
	  margin-top: 10px;
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
		gap: 10px;
	}

	&__services {
	  margin: 0;
	}
  }

  .b-ticket-pricing__section-info {
	margin-left: 30px;
  }

  .b-ticket-pricing__section-title {
	margin-bottom: 10px;
  }

  .b-pricing__total-section--mobile {
	padding-top: 20px;
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: center;
	border-top: 1px dashed #4A5767;

	.b-pricing__total-price {
	  font-size: 20px;
	  font-weight: 600;
	  display: flex;
	  gap: 3px;

	  &:only-child {
		position: absolute;
		right: 0;
	  }
	}
  }

  .b-flight-ticket {
	.b-flight-ticket__gds-info {
	  top: 0;
	}
  }
}

@media (min-width: 1440px) {
	.b-flight-ticket {
		padding: 30px 20px 25px;

		&__extra-info-container {
			top: 7px;
			right: 7px;
		}
	}

	.b-leg {
		font-size: 14px;
		&__date {
			&-time {
				font-size: 20px;
				margin-right: 10px;
			}
			&-next-day {
				font-size: 14px;
			}
			&-info {
				font-size: 14px;
			}
		}

		&__services {
			margin: 0 10%;
			white-space: nowrap;
		}

		&__segments {
			&-duration {
				&-time {
					font-size: 20px;
					margin-right: 10px;
				}

				&-stops {
					font-size: 16px;
				}
			}
		}

		&__title {
			.b-leg__duration {
				font-size: 20px;
				font-weight: normal;
			}
		}

		&__flights {
			& > .b-leg__flight:before {
				right: calc(~'-18% - 18px');
			}
		}
	}

	.b-flight-services {
		flex-direction: row;
		align-items: center;

		&__option {
			width: 25px;
			height: 30px;
		}

		&__tariff {
			position: static;
			top: unset;
		}

		&__options {
			margin-left: 10px;
			bottom: unset;
		}
	}

	.b-stop {
		&__locations {
			&-transfer, &-city {
				font-size: 16px;
				font-weight: bold;
			}

			&-airport {
				font-size: 14px;
				font-weight: normal;
			}
		}
		&__duration {
			font-size: 22px;
			font-weight: 500;
		}
	}

	.b-leg__wrapper {
		.b-leg__duration {
			.b-flight-services__options {
				display: none;
			}
		}

		.b-leg__description {
			.b-flight-services__options {
				display: block;
			}
		}

		.b-leg {
			padding: 30px 0;
			&__company-logo {
				margin-right: 30px;
			}
			&__wrapper {
				padding: 0 45px 0 30px;

				& > div {
					&:after {
						margin: 0 -45px 0 -30px;
					}
				}
			}
			&__flight {
				&-description {
					display: none;
				}
			}
			&__description {
				display: block;
				& > div {
					max-width: 75%;
				}
			}
			&__date {
				&-time {
					font-size: 22px;
				}
			}
			&__duration {
				font-size: 22px;
				font-weight: bold;
			}
		}

		.b-leg__description {
			display: block;
		}

		.b-leg__flight-description {
			display: none;
		}
	}

	.b-flight-price__top {
		margin-bottom: 6px;
	}
	
}

@media (min-width: 1920px) {
	.b-flight-ticket {
		padding: 30px 106px 30px 40px;
	}
	.b-leg {
		&__company-logo {
			margin-right: 40px;
		}
		&__date {
			&-time {
				font-size: 20px;
			}
		}
		&__flights {
			& > .b-leg__flight:before {
				right: -10%;
			}
		}
	}
	.b-stop {
		padding: 25px 0;
	}
	.b-branded-fares {
		font-size: 16px;

		&__select {
			font-size: 16px;
			min-width: 160px;
		}

		&__column {
			&--type-caption {
				width: 180px;
				& > .b-branded-fares__row {
					align-items: center;
					font-size: 20px;
					padding: 0;
				}
			}
		}

		&__basis {
			font-size: 16px;
		}

		&__company-logo {
			width: 130px;
			height: 70px;
			margin: 0 auto;
		}
		&__name {
			font-size: 20px;
		}
	}

	.action-cells {
	    padding-left: 180px;
	}

	.b-standart-fares {
		&__item {
			&-class {
				font-size: 20px;
			}

			&-basis {
				font-size: 16px;
			}
		}
	}
	.b-leg__wrapper {
		padding: 0 140px 0 50px;

		& > div {
			&:after {
				margin: 0 -140px 0 -50px;
			}
		}
	}
}

@media (max-width: 768px) {
	.l-content-table-title-avia {
		padding-right: 12px;
	}
}
