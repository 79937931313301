@import (reference) "~assets/css/imports";

@media @tablet {
  .l-layout.show-filters {
	.b-input__value {
	  color: #4A5767;
	  background-color: #ffffff;
	  border: 1px solid #A4ABB3;
	  .placeholder(#4A5767);
	}

	.b-input__label {
	  color: #4a5767;
	}
  }
}

.b-input {
	display: block;
	width: 100%;
	position: relative;
	margin-bottom: 5px;
	&.b-email {
		.validations-errors__container {
			bottom: -25px;
			border-radius: 0;
			border-bottom-left-radius: 3px;
			border-bottom-right-radius: 3px;
		}
	}

}

.b-input__wrapper {
  position: relative;
}

.b-input__remove {
.g-icon-delete-collection;
cursor: pointer;
display: block;
background-size: 10px 10px;
background-position: 50% 50%;
width: 30px;
height: 30px;
bottom: 0;
position: absolute;
right: 0;
z-index: 5;
border-left: 1px solid #A4ABB3;
}

.b-input__label {
font-size: @base-font-size;
color: @field1-font-color;
margin-bottom: 2px;
display: inline;
width: min-content;
position: relative;
margin-left: 4px;
margin-right: 4px;
padding: 0 4px;
z-index: 3;

&:after {
	content: '';
	display: block;
	position: absolute;
	left: 0; right: 0;
	top: calc(50%);
	height: 3px;
	background: #ffffff;
	z-index: -1;
}
}

.b-input__value {
border: 1px solid @input-border-color;
font-size: @base-H1-size;
color: @field1-H1-color;
background: @field1-input-background;
padding: 0 10px;
height: 30px;
width: 100%;
.rounded;
z-index: 2;
background: none;
background-clip: padding-box;
position: relative;
margin-top: -8px;
display: block;
.placeholder(@input-placeholder-color);

&--without-label {
	margin-top: 0;
}

&:disabled, &[disabled], &[readonly] {
	background-color: rgba(74, 87, 103, 0.1);
}
}

.b-input.validation-error {

.b-input__error {
	display: inline-block;
}

.b-input__value {
	border-color: @field1-warning-color;
}

}

.b-input .validations-errors__container {
opacity: 0;
overflow: hidden;
.transition-fast;
}

.b-input .b-input__value:focus ~ .validations-errors__container {
height: auto;
opacity: 1;
}

@import "./style.mobile";
