@media @tablet {
  .b-transfer {

	&__wrapper {
	  padding: 0;
	  flex-flow: nowrap column;
	}
	  
	&__travel-policy {
		right: 10px;
		top: 32px;
	}

	&__info {

	  &-photo {
		min-width: 120px;
		min-height: 120px;
		max-width: 100%;
		max-height: 100%;
		width: 120px;
		height: 120px;
	  }

	  &-section {
		margin-top: unset !important;
		margin-left: unset !important;
	  }

	  &-details {
		margin-top: 25px !important;
		margin-left: 10px !important;

	  }


	  &-restrictions {
		position: absolute;
		top: 0;
		right: 0;
		background: #EDEEF0;
		color: #099C9D;
		.border-radius(~'0 4px 0 15px');
		padding: 4px 10px 4px 12px;
		flex-flow: row;
		gap: 10px;

		.b-transfer__info-capacity-text {
		  font-size: 14px !important;
		  line-height: 18px !important;
		}

		.b-transfer__info-capacity-icon {
		  width: 14px !important;
		  height: 14px !important;
		  margin-right: 8px !important;
		}
	  }
	}

	&__car-class {
	  margin-bottom: 10px;

	  &-text {
		font-size: 16px;
		line-height: 19px;
	  }
	}

	&__model-name-text {
	  font-size: 12px;
	  line-height: 14px;
	}

	&__minimal-rent-price {
	  margin-top: 10px;
	  color: #4882BD;
	  font-size: 12px;
	  line-height: 14px;
	}


	&__price-block {
	  margin-left: unset;
	  margin-top: unset;

	  .b-transfer {

		&__select-container {
		  display: flex;
		}

		&__select-button, &__select-button--offer {
		  margin-bottom: unset;
		}

		&__select-button {
		  order: 2;
		  background-color: #099C9D;
		  color: #fff;
		  .border-radius(~'0px 0px 4px 0px');
		}

		&__select-button--offer {
		  background-color: #4882BD;
		  color: #fff;
		  min-width: unset;
		  .border-radius(~'0px 0px 0px 4px');

		  &.b-ticket-offer__cancel {
			background-color: #D70000;
			color: #fff;

			&:before {
			  border-color: #fff;
			  color: #fff;
			}
		  }

		  &:before {
			content: unset;
			left: unset;
			right: 18px;
			border-color: #fff;
			color: #fff;
		  }
		}
	  }
	}
  }
}

@media (min-width: 1220px) {

  .b-transfer {

	&__info {
	  &-photo {
		flex-flow: column;
		width: 120px;
		height: 90px;
	  }
	}
  }
}

@media (min-width: 1440px) {
  .b-transfer__select-button,
  .b-transfer__select-button--offer {
	width: 225px;

  }

  .b-transfer {


	&__wrapper {
	  padding: 33px 13px;
	}

	&__info {


	  &-section:not(:first-child) {
		margin-top: 25px;
		margin-left: 5px;
	  }

	  &-photo {
		flex-flow: column;
		width: 150px;
		height: 130px;
	  }

	  &-restrictions {
		&-icon {
		  width: 18px;
		  height: 18px;
		  margin-right: 15px;
		}

		&-text {
		  font-size: 12px;
		}
	  }

	  &__car-class {
		&-text {
		  font-size: 24px;
		}
	  }

	  &__model-name {
		&-text {
		  font-size: 11px;
		}
	  }
	}
  }
}

@media (min-width: 1920px) {
  .b-transfer {
	z-index: 5;


	&__wrapper {
	  font-size: 14px;
	  padding: 33px;
	  display: flex;
	  flex-flow: nowrap row;
	  position: relative;
	  overflow: hidden;
	}

	&__section {
	  display: flex;
	  flex: 1 1;
	}

	&__info {
	  flex-direction: row;
	  justify-content: flex-start;
	  flex: 1 1 80%;

	  &-section:not(:first-child) {
		margin-top: 20px;
		margin-left: 23px;
	  }

	  &-section {
		display: flex;
		flex: 1 1;
	  }

	  &-row {
		display: flex;
		align-items: center;
		flex-flow: nowrap row;
	  }

	  &-photo {
		width: 180px;
		height: 150px;

	  }

	  &-details {
		flex-basis: 30%;
	  }

	  &-restrictions {
		flex-basis: 20%;


		.b-transfer__info-capacity {

		  &-icon {
			.g-icon-transfer-passenger;
			width: 22px;
			height: 22px;
			margin-right: 15px;
		  }

		  &-text {
			font-size: 15px;
			line-height: 33px;
		  }
		}

		.b-transfer__info-capacity--exceeded {

		  &-icon {
			.g-icon-information;
			width: 22px;
			height: 22px;
			margin-right: 15px;
		  }

		  &-text {
			font-size: 11px;
			line-height: 13px;
		  }
		}
	  }

	}

	&__car-class {

	  &-text {
		font-size: 28px;
		line-height: 33px;
	  }
	}

	&__model-name {

	  &-text {
		font-size: 13px;
		line-height: 15px;
	  }
	}

	&__minimal-rent-price {

	}


	&__price-block {
	  flex-basis: 15%;
	  margin-top: 20px;
	  margin-left: 23px;

	  .b-transfer__select-button,
	  .b-transfer__select-button--offer {
		height: 36px;
		width: 100%;
		padding: 4px 0;
	  }

	  .b-transfer__select-button--offer {
		height: 36px;

		&::before {
		  content: "+";
		  left: 20px;
		  top: 7px;
		  z-index: 100;
		  width: 20px;
		  height: 20px;
		  font-size: 14px;
		  border: 2px solid #4882BD;
		}
	  }
	}
  }

  .b-transfer__select-button {
	width: 100%;
	font-size: 20px;
  }

  .b-transfer__select-button--offer {
	width: 260px;
	font-size: 18px;
	white-space: nowrap;
  }
}
