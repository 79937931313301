@import (reference) "~assets/css/imports";


.b-transfers__ride-details {

  	&__header {
	  	background: #4a5767;
	  	border-top-left-radius: 3px;
	  	-webkit-border-top-right-radius: 3px;
	  	-webkit-background-clip: padding-box;
	  	-moz-border-radius-topright: 3px;
	  	-moz-background-clip: padding;
	  	border-top-right-radius: 3px;
	  	background-clip: padding-box;
	  	display: flex;


	  	&-label {
		  	padding: 12px;
		  	font-weight: 500;
		  	font-size: 14px;
		  	color: #fff;
		  	text-transform: uppercase;
		}

	}

  	&__wrapper {
	  	background: #FFFFFF;
	  	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
	  	border-radius: 0 3px;
	  	margin-bottom: 20px;
	}

  	&__warning {
	  display: flex;
	  align-items: center;
	  height: 50px;
	  margin: 18px 0;
	  padding: 20px 20px;

	  	&-icon {
		  display: block;
		  width: 22px;
		  height: 22px;
		  margin-right: 10px;
		  .g-icon-transfer-warning;
		}

	  	&-text {
		  font-weight: 600;
		}
	}

  	&__trips {

	}

  .p-search__direction-date {
	width: 100%;
  }

  .p-search__direction-date-label {
	position: relative;
	z-index: 3;
	display: inline;
	width: min-content;
  }

  .p-search__direction-date-input {
	width: 100%;
	margin-top: -8px;
	margin-bottom: 5px;
	border: 1px solid #4A576766;
	border-radius: 3px;
	background: #fff;
	color: #4a5767;
	font-weight: 400;
	text-align: center;
  }

  .p-search__direction-date-calendar {
	display: inline;
  }
}

.b-transfer {

  &__trip-container {
	display: flex;
	width: 100%;
	flex-flow: row wrap;
	margin: 18px 0;
	padding: 0 10px;
  }

  &__trip-row {
	min-width: 178px;
	display: flex;
	align-items: center;
	padding-right: 18px;
	margin-bottom: 10px;
	width: 16.6667%;
  }

  &__passengers-amount {
	width: 32.6%;
	padding: 10px 0 10px 10px;
	min-width: 360px;
  }

  &__trip-address {
	background: #F0F0F0;
	font-weight: 500;
	font-size: 13px;
	line-height: 15px;
	color: #4A5767;
	padding: 18px 9px;
  }
}



@import "./style.mobile";
