@import (reference) '~assets/css/imports';
@import "../b-ticket-luggage";
@import "../b-branded-fares";
@import "../b-standart-fares";


@media @tablet {
  .b-avia-offer-preview {
	.b-avia-offer-preview__legs {
	  display: flex;
	  flex-direction: column;
	  width: 100%;

	  &.dn {
		display: none;
	  }

	  .b-avia-offer-preview__leg {
		box-shadow: 0 8px 18px -6px rgba(24, 39, 75, 0.12),
					0 12px 42px -4px rgba(24, 39, 75, 0.12);
		margin-bottom: 23px;

		&-wrapper {
		  background-color: #FFFFFF;
		  .border-radius(3px);
		  border: 1px solid #FAFAFA;
		  padding: 10px;
		  display: flex;
		  flex-direction: column;
		  width: 100%;

		  & > div:not(:last-child) {
			margin-bottom: 20px;
		  }
		}

		&-wrapper.hidden {
		  overflow: hidden;
		}

		&-services {
		  display: flex;
		  flex-direction: column;
		  background-color: #FAFAFA;
		  width: 100%;
		  padding: 10px 0;
		  font-size: 12px;

		  .b-avia-offer-preview-services__option-row {
			display: flex;
			gap: 10px;
			align-items: center;
			margin-bottom: 20px;

			& > span {
			  display: block;
			  width: 22px;
			  height: 22px;
			}

			.b-ticket-refund-exchange {
			  color: #4A5767;
			  text-align: left;
			}

			.b-ticket-refund-exchange__item {
			  margin: unset;
			}

			.b-ticket-refund-exchange__item-title {
			  margin-bottom: unset;
			  min-width: 80px;
			  text-align: left;
			  display: flex;
			}

			.b-ticket-refund-exchange__item-rule {
			  text-align: left;
			}
		  }

		  .b-avia-offer-preview__branded-fares {
			color: #4882BD;
			border-bottom: unset;
			font-size: 14px;
		  }
		}
	  }

	  .b-avia-offer-preview__leg-container {
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
	  }

	  .b-avia-offer-preview__leg-header {
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 20px;
	  }

	  .b-avia-offer-preview__leg-route {
		display: flex;
		flex-direction: column;
		width: 100%;

		& > div:not(:last-child) {
		  margin-bottom: 10px;
		}

		.b-avia-offer-preview__route-dates {
		  display: flex;
		  justify-content: space-between;
		  align-items: center;
		  width: 100%;

		  .b-avia-offer-preview__route-date-time {
			line-height: 21px;
			font-size: 18px;
			font-weight: 500;
			min-width: 120px;

			&:last-of-type {
			  text-align: end;
			}
		  }

		  .b-avia-offer-preview__route-duration {
			color: #A4ABB3;
			font-size: 12px;
		  }
		}

		.b-avia-offer-preview__route-line {
		  height: 2px;
		  background-color: #099c9d;
		  width: 100%;
		  position: relative;

		  &:after,
		  &:before {
			content: " ";
			width: 6px;
			height: 6px;
			position: absolute;
			background-color: #099c9d;
			.border-radius(50%);
			top: -2px;
		  }

		  &:after {
			right: 0;
		  }

		  &:before {
			left: 0;
		  }
		}

		.b-avia-offer-preview__route-info {
		  font-size: 12px;
		  display: flex;
		  justify-content: space-between;
		  width: 100%;

		  .b-avia-offer-preview__route-location {
			display: flex;
			flex-direction: column;
			min-width: 120px;

			& > span:first-child {
			  font-weight: 500;
			}

			& > span:not(:first-child) {
			  color: #A4ABB3;
			}

			&:last-of-type {
			  text-align: end;
			}
		  }
		}

		.b-avia-offer-preview__route-stops {
		  color: #099c9d;
		}

	  }

	  .b-avia-offer-preview__leg-info {
		display: flex;
		flex-direction: row;
		width: 100%;
		align-items: center;
		font-size: 12px;

		.b-avia-offer-preview__company-icon-wrapper {
		  width: 70px;
		  height: 50px;
		  margin-right: 12px;
		  display: flex;
		  overflow: hidden;
		  .border-radius(50%);


		  .b-avia-offer-preview__company-icon {
			background-repeat: no-repeat;
			background-position: center;
			background-size: 80%;
			width: 70px;
			height: 50px;
		  }
		}

		.b-avia-offer-preview__carrier-info {
		  width: 100%;
		  display: flex;
		  flex-direction: column;

		  & > span {
			line-height: 14px;
		  }
		}

		.b-avia-offer-preview__company-name {
		  font-weight: 500;
		}

		.b-avia-offer-preview__carrier-name {

		}

		.b-avia-offer-preview__flight-country {
		  color: #D70000;
		}

		.b-avia-offer-preview__flight-number {
		  font-size: 14px;
		}

	  }
	}

	.b-avia-offer-preview__wrapper {
	  width: 100%;
	  height: 100%;
	  display: flex;
	  flex-direction: column;
	  padding: 0 12px;
	}

	.b-avia-offer-preview__price-details {
	  padding: 10px;
	  margin-bottom: 20px;
	  background-color: #f0f8ff;
	}

	.b-avia-offer-preview__leg-segments-duration-stop {
	  display: flex;
	  align-items: flex-start;
	  gap: 12px;
	  padding: 7px;
	  background-color: #FCEFE0;
	  border-radius: 4px;
	  margin-bottom: 20px;

	  & > i {
		width: 16px;
		height: 16px;
	  }

	  &.b-stop-short {
		& > i {
		  .g-icon-attention-green;
		}
		color: #099c9d;
		background-color: rgba(9,156,157,.1);
	  }
	  &.b-stop-medium {
		& > i {
		  .g-icon-attention-orange;
		}
		color: #ff8d2e;
		background-color:rgba(255,141,46,.1);
	  }
	  &.b-stop-long {
		& > i {
		  .g-icon-attention-red;
		}
		color: #d70000;
		background-color: rgba(215,0,0,.1);
	  }
	}


	.b-ticket-luggage-val-block {
	  padding: unset;

	  &.green {
		color: #099C9D;
	  }
	}

	.b-flight-ticket__branded-fares--mobile,
	.b-flight-ticket__standard-fares--mobile {
	  display: none;

	  &.open {
		display: block;
		position: absolute;
		width: 100%;
		background-color: #FFFFFF;
		overflow: hidden;
		height: 100vh;
		top: 0;
		left: 0;
		z-index: 100;
	  }

	  .b-fares__header {
		height: 50px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
	  }

	  .b-fares__title {
		letter-spacing: 0.02em;
		font-weight: 500;
		font-size: 18px;
		line-height: 24px;
		color: #4A5767;
	  }

	  .b-fares__close {
		position: absolute;
		width: 20px;
		height: 20px;
		top: 50%;
		right: 20px;
		transform: translateY(-50%);
		z-index: 9999;
		.g-icon-b-cancel;
	  }

	  .b-fares__brand-name-container {
		width: 100%;
		padding: 0 20px;
		margin-bottom: 10px;
		height: 30px;
		display: flex;
		align-items: center;

		& > div {
		  width: 130px;
		  height: 100%;
		  padding: 5px;
		  background: no-repeat center;
		  background-size: 80% 80%;
		  .border-radius(3px);
		}
	  }

	  .b-fares__fares-container {
		height: 100vh;
		display: inline-flex;
		overflow: hidden;
		transform: translateX(0);
		transition: transform .3s ease-in-out;
	  }

	  .slider-overflow-arrow {
		display: none;
	  }

	  .b-fares__slider-indicators-container {
		max-width: 60%;
		height: 50%;
		display: flex;
		align-self: center;
		align-items: center;
		justify-content: flex-start;
		position: relative;
		overflow-x: scroll;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
		  display: none;
		}

		.slider-indicator {
		  width: 14px;
		  height: 14px;
		  position: relative;
		  padding: 8px;
		  .border-radius(50%);
		  border: 1px solid #099C9D;
		  transition: all .3s ease-in-out;
		}

		.slider-indicator.active {
		  background-color: #099C9D;
		}
		.slider-indicator:not(:last-of-type) {
		  margin-right: 14px;
		}
	  }

    .slider-wrapper {
      display: flex;
      justify-content: center;
      height: 64px;
      gap: 16px;
      align-items: center;
    }

	  .b-fares__footer.overflowed .slider-overflow-arrow {
		display: flex;
		width: 20px;
		height: 20px;
		/*position: absolute;*/
		background: no-repeat center;
		.g-icon-arrow-left-blue;
	  }

	  [data-prev-card] {
      
		left: 11%;
		/*top: 12%;*/
	  }

	  [data-next-card] {
		right: 11%;
		/*top: 12%;*/
		transform: rotate(180deg);
	  }


	  .b-fares__footer {
		height: 100px;
		width: 100%;
		background-color: #ffffff;
		position: fixed;
		bottom: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		.box-shadow(~'0px -8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12)');

		.b-fares__footer-content {
		  display: flex;
		  padding: 0 20px;
		  justify-content: space-around;
		  width: 100%;
		  align-items: center;
		  height: 80px;
		}

		.b-fares__price-section {
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  flex-direction: column;
		  gap: 5px;
		  width: 50%;
		}

		.b-fares__select-tariff {
		  background-color: #099C9D;

		  &:after,
		  &:before {
			content: unset;
		  }
		}

		.b-fares__select-tariff--offer,
		.b-fares__select-tariff {
		  position: relative;
		  color: #FFFFFF;
		  .border-radius(4px) !important;
		  padding: 9px 10px;
		  min-width: 170px;
		  height: 38px;
		  width: 50%;
		  text-align: center;

		  &:before {
			content: "+";
			position: absolute;
			right: 10%;
			top: 50%;
			transform: translateY(-50%);
			display: flex;
			justify-content: center;
			align-items: center;
			width: 20px;
			height: 20px;
			font-size: 14px;
			border: 2px solid #FFFFFF;
			border-radius: 50%;
			transition: transform .3s;
		  }

		  &.b-ticket-offer__cancel {
			background-color: #D70000 !important;
		  }
		}

		.b-fares__select-tariff:before {
		  content: unset;
		}

		.b-fares__select-tariff--offer {
		  background-color: #4882BD;
		}
	  }
	}

    &__available-seats {
        text-align: center;
        margin-bottom: 15px;
    }
  }

  .b-fares-mobile {
	width: 100vw;
	height: calc(~'100vh - 180px');
	overflow-y: auto;
	border: 1px solid #EDEEF0;

	&.selected .b-fares-mobile__container {
	  border-color: #099C9D;
	}


	&__row {
	  display: flex;
	  gap: 10px;

	  .b-ticket-refund-exchange {
		color: #4A5767;
		text-align: unset;
	  }

	  .b-ticket-refund-exchange__item-title:after {
		content: unset;
	  }
	}

	&__container {
	  padding: 30px;
	  background-color: #FAFAFA;
	  transition: all .3s ease-in-out;
	  user-select: none;
	  display: flex;
	  flex-direction: column;
	  width: 90%;
	  margin: 20px auto 0;
	  .border-radius(15px);
	  .box-shadow(0px 10px 20px #a3a3a3);
	  border: 1px solid #EDEEF0;
	  min-height: 50%;
	  gap: 12px;
	}

	&__price {
	  color: #4A5767;
	  font-weight: 500;
	  font-size: 16px;
	  line-height: 19px;
	}

	&__tariff {
	  font-size: 20px;
	  font-weight: 600;
	  line-height: 16px;
	  color: #0097D9FF;
	}

  &__fare-basis-wrapper {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

	&__fare-basis {
	  font-size: 14px;
	  line-height: 16px;
	  text-decoration-line: underline;
	  color: #099C9D;
	}

	&__seats {
	  font-size: 14px;
	  line-height: 16px;
	}

	&__services {
	  font-size: 12px;
	  line-height: 14px;

	  & > div {
		margin-bottom: 10px;
	  }
	}

    &__available-seats {
        text-align: center;
        border-bottom: 1px solid #EDEEF0;
    }

	&__refund-exchange {
	  background-color: #d0e8fd;
	  display: flex;
	  gap: 10px;
	}

	.b-ticket-refund-exchange__item-title {
	  margin: 0;
	  text-decoration: underline;
	}

	.b-ticket-refund-exchange__item {
	  margin-left: 0;
	}

	&__service-icon {
	  display: block;
	  width: 22px;
	  height: 22px;
	}

	&__travel-policy {
	  margin-top: 20px;
	  display: flex;
	  flex-direction: column;
	  gap: 10px;
	}
  }

}
