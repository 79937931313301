@media (min-width: 769px) {
	.l-layout__content-side {
		.sidebar__toggle-block.collapse {
			display: block;
		}
	}
}

@media @desktop-1280-width {

}

@media @tablet {
	.l-layout {
		font-size: 16px;
	}

  	.l-layout__wrapper {
		padding: unset;
  	}

	.l-layout__header {
		.b-header__info-title {
			display: none;
		}
		.b-header__info-wrapper {
			.b-header__info-account {
				display: none;
				&-text {
					font-size: 18px;
					line-height: 18px;
				}
			}
			.b-header__info-language {
				display: none;
			}
		  	.b-header__info-currency {
			  display: none;
			}
		}
	}

	.l-layout__content {
		padding: 0;
		flex-direction: column;
		.l-layout__content-side {

			.sidebar__toggler {
				display: block;
			}

			& > .b-cabinet-menu {
				display: none;
			}
		}
		.l-layout__content-main {
			margin:0;
		}

	}

	.l-layout:not(.show-menu) {
	  .l-layout__content-side {
		display: none;
		width: 100%;
		position: absolute;
		top: 0 !important;
		left: 0;
		box-sizing: border-box;
		z-index: 9990;
		flex-basis: 0;

		& .b-menu--mobile {
		  display: none !important;
		}
	  }

	  &.show-filters,
	  &.show-offer-preview {
		.l-layout__content-side {
		  display: block;
		}
		.l-layout__content-main {
		  position: absolute;
		  top: -5000000px;
		}
	  }

	  &.show-filters {
		.l-layout__content-side > :not(.l-block__back) {
		  display: none;
		}

		.b-cabinet__filters {
		  display: flex;
		  flex-direction: column;
		  background: #fff;
		  min-height: 100vh;
		  z-index: 100;
		  gap: 20px;
		  padding: 10px;

		  .b-block-container__element-dates .p-search__direction-date-input {
			max-width: unset;
		  }

		  .p-search__direction-date-calendar {
			pointer-events: unset !important;
		  }

		  .b-block-container__element {
			display: flex;
			align-items: flex-end;

			.b-datepicker__icon {
			  background-image: url("~assets/img/icon-calendar-black-1.svg");
			  width: 38px;
			  height: 38px;
			}

			.p-search__direction-date-calendar {
			  background-color: unset;
			  width: 38px;
			  height: 38px;
			}
		  }
		  .p-search__submit-button {
			pointer-events: none;
		  }
		}
	  }

	  &.show-offer-preview {

		.l-layout__content-side > :not(.l-offer-preview-container) {
		  display: none;
		}

		.l-offer-preview-container {
		  display: block;
		  width: 100%;
		  height: 100%;
		}
	  }
	}

	.l-layout.show-menu {
		background:#4A5767;
		.l-layout__content-side {
			position: relative;
			top: 0;
			z-index: 100;
			width: 100%;
			flex: initial;

			> .b-cabinet-menu {
				display: initial;
			}
			> :not(.b-cabinet-menu) {
				display: none;
			}
		}

	  	.l-layout__wrapper {
		  	padding: unset;
		}

		.l-layout__content-main {
		  	position: absolute;
		  	top: -500000px;
		}

	  	.l-layout__top {
		  display: none;
		}

	  	.l-layout__top-block {
		  display: none;
		}

	  	.l-layout__footer {
		  display: none;
		}

		.b-header__info-account {
			display: block;
			font-size: 18px;
			line-height: 18px;
		}

		.b-header__info-currency {
			display: none;
		}

	  	.b-cabinet__info-currency, .b-cabinet__additional-client {
		  .b-header__info-currency {
			display: block;

			&-text {
			  display: block;
			  font-size: 14px;
			  line-height: 14px;
			  padding: 0 !important;
			}
		  }
		}

	}

  	.l-layout.show-search-form {
	.b-train,
	.b-avia,
	.b-hotels,
	.b-transfers {

	  &-search {
		position: fixed;
		width: 100%;
		left: 0;
		top: 0;
		z-index: 9999;
		background-color: #4A5767;

		&__row {
		  position: relative;
		  z-index: 2;
		  margin-top: 50px;
		  display: flex;
		  flex-flow: wrap column;
		}

		&__dates-container {
		  min-width: unset;
		  margin-left: unset !important;
		}

		.p-search__direction-date-calendar {
		  display: none;
		}

		&__button-container {
		  margin-left: unset;
		  height: 38px;
		  margin-top: 20px;
		}

		&__direction {
		  margin-bottom: 16px;
		  margin-right: unset;
		}

		&-swap {
		  position: absolute;
		  top: 23%;
		  left: 48%;
		}

		&-swap__img {
		  width: 34px;
		  height: 34px;
		  background-size: 100%;
		  position: absolute;
		}

		&-form-mobile__background {
		  display: block;
		  background-color: #4A5767;
		  z-index: 0;
		  opacity: .8;
		  width: 100%;
		  bottom: 0;
		  top: 0;
		  left: 0;
		  position: fixed;
		}
	  }
	  &-search-mobile {
		display: none;
	  }
	  &-search-form-mobile__close {
		.g-icon-w-cancel;
		width: 20px;
		height: 20px;
		top: 20px;
		right: 25px;
		position: absolute;
		z-index: 1;
	  }
	}

	.p-search__direction-date {
	  pointer-events: none;

	  &.b-datepicker__remove {
		pointer-events: all;
	  }
	}
  }
}

@media (min-width: 769px) and (max-width: 1279px) {
  [data-route-id="aviaTickets"], [data-route-id="hotelsOffers"], [data-route-id="trainTickets"], [data-route-id="transfers"] {
	.show-filters-button {
	  display: flex;
	}
  }
  .l-layout__content-side {
	position: absolute;
	left: -100%;
  }

  .l-layout__content-main {
	margin-left: unset !important;
  }

  .l-layout.show-menu, .l-layout.show-filters {

	.l-layout__content-side {
	  position: absolute;
	  top: 62px;
	  z-index: 1000;
	}
  }

  .l-layout.show-menu {
	.l-layout__content-side {
	  left: unset;
	  top: 62px;
	  right: 12px;
	  animation: show-menu--slide-down linear .5s;

	  & > :not(.b-cabinet-menu) {
		display: none;
	  }
	  .b-cabinet {
		box-shadow: 0 0 20px black;
	  }
	}
  }

  .l-layout.show-filters {
	.l-layout__content-side {
	  left: 12px;
	  animation: slide-from-left linear .5s;

	 & > :not(.l-block__back) {
	   display: none;
	 }
	  .b-matrix-controls {
		display: none;
	  }

	  .l-block__back {
		box-shadow: 0 0 20px black;
	  }
	}
  }

  @keyframes slide-from-left {
	0% {
	  left: -100%;
	}

	100% {
	  left: 12px;
	}
  }

  @keyframes show-menu--slide-down {
	0% {
	  left: unset;
	  top: -100%;
	}

	100% {
	  top: 62px;
	}
  }

}
