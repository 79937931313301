@import (reference) "~assets/css/imports";

.b-hotel {
	&-room {
		margin-bottom: 10px;

		.b-hotel-room__additional-services .b-hotel-room__info-wrapper {
			display: none;
		}

	  &__offer-anti-covid {
		background: url("../../../../../assets/img/icons/icon-antiCovid_30_30.svg") no-repeat center;
	  }

	  &__offer-passport {
		background: url("../../../../../assets/img/icons/icon-hotel-pass_30_30.svg") no-repeat center;
	  }

	  &__offer-information {
		background: url("../../../../../assets/img/icons/icon-hotel-information_30_30.svg") no-repeat center;
	  }

	  &__offer-checked {
		background: url("../../../../../assets/img/icons/icon-hotel-checked_25_25.svg") no-repeat center;
		height: 25px;
		width: 25px;
		margin: 5px 5px ;
		text-indent: 30px;
		min-width: 25px;
	  }
	}

	&-room__toggle-container-essantial {
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		padding: 10px 10px 10px 0;
		margin-left: 12px;
	  	display: flex;
	  	align-items: center;
	  	gap: 10px;

	  & > span {
		width: 30px;
		height: 30px;
	  }

	  & > span:first-of-type {
		margin-left: 0;
	  }
	}

	&-room__toggle-container-penalty {
		padding: 10px 10px 0 12px;

		& + .b-hotel-room__toggle-container-inner {
			padding-top: 5px;
		}
	}

	&-room__info-wrapper {
		display: flex;
		flex-flow: row nowrap;
		padding: 24px 0;
		align-items: center;

		.b-hotel-room__info-item {
			display: flex;
			flex-flow: column;
			font-size: 16px;
			border-left: 1px solid rgba(0, 0, 0, .1);
			white-space: nowrap;
			padding-left: 20px;
			margin-right: 10%;
		}

		.b-hotel-room__info-value {
			margin-top: 5px;
		}
	}

	&-room__header {
		color: #fff;
		font-size: 16px;
		background: #4A5767;
		border-radius: 3px 3px 0 0;
		padding: 12px;
		position: relative;
		z-index: 1;

		&-title {
			color: #fff;
			font-size: 18px;
			text-transform: uppercase;
		}

		&-wrapper {
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
			position: relative;
			margin-left: 7px;

			&:after {
				content: '';
				position: absolute;
				bottom: -7px;
				left: 43px;
				border-top: 10px solid #E50000;
				border-right: 30px solid transparent;
			}

			&:before {
				content: '';
				position: absolute;
				top: 0;
				bottom: -7px;
				left: -7px;
				background: #E50000;
				border-radius: 3px 0 0;
				width: 50px;
			}
		}
	}

	&-room__content {
		padding: 30px 0 5px 18px;
		background: #fff;
		box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
		border-radius: 0 0 3px 3px;
		margin-bottom: 10px;
	}

	&-room__content-header {
		display: flex;
		flex-flow: row nowrap;
		margin-bottom: 20px;
	}

	&-room__content-leftbar {
		font-size: 16px;
	}

	&-room__content-notification {
		margin-top: 7px;
	}

	&-room__content-rightbar {
		margin-left: auto;
		margin-right: 18px;
	}

	&-room__name {
		font-weight: 500;
	}

	&-room__important {
		color: #E50000;
	}

	&-room__services-list {
		display: flex;
		flex-direction: row;
		margin: 0 0 -12px -12px;

		.hotel-service__img {
			min-width: 24px;
			min-height: 24px;
			margin: 0 0 12px 12px;
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	&-room__content-description {
		display: flex;
		flex-flow: column;
	}

	&-room__toggle-caption-wrapper {
		position: relative;
		margin-bottom: 16px;

		&:before {
			content: '';
			height: 0;
			width: 100%;
			display: block;
			border-top: 1px dashed rgba(0, 0, 0, 0.1);
			position: absolute;
			top: 50%;
		}
	}

	&-room__toggle-caption {
		background: #fff;
		font-size: 16px;
		display: inline-block;
		padding-left: 30px;

		&--type-alert {
			background: #fff url('~assets/img/icons/icon-notification-alert.svg') no-repeat 0 center;
		}
	}

	&-room__toggle-container {
		background: #fff;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
		border-radius: 0 0 3px 3px;
		margin-right: 18px;
		margin-bottom: 24px;
		line-height: 1.5;
	}

	&-room__toggle-container-inner {
		padding: 14px 12px;
	}

	&-travellers__header {
		color: #fff;
		font-size: 16px;
		background: #4A5767;
		border-radius: 3px 3px 0 0;
		padding: 12px;
		position: relative;
		z-index: 1;

		&-title {
			color: #fff;
			font-size: 18px;
			text-transform: uppercase;
		}

		&-wrapper {
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
			position: relative;
		}
	}

	&-travellers__wrapper {
		background: #FFFFFF;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
		border-radius: 0 0 3px 3px;
	}

  	&-room__offer-remarks {
	  width: 100%;

	  &-header {
		background: #F0F0F0;
		width: 100%;
		padding: 5px 18px 5px 18px;
		font-size: 16px;
		font-weight: 500;
		display: flex;
		gap: 10px;
		align-items: center;

		& > .g-icon-attention {
		  width: 16px;
		  height: 16px;
		}
	  }

	  .b-remarks {
		max-width: 50%;
		padding: 18px;
	  }
	}
}

@import "./style.mobile";
