@import (reference) "~assets/css/imports";

.b-cabinet-docodoca {
	&__footer {
		padding: 13px;
	}
}
.b-cabinet-docodoca .b-visa-info__info {
	margin-top: 0;
}

.b-cabinet-docodoca .b-visa-info__header {
	height: 45px;
	font-size: @base-H1-size;
	text-transform: uppercase;
	color: @field2-H2-color;
	line-height: 45px;
	padding: 0 15px;
	position: relative;
}

.b-visa-info__passenger-title {
	.b-visa-passenger__date {
		font-size: @base-font-size;
		color: @field1-font-color;
		float: right;
	}
}
