@import (reference) "~assets/css/imports";

.b-textarea {
	display: block;
	width: 100%;
	position: relative;
	padding-bottom: 5px;
}

.b-textarea__label {
	display: block;
	font-size: @base-font-size;
	color: @field1-H1-color;
	margin-bottom: 2px;
}

.b-textarea__value {
	border: 1px solid @field1-object-color;
	font-size: @base-H1-size;
	color: @field1-active-color;
	padding: 10px;
	height: 40px;
	width: 100%;
	.rounded;
	position: relative;
	z-index: 2;
	min-height: 70px;

	&:disabled, &[disabled], &[readonly] {
		background-color: rgba(74, 87, 103, 0.1);
	}
}

.b-textarea.validation-error {

	.b-textarea__error {
		display: inline-block;
	}

	.b-textarea__value {
		border-color: @field1-warning-color;
	}

}

.b-textarea .validations-errors__container {
	opacity: 0;
	overflow: hidden;
	height: 0;
	.transition-fast;
}

.b-textarea__value:focus ~ .validations-errors__container {
	height: auto;
	opacity: 1;
}
