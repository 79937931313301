@import (reference) "~assets/css/imports";

.b-emails-collection {
	margin-right: 100px;
}

.b-emails-collection__add {
	display: inline-flex;
	justify-content: flex-end;
	align-items: center;
	cursor: pointer;
	float: right;
}

.b-emails-collection__add-icon {
	.collection-add-icon;
}

.b-emails-collection__content{
  	display: inline-block;
  	font-size: 20px;
}
