@import (reference) "~assets/css/imports";

@primaryColor: #099C9D;
@primaryRedColor: #D70000;

.b-cabinet-order-auth-steps {
  width: 782px;
  height: fit-content;
  max-height: 700px;

  &__modal {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 9999;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    // min-height: 100vh;
    height: 100vh;
  }

  &__modal-content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 32px;
    background: #fff;
  }

  &__modal-footer-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    width: 100%;

    .b-cabinet-order-auth-steps__modal-footer-btn {
      text-transform: none;
      font-weight: 500;
      font-size: 14px;
      border: 1px solid #A9A9A9;
      border-left: 0;
      border-right: 0;
      border-radius: 0;
      padding: 8px 10px;
      overflow: visible;
      position: relative;
      min-width: 100px;
      cursor: pointer;
      text-align: center;

      & > span {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        z-index: 2;
      }
    }

    .b-cabinet-order-auth-steps__modal-footer-btn--close {
      color: #fff;
      background-color: #B60A0A;
      border-color: #B60A0A;
    }

    .b-cabinet-order-auth-steps__modal-footer-btn:before,
    .b-cabinet-order-auth-steps__modal-footer-btn:after {
      position: absolute;
      content: '';
      top: -1px;
      bottom: -1px;
      z-index: 1;
      border-width: 2px;
      border-style: solid;
      width: 60%;
    }

    .b-cabinet-order-auth-steps__modal-footer-btn:before {
      right: 40%;
      border-width: 1px 0 1px 2px;
      transform-origin: left bottom;
      transform: skew(60deg);
    }

    .b-cabinet-order-auth-steps__modal-footer-btn:after {
      left: 40%;
      border-width: 1px 2px 1px 0;
      transform-origin: right bottom;
      transform: skew(-60deg);
    }

    .b-cabinet-order-auth-steps__modal-footer-btn--close:before,
    .b-cabinet-order-auth-steps__modal-footer-btn--close:after {
      background: #B60A0A;
      border-color: #B60A0A;
    }

  }

  &__modal-header {
    flex-shrink: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    line-height: normal;
    padding-bottom: 12px;
    border-bottom: 2px solid #D9D9D9;

    .b-cabinet-order-auth-steps__header-content {
      flex-grow: 1;
      color: #4A5767;
      overflow: hidden;
    }

    .b-cabinet-order-auth-steps__header-close-btn {
      flex-shrink: 0;
      cursor: pointer;
      background: url("~assets/img/icon-cancel-b.svg") center no-repeat;
      display: inline-block;
      background-size: 16px;
      width: 32px;
      height: 32px;
      border-radius: 100%;
      border: 2px solid #7A7A7A;
    }

    .b-cabinet-order-auth-steps__header-title {
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
      margin: 0;
    }

    .b-cabinet-order-auth-steps__header-row {
      margin-top: 8px;
      display: flex;
      gap: 16px;
    }

    .b-cabinet-order-auth-steps__header-row--al-i-c {
      align-items: center;
    }

    .b-cabinet-order-auth-steps__header-row-label {
      font-size: 14px;
      font-weight: 400;
    }

    .b-cabinet-order-auth-steps__header-row-value {
      font-size: 16px;
      font-weight: 500;
      display: inline-flex;
      flex-direction: column;
      gap: 8px;
      overflow: hidden;
      text-overflow: ellipsis;

      & > span {
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
    }

  }

  &__modal-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    // max-height: 540px;
    height: 540px;
    padding-top: 12px;
    overflow-y: hidden;

    .b-cabinet-order-auth-steps__content-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 100%;
      box-sizing: border-box;
      position: relative;
    }

    .b-cabinet-order-auth-steps__content-scroll-wrapper {
      overflow-y: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
    }

    .b-cabinet-order-auth-steps__steps-list {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .b-cabinet-order-auth-steps__steps-list-item {
      padding: 0;
    }
  }

}

.b-cabinet-order-auth-step {

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    gap: 7px;

    .b-cabinet-order-auth-step__header-step-line-container {
      display: flex;
      justify-content: center;
      align-self: flex-start;
    }

    .b-cabinet-order-auth-step__header-step-line-tail {
      position: absolute;
      top: 32px;
      left: 14px;
      width: 3px;
      height: calc(100% + 20px);
      border: 1px solid;
      transition: height .05s linear;
    }

    .b-cabinet-order-auth-step__header-status-icon,
    .b-cabinet-order-auth-step__header-toggler {
      width: 32px;
      height: 32px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 32px;
    }

    .b-cabinet-order-auth-step__header-status-icon {
      z-index: 2;
    }

    .b-cabinet-order-auth-step__header-content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
    }

    .b-cabinet-order-auth-step__header-title {
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 500;
    }

    .b-cabinet-order-auth-step__header-right-col {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
    }

    .b-cabinet-order-auth-step__header-additional-text {
      font-size: 14px;
      font-weight: 500;
      flex-grow: 1;
    }

    .b-cabinet-order-auth-step__header-toggler {
      flex-shrink: 0;
      transition: background-image .1s linear;
      background-image: url("~assets/img/icons/travel_policy_icons/collapse_step_icon/down.svg");
    }

  }

  &__header--collapsible {
    cursor: pointer;
  }

  &__content {
    height: 100%;
    opacity: 1;
    transition: height .1s linear, opacity .2s linear;
  }

  &__units-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 24px 0 0 0;
    gap: 24px;
  }

  &__units-list-item {
    padding: 0;
  }

}

.b-cabinet-order-auth-step--collapsed {
  .b-cabinet-order-auth-step__header-step-line-tail {
    height: 100%;
  }

  .b-cabinet-order-auth-step__content {
    height: 0;
    opacity: 0;
  }
}

.b-cabinet-order-auth-step--collapsed .b-cabinet-order-auth-step__header-toggler {
  background-image: url("~assets/img/icons/travel_policy_icons/collapse_step_icon/up.svg");
}

.b-cabinet-order-auth-step--blocked .b-cabinet-order-auth-step__header-status-icon {
  background-image: url("~assets/img/icons/travel_policy_icons/step_status_icon/blocked.svg");
}

.b-cabinet-order-auth-step--wait .b-cabinet-order-auth-step__header-status-icon {
  background-image: url("~assets/img/icons/travel_policy_icons/step_status_icon/wait.svg");
}

.b-cabinet-order-auth-step--success .b-cabinet-order-auth-step__header-status-icon {
  background-image: url("~assets/img/icons/travel_policy_icons/step_status_icon/success.svg");
}

.b-cabinet-order-auth-step--error .b-cabinet-order-auth-step__header-status-icon {
  background-image: url("~assets/img/icons/travel_policy_icons/step_status_icon/error.svg");
}

.b-cabinet-order-auth-step-unit {
  display: flex;
  gap: 8px;
  width: 100%;

  &__step-line-container {
    width: 31px;
    padding-top: 20px;
    flex-shrink: 0;
    position: relative;
    display: flex;
    justify-content: center;
  }

  &__step-line-point {
    width: 25px;
    height: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 25px;
    z-index: 2;
  }

  &__step-line-tail {
    position: absolute;
    top: 41px;
    width: 3px;
    height: 100%;
    border: 1px solid;
  }

  &__step-line-connect {
    position: absolute;
    top: 32px;
    right: -6px;
    width: 10px;
    height: 1px;
    background-color: #D9D9D9;
  }

  &__content-container {
    flex-grow: 1;
    padding: 24px;
    border: 1px solid #DBDBDB;
  }

  &__content-container > * + * {
    margin-top: 16px;
  }

  &__status-chip {
    display: inline-flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
    background-color: #fff;
    border: 1px solid #A4ABB3;
  }

  &__status-chip-icon {
    width: 17px;
    height: 17px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 17px;
  }

  &__status-chip-date {
    color: #A4ABB3;
  }

  &__rule-descr-container {
    border-bottom: 1px solid #bbb;
  }

  &__rule-descr-title,
  &__rule-descr {
    font-size: 14px;
    font-weight: 500;
  }

  &__rule-descr-title {
    color: #A4ABB3;
  }

  &__rule-descr {
    margin: 0;
    padding: 8px 0;
    color: #4A5767;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  &__auth-persons-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__auth-persons-list-item {
    padding: 0;
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__auth-person-name {
    font-size: 16px;
    font-weight: 500;
    color: #4A5767;
  }

}

.b-cabinet-order-auth-step--blocked {
  .b-cabinet-order-auth-step__header-step-line-tail,
  .b-cabinet-order-auth-step-unit__step-line-tail {
    border-color: #AFAFAF;
    background-color: #AFAFAF;
  }

}

.b-cabinet-order-auth-step--wait {
  .b-cabinet-order-auth-step__header-step-line-tail,
  .b-cabinet-order-auth-step-unit__step-line-tail {
    border-left-color: #EDEEF0;
    border-right-color: #EDEEF0;
    border-top-color: #fff;
    border-bottom-color: #fff;
    background-color: #fff;
  }
}

.b-cabinet-order-auth-step--success {
  .b-cabinet-order-auth-step__header-step-line-tail,
  .b-cabinet-order-auth-step-unit__step-line-tail {
    border-color: @primaryColor;
    background-color: @primaryColor;
  }
}

.b-cabinet-order-auth-step--error {
  .b-cabinet-order-auth-step__header-step-line-tail,
  .b-cabinet-order-auth-step-unit__step-line-tail {
    border-color: @primaryRedColor;
    background-color: @primaryRedColor;
  }
}

.b-cabinet-order-auth-step-unit--blocked {
  .b-cabinet-order-auth-step-unit__step-line-point {
    background-image: url("~assets/img/icons/travel_policy_icons/substep_status_icon/blocked.svg");
  }

  .b-cabinet-order-auth-step-unit__content-container {
    background-color: #f9f9f9;
  }

  .b-cabinet-order-auth-step-unit__status-chip {
    color: #4A5767;
  }

  .b-cabinet-order-auth-step-unit__status-chip-icon {
    display: none;
  }
}

.b-cabinet-order-auth-step-unit--wait {
  .b-cabinet-order-auth-step-unit__step-line-point {
    background-image: url("~assets/img/icons/travel_policy_icons/substep_status_icon/wait.svg");
  }

  .b-cabinet-order-auth-step-unit__content-container {
    background-color: #fff;
  }

  .b-cabinet-order-auth-step-unit__status-chip {
    color: #4A5767;
  }

  .b-cabinet-order-auth-step-unit__status-chip-icon {
    display: none;
  }
}

.b-cabinet-order-auth-step-unit--success {
  .b-cabinet-order-auth-step-unit__step-line-point {
    background-image: url("~assets/img/icons/travel_policy_icons/substep_status_icon/success.svg");
  }

  .b-cabinet-order-auth-step-unit__content-container {
    background-color: #e8e8e8;
  }

  .b-cabinet-order-auth-step-unit__status-chip {
    color: @primaryColor;
  }

  .b-cabinet-order-auth-step-unit__status-chip-icon {
    background-image: url("~assets/img/icons/travel_policy_icons/status_chip_icon/check.svg");
  }

  .b-cabinet-order-auth-step-unit__auth-person-name--is-authorizer {
    color: @primaryColor;
  }
}

.b-cabinet-order-auth-step-unit--error {
  .b-cabinet-order-auth-step-unit__step-line-point {
    background-image: url("~assets/img/icons/travel_policy_icons/substep_status_icon/error.svg");
  }

  .b-cabinet-order-auth-step-unit__content-container {
    background-color: #e8e8e8;
  }

  .b-cabinet-order-auth-step-unit__status-chip {
    color: #4A5767;
  }

  .b-cabinet-order-auth-step-unit__status-chip-icon {
    background-image: url("~assets/img/icons/travel_policy_icons/status_chip_icon/cross.svg");
  }

  .b-cabinet-order-auth-step-unit__auth-person-name--is-authorizer {
    color: @primaryRedColor;
  }

}

@import "./style.mobile";
