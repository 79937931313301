@import (reference) "~assets/css/imports";

.l-content-table-title-transfers {
  color: rgba(74, 87, 103, 0.8);
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  margin-left: auto;
}

.b-transfers {


  &__search-no-results {
    margin: 0;
    padding: 5px 10px;

    &.dn {
      display: none;
    }
  }

  &__exceeded-capacity {
    opacity: .8;
    margin-top: 60px;

    &.dn {
      display: none;
    }
  }

  &__search {
    position: fixed;
    background: #fff;
    border: 1px solid #4882BD;
    top: 50px;
    right: 15px;
    text-align: center;
    z-index: 19999;
    padding: 15px 40px;
    border-radius: 4px;


    &.dn {
      display: none;
    }

  }

  &__search-all {
    margin-top: 5px;
    border-bottom: 1px dotted;
    cursor: pointer;
    font-weight: bold;

    &.dn {
      display: none;
    }
  }

  &__search-close {
    background: url("~assets/img/icon-clear-dark.svg") no-repeat center;
    background-size: 12px;
    position: absolute;

    cursor: pointer;

    right: 5px;
    top: 5px;
    height: 12px;
    width: 12px;
  }

  &__results {
    margin-bottom: 15px;
  }

  .b-transfer + .b-transfer {
    margin-top: 20px;
  }
}

.b-tickets-container-transfers {

}

.options-list {
  padding-left: 20px;
  margin: 5px 0;

  & > li {
    padding: 0;
  }
}
