@media @tablet {
  .l-block__back {
	margin: 0;
  }
  .b-filters {
	display: flex;
	flex-direction: column;
	position: relative;
	background-color: #fff;
	color: #4a5767;
  }

  .b-filters__leg {
	background-color: unset;
	padding: unset;
	border: unset;

	> .b-filters__line-title {
	  color: #099C9D;
	  border-bottom: 1px solid #2f3842;
	  padding: 15px 0;
	}
  }

  .b-filters__sorting {
	order: 1;

	.b-sorting__label {
	  color: #4a5767;
	}
  }

  .b-filters__mobile-common-filters-title {
	display: block;
	color: #099C9D;
	border-bottom: 1px solid #2f3842;
	padding: 15px 0;
	order: 2;
  }

  .b-filters__common-filters {
	order: 3;
  }

  .b-filters__select {
	order: 4;
  }

  .b-filters__train_leg, .b-filters__leg-first {
	order: 5;
  }

  .b-filters__train_leg, .b-filters__leg-second {
	order: 6;
  }

  .b-filters__train_leg-first, .b-filters__leg-first {
	background: #fff !important;
  }

  .b-filters__filter-lines-container__leg-second {
	margin-bottom: 40px;
  }

  .b-filters__mobile-buttons {
	order: 7;
	width: 100%;
	margin-top: 20px;
	display: flex;
	.box-shadow(unset);

	&.fixed {
	  margin-top: unset;
	  position: fixed;
	  bottom: 0;
	  left: 0;
	  z-index: 9999;
	  .box-shadow(1px 2px 6px #4a5767);
	}

	> span {
	  width: 100%;
	  display: flex;
	  padding: 20px 0;
	  justify-content: center;
	  align-items: center;

	  &.disabled {
		color: #A4ABB3;
		pointer-events: none;
		opacity: .5;
	  }
	}
	.b-filters__mobile-apply {
	  background: #099C9D;
	  color: #FFFFFF;
	  .border-radius('0px 4px 4px 0px');

	}
	.b-filters__mobile-reset {
	  background: #EDEEF0;
	  color: #D70000;
	  .border-radius('4px 0px 0px 4px');

	  &:after {
		display: none;
	  }
	}
  }
}
