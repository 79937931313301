.b-news-card {
	padding: 16px 0;
	display: flex;
	flex-direction: column;
	gap: 8px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .b-news-card-button-wrapper {
    width: 250px; 
  }

	.b-news-card-date {
		display: flex;
		flex-direction: row;
		gap: 16px;
		font-size: 12px;
		font-weight: 700;

		.b-news-card-date__formatted {
			color: #4a5767;
		}
		.b-news-card-date__important {
			background-color: #fff;
			color: #e50000;
      border: 1px solid #e50000;
			padding: 0 8px;
			border-radius: 2px;
		}

		.b-news-card-date__unread {
			background-color: #fff;
			color: #4A5767;
      border: 1px solid #4A5767;
			padding: 0 8px;
			border-radius: 2px;
		}

		.b-news-card-date__read {
			background-color: #fff;
			color: rgba(74, 87, 103, 0.4);
      border: 1px solid rgba(74, 87, 103, 0.4);
			padding: 0 8px;
			border-radius: 2px;
		}
	}

	.b-news-card-title {
		color: #099c9d;
		font-size: 16px;
		font-weight: 500;

		a.b-news-card-read-more {
			color: #4A5767;
			font-size: 12px;
			font-weight: 400;
      text-decoration: underline;
		}
	}
}
