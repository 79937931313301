.b-standart-fares {
	display: table;
	padding: 10px 25px 10px 50px;
	width: 100%;

	&__extra-info-container {
		margin: -15px 0 5px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 6px;
	}

	&__travel-policy {

		&.b-travel-policy {
			.b-travel-policy-rule {
				width: 12px !important;
				height: 16px !important;
				&:last-child {
					margin-left: 0;
				}
			}
		}
	}

	&__item {
		display: table-row;
		width: 100%;

		&-class {
			font-weight: bold;
			font-size: 16px;
		}

		&-basis {
			font-size: 14px;
			color: rgba(74, 87, 103, 0.8);
		}

		&-column {
			display: table-cell;
			vertical-align: middle;
			white-space: nowrap;
			padding: 10px 0;
			width: 1px;

			&--type-full {
				width: 100%;
				padding: 0 25px;
			}
		}

		&-border {
			display: block;
			border-top: 1px dashed #000;
			opacity: .3;
			position: relative;
			top: 1px;
		}
	}

	&__inner-flex-container {
		display: flex;
			justify-content: space-between;
	}

	&__select-container {
		display: flex;
		align-items: center;
	}

	&__select--offer,
	&__select {
		padding: 0 50px;
		text-align: center;
		cursor: pointer;
		font-size: 16px;
		height: 30px;
		line-height: 29px;
		display: inline-block;
		width: 100%;
		margin-bottom: 15px;

		&:hover {
			border-color: #D70000;
			background: #D70000;
		}
	}

	&__select--offer {
		position: relative;
		line-height: 20px;
		font-weight: bold;
		padding: 5px 50px;
		height: unset;
		margin-bottom: 0;
		background: none;
		border: none;

		&::before {
			content: "+";
			position: absolute;
			left: 15px;
			top: 3px;
			z-index: 100;
	
			display: block;
			width: 20px;
			height: 20px;
	
			font-size: 14px;
			border: 2px solid #4A5767;
			border-radius: 50%;
	
			transition: transform 0.2s;
		}
	
		&:hover {
			color: #D70000;
			background: none;
	
			&::before {
				border-color: #D70000;
			}
			
		}
	}

	&__select-price {
		font-weight: bold;
		font-size: 18px;
		color: #4A5767;
		text-align: center;
		margin-left: 15px;
	}
}