@import (reference) "~assets/css/imports";

.b-hotel-order-info {
	padding: 20px 15px 10px 0;

	.b-hotel-order_column-wrapper {
		display: flex;
		flex-flow: row nowrap;
	}

	.b-hotel-order_column {
		flex: 1;
	}

	.b-hotel-order__services {
		margin-top: 12px;
		margin-bottom: -8px;
	}

	.b-hotel-order__column-container {
		border-bottom: 1px dashed rgba(0, 0, 0, .3);
		padding: 15px 40px 12px 30px;
		margin-right: 35px;

		&:last-child {
			border-bottom: none;
		}
	}

	.b-hotel-order__name {
		font-size: 20px;
		font-weight: bold;
		display: inline-block;
		vertical-align: middle;
		margin-right: 15px;
		text-transform: uppercase;
	}

	.b-hotel-order__stars {
		display: inline-block;
		vertical-align: middle;
	}

	.b-hotel-order__line + .b-hotel-order__line {
		margin-top: 7px;
	}

	.b-hotel-order__link {
		color: #467EB7;
	}

	.b-hotel-order__important {
		color: #D70000;
	}

	.b-hotel-order__caption {
		font-size: 16px;
		font-weight: bold;
		text-transform: uppercase;

		& + .b-hotel-order__line {
			margin-top: 12px;
		}
	}


	.b-hotel-order__description {
    line-height: 30px;
    display: flex;

    gap: 12px;

    .b-hotel-order__line-item {
      line-height: 30px;
      display: flex;
      align-items: center;

      &:not(:last-child)::after {
        content: '';
        margin-left: 12px;
        height: 24px;
        background: #A4ABB3;
        width: 1px;
      }
    }

    .b-hotel-offer__information,
    .b-hotel-offer__agencyInfo {
      display: inline-block;
      width: 30px;
      height: 30px;
      margin-left: 8px;
    }

	}

	.b-hotel-order__standart-info {
		& > span {
			margin-right: 20px;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.b-hotel-order_slider {
		max-width: 240px;
		padding: 50px 15px 0 0;
		flex: 0;
		
		.b-slider__control--type-prev {
			left: -22px;
		}

		.b-slider__control--type-next {
			right: -22px;
		}
	}
}

.b-hotel-stars {
	margin-bottom: 3px;
	.b-hotel-star {
		display: inline-block;
		vertical-align: middle;
		height: 19px;
		width: 19px;
		background: url('~assets/img/hotels/icon-star.svg') center no-repeat;
	    background-size: contain;
	}
}

.b-hotel-services {
	display: flex;
	flex-direction: row;
	margin: 0 0 -12px -12px;

	.b-hotel-service {
		min-width: 24px;
		min-height: 24px;
		margin: 0 0 12px 12px;
		background-repeat: no-repeat;
		background-position: center;

		&--type-WIFI {
			background-image: url(~assets/img/hotels/services/WIFI.svg);
		}

		&--type-PETS {
			background-image: url(~assets/img/hotels/services/PETS.svg);
		}

		&--type-MEAL {
			background-image: url(~assets/img/hotels/services/MEAL.svg);
		}
	}

	&--color-gray {
		.b-hotel-service {
			&--type-WIFI {
				background-image: url(~assets/img/hotels/services/gray/WIFI.svg);
			}

			&--type-PETS {
				background-image: url(~assets/img/hotels/services/gray/PETS.svg);
			}

			&--type-MEAL {
				background-image: url(~assets/img/hotels/services/gray/MEAL.svg);
			}
		}
	}
}

@import "./style.mobile";