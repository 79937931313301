@import (reference) "~assets/css/imports";

.b-ticket-refund-exchange {
	color: #fff;
	text-align: center;
	margin: -10px 0;
}

.b-ticket-refund-exchange__item {
	margin: 10px;
	position: relative;

	&.b-ticket-refund-exchange__item-italics {
		font-style: italic;
	}
}

.b-ticket-refund-exchange__item {
	margin: 10px;
	position: relative;
}

.b-ticket-refund-exchange__item-title {
	position: relative;
	padding-bottom: 3px;
	margin-bottom: 8px;

	&:after {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		left: 25%;
		right: 25%;
		border-bottom: 1px solid #FFFFFF;
		opacity: .5;
	}
}

.b-ticket-refund-exchange__item-rules {
	overflow: hidden;
	display: block;
	width: 100%;
}

.b-ticket-refund-exchange__item-rule {
	display: block;
	margin: 0 0 3px;
}

.b-ticket-refund-exchange__item-rule-type {
	display: block;
	font-weight: normal;
}

