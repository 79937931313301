@import (reference) "~assets/css/imports";

.b-booking-trains {
	.b-booking__travellers {
		padding: 0;

		.b-traveller {

			header {
				background: #F0F0F0;
				display: flex;
				flex-direction: row;
				padding: 10px;
				font-size: 13px;
				font-weight: 500;

				.b-header-border {
					width: 10px;
					height: 1px;
					background: rgba(0, 0, 0, 0.1);
					transform: rotate(90deg);
					top: 0;
					position: relative;
					margin: 0 10px;
				}

				.b-traveller-contact {
					flex-grow: 10;
				}
			}

			.b-traveller-info {
				padding: 0 10px
			}

			.b-traveller-info_language {
			  margin: 15px 0 15px;
			}

			.b-traveller-select-trariff {

			  &:not(:only-child) {
				margin-right: 15px;
			  }
			}


			.b-traveller-select-trariff.current {
				text-decoration: none;
				color: #099C9D;
			}
		}
	}

	.b-traveller__title-contact + .b-traveller__controls {
		margin-left: 20px;
	}
}

.b-traveller__remove {
	.g-icon-delete-collection;
	.rounded;
	cursor: pointer;
	display: block;
	background-size: 10px 10px;
	background-position: 50% 50%;
	width: 30px;
	height: 30px;
	border: 1px solid #A4ABB3;
}

.b-header-nonrefundable-tariff {
	display: none;
    line-height: 26px;
}

.b-header-nonrefundable-tariff.open {
	display: block;
}

.b-traveller-tariff-container {
  display: flex;
}

.b-traveller-tariff-container--mobile {
  display: none;
}
.b-traveller-info__tariff--mobile {
  display: none;
}

.b-traveller-tariff {
  display: flex;
  align-items: baseline;
  margin-left: 20px;

  & > .b-radiobutton {
	margin-bottom: unset;
  }

  &__hint {
	.g-icon-help;
	width: 16px;
	height: 15px;
	cursor: pointer;
	display: block;
  }
}

.b-header-nonrefundable-tariff .b-checkbox__view {
	top: -16px;
	left: 5px;
}

@import 'style.mobile';
