@media @tablet {

	.b-input__select-value {
		padding-right: 48px;

		&:before {
			width: 38px;
		}
	}

	.b-input__select-value-label {
		line-height: 38px;
	  	width: calc(~'100% - 38px');
	}

}
