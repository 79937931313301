@media @tablet {
  .b-popup {
	width: 100%;
	height: 100%;
	flex-direction: column;
	justify-content: center;

	&.visible {
	  display: flex;
	}

	&__header {
	  padding: 12px 36px 12px 20px;
	  background-color: #fff;
	}

	&__title {
	  color: #4A5767;
	}

	&__close {
	  .g-icon-b-cancel;
	  width: 18px;
	  height: 18px;
	  top: 12px;
	}

	&__content {
	  border: none;
	  margin: auto;
	  padding: 20px 42px;
	}

	.b-button-container {
	  &__top {
		.box-shadow(unset);
	  }

	  &__footer {
		padding: 15px 0;
		background-color: #fff;
		flex-wrap: wrap;
		gap: 9%;

		.b-button-container__button {
		  .border-radius(4px);
		  border-left: 1px solid;
		  border-right: 1px solid;
		  margin-right: unset;
		  min-width: 100px !important;

		  &:before, &:after {
			display: none;
		  }
		}
	  }
	}

		&--type-info {
			.b-button-container__footer {
				.b-button-container__button {
					&:nth-child(2n-1):not(:only-child) {
						border-color: #4A5767;
					}
				}
			}
		}

		&--type-danger {
			.b-button-container__footer {
				.b-button-container__button {
					&:nth-child(2n):not(:only-child) {
						border-color: #4A5767;
					}
				}
			}
		}

	&--type-warning {
		.b-button-container__footer {
			.b-button-container__button {
				&:nth-child(2n):not(:only-child) {
					border-color: #4A5767;
				}
			}
		}
	}


	&.without-title,
	&.b-error-popup {
	  height: unset;
	}

	.b-button-container__top {
	  max-height: unset !important;
	}

	&:not(.b-error-popup),
	&:not(.without-title),
	&:not(.mobile-fullscreen) {
	  padding: 10px 20px;
	}
  }

  .b-error-popup {

	&__more-wrapper {
	  .toggle-b-error-popup__more {
		padding: 10px 20px;
		font-size: 10px;
	  }
	}
  }
}
