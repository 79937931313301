@media @tablet {
	.b-passport {
		padding-bottom: 15px;

		.l-grid-container {
			display: flex;
			flex-direction: column;

			& > .l-grid-layout-33 {
				order: 1;

				&.b-passport__remove-container {
					order: 0;
				}
			}
		}

		.b-passport__remove {
			margin: 9px 0;
			float: right;
		}
	}
}