@import (reference) "~assets/css/imports";

.b-geocoder-autocomplete {

  	&__value {
	  	height: 33px;
	  	background: @field1-background-color;

	  	@media @desktop-min-width {
			height: 41px;
	  	}

	  	&--without-label {
		  margin-top: 0;
		}
	}

  	&__label {

	}

  	&__item {
	  height: unset !important;
  	}

  	&__item-caption {
	  white-space: normal !important;
  	}
}

