@import (reference) "~assets/css/imports";

.b-middle-name,
.b-middle-name-lat {
	display: block;
	width: 100%;
	position: relative;
	padding-bottom: 5px;
}

.b-middle-name .validations-errors__container,
.b-middle-name-lat .validations-errors__container {
	bottom: unset;
	top: 30px;
}

.b-middle-name__label {
	font-size: @base-font-size;
	color: @field1-H1-color;
}

.b-input__label-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	white-space: nowrap;

	.b-input__label {
		margin-bottom: 0;
		&:not(:only-child):first-child {
			margin-right: auto;
		}

		&:not(:only-child):last-child {
			margin-left: auto;
		}
	}
}

.b-middle-name__value {
	border: 1px solid @input-border-color;
	font-size: @base-H1-size;
	color: @field1-active-color;
	padding: 0 10px;
	height: 30px;
	width: 100%;
	.rounded;
	position: relative;
	z-index: 2;
}

.b-middle-name.validation-error,
.b-middle-name-lat.validation-error {
	.b-middle-name__error {
		display: inline-block;
	}

	.b-middle-name__value {
		border-color: @field1-warning-color;
	}
}

.middle-name__validations-errors__container {
	background: @field1-warning-color;
	color: @field1-background-color;
	padding: 5px 5px 10px 5px;
	bottom: 40px;
	position: absolute;
	display: block;
	.rounded-top;
	z-index: 100;
	width: 100%;
	margin-bottom: -5px;
	overflow: hidden;
	text-overflow: ellipsis;
	opacity: 0;
	overflow: hidden;
	height: 0;
	.transition-fast;
}

.middle-name__validations-errors__checkbox-container {
	background: @field1-warning-color;
	color: @field1-background-color;
	padding: 5px 5px 10px 5px;
	top: 100%;
	margin-top: -2px;
	position: absolute;
	display: block;
	z-index: 100;
	width: 100%;
	margin-bottom: -5px;
	overflow: hidden;
	text-overflow: ellipsis;
	.border-radius(0px);
	.rounded-bottom;
}

.middle-name__validations-errors__checkbox-container .b-checkbox__view:before {
	background: @field1-background-color;
	border: none;
}


.b-middle-name__value:focus ~ .middle-name__validations-errors__container {
	height: auto;
	opacity: 1;
}

@import "./style.mobile";
