.b-branded-services {

}

	.b-branded-services__feature {
		margin: 10px 0;
		min-height: 18px;
		padding-left: 35px;
		color: @field1-H1-color;
		position: relative;

		&:before {
			content: " ";
			position: absolute;
			display: block;
			width: 16px;
			height: 14px;
			left: 4px;
			top: 1px;
		}

	}

	.b-branded-services__title {
	  color: @field1-font-color;
	  font-size: @base-font-size;
	  font-weight: 400;
	  margin: 7px 0 0 0;
	}

	.b-standart-services__caption,
	.b-branded-services__caption {
		color: @field1-font-color;
		font-size: 20px;
		margin: 0 0 20px 20px;
		display: none;

		.is-roundtrip-ticket &,
		.is-complex-ticket & {
			display: inline-block;
		}
	}

	.b-standart-services__item-container + .b-standart-services__item-container:not(.last),
	.b-branded-services__item-container + .b-branded-services__item-container:not(.last) {
		margin-top: 30px;

		.b-branded-fares__controls {
			position: absolute;
			right: 0;
			top: -50px;
			z-index: 99;
		}
	}

	.b-branded-services__item-container.last {
		.b-branded-fares__controls,
		.b-branded-services__caption {
			display: none;
		}
	}

	.b-branded-services__feature-positive:before {
		background: url("~assets/img/icon-ok.svg") no-repeat 50% 50%;
	}

	.b-branded-services__feature-info:before, .b-branded-services__feature-paid:before {
		background: url("~assets/img/icon-rub-yellow.svg") no-repeat 50% 50%;
	}

	.b-branded-services__feature-negative:before {
		background: url("~assets/img/icon-cancel.svg") no-repeat 50% 50%;
	}
