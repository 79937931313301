@import (reference) "~assets/css/imports";

.b-passengers {
	font-size: @base-H1-size;
	height: 30px;
	margin-right: 10px;
	width: 50%;
	position: relative;
	.rounded;
	.clearfix;

	&.validation-error {
		border-color: @field1-warning-color;
	}

	&:before {
		display: none;
	}

	&__wrapper {
		display: flex;
	}

	&__select {
		font-family: 'Roboto', sans-serif;
		font-size: 14px;
		color: #fff;
		background-color: #4A5767;
		border: 1px solid #A4ABB3;
		border-left: none;
		border-radius: 0 3px 3px 0;
		height: 30px;
		box-sizing: border-box;
		display: flex;
	    align-items: center;

		&-img {
			background: url('~assets/img/icon-arrow-big-down.svg') no-repeat center;
			width: 14px;
			padding: 13px;
		}
	}
}

.open.b-passengers .b-passengers__input:before {
	.g-icon-arrow-up_w;
}
.open.b-passengers .b-passengers__select-img {
	transform: rotate(180deg)
}

.b-passengers__input {
	width: 100%;
	border: 1px solid #A4ABB3;
	background: none;
	display: block;
	color: @field2-active-color;
	cursor: pointer;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	position: relative;
	border-radius: 3px 0 0 3px;
	height: 30px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	padding-left: 8px;
}

.b-passengers__popup {
	position: absolute;
	top: 100%;
	right: 0;
	float: right;
	z-index: 5;
	opacity: 0;
	max-height: 0;
  	pointer-events: none;
	overflow: hidden;
	.transition-fast;
	border: 1px solid #A4ABB3;
}

.open .b-passengers__popup {
	opacity: 1;
  	pointer-events: all;
  	max-height: 10000px;
	overflow: visible;
}

.b-passengers__list {
	float: right;
	background: @field2-background-color;
	padding: 0 10px;
	margin: 1px 0 0 0;
	min-width: 260px;
	opacity: 0;
	max-height: 0;
	z-index: 100;
}

.b-passengers__list {
	opacity: 1;
	max-height: 1000px;
	position: relative;
	z-index: 6;
	padding-bottom: 5px;
}

.b-passengers__item {
	border-bottom: 1px solid @field2-elements-color;
	padding: 10px 0;
	display: block;
	list-style: none;
	text-align: left;
	position: relative;
	color: @field2-font-color;
	padding-right: 70px;
	white-space: nowrap;

	&:last-child {
		border: 0;
	}
}

.b-passengers__item-plus {
	.g-icon-arrow-right;
	position: absolute;
	top: 10px;
	right: 5px;
	z-index: 10;
	cursor: pointer;
	width: 14px;
	height: 14px;
	display: block;
}

.b-passengers__item-minus {
	.g-icon-arrow-left;
	position: absolute;
	top: 10px;
	right: 50px;
	z-index: 10;
	cursor: pointer;
	width: 14px;
	height: 14px;
	display: block;
}

.b-passengers__item-value {
	top: 7px;
	right: 23px;
	z-index: 10;
	cursor: pointer;
	width: 25px;
	height: 20px;
	text-align: center;
	display: block;
	position: absolute;
	color: @field2-active-color;
}

.b-passengers__child-age {
	.rounded;
	background: mix(@field2-background-color, @field2-elements-color, 80);
	color: @field2-font-color;
	padding: 10px;
	position: absolute;
	top: 68px;
	width: 185px;
	right: -190px;
	z-index: 5;
	display: none;
}

.b-passengers__child-age-title {
	padding: 2px 0 8px 0;
	line-height: 1;
}

.b-passengers__child-age__footer{
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  padding-top: 7px;
}

.b-passengers__child-age__item {
	border-top: 1px solid @field2-elements-color;
	height: 40px;
	line-height: 40px;
	padding: 0 10px;
	.clearfix;

	label {
		float: left;
		height: 39px;
		line-height: 40px;
	}

	.b-passengers__child-age__item-input {
		float: right;
		height: 30px;
		border: 1px solid @field2-object-color;
		width: 50px;
		margin-top: 5px;
		background: @field2-background-color;
		.rounded;
		text-align: center;
		color: @field2-active-color;

		&.validation-error {
			border-color: @field2-warning-color;
		}
	}
}

.b-passengers-complex {

	&:before {
		top: 7px;
	}

	&:after {
		content: " ";
		display: block;
		width: 1px;
		height: 16px;
		position: absolute;
		right: 0;
		top: 50%;
		margin-top: -8px;
		background: @field2-object-color;
	}

	.b-passengers__input {

		&:after {
			content: " ";
			display: block;
			width: 1px;
			height: 16px;
			position: absolute;
			left: 0;
			top: 50%;
			margin-top: -8px;
			background: @field2-object-color;
		}

	}
}

.b-passengers__info {
	.rounded;
	background: @field1-background-color;
	padding: 10px;
	color: @field1-font-color;
	position: absolute;
	top: 100%;
	text-align: center;
	.shadow;
  	margin-bottom: 40px;
	margin-top: -5px;
	z-index: 10;
	font-size: @base-font-size;
}

.b-passengers__validation {
	position: absolute;
	bottom: 100%;
	right: -1px;
	margin-bottom: -3px;
	left: -1px;
	width: auto;
}

.b-passengers__validation-visible {
	opacity: 1 !important;
	display: block;
	height: auto !important;
}

@media @tablet {

	.b-passengers__popup {
		width: 100%;
		left: 0;
	}

	.b-passengers__list {
		width: 100%;
		float: none;
	}

	.b-passengers__child-age {
		position: static;
		width: 100%;
		left: 0;
		top: 0;
		padding: 10px;
		right: auto;
		margin: 0;
	}

	.b-passengers__item {
		white-space: normal;
	}
}
