@import (reference) "~assets/css/imports";

.b-transfer-pricing {
  font-size: 14px;
  color: #fff;

  &__header {
	text-transform: uppercase;
	font-weight: 500;
	border-bottom: 1px solid #2F3842;
	padding: 11px 0;
  }

  &__section {
	border-bottom: 1px solid #2F3842;
	padding: 5px 0 7px;

	&:last-child {
	  border: none;
	}
  }

  &__service {
	flex-flow: column;
	margin: 8px 10px;
	display: flex;

	&:first-child {
	  margin-top: 0;
	}
  }

  &__caption {
	font-weight: 500;
  }

  &__direction {
	margin-top: 10px;
  }
}

.b-transfer-pservice {

  &__caption {
	font-weight: 500;
	color: #fff;

	&:first-letter {
	  text-transform: capitalize;
	}
  }

  &__caption-tax, &__caption-mr {
	color: #fff;
	font-weight: 500;
	margin: 12px 0;
  }

  &__element {
	display: flex;
	width: 100%;
	flex-flow: row nowrap;
	color: rgba(255, 255, 255, 0.8);
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	margin: 4px 0;

	&-p {
	  white-space: nowrap;
	}
  }
}

.b-transfer-pricing__section.with-taxs {
  .b-transfer-pricing__total {
	border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
	padding-bottom: 12px;
  }
}

.b-transfer-pricing__total {
  color: #fff;
  font-weight: 500;
  border-top: 1px dashed rgba(0, 0, 0, 0.1);
  padding-top: 12px;
}

@media @tablet {
  .b-transfer-pricing__section-title {
	color: #4A5767 !important;
  }

  .b-transfer-pricing__section-passenger {
	color: #4A5767 !important;
  }

  .b-transfer-pricing__section-price {
	color: #4A5767 !important;
  }

  .b-transfer-pricing__total-section {
	color: #4A5767 !important;
  }
}
