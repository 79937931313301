@import (reference) "~assets/css/imports";
@import "./b-travellers";
@import '../styles';

body[data-route-id="trainPassengers"] {
  .b-booking__footer {
    .b-booking__back {
      display: none;
    }
    .b-booking__submit {
      margin-left: auto;
    }
  }
}

body.overflow {
  overflow: hidden;

  .l-layout__content {
    position: relative;
		&:before {
			content: '';
			display: block;
			position: absolute;
			left: -10vw;
			right: -10vw;
			top: 0;
			bottom: 0;
      background: rgba(255, 255, 255, .6);
      z-index: 10;
		}
  }
}

.b-booking {
  .incomplete .b-input__value.b-masked-input-date__value {
    color: @input-placeholder-color;
  }
  .b-input__value:not(.b-input__select-value):not(.b-masked-input-date__value), .b-input-phone__value {
    color: @field1-active-color;
  }
  .b-datepicker__field {
    color: inherit;
    background: none;
    height: 30px;
    line-height: 30px;
  }

  .p-search__options-date-with-label {
    position: relative;
    padding-bottom: 5px;

    .p-search__direction-date {
      margin-top: -8px;
    }

    .p-search__direction-date-calendar {
      background-color: unset;
      height: 30px;
      width: 30px;

      .p-search__direction-date-calendar-img {
        background: url("~assets/img/icon-calendar-black-1.svg") no-repeat center;
      }
    }
  }

  .b-booking__footer {
    background: #F0F0F0;
    padding: 13px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .b-block-container__footer {
    display: flex;
    flex-direction: row;
    position: relative;

    .b-block-container__footer-leftbar {
      margin-right: auto;
    }

    .b-block-container__footer-rightbar {
      margin-left: auto;
    }
  }
}

.b-booking__ticketing-before {
  color: @field1-alert-color;
}

.b-booking__dk {
  background-color: @field1-background-color;
  border-left: @field1-border;
  border-right: @field1-border;
  padding: 0 10px;
  .clearfix;
  .shadow;
}

.b-booking__rule-booking {
  height: 400px;
  overflow: auto;
}

.b-booking__rule-carrier {
  height: 400px;
  overflow: auto;
}


.b-booking__has-dupes {
  height: 200px;
  overflow: auto;
}

.b-booking__travellers {
  border-left: @field1-border;
  border-right: @field1-border;
  .clearfix;
  .shadow;
}

.b-booking__customer {
  background: #fff;
}

.b-booking__two-selector-wrapper {
  padding: 10px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
  margin: 30px 0;
  border: 1px solid #f8f8f8;
}

@import "./style.mobile";
