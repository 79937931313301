@import (reference) "~assets/css/imports";

.b-header__blocks.fixed {
	position: fixed;
	top: 0;
	z-index: 999;
	width: 100%;

	.b-breadcrumbs {
		background: #4a5767;
		padding: 8px;
		margin: 0 12px;
		border-radius: 0 0 3px 3px;

		.b-breadcrumbs__item {
			color: #2f3842;
			&-active {
				color: #fff;
			}
		}
	}	
}

.b-breadcrumbs {
	border: 0;
	padding: 16px 20px 4px;
}

.b-breadcrumbs__items {
	margin: 0;
	padding: 0;
	display: flex;
	color: @__base-color-light-grey;
	list-style: none;

	.b-breadcrumbs__item:first-child {
		padding-left: 0;
	}	
}

.b-breadcrumbs__item:first-child:before {
	position: relative;
	width: initial;
	height: initial;
	display: block;
	border-radius: initial;
}

.b-breadcrumbs__item:last-child:after {
	content: '';
}

.b-breadcrumbs__item {
	font-size: 14px;
	flex-grow: 1;
	display: flex;
	line-height: 20px;
	color: rgba(74, 87, 103, 0.3);
	padding-left: 5px;
}

.b-breadcrumbs__item:after {
	content: '\2192';
	padding-left: 5px;
}

.b-breadcrumbs__item.last:after {
	color: @field1-H1-color;
}

.b-breadcrumbs__item-active {
	color: #4A5767;
	cursor: pointer;
}

@import "./style.mobile";
