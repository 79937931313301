@import (reference) "~assets/css/imports";

.b-avia-search {
	background: #4A5767;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
	border-radius: 3px;
	padding: 24px;
	margin-bottom: 13px;

	&-form__complex {
		color: #fff;
		font-size: 14px;
		line-height: 16px;
		display: flex;
	}

	&__leg {
		+ .b-avia-search__leg {
			margin-left: 30px;
			position: relative;

			&:before {
				content: '|';
				position: absolute;
				left: -16px;
			}
		}
	}

	&__dates-container,
	&__row {
		display: flex;
		flex-flow: nowrap row;
	}

	&-swap {
		position: relative;
		display: flex;
		align-items: center;
		width: 10px;

		&__img {
			background: url("~assets/img/icon-swap-n.svg") no-repeat center;
			height: 24px;
			width: 24px;
			color: #fff;
			position: absolute;
			z-index: 999;
			cursor: pointer;
			left: -7px;
		}
	}

	&__dates-container {
		min-width: 230px;

		.b-avia-search__options-date-element {
			flex: 1;

			&--type-datefrom {
				.p-search__direction-date-calendar {
					width: 40px;
					height: 100%;
					display: block;
					position: absolute;
					top: 0;
					right: 0;
				}

				.b-datepicker__remove {
					background: url("~assets/img/icon-clear-dark.svg") no-repeat center;
					background-size: 14px;
				}
			}
		}
	}

	&__direction {
		flex: 1 1 50%;

		& + .b-avia-search__dates-container {
			margin-left: 12px;
		}
	}

	&__options-date-element {
		& + .b-avia-search__options-date-element {
			position: relative;
			margin-left: 12px;
		}
	}

	.p-search__direction-date {
		position: relative;
	}

	.p-search__input {
		color: #4A5767;
		font-size: 18px;
		background: #fff;
		border: 1px solid transparent;
		box-sizing: border-box;
		border-radius: 3px 3px 0 0;
		width: 100%;
		padding: 12px 20px;
		.placeholder(rgba(74, 87, 103, .5));
	}

	.b-avia-search__button {
		.search-button;
	}

	.b-avia-search__button-container {
		margin-left: 10px;
	}
}

.b-avia-search-mobile {
  display: none;
}

.b-avia-search-form-mobile__background {
  display: none;
}

@import "style.mobile";
