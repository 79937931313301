@import (reference) "~assets/css/imports";
@import (reference) "../b-input/style";


.b-input__select {
	border: 0;
	background: none;

	position: absolute;
	top: 0;
	left: 0;
	right: -40px;
	bottom: 0;
	display: block;
	width: 100%;
	box-sizing: content-box;
	opacity: 0;
	z-index: 10;
}

.b-input__select-value {
	overflow: hidden;
	display: block;

	&:before {
		content: " ";
		display: block;
		.g-icon-arrow-down;
		background-color: #fff;
		background-size: 12px 7px;
		background-position: 50% 50%;
		width: 30px;
		top: 0;
		bottom: 0;
		height: 100%;
		position: absolute;
		right: 0;
		z-index: 5;
		border-left: 1px solid #A4ABB3;
	}

	.placeholder {
		color: rgba(74, 87, 103, .5);
	}
}

.b-input__select-value-label {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 40px;
  	width: calc(~'100% - 30px');
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 30px;
	white-space: nowrap;
	padding-left: 10px;
	display: block;

  	&.disabled {
	  background: rgba(74, 87, 103, 0.1);
	  color: #099C9D;
	}
}

.b-input__select__remove {
	background: #fff url("~assets/img/icon-clear-dark.svg") no-repeat center;
	width: 28px;
	// height: 100%;
	background-size: 10px;
	position: absolute;
	right: 0;
	// top: 0;
  border: 1px solid rgba(74, 87, 103, 0.4);
    height: 30px;
    bottom: 3px;
	cursor: pointer;
	z-index: 99;

	&.dn {
		display: none;
	}
}

@import "./style.mobile";
