@import (reference) "~assets/css/imports";

.b-slider {
	.b-slider__image {
		display: flex;
		flex-flow: column;

		width: 170px;
		height: 170px;

		position: relative;

		&-not-available {
			background: url('~assets/img/hotels/icon-no-photo.svg') center no-repeat;
			background-size: contain;
			width: 112px;
			height: 112px;
			opacity: .3;
			margin: auto;
		}
	}

	&__container {
		position: relative;
		height: 100%;
		overflow: hidden;
		border-radius: 3px;
	}

	&__dots {
		position: absolute;
		top: 34px;
		right: 4px;
		display: flex;
		z-index: 2;
		min-width: 60px;
		justify-content: center;
		align-items: center;
	}

	&__dot {
		width: 3px;
		height: 3px;
		background: #2F3842;
		border-radius: 100%;
		opacity: 0.5;
		cursor: pointer;
		margin-left: 6px;

		&.active {
			opacity: 1;
		}

		&:first-child {
			margin-left: 0;
		}
	}

	&__controls {
		position: absolute;
		top: 4px;
		right: 4px;
		background: #2F3842;
		border-radius: 0 3px;
		display: flex;
		z-index: 2;
	}

	&__control {
		cursor: pointer;
		height: 50px;
		width: 22px;
		position: absolute;
		top: calc(~'50% - 25px');
		opacity: .5;
		z-index: 2;

		background: #2F3842;
		background-repeat: no-repeat;
		background-position: center;

		border: 1px solid rgba(255, 255, 255, 0.5);

		&:hover {
			opacity: 1;
		}

		&--type-prev {
			background-image: url("~assets/img/hotels/icon-arrow-left-white.svg");
			left: 0;
		}

		&--type-next {
			background-image: url("~assets/img/hotels/icon-arrow-right-white.svg");
			right: 0;
		}
	}

	&__list {
		position: relative;
	}

	&__slide {
		position: relative;
		float: left;
		width: 170px;
		height: 170px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;

		.lds-ring {
			display: inline-block;
			position: relative;
			width: 64px;
			height: 64px;
			opacity: .1;
		}
		.lds-ring div {
			box-sizing: border-box;
			display: block;
			position: absolute;
			width: 51px;
			height: 51px;
			margin: 6px;
			border-width: 6px;
			border-style: solid;
			border-radius: 50%;
			animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
			border-color: #2F3842 transparent transparent transparent;
		}
		.lds-ring div:nth-child(1) {
			animation-delay: -0.45s;
		}
		.lds-ring div:nth-child(2) {
			animation-delay: -0.3s;
		}
		.lds-ring div:nth-child(3) {
			animation-delay: -0.15s;
		}
		@keyframes lds-ring {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}

		& > img {
			width: 100%;
		}
	}
}
