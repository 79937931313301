@media (max-width: 752px) {
  .b-transfer-order-info {

	&__wrapper {
	  padding: 10px;
	  margin: 0;
	}

	&__row {
	  margin-bottom: 5px;
	}

	&__car-info {

	  &__passengers-icon {

	  }

	  &__passengers-text {
	  }

	  &-photo {
		width: 120px;
		height: 90px;
		margin-right: 0;
	  }

	  &-class {
		font-size: 16px;
		line-height: 16px;
	  }

	  &-element {
		margin-top: 13px;
	  }
	}

	&__trip-info {

	  &-time {
		font-size: 16px;
		line-height: 16px;
	  }

	  &-route {
		font-size: 12px;
	  }

	  &-element {
		margin-top: 13px;
	  }
	}

	&__trip-sub-addresses {
	  font-size: 12px;
	}
  }
}
@media (min-width: 753px) {
  .b-transfer-order-info {

	&__wrapper {
	  padding: 10px;
	  margin: 0;
	}



	&__car-info {

	  &__passengers-icon {

	  }

	  &__passengers-text {
	  }

	  &-photo {
		width: 120px;
		height: 90px;
		margin-right: 0;
	  }

	  &-class {
		font-size: 16px;
		line-height: 16px;
	  }

	  &-element {
		margin-top: 13px;
	  }
	}

	&__trip-info {

	  &-time {
		font-size: 16px;
		line-height: 16px;
	  }

	  &-route {
		font-size: 12px;
	  }

	  &-element {
		margin-top: 13px;
	  }
	}
  }
}
@media (min-width: 1220px) {
  .b-transfer-order-info {

	&__wrapper {
	  padding: 10px;
	}

	&__row {
	}

	&__car-info {

	  &__passengers-icon {
	  }

	  &__passengers-text {
	  }

	  &-photo {
		width: 120px;
		height: 90px;
		margin-right: 15px;
	  }

	  &-class {
		font-size: 16px;
		line-height: 19px;
	  }

	  &-element {
		margin-top: 23px;
	  }
	}

	&__trip-info {

	  &-time {
		font-size: 14px;
		line-height: 19px;
	  }

	  &-route {
		line-height: 12px;
	  }

	  &-element {
		margin-top: 23px;
	  }
	}

	&__trip-sub-addresses {
	  font-size: 12px;
	}
  }
}
@media (min-width: 1440px) {
  .b-transfer-order-info {

	&__wrapper {
	  display: flex;
	  padding: 20px;
	  margin: 0;
	}

	&__car-info {

	  &__passengers-icon {
	  }

	  &__passengers-text {
	  }

	  &-photo {
		width: 150px;
		height: 130px;
	  }

	  &-class {
		font-size: 20px;
		line-height: 23px;
	  }

	  &-element {
		margin-top: 33px;
	  }
	}

	&__trip-info {

	  &-time {
		font-size: 18px;
		line-height: 23px;
	  }

	  &-route {
		line-height: 14px;
		font-size: 14px;
	  }

	  &-element {
		margin-top: 33px;
	  }
	}

	&__trip-sub-addresses {
	  font-size: 14px;
	}
  }
}
@media (min-width: 1920px) {
  .b-transfer-order-info {

	&__wrapper {
	  display: flex;
	  padding: 20px;
	  margin: 0;
	}

	&__car-info {

	  &__passengers-icon {
	  }

	  &__passengers-text {
	  }

	  &-photo {
		width: 180px;
		height: 150px;
		margin-right: 30px;
	  }

	  &-class {
		font-size: 20px;
		line-height: 23px;
	  }

	  &-element {
		margin-top: 33px;
	  }
	}

	&__trip-info {

	  &-time {
		font-size: 18px;
		line-height: 23px;
	  }

	  &-route {
		font-size: 14px;
	  }

	  &-element {
		margin-top: 33px;
	  }
	}

	&__trip-sub-addresses {
	  font-size: 14px;
	}
  }
}
