.b-company-employees {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #fff;

    &-wrapper {
        background-color: #4A5767;
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 3px;
        margin-top: 2px;
    }
    &-service {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px 0 16px 16px;

        &__wrapper {
            width: 100%;
            align-items: center;
            display: flex;
            flex-direction: row;
        }
    }

    .b-company-autocomplete {
        padding-bottom: 0;
        .b-input__value {
            border-color: #A4ABB3;
            color: #fff;
        }
    }

    .b-search-form__params {
        width: 345px;
        flex-grow: 1;
    }

    .b-search-form__employees {
        flex-grow: 4;
        margin-left: 15px;
    }
}
