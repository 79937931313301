@media @tablet {
	.b-input-phone__remove {
		height: 38px;
		width: 38px;
	}

	.b-input__value {
		padding-right: 38px;
	}

	.b-input-phone__country-visible, .b-input-phone__value {
		line-height: 38px;
		height: 38px;
	}

  	.b-input-phone__value {
		width: 95%;
  	}

	.b-input-phone__country {
		height: 38px;
	}
}
