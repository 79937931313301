@import (reference) "~assets/css/imports";

.b-products-page {

}

.b-products-page__section-title {
	.rounded-top;
	border-left: @field2-border;
  	border-right: @field2-border;
  	border-top: @field2-border;
	background: @field2-background-color;
	color: @field2-H1-color;
	text-transform: uppercase;
  	font-size: @base-H2-size;
	height: 45px;
	line-height: 45px;
	padding: 0 15px;
}

.b-products-page__filters {
	display: flex;
	flex-wrap: wrap;
}

.b-products-page__filters-filter {
	flex-grow: 1;
	flex-shrink: 0;
}

.b-products-page__filters-filter_width-single {
	flex-basis: 16.666%;
}

.b-products-page__filters-filter_width-double {
	flex-basis: 33.333%;
}

/* Multiselects */

.b-products-page__input-multiselect.b-multiple-select .b-multiple-select__default-label {
    white-space: nowrap;
}

/* EOF Multiselects */

/* Airlines autocomplete */

.b-products-page__airline-autosuggest.b-airlines-input .b-airlines-input__input {
    height: 30px;
    padding: 0 10px;
    float: none;
    border: 1px solid rgba(74, 87, 103, 0.4);
    color: @field2-elements-color;
}

.b-products-page__airline-autosuggest.b-airlines-input .b-input__select__remove {
    height: 28px;
    top: 10px;
    right: 1px;
}

/* EOF Airlines autocomplete */

.p-cabinet-products {
	.p-search__options-date-element {
		.p-search__bridge {
			display: none;
		}
	}


  	.b-block-container {

		&__top-controls {
		  background: #FFFFFF;
		  border: 1px solid #A9A9A9;
		  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
		  border-radius: 0 0 3px 3px;
		  border-top: none;
		  padding: 10px 20px 12px 12px;
		  display: flex;
		  align-items: center;
		  flex-flow: row wrap;
		  position: relative;
		  z-index: 1;
		}

		&__element {
		  flex: 1 1 20%;

		  &:not(:last-child) {
			margin-right: 15px;
		  }

		}

	  	&__element-dates {
		  	display: flex;
		  	flex-flow: row;
		  	position: relative;
		  	padding-top: 8px;
		  	max-width: 350px;

		  	.p-search {

			  	&__options-date-element {

				  	&:first-child {
				  	  	margin-right: 15px;

					  	.p-search__direction-date-calendar {
							position: relative;

							&::after {
							  content: '';
							  display: block;
							  height: 0;
							  width: 15px;
							  top: 50%;
							  right: -15px;
							  position: absolute;
							  border-top: 1px solid #a4abb3;
							}
					  	}
				    }
				}

			  	&__options-date-label {
					position: absolute;
					padding: 0 4px;
					background: #fff;
					top: 0;
					left: 4px;
					z-index: 2;
			  	}

			  	&__direction-date-calendar {
				  	background-color: unset;
				}
			}

		  	.b-datepicker__icon {
				background-image: url("~assets/img/icon-calendar-black-1.svg");
		  	}

		}

	  	.b-block-table {

		  &__cell {
			&.text-align-center {
			  text-align: center;
			}
		  }
		  &__data-icon {
			display: inline-block;
			vertical-align: middle;
			width: 23px;
			height: 23px;

			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;

			&--3D {
			  display: inline-block;
			  width: 18px;
			  height: 18px;
			  background-position: center;
			  background-repeat: no-repeat;
			  background-size: contain;
			}

			&--INSURANCE_ACCIDENT {
			  background-image: url("~assets/img/icons/icon-med-insurance.svg");
			  &--ISSUED, &--PAYED {
				background-image: url("~assets/img/icons/icon-med-insurance-colored.svg");
			  }
			}
			&--INSURANCE_ACCIDENT-white {
			  background-image: url("~assets/img/icons/icon-med-insurance-white.svg");
			}
			&--TAXI {
			  background-image: url("~assets/img/icons/icon-taxi.svg");
			  &--ISSUED, &--PAYED {
				background-image: url("~assets/img/icons/icon-taxi-colored.svg");
			  }
			}
			&--TAXI-white {
			  background-image: url("~assets/img/icons/icon-taxi-white.svg");
			}
			&--OTHER, &--MISC_PRODUCT {
			  background-image: url("~assets/img/icons/icon-universal.svg");
			  &--ISSUED, &--PAYED {
				background-image: url("~assets/img/icons/icon-universal-colored.svg");
			  }
			}
			&--OTHER-white, &--MISC_PRODUCT-white {
			  background-image: url("~assets/img/icons/icon-universal-white.svg");
			}
			&--AEROEXPRESS {
			  background-image: url("~assets/img/icons/icon-air-express.svg");
			  &--ISSUED, &--PAYED {
				background-image: url("~assets/img/icons/icon-air-express-colored.svg");
			  }
			}
			&--AEROEXPRESS-white {
			  background-image: url("~assets/img/icons/icon-air-express-white.svg");
			}
			&--INSURANCE_TOURISTIC {
			  background-image: url("~assets/img/icons/icon-tour-insurance.svg");
			  &--ISSUED, &--PAYED {
				background-image: url("~assets/img/icons/icon-tour-insurance-colored.svg");
			  }
			}
			&--INSURANCE_TOURISTIC-white {
			  background-image: url("~assets/img/icons/icon-tour-insurance-white.svg");
			}
			&--CAR_RENTAL {
			  background-image: url("~assets/img/icons/icon-rent.svg");
			  &--ISSUED, &--PAYED {
				background-image: url("~assets/img/icons/icon-rent-colored.svg");
			  }
			}
			&--CAR_RENTAL-white {
			  background-image: url("~assets/img/icons/icon-rent-white.svg");
			}
			&--VISA, &--VISA_PRODUCT {
			  background-image: url("~assets/img/icons/icon-visa.svg");
			  &--ISSUED, &--PAYED {
				background-image: url("~assets/img/icons/icon-visa-colored.svg");
			  }
			}
			&--VISA-white, &--VISA_PRODUCT-white {
			  background-image: url("~assets/img/icons/icon-visa-white.svg");
			}
			&--BUS {
			  background-image: url("~assets/img/icons/icon-bus.svg");
			  &--ISSUED, &--PAYED {
				background-image: url("~assets/img/icons/icon-bus-colored.svg");
			  }
			}
			&--BUS-white {
			  background-image: url("~assets/img/icons/icon-bus-white.svg");
			}

			&--RAILWAY_PRODUCT {
			  background-image: url("~assets/img/icons/icon-train.svg");

			  &--ISSUED, &--PAYED {
				background-image: url("~assets/img/icons/icon-train-colored.svg");
			  }
			}
			&--RAILWAY_PRODUCT-white {
			  background-image: url("~assets/img/icons/bc-railways.svg");
			}
			&--AIR_TICKET {
			  background-image: url("~assets/img/icons/icon-avia.svg");

			  &--ISSUED, &--PAYED {
				background-image: url("~assets/img/icons/icon-avia-colored.svg");
			  }
			}
			&--AIR_TICKET-white {
			  background-image: url("~assets/img/icons/bc-avia.svg");
			}
			&--HOTEL_RESERVATION {
			  background-image: url("~assets/img/icons/icon-hotel.svg");

			  &--ISSUED, &--PAYED {
				background-image: url("~assets/img/icons/icon-hotel-colored.svg");
			  }
			}
			&--HOTEL_RESERVATION-white {
			  background-image: url("~assets/img/icons/icon-hotel-active.svg");
			}
			&--TRANSFER {
			  background-image: url("~assets/img/icons/icon-transfer-s.svg");

			  &--ISSUED, &--PAYED {
				background-image: url("~assets/img/icons/icon-transfer-s-colored.svg");
			  }
			}
			&--TRANSFER-white {
			  background-image: url("~assets/img/icons/icon-transfer-s-active.svg");
			}
			&--UNKNOWN {
			  background-image: url("~assets/img/icons/icon-unknown.svg");
			  background-size: auto;
			}
			&--UNKNOWN-white {
			  background-image: url("~assets/img/icons/bc-unknown.svg");
			  background-size: auto;
			}
		  }
		}
  	}

	.p-search__input {
		.placeholder(@input-placeholder-color);
	}
}

@import "./style.mobile";
