.b-cabinet-order-pdf-viewer {
	.b-block-container {
		&__footer {
			padding: 13px;
			display: flex;
			flex-direction: row;
			position: relative;

			&-leftbar {
				margin-right: auto;
			}

			&-rightbar {
				margin-left: auto;
			}

		}
	}

	&__modal {
		.b-popup__content {
			padding: 13px;
		}
	}

	&__pdf-modal {
		min-width: 840px;
		max-width: 70vw;
		width: 100%;
	}
}