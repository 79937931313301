@import (reference) "~assets/css/imports";
@import "./b-ticket-header";
@import "./b-standard-services";
@import "./b-branded-services";
@import "./b-ticket-section";
@import "./b-ticket-stop";
@import "./b-ticket-pricing";
@import "./b-ticket-refund-exchange";
@import "./b-ticket-luggage";
@import "./b-branded-fares";
@import "./b-standart-fares";
@import "./b-ticket-available-seats";

/* OLD STYLES - delete when branded tickets redesign */
.b-ticket {
	.rounded;
	.shadow;
	margin: 15px 0;
	display: none;
}

.b-ticket__visible {
	display: block;
	margin: 0;
}

.b-ticket__visible ~ .b-ticket__visible {
	margin: 15px 0;
}

.b-ticket__header {
	padding: 0 15px;
	height: 39px;
	line-height: 39px;
	.rounded-top;
	overflow: hidden;
	background-color: @field2-background-color;
	border-top: @field2-border;
	border-left: @field2-border;
	border-right: @field2-border;
}

.b-ticket__header-separator {
	width: 32px;
	height: 100%;
	float: left;
}

.b-ticket__header-separator:after {
	content: '';
	width: 1px;
	height: 20px;
	display: block;
	margin: 0 auto;
	background: #0a0a0e;
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.b-operating-airline {
	float: left;
	font-size: 14px;
	color: @__base-color-2;
}



.b-ticket__price {
	color: @__base-color-2;
	text-transform: uppercase;
	float: right;
	height: 45px;
	line-height: 45px;
	font-size: 22px;
	font-weight: 200;



	.b-ticket__price-value {
		float: left;
		position: relative;
		height: 45px;
		line-height: 35px;
		font-size: 16px;
		font-weight: bold;

		.b-ticket__price-full-label {
			font-size: 13px;
			font-weight: normal;
			text-transform: none;
		}

		.b-ticket__price-info {
			position: absolute;
			right: 0;
			bottom: 4px;
			font-size: 11px;
			float: right;
			text-transform: none;
			white-space: nowrap;
			height: 12px;
			line-height: 12px;

			&:first-letter {
				text-transform: uppercase;
			}
		}
	}

	.b-ticket__price-rates {
		float: left;
		height: 45px;
		padding-left: 15px;
		padding-top: 7px;

		.b-ticket__price-rate {
			font-size: 12px;
			display: block;
			height: 16px;
			line-height:16px;
			margin-bottom: 1px;
		}
	}
}


.b-ticket-leg__open {
	height: 30px;
	width: 30px;
	display: block;
	position: absolute;
	bottom: 0;
	right: 0;
	background: url("~assets/img/icon-arrow-down-black.svg") no-repeat;
	background-size: 14px 14px;
	background-position: 50% 50%;
	stroke: @field1-H1-color;
	border-top: 1px solid @field1-elements-color;
	border-left: 1px solid @field1-elements-color;
	.rounded-top-left;
	cursor: pointer;
	z-index: 100;
}

.b-ticket__is-open .b-ticket-leg__open {
	background: @field1-elements-color url("~assets/img/icon-arrow-up-black.svg") no-repeat;
	background-size: 14px 14px;
	background-position: 50% 50%;
}

.b-ticket__footer {
	height: 40px;
	position: relative;

}

.b-ticket__footer-inner {
	width: 100%;
	display: table;
}

.b-ticket__footer-button {
	.rounded-bottom;
	background: @button1-color;
	color: @button1-font-color;
	font-size: @base-H1-size;
	font-weight: bold;
	text-align: center;
	height: 40px;
	line-height: 40px;
	width: 50%;
	white-space: nowrap;
	display: table-cell;
	cursor: pointer;
	border: @button1-color-border;
	.transition-standard;

	&:hover {
		background: @button1-color-act;
		color: @button1-font-color-act;
		border: @button1-color-border-act;
	}

	&:active {
		background: @button1-color-act;
		color: @button1-font-color-act;
		border: @button1-color-border-act;
	}
}

.b-ticket__order {}

.b-ticket__branded-fares {
	background: @field2-font-color;
	color: @button2-font-color;
	border: @button2-color-border;
	font-weight: bold;

}

.b-ticket__branded-fares:hover {
	background: @button2-color-act;
	color: @button2-font-color-act;
	border: @button2-color-border-act;

}

.b-ticket__common-services {
	background: @field2-background-color;
	padding: 0 15px;
	position: relative;
	border-right: @field2-border;
	border-left: @field2-border;
}

.b-ticket__is-open .b-ticket-leg {
	border-bottom-left-radius: 0;
}

.b-ticket-leg {
	position: relative;
	padding-top: 2px;
	background: @field2-background-color;
	border-bottom-left-radius: 5px;

	&:first-child {
		padding-top: 0;
	}
}

.b-ticket-leg__short {
	position: relative;
	.rounded;
	.shadow;
	background: @field1-background-color;
	border-right: @field1-border;
	border-left: @field1-border;
	border-bottom: @field1-border;

	.b-ticket-leg__segments {
		position: relative;
		display:flex;
		justify-content: space-between;
	}

	.b-ticket-leg-segment {
		flex-grow: 1;
		padding: 11px 13px;
		width: 250px;
	}

	.b-ticket-leg-route-info {
		flex-grow: 5;
		padding-right: 7px;

		.b-ticket-leg-route-info-description {
			width: 300px;
			text-align: center;
			position: absolute;
			left: 0;
			right: 0;
			top: 15px;
			margin-left: auto;
			margin-right: auto;

			.travel-duration {
				font-weight: bold;
			}
		}

	}

}

.b-ticket-leg__places {
	height: 42px;
	width: 100%;
	overflow: hidden;
	line-height: 38px;
	padding: 1px 11px;
	margin:  0;
	border-bottom: 1px solid @field1-elements-color;
	font-size: 20px;
	position: relative;

	.b-ticket-leg__features {
		position: absolute;
		right: 6px;
		top: 0;
		z-index: 2;
	}

	.b-ticket-leg__feature, .b-ticket-leg__travel-policy-rule {
		float: right;

		margin-left: 10px;
		position: relative;

		&.g-icon-travel-policy-rules-RECOMMENDED,
		&.g-icon-features-3D-contract {
			width: 18px;
			height: 24px;
			top: 0;
		}

		&.g-icon-travel-policy-rules-VIOLATION,
		&.g-icon-travel-policy-rules-STRICT_VIOLATION,
		&.g-icon-travel-policy-rules-AUTHORIZATION_REQUIRED {
			width: 18px;
			height: 24px;
			top: 8px;
		}
	}
}

.b-ticket-leg__place {
	color: @field1-H1-color;
	font-size: @base-H1-size;
}

.b-ticket__place-iata {
	color: @field1-object-color;
	text-transform: uppercase;
	font-size: @base-H1-size;
	font-weight: normal;
}

.b-ticket-leg__info {
	position: relative;
	height: 100px;
}
.b-ticket-leg__opairline {
	position:absolute;
	bottom:10px;
	left:14px;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 94%;
	white-space: nowrap;
	color:@field1-H1-color;
	font-size:@base-H1-size;
}

.b-ticket-leg__date {
	color: @field1-font-color;
	font-size: @base-H1-size;
}
.b-ticket-leg__time {
	color: @field1-H1-color;
	font-size: @base-H1-size;
}

.b-ticket-leg__travel-time {
	font-size: @base-H2-size;
	left: 50%;
	margin-left: -60px;
	position: absolute;
	top: 15px;
	float: left;
	padding-left: 20px;
	color: @field1-H1-color;
}

.b-ticket-leg__travel-time:before {
	content: " ";
	position: absolute;
	top: 2px;
	left: 0;
	.g-icon-clock-black;
	display: block;
	height: 15px;
	width: 15px;
	background-position: 50% 50%;
}

.b-ticket-leg-route-info {
	font-size: @base-H1-size;
}

.b-ticket-leg__info-line {
	height: 2px;
	background: @field1-object-color;
	top: 65%;
	position: relative;
}

.b-ticket-leg__info-line:after, .b-ticket-leg__info-line:before {
	content: " ";
	display: block;
	border: 2px solid @field1-object-color;
	background: @field1-background-color;
	border-radius: 50%;
	width: 15px;
	height: 15px;
	position: absolute;
	top: 50%;
	margin-top: -8px;
	left: 0;
}

.b-ticket-leg__info-line:after {
	right: 0;
	left: auto;
}

.b-ticket-stops-info {
	font-weight: bold;
	color: #f8902c;
}

.b-ticket-leg__transplants {
	background: @field1-background-color;
	display: block;
	height: 10px;
	line-height: 18px;
	font-size: @base-H1-size;
	padding: 0 5px;
	float: left;
	position: absolute;
	left: 50%;
	color: @field1-active-color;
	margin-top: -6px;
	top: 50%;
}

.b-ticket-leg__transplants:before {
	content: " ";
	width: 8px;
	height: 8px;
	display: block;
	position: absolute;
	.border-radius(50%);
	left: 3px;
	top: 50%;
	margin-top: -3px;
	background: @field1-object-color;
}

.b-ticket-leg__extended {
	overflow: hidden;
	margin-top: -10px;
	background: @field1-background-color;
	padding-top: 10px;
	.rounded-bottom;
	border-right: @field1-border;
}

.b-ticket-leg__extended-visible {
	display: none;

	& > * {
		margin-top: -10px;
		padding-top: 10px;
	}
}

.b-ticket-leg__extended-inner {
	border-left: solid @field2-background-color 105px;
	.clearfix;

}


.b-ticket-small {

	.b-ticket-leg__short {
		flex-direction: column;
	}

	.b-ticket-leg__place {
		display: block;
		margin: 4px 0;
		position: relative;
		padding: 0 0 0 3px;

		&:before {
			content: "";
			height: 1px;
			background: @field1-elements-color;
			width: 10px;
			position: absolute;
			left: -21px;
			top: 11px;
			display: block;
		}

		&:after {
			content: "";
			height: 7px;
			width: 7px;
			display: block;
			overflow: hidden;
			.border-radius(50%);
			background: @field1-elements-color;
			position: absolute;
			left: -11px;
			top: 8px;
		}
	}

	.b-ticket-leg__transplants {
		margin-top: 4px;
		background: none;
		margin-left: -54px;

		&:before {
			width: 9px;
			height: 9px;
			left: 50px;
			top: -6px;
		}
	}

	.b-ticket-leg__from-to {
		display: none;
	}

	.b-ticket-leg__places {
		height: auto;
		line-height: 1.25;
		margin: 0 0;
		overflow: visible;
	}

	.b-ticket-leg__info {
		height: 78px;
		position: relative;
	}

	.b-ticket-leg__opairline {
		display:none;
	}

	.b-ticket-leg__info-line {
		top: initial;
		bottom: 40px;
		left: 68px;
		right: 94px;

		&:after {
			right: 0;
		}

	}

	.b-ticket-leg__date {
		top: 48px;
		font-size: @base-font-size;
	}

	.b-ticket-leg__extended-inner {
		border: @field2-border;
		margin-right: -1px;
	}

	.b-ticket-section {
		padding-left: 30px;

		&:last-child {
			margin-bottom: 0;
		}

	}

	.b-ticket-section__decoration {
		left: 13px;

		&:after {
			background: @field1-background-color;
		}

		&:before {
			background: @field1-background-color;
		}

	}

	.b-ticket-section__date {
		position: static;
		display: block;
		color: @field1-H1-color;
		text-align: left;
	}

	.b-ticket-section__time {
		position: static;
		display: inline;
		color: @field1-H1-color;
	}

	.b-ticket-stop:before {
		left: 13px;
	}

	.b-ticket-section__info-flight-item {
		float: none;
		width: 100%;
	}

	.b-ticket__footer {
		display: none;
	}

	.b-ticket-leg__travel-time {
		margin-left: -65px;
		top: 5px;
		padding-left: 22px;
		font-size: @base-H1-size;
	}

	.b-ticket-leg__date-departure {
		left: 5px;
	}

	.b-ticket-leg__date-arrival {
		right: 32px;
	}

	.b-ticket__price {
		display: none;
	}

	.b-ticket-stop {
		padding: 1px 0;
	}

	.b-ticket-section__place {
		@base-H2-size;
	}

}


/* NEW STYLES */
.l-content-table-title-avia {
	color: rgba(74, 87, 103, 0.8);
	text-align: right;
	font-size: 14px;
	font-weight: 500;
	right: 0;
	margin-left: auto;
}

.b-tickets-container-avia {
	margin-top: 8px;
}

.b-ticket {
	position: relative;
	z-index: 2;

	box-shadow: none;
	border-radius: 0;
}

.b-ticket__visible ~ .b-ticket__visible {
	margin: 27px 0;
}

.b-flight-ticket {
	background: #fff;
	border: 1px solid #A9A9A9;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
	border-radius: 3px;
	padding: 30px 65px 20px 20px;
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	//min-width: 924px;

	&__branded-fares:not(:empty) + .b-flight-ticket__standart-fares:not(:empty) {
		margin-top: 10px;
	}

  	&__content-container {
		width: 100%;
  	}

  	&__mobile-company-logos {
		display: none;
  	}

  	&__content-container {
	  display: flex;
	  flex-direction: row;
	  align-items: center;
	}

	&__short-container {
		position: relative;
		z-index: 2;
	}

	&__expand-container,
	&__branded-fares,
	&__standart-fares {
		background: #FFFFFF;
		border: 1px solid #A9A9A9;
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
		border-radius: 0 0 3px 3px;
		width: calc(~'100% - 40px');
		margin: 0 20px;
		padding: 25px 0 0;
		position: relative;
		bottom: 5px;
		z-index: 1;
		display: none;
	}

	&__expand-container {
		padding-top: 0;
	}

	&__branded-fares,
	&__standart-fares {
		padding-bottom: 20px;
	}

	&__expand-container {
		&-inner {
			.b-leg__wrapper {
				padding: 0 60px 0 20px;

				position: relative;

				& > div {
					position: relative;

					&:after {
						content: '';
						display: block;
						border-bottom: 1px dashed #AEB3B9;
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						margin: 0 -60px 0 -20px;
					}

					&:last-child:after {
						display: none;
					}
				}
			}
		}

		.b-leg {
			padding: 16px 0;

			&__wrapper + .b-leg__wrapper {
				&:before {
					content: '';
					border-top: 1px solid #A9A9A9;
					box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
					width: 100%;
					height: 1px;
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
				}
			}

			&-stop {
				padding-left: 130px;
			}

			&__date {
				&-time {
					font-size: 18px;
					font-weight: 500;
				}
			}
			&__flight {
				&-info {
					&-city {
						font-weight: 500;
						font-size: 16px;
					}
					&-perfom {
						margin-top: 0;
					}
				}
			}
			&__company-logo {
				margin-right: 45px;
			}
			&__description {
				display: none;
			}
			&__duration {
				font-size: 20px;
			}
		}
	}

	&__legs {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;

		.b-flight-ticket__leg {
			& + .b-flight-ticket__leg {
				margin-top: 20px;
			}
		}
	}

	&__price {
		text-align: center;
	}

	&__expand,
	&__expand-branded-fares {
		position: absolute;
		right: 0;
		bottom: 0;
		display: none;

		&.collapsed {
			display: block;
		}
	}

	&__extra-info-container {
		position: absolute;
		right: 4px;
		top: 4px;
		display: inline-flex;
		align-items: center;
		justify-content: flex-end;
		gap: 16px;

		& + .b-flight-ticket__price {
			margin-top: 20px;
			
		}

		& + .b-flight-ticket__price--multiticket {
			margin-top: 34px;
		}
	}

	&:after {
		content: '';
		display: block;
		width: calc(~'100% + 2px'); /* + border witdh 2px */
		position: absolute;
		height: 10px;
		background: #4A5767;
		border-radius: 3px;
		z-index: -1;
		left: -1px;
		top: -8px;
	}
}

.b-ticket__is-open {
	.b-flight-ticket {
		&__expand-container {
			.b-flight-ticket__expand {
				display: block;
			}
		}

		&__branded-fares {
			bottom: 0;
		}

		&__expand {
			display: none;
		}
	}
}

.b-ticket__fares-is-open {
	.b-flight-ticket__expand-branded-fares {
		display: block;
	}
}

.b-leg {
	display: flex;
	flex-direction: row;
	font-size: 13px;

	align-items: start;

	& > div {
		flex: 1 1 auto;
		position: relative;

		&.b-leg__services,
		&.b-leg__segments,
		&.b-leg__company-logo,
		&.b-leg__duration {
			flex-grow: 0;
		}

		&.b-leg__flights {
			flex-grow: 2;
			flex-basis: 50%;
		}
	}

  	&__mobile, &__mobile-controls {
		display: none;
  	}

	&__title {
		font-size: 20px;
		color: #4A5767;
		border: 0 !important;
		padding-top: 20px;

		&:after {
			display: none !important;
		}

		.b-leg__duration {
			color: #E50000;
		}
	}

	&__services {
		white-space: nowrap;
		margin: 0 10% auto 0;
	}

	&__company-logo {
		width: 100px;
		height: 42px;
		margin-right: 30px;
	}

	&__date {
		display: flex;
		align-items: center;
		white-space: nowrap;

		&-time {
			color: #4A5767;
			font-size: 16px;
			margin-right: 5px;
			font-weight: bold;
		}

		&-next-day {
			color: #ff8d2e;
		    font-size: 12px;
		    position: relative;
		    right: 4px;
		    top: -6px;
		}

		&-info {
			color: rgba(74, 87, 103, 0.8);
			font-size: 13px;
			text-transform: capitalize;
			font-weight: normal;
		}
	}

	&__flights {
		display: flex;

		& > .b-leg__flight {
			max-width: unset;
			width: 50%;
			flex: 1 1 auto;
			position: relative;

			&:first-child {
				max-width: 35%;
				margin-right: 18%;
			}

			&:before {
				content: '';
				position: absolute;
				right: calc(~'-18% - 10px');
				top: 15px;
				width: 20px;
				height: 20px;

				background: url("~assets/img/icons/bc-avia-direct-black.svg") center no-repeat;
				background-size: contain;
				opacity: .8;
			}

			&:last-child {
				.b-leg__flight-info {
					max-width: unset;
				}
				.b-leg__flight-info-destination {
					max-width: unset;
					margin-right: 10px;
				}
				&:before {
					display: none;
				}
			}
		}
	}

	&__flight {
		max-width: 25%;
		margin-right: 7.5%;
		margin-top: -0.5em;
		padding-top: 0.5em;

		&-info {
			font-weight: normal;
			color: rgba(74, 87, 103, 0.8);
			margin-top: 5px;

			&-destination {
				margin-bottom: 10px;
			}

			&-city {
				color: #4A5767;
			}

			&-airport {
				font-weight: normal;
			}

			&-important {
				color: #E50000;
			}

			&-perfom {
				margin-top: 4px;
				&-airlines {
					color: #E50000;
				}
			}
		}
	}

	&__description {
		color: rgba(74, 87, 103, 0.8);
		margin-bottom: auto;
		margin-top: 10px;
		width: 20%;
	}

	&__duration {
		color: #4A5767;
		font-size: 16px;
		margin-top: 5px;
	}

	&__segments {
		white-space: nowrap;
		margin: 0 auto auto 0;
		display: flex;
		&-container {
			display: flex;
			flex-direction: column;
		}

		&-duration {
			&-time {
				color: #4A5767;
				font-size: 16px;
			}
			&-stop {
				white-space: nowrap;
				& + .b-leg__segments-duration-stop {
					margin-top: 10px;
				}
			}
		}
		&-duration-stops {
			  	color: #FF8D2E;
				font-size: 13px;
				font-weight: 400;
				
			}

		&-iata {
			color: rgba(74, 87, 103, 0.8);
			font-size: 14px;
			font-weight: bold;
			margin-top: 4px;
		}
	}
}

.b-flight-services {
	display: flex;
	flex-direction: column-reverse;
	align-items: center;

	&__tariff {
		color: rgba(74, 87, 103, 0.8);
		font-size: 14px;
		font-weight: bold;
		position: relative;
		top: 1px;
	}

	&__options {
		margin: 0;
		padding: 0;
		display: inline-block;
		vertical-align: middle;
		position: relative;
		bottom: 2px;
	}

	&__option {
		list-style: none;
		display: inline-block;
		vertical-align: middle;
		margin: 0 3px;
		width: 22px;
		height: 22px;
		padding: 0;

		&.g-icon-option-cabin-luggage-OFF-black,
		&.g-icon-option-cabin-luggage-PAID-black,
		&.g-icon-option-cabin-luggage-FREE-black {
			background-position: 0 2px;
		}
	}
}

.b-flight-price {
	position: relative;
	font-size: 14px;

	&__top {
		margin-bottom: 10px;
	}

	&__button--offer,
	&__button {
		display: block;
		padding: 5px 50px;
		background: #4A5767;
		cursor: pointer;
		height: unset;
		border: 0;
		white-space: nowrap;
		font-size: 16px;
		font-weight: bold;

		> .currency {
			font-weight: 400;
		}

		&:hover {
			background: #E50000;
			border-color: #E50000;
		}
	}

	&__button--offer {
		font-size: 14px;
	}

  	&__commission-section {
	  margin-top: 10px;
	  display: flex;
	  flex-direction: column;
	  gap: 5px;

	  .b-flight-price__commission-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;

		& > i {
		  width: 14px;
		  height: 14px;
		  display: inline-block;
		}
	  }
	}
}

.b-flight-price__offer-total {
	white-space: nowrap;
	font-size: 18px;
	font-weight: bold;
	color: #4A5767;
	margin-bottom: 5px;
}

.b-ticket-leg__feature-tooltip {
	text-align: center;
	width: 200px;
}

.b-travel-policy {
	&-tooltip {
		text-align: center;

		.tooltip__header {
			color: #fff;
			display: inline-block;
			width: auto;
			padding: 0;
		}

		.tooltip__body {
			text-align: center;
			border: 0 !important;
			position: relative;
			margin-top: 5px;
			padding: 5px 0 0 !important;

			&:before {
				content: '';
				display: block;
				border-top: 1px solid #fff;
				position: absolute;
				left: 25%;
				right: 25%;
				top: 0;
				opacity: .3;
			}
		}
	}

	.b-ticket-leg__feature,
	.b-travel-policy-rule {
		margin-left: 6px;
		position: relative;
		display: inline-block;
		vertical-align: middle;

		&.g-icon-travel-policy-rules-RECOMMENDED,
		&.g-icon-features-3D-contract {
			width: 18px;
			height: 24px;
		}

		&.g-icon-travel-policy-rules-VIOLATION,
		&.g-icon-travel-policy-rules-STRICT_VIOLATION,
		&.g-icon-travel-policy-rules-AUTHORIZATION_REQUIRED {
			width: 18px;
			height: 24px;
		}
	}
}

.b-expand {
	height: 30px;
	width: 30px;
	border: 1px solid #AEB3B9;
	border-radius: 3px 0 3px;
	border-width: 0 1px 1px 0;

	transform: rotate(180deg);
	background: #4A5767 url("~assets/img/icon-arrow-down-white.svg") no-repeat;
	background-size: 14px 14px;
	background-position: 50% 50%;

  &.b-order__info-expand {
    background-color: #4A5767;
  }

	cursor: pointer;

	&.collapsed {
		transform: rotate(0);
		border-radius: 3px 0 3px;
		border-width: 1px 0 0 1px;
    border-bottom: 2px solid #AEB3B9;
    border-right: 2px solid #AEB3B9;
	}
}

.b-stop {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 20px 0;

  	&-short {
		.b-stop__locations-transfer,
		.b-stop__duration {
			color: @layover-color-little;
		}
  	}

	&-medium {
		.b-stop__locations-transfer,
		.b-stop__duration {
			color: @layover-color-medium;
		}
	}

    &-long {
		.b-stop__locations-transfer,
		.b-stop__duration {
			color: @layover-color-long;
		}
	}

	&__locations {
		flex: 1 1 auto;
		font-size: 18px;
		color: #4A5767;

		&-airport {
			font-size: 13px;
			color: rgba(74, 87, 103, 0.8);
		}
	}

	&__duration {
		font-size: 20px;
	}
}

.b-leg__wrapper {
	padding: 0 60px 0 20px;
	position: relative;

	.b-flight-services__options {
		.b-flight-services__option {
			width: 21px;
			height: 24px;
		}
	}

	.b-leg__duration {
		.b-flight-services__options {
			display: block;
			text-align: center;
			margin: 15px 0 0;
		}
	}

	.b-leg__description {
		.b-flight-services__options {
			margin: -10px 0 8px 0;
			display: none;
		}

	  	.b-flight-services__branded-fares {
		  font-weight: 500;
		  color: #4A5767;
		}
	}

	& > div {
		position: relative;

		&:after {
			content: '';
			display: block;
			border-bottom: 1px dashed #AEB3B9;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			margin: 0 -60px 0 -20px;
		}

		&:last-child:after {
			display: none;
		}
	}

	.b-leg {
		padding: 16px 0;

		&__wrapper + .b-leg__wrapper {
			&:before {
				content: '';
				border-top: 1px solid #A9A9A9;
				box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
				width: 100%;
				height: 1px;
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
			}
		}

		&-stop {
			padding-left: 130px;
		}

		&__date {
			&-time {
				font-size: 18px;
				font-weight: 500;
			}
		}
		&__flight {
			&-info {
				&-city {
					font-weight: 500;
					font-size: 16px;
				}
				&-perfom {
					margin-top: 0;
				}
			}
		}
		&__company-logo {
			margin-right: 45px;
		}
		&__description {
			display: none;
		}
		&__duration {
			font-size: 20px;
		}
	}

	& + .b-leg__wrapper {
		&:before {
			content: '';
			border-top: 1px solid #A9A9A9;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
			width: 100%;
			height: 1px;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
		}
	}

	.b-leg__description {
		display: none;
	}

	.b-leg__flight-description {
		display: block;
	}
}


.b-ticket-popup-offers {
	position: fixed;
	right: 33px;
	bottom: 25px;
	z-index: 19999;
	background: #4882BD;
	box-shadow: 6px 6px 14px rgba(0, 0, 0, 0.2);
	border-radius: 29px;
	color: #fff;
	font-weight: bold;
	font-size: 16px;
	padding: 15px 30px 15px 20px;
	cursor: pointer;

	&:after {
		content: '';
		display: inline-block;
		position: absolute;
		right: 18px;
		top: 50%;
		transform: translate(0, -50%);
		background: url('~assets/img/icons/icon-arrow-right-w.svg') center no-repeat;
		background-size: contain;
		width: 5px;
		height: 10px;
	}
}

.btn.b-flight-price__button--offer {
	position: relative;
	background: none;
	color: #4A5767;

	&::before {
		content: "+";
		position: absolute;
		left: 25px;
		top: 2px;
		z-index: 100;

		display: block;
		width: 20px;
		height: 20px;

		font-size: 14px;
		border: 2px solid #4A5767;
		border-radius: 50%;

		transition: transform 0.2s;
	}

	&:hover {
		color: #D70000;

		&::before {
			border-color: #D70000;
		}

	}
}

.btn.b-ticket-offer__cancel {
	color: #D70000;

	&::before {
		border-color: #D70000;
		transform: rotate(45deg);
	}
}

.relative {
	position: relative;
}

.b-flight-ticket {
	.b-flight-ticket__gds-info {
		padding-left: 5px;
		padding-top: 5px;
    	position: absolute;
    	left: 0;
    	bottom: 0;
    	width: 300px;
    	height: 25px;
    	z-index: 3;
		font-size: 13px;
		color: #4A5767;
	}
}

@import "./style.mobile";
