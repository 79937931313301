@import (reference) "~assets/css/imports";
@import "./b-passengers-block";
@import "./b-passengers-info";
@import "./b-passengers-info-ext";

.b-flight-info {
  .rounded;
  .shadow;
  background: @field2-background-color;
  border-bottom: @field2-border;
}

.b-flight-info__header {
  background: @field2-background-color;
  padding: 0 15px;
  height: 45px;
  line-height: 45px;
  .rounded-top;
}

.b-flight-info__compay-logo {
  width: 100px;
  height: 42px;
  float: left;
  top: 2px;
  position: relative;
}

.b-flight-info__options {
  padding: 0;
  margin: 0 0 0 10px;
  float: left;
  .clearfix;
  position: relative;
}

.b-flight-info__options:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -13px;
  height: 26px;
  width: 1px;
  display: block;
  background: rgba(0, 0, 0, 0.3);
}

.b-flight-info__option {
  margin: 10px 10px;
  float: left;
  list-style: none;
  width: 23px;
  height: 23px;
}

.b-flight-info__option:before {
  display: none;
}

.b-flight-info__legs-direction {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: #F0F0F0;
  display: flex;
  justify-content: space-between;
  padding: 9px;

  .b-flight-info__direction__value {
    color: #2F3842;
    margin-right: auto;
  }

  .b-flight-info__price__value {
    margin-left: auto;
  }
}

.b-flight-info__commission-section {
  margin-top: -5px;
  display: flex;
  background: #fff;
  border-top: 1px solid #4A5767;
  border-bottom: 1px solid #4A5767;
  padding: 10px;
  flex-direction: column;
  gap: 5px;

  .b-flight-info__commission-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;

	& > i {
	  width: 14px;
	  height: 14px;
	  display: inline-block;
	}
  }
}

.b-flight-info__messages-list {
  list-style: none;
  margin: 0;
  padding: 10px;
  color: #fff;
}

.b-flight-info__messages-list-item {
  padding: 0;
}

.b-flight-info__messages-list-item + .b-flight-info__messages-list-item {
  margin-top: 8px;
}

@import "./style.mobile";
