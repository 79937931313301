@import (reference) "~assets/css/imports";

.b-refund-exchange, .b-exchange {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  z-index: 9999;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);


  &__modal {
	width: 60%;
	height: min-content;
  }

  &__header {
	width: 100%;
	background-color: #4A5767;
	color: #FFFFFF;
	padding: 8px 16px;
	border: 1px solid #4A5767;
  }

  &__content {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 16px;
	background-color: #FFFFFF;
	border: 1px solid #4A5767;
	max-height: 500px;
	overflow-y: auto;
  }

  &__traveller-view {
	width: 100%;
	margin-bottom: 20px;
  }

  &__traveller-service {
	display: flex;
	padding: 10px;
	padding-right: 36px;
	gap: 20px;
	align-items: center;
	justify-content: flex-end;
	font-size: 14px;
	color: #4A5767;
	margin-bottom: 10px;

	&__ticketNumber {
	  display: flex;
	  gap: 10px;

	  &:before {
		content: '';
		display: block;
		width: 17px;
		height: 17px;
		.g-icon;
		background-image: url("~assets/img/icons/icon-tickets.svg");
	  }
	}

	&__fullName {
	  display: flex;
	  gap: 10px;

	  &:before {
		content: '';
		display: block;
		width: 17px;
		height: 17px;
		.g-icon;
		background-image: url("~assets/img/transfers/icons/icon-passenger.svg");
	  }
	}
  }

  &__flight {
	width: 100%;
	border: 1px solid #4A5767;
	border-radius: 3px 3px 3px 3px;
  }

  &__flight-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px;
	border-bottom: 1px solid #4A5767;
  }

  &__flight-price {
	font-size: 16px;
	font-weight: 700;
  }

  &__flight-legs {

  }

  &__flight-leg {
	padding: 10px 26px;

	& + & {
	  border-bottom: 1px solid #4A5767;
	}
  }

  &__flight-segment {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
  }

  &__footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 16px;
	font-weight: 500;
  }

  &__refund-price {
	display: flex;
	align-items: center;
	gap: 10px;
  }

  &__refund-price-details {

	&:before {
	  content: '?';
	  display: flex;
	  width: 20px;
	  justify-content: center;
	  align-items: center;
	  height: 20px;
	  border: 1px solid #4A5767;
	  border-radius: 50%;
	  font-weight: normal;
	  font-size: 14px;
	  padding: 5px;
	}
  }

  &__refund-price-total {
  }

  &__modal-buttons {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 40px;

	& > button {
	  border: none;
	  padding: 10px 40px;
	  position: relative;
	  cursor: pointer;


	  &:after,
	  &:before {
		content: ' ';
		position: absolute;
		width: 50px;
		top: 0;
		height: 100%;
		z-index: -1;
		cursor: pointer;
	  }

	  &:before {
		transform-origin: right bottom;
	  }

	  &:after {
		transform-origin: left bottom;
	  }
	}
  }

  &__cancel {
	background: #fff;
	color: #4A5767;
	&:after,
	&:before {
	  border-color: #fff;
	  background: #fff;
	}

	&:after {
	  right: 0;
	  transform: skew(320deg);
	}

	&:before {
	  left: 0;
	  transform: skew(-320deg);
	}
  }

  &__confirm {
	background: #4A5767;
	color: #fff;

	&:after {
	  right: 0;
	  transform: skew(320deg);
	}

	&:before {
	  left: -40px;
	  transform: skew(320deg);
	}

	&:after,
	&:before {
	  background: #4A5767;
	  border-color: #4A5767;
	}

	&.disabled {
	  background: rgba(74, 87, 103, 0.8);
	  color: rgba(255, 255, 255, 0.8);
	  cursor: default;

	  &:after,
	  &:before {
		background: rgba(74, 87, 103, 0.8);
		border-color: rgba(255, 255, 255, 0.8);
	  }
	}
  }
}

.b-segment {
  color: #4A5767;
  font-size: 16px;
  font-weight: 500;

  & > * {
	flex: 1 1 25%;
	min-width: 10%;
	display: flex;
	justify-content: center;
	align-items: center;
  }

  &__name {
  }

  &__aircraft {

  }

  &__company-logo-container {

  }

  &__company-logo {
	.g-icon;
	width: 100px;
	height: 50px;
  }

  &__departure-date {
  }

  &__travel-time-container {
	justify-content: center;
  }

  &__travel-time {

  }
}
