@import (reference) "~assets/css/imports";

.b-ticket-info__header {
	padding: 0 9px;
	.rounded-top;
	overflow: hidden;
	background-color: @field2-background-color;
	border-top: @field2-border;
	border-left: @field2-border;
	border-right: @field2-border;

	.b-ticket-header {
		float: none;
	}

	.b-travel-policy {
		float: right;
	}

	.b-operating-airline {
		float: none;
		padding-bottom: 8px;
	}

}

.b-ticket-info__header-separator {
	height: 1px;
	background: #0a0a0e;
	margin-top: 8px;
	margin-bottom: 8px;
}

.b-flight-services__tariff-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;


  .b-fare-rules-group {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

}

.b-ticket-info__legs {
	.b-flight-services__options-wrapper {
		padding-top: 20px;
		display: flex;
		align-items: center;
		width: 100%;
	}

	.b-flight-services__tariff {
		margin-right: 20px;
		top: unset;
	}

	padding-bottom: 5px;
	.b-ticket-info__legs-leg {
		&-direction {
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			background: #F0F0F0;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			padding: 9px;

			.direction__label {
				font-size: 13px;
				color: rgba(74, 87, 103, 0.8);
			}

			.direction__value {
				font-size: 13px;
				color: #2F3842;
				width: 160px;
			}

		  	.b-ticket-info__legs-leg-carrier, .b-ticket-info__legs-leg-flight-number  {
			  font-size: 13px;
			  margin-top: 5px;
			  width: 100%;
			  display: flex;
			  justify-content: space-between;

			  > span:first-of-type {
				color: rgba(74, 87, 103, 0.8);
			  }

			  > span:last-of-type {
				width: 160px;
			  }
			}

			.b-flight-services__options {
				display: block;
				margin: 0;

				&:before {
					display: none;
				}
			}
		}

		.b-ticket-info__legs-leg-info {
			background: #FFFFFF;
			padding: 25px 9px;
			position: relative;
			display: flex;

			.ticket-info__description {
				margin-left: 5px;
			}

			.ticket-info__transplants {
				background: #4A5767;
				display: block;
				width: 3px;
				font-size: @base-H1-size;
				color: @field1-active-color;
				margin-left:12px;
				margin-right:12px;
			}

			.ticket-info__transplants:after, .ticket-info__transplants:before {
				content: " ";
				display: block;
				border: 2px solid @field1-object-color;
				background: @field1-background-color;
				border-radius: 50%;
				width: 14px;
				height: 14px;
				position: absolute;
				margin-top: 0;
				left: 15px;
			}

			.ticket-info__transplants:after {
				bottom: 22px;
			}

			.ticket-info__leg-travel-duration {
				border-top: 2px dotted rgba(0, 0, 0, 0.1);
				border-bottom: 2px dotted rgba(0, 0, 0, 0.1);
				padding: 13px 0 10px 27px;
				margin-top: 10px;
				margin-bottom: 10px;

				.travel-duration {
					font-weight: bold;
				}
			}

			.ticket-info__leg-segment {
				margin-bottom: 5px;
			}

		}
	}
}
