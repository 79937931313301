@import (reference) "~assets/css/imports";

.dropBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  .box-shadow(~'0 10px 20px #4882bd');
  top: 0;
  z-index: 10;
  left: 0;
  background: #99c9f0;
  color: #4882BD;
  opacity: 0.8;
}

.b-order__container-title {
  margin-bottom: 30px;
}
.b-order__notes {
    &-container {
        position: relative;

	  	.b-order-notes__drop-background {
		  display: none;
		}

	  	&.dragging .b-order-notes__drop-background {
		  .dropBackground();
		}
    }

  	&-container, &-container-issues {
	  .b-cabinet-order__notes-create {
		line-height: normal;
		height: unset;
		margin-left: auto;

		padding: 2px 13px 2px 19px;
		font-size: 12px;
		background: #fff;
		border: 1px solid #099C9D;
		color: rgba(74, 87, 103, 0.8);
		outline: none !important;

		.g-icon-ok	{
		  width: 10px;
		  height: 7px;
		  display: inline-block;
		  vertical-align: middle;
		}
	  }
	}

    &-container-footer {
        margin-top: 20px;
        text-align: right;
    }

    .b-textarea {
        padding: 0;

        &__value {
            resize: none;
            border: 1px solid #D9D9D9;
            border-radius: 3px;
            color: #4A5767;
            height: 105px;
            padding-bottom: 35px;

            &::-webkit-input-placeholder,
            &:-ms-input-placeholder,
            &::placeholder {
                color: red !important;
                opacity: 0.5;
            }
        }
    }

    .b-order__container {
        &-title {
            display: inline-block;
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 500;

            &:after {
                width: 18px;
                height: 10px;
                top: calc(~'50% - 5px');
            }
        }
    }
}

.b-order__attachments {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-flow: row wrap;

  .b-uploaded-attachment {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	background: #4882bd;
	font-size: 12px;
	.border-radius(4px);
	color: #fff;
	padding: 5px;

	& > .g-icon-w-cancel {
	  width: 12px;
	  height: 12px;
	  cursor: pointer;
	}
  }
}

.b-order-issues {
    margin-bottom: 45px;
}
.b-order-issues__empty {
    text-align: center;
}
.b-order-issues__item {
    display: flex;
    flex-flow: column;
    position: relative;

  	&-textarea-container.dragging &-drop-background {
	 .dropBackground();
	}
	&-drop-background {
	  display: none;
	}

    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.5;
        border: 1px solid #FFFFFF;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    }

    & + .b-order-issues__item {
        margin-top: 20px;
    }
}
.b-order-issues__item-name {
    background: #FAFAFA;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    padding: 8px 30px 8px 10px;
    font-size: 14px;
    line-height: 16px;
    color: #4A5767;
}
.b-order-issues__item-title {
    white-space: nowrap;
    max-width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.b-order-issue__posts {
    margin-top: 30px;
    max-height: 30vh;
    overflow: auto;
}
.b-order-issue__post-date {
    color: #4A5767;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    margin: 17px auto 35px;
}
.b-order-issue__post {
    margin: 0 0 25px;

    &--outself {
        text-align: right;
        .b-order-issue__post-content {
            border-radius: 8px 0 8px 8px;
        }
        .b-order-issue__post-attachments {
            margin-left: auto;
        }
    }

    .g-icon-download {
        vertical-align: middle;
        display: inline-block;
        height: 15px;
        width: 15px;
    }
}
.b-order-issue__post-author {
    color: #A5ABB3;
    font-size: 14px;
    line-height: 16px;
    margin: 10px 0;
}
.b-order-issue__post-content {
    display: inline-block;
    border: 1px solid #099C9D;
    box-sizing: border-box;
    border-radius: 0 8px 8px 8px;
    padding: 10px 15px;
    width: auto;
  	overflow-x: auto;
    max-width: 85%;
    font-size: 14px;
    line-height: 16px;
    color: #4A5767;

  & > pre {
	margin: 0;
	white-space: pre-wrap;
  }
}
.b-order-issue__post-attachments {
    margin-top: 10px;
    max-width: 45%;
    margin-bottom: -5px;
}
.b-order-issue__post-attachment {
    display: inline-block;
}
.b-order-issue__post-attachment-container {
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 5px;

    & + .b-order-issue__post-attachment-container {
        margin-left: 10px;
    }
}
.b-order-issue__post-attachment-size {
    font-size: .9em;
}

.b-order__voucher-message {
    display: none;
    padding: 10px 0;

    &--shown {
        display: block;
    }
}


