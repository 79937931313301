@import (reference) "~assets/css/imports";

.b-cabinet-person {
	.rounded-top;

	.b-cabinet-get-auth-key {
		background: #4882BD;
		border-radius: 3px;
		height: 35px;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 0;
	    max-width: 275px;
	    cursor: pointer;
	}

	.b-two-factor-auth-token {
	    color: #060604;
	    font-size: 15px;
	    font-weight: bold;
	    letter-spacing: 5px;
	}

	.b-two-factor-auth-image {
		display: block;
		margin: auto;
	}

	.incomplete .b-input__value.b-masked-input-date__value {
		color: @input-placeholder-color;
	}
	.b-input__value:not(.b-input__select-value):not(.b-masked-input-date__value), .b-input-phone__value {
		color: @field1-active-color;
	}

	.b-cabinet-block {
		&:after {
			content: '';
			display: block;
			height: 1px;
			width: 100%;
			background: rgba(0, 0, 0, 0.1);
			border-radius: 2px;
			margin: 9px 0;
		}

		&:last-child:after {
			display: none;
		}
	}

	.b-datepicker__field {
		color: inherit;
		background: none;
		height: 30px;
		line-height: 30px;
	}

	.p-search__options-date-with-label {
		position: relative;
		padding-bottom: 5px;

		.p-search__direction-date {
			margin-top: -8px;
		}

		.p-search__direction-date-calendar {
			background-color: unset;
			height: 30px;
			width: 30px;

			.p-search__direction-date-calendar-img {
				background: url("~assets/img/icon-calendar-black-1.svg");
				background-repeat: no-repeat;
				background-position: center;
			}
		}
	}

	.p-login__form-password {
		margin: 0;
		max-width: unset;
		display: block;
		width: 100%;
		position: relative;
		padding-bottom: 5px;

		.p-login__form-password-title {
			font-size: 14px;
			color: #4A5767;
			margin-bottom: 2px;
			display: inline;
			width: min-content;
			position: relative;
			margin-left: 4px;
			margin-right: 4px;
			padding: 0 4px;
			z-index: 3;
			top: 0;
			left: 0;
			font-family: inherit;
			font-weight: normal;
			line-height: normal;
			background: none;

			&:after {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				top: calc(50%);
				height: 3px;
				background: #ffffff;
				z-index: -1;
			}
		}

		.p-login__form-input {
			border: 1px solid rgba(74, 87, 103, 0.4);
			font-size: 14px;
			color: #2F3842;
			padding: 0 10px;
			height: 30px;
			width: 100%;
			border-radius: 3px;
			z-index: 2;
			background: none;
			background-clip: padding-box;
			position: relative;
			margin-top: -8px;
			display: block;
		}
	}

	&__cost-codes-wrapper {
		.flex-grid-container {
			width: 100%;
			margin-bottom: -8px;

			.flex-grid-layout {
				width: 33.33333%;
				padding-right: 10px;

				&:nth-child(3n) {
					padding: 0;
				}
			}
		}
	}

  	.b-cabinet-person__bonus-card {
	  .b-airlines-input__input {
		border: 1px solid rgba(74, 87, 103, 0.4);
		height: 30px;
	  }
	}
}

.b-cabinet-person__buy {
	height: 50px;
	background: @button1-color;
	text-align: center;
	line-height: 50px;
	text-transform: uppercase;
	display: block;
	.rounded-bottom;
	cursor: pointer;
}

.icon__help {
	.g-icon-help;
	height: 16px;
	width: 16px;
	display: inline-block;
	vertical-align: middle;
}

@import "./style.mobile";
