.b-button-container {
	&__top {
		.shadow;
		position: relative;

		& > * {
			position: relative;
			z-index: 2;
		}

		&:before {
			content: " ";
			display: block;
			right: -2px;
			width: 100%;
			height: 100%;
			.rounded-top;
			position: absolute;
			z-index: 1;
		}
	}

	.b-button-container__footer {
		justify-content: center;
		display: flex;
		width: 100%;
		color: @button1-font-color;

		.b-button-container__button {
			text-transform: none;
			font-weight: 500;
			font-size: 14px;
			border: 1px solid #A9A9A9;
			border-left: 0;
			border-right: 0;
			height: unset;
			line-height: unset;
			margin-right: 9%;
			border-radius: 0;
			width: unset;
			padding: 8px 10px;
			overflow: visible;
			position: relative;
			min-width: 100px;
			cursor: pointer;
			text-align: center;
			box-sizing: content-box;

			.g-icon {
				width: 20px;
				height: 17px;
				display: inline-block;
				vertical-align: middle;
				margin-right: 15px;
				position: relative;
				bottom: 2px;
			}

			&:only-child {
				min-width: 135px;
			}

			&:first-child:not(:only-child) {
				min-width: 105px;
			}

			& > span {
				max-width: 200px;
				overflow: hidden;
				text-overflow: ellipsis;
				position: relative;
				z-index: 2;
			}

			&:last-child {
				margin-right: 0;
			}

			&:after,
			&:before {
				position: absolute;
				content: '';
				top: -1px;
				bottom: -1px;
				z-index: 1;
				background: #A9A9A9;
				border-width: 2px;
				border-style: solid;
				border-color: #A9A9A9;
				width: 60%;
			}

			&:before {
				right: 40%;
				border-width: 1px 0 1px 2px;
				transform-origin: left bottom;
				transform: skew(60deg);
			}

			&:after {
				left: 40%;
				border-width: 1px 2px 1px 0;
				transform-origin: right bottom;
				transform: skew(-60deg);
			}

			&:nth-child(2n-1) {
				background: #A9A9A9;
				color: #fff;
			}

			&:nth-child(2n) {
				background: #fff;
				color: #4A5767;

				&:before {
					right: 100%;
					transform: skew(-60deg);
					z-index: 0;
				}

				&:before,
				&:after {
					background: #fff;
				}
			}
		}
	}
}
