@media (min-width: 1440px) {

}

@media (min-width: 1920px) {
	.b-hotel {
		&-room__toggle-caption {
			pointer-events: none;
		}
		&-room {
			.b-hotel-room__content-description .b-hotel-room__info-wrapper {
				display: none;
			}

			.b-hotel-room__additional-services .b-hotel-room__info-wrapper {
				display: flex;
			}
		}

		&-room__row {
			display: flex;
			flex-flow: row nowrap;
			margin-bottom: 10px;
			box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
		}

		&-room__additional-services,
		&-room__content {
			border-radius: 0;
			box-shadow: none;
			margin: 0;
			flex: 1;
		}

		&-room__content {
			max-width: 45%;
			padding: 30px 10px 0 18px;
		}

		&-room__toggle-caption:after {
			display: none;
		}

		&-room__toggle-container.collapse:not(.show) {
			display: block !important;
		}

		&-room__additional-services {
			border-left: 1px solid rgba(47, 56, 66, .3);
		}

		&__toggle-container,
		&__content-rightbar {
			margin-right: 0;
		}
	}
}

@media @tablet {
  .b-hotel-room {
	&__wrapper {
	}

	&__header-wrapper {
	}

	&__header {
	}

	&__row {
	}

	&__content {
	  padding: 20px 12px;
	}

	&__content-header {
	}

	&__content-leftbar {
	  width: 100%;
	}

	&__name {
	  display: flex;
	  align-items: center;
	  padding: 10px;
	  height: 40px;
	  width: 100%;
	  background: #EDEEF0;
	  text-transform: capitalize;
	}

	&__content-notification {
	  background: #FCEFE0;
	  color: #FF8D2E;
	}

	&__content-description {
	}

	&__toggle-caption-wrapper {
	  margin-bottom: 0;
	  padding-bottom: 16px;
	  background: #FCEFE0;
	  color: #FF8D2E;
	  order: 2;
	}

	&__toggle-caption {
	  background-color: #FCEFE0;
	  padding-left: 30px;
	  .g-icon-coin-orange;
	  background-size: 10% 100%;
	  background-position: 2% 10%;
	}

	&__toggle-container {
	  background: #FCEFE0;
	  order: 3;
	  box-shadow: none;
	  border: none;
	  padding: 0 16px;
	  margin: 0;
	}

	&__toggle-container-essantial {
	  border-bottom: unset;
	  margin-left: 30px;
	  padding: 0;
	}

	&__toggle-container-penalty {
	}

	&__important {
	}

	&__toggle-container-inner {
	  padding: 10px 0 0 30px;
	}

	&__info-wrapper {
	  flex-flow: column;
	  padding: unset;
	  align-items: unset;
	  justify-content: unset;

	  .b-hotel-room__info-item {
		margin-right: unset;
		padding: 12px;
		border-left: unset;
		flex-flow: row;
		align-items: center;
		gap: 10px;
	  }

	  .b-hotel-room__info-item:not(:last-of-type) {
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	  }
	}

	&__info-service {
	  font-weight: 500;
	}

	&__info-value {
	  margin-top: unset !important;
	}

	&__additional-services {
	  padding: 0 !important;
	}

	&__travellers {

	}

	&__offer-remarks {
	  .b-remarks {
		max-width: unset;
	  }
	}
  }

  .b-hotel-travellers {
	&__header-wrapper {
	}

	&__header {
	  display: flex;
	  align-items: center;
	  text-transform: uppercase;

	  &:before {
		display: block;
		margin-right: 10px;
		content: ' ';
		width: 22px;
		height: 22px;
		.g-icon-passenger-black;
	  }
	}

	&__wrapper {
	  box-shadow: none;
	}
  }
}


