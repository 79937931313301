@media (min-width: 1440px) {
	.b-booking-hotels {
		.b-customer__info {
			.l-grid-container {
				margin-bottom: 18px;
			}

			.l-grid-layout-33 {
				padding-right: 40px;
			}
		}
	}
}

@media (min-width: 1920px) {
	.b-booking {
		&-room__additional-services-list {
			.l-grid-container .l-grid-layout-33 {
				width: 33.33333%;
				padding-right: 35px;

				&:nth-child(4n) {
					padding-right: 35px;
				}

				&:nth-child(3n) {
					padding-right: 0;
				}
			}
		}
	}

	.b-booking-hotels {
		.b-customer__info {
			.l-grid-layout-33 {
				padding-right: 18px;
				width: 25%;
			}
		}
	}
}

@media @tablet {
  .b-booking-hotels__notes {
	background: #FAFAFA;
	padding: unset;

	.b-booking-notes__caption {
	  color: #fff;
	  font-size: 16px;
	  font-weight: 500;
	  background: #4A5767;
	  border-radius: 3px 3px 0 0;
	  padding: 12px;
	  box-shadow: none;
	  position: relative;
	  text-transform: uppercase;
	  z-index: 1;
	  display: flex;
	  align-items: center;

	  &:before {
		width: 22px;
		height: 22px;
	  }
	}

	.b-textarea.toggle-notes {
	  padding: 12px;
	}
  }

  .b-booking__price-info-container {
	padding: 12px;

	.b-hotel-info__wrapper {
	  margin-bottom: unset;
	  background: unset;

	  .b-hotel-info__pricing {
		padding: unset;
	  }
	}
	.b-hotel-info {
	  display: none;
	}
  }

  .b-booking__customer {
	margin-bottom: 5px;
  }

  .b-hotel-pricing__service {
	margin: 0 !important;
  }

  .b-hotel-pricing {
	margin-top: 10px;
	color: #4A5767 !important;

	&__header {
	  display: none;
	}
  }
  .b-hotel-pricing__services, .b-hotel-pservice__element, .b-hotel-pservice__caption {
	color: #4A5767 !important;
  }
}
