.r-button {
  width: auto;
	height: 30px;
	
	align-items: center;
	padding: 0 8px;

  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
	border-radius: 3px;
  border:0px;
  background: #E50000;

  font-family: 'Roboto', sans-serif;
	font-size: 14px;

  color: #fff;
}

.p-button-rounded {
	border-radius: 5px;
}
