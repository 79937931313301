@media @tablet {
  .b-order {

	&__header {
	  &-info_right {
		max-width: 20%;
	  }
	  &-title, &-info-client, &-date {
		font-size: 14px;
	  }
	}

	.missing-info-warning {
	  width: 16px;
	  height: 18px;
	  background-size: 16px;
	}

	.b-order-reservation {
	  margin: 0 12px 16px 12px;

	  .b-order__container-header {
		background-size: cover !important;
		display: flex;
		flex-direction: column;
		height: unset;
		padding: unset;

		.b-order-price {
		  font-size: 14px;
		  background-color: rgba(74, 87, 103, 0.7);
		  padding: 5px 10px;
		  .border-radius(15px);
		}
	  }

	  .b-order__container-header-label {
		width: 100%;
		padding: 5px 10px;
		white-space: normal;
		background: rgba(74, 87, 103, 0.5);
		display: flex;
		align-items: center;

		.b-order-label__route {
		  max-width: unset;

		  &:after {
			display: none !important;
		  }
		}

		.b-checkbox {
		  line-height: 0 !important;

		  &__view:before, &__view:after {
			border-color: #fff;
		  }
		}
	  }

	  .b-order__container-header-right {
        display: flex;
        flex-wrap: wrap;
		padding: 5px 10px;
		margin-left: unset;
		color: #fff;
		font-size: 14px;
		width: 100%;
		background: rgba(74, 87, 103, 0.5);

		.b-order-label__item:not(.b-order-label__create-date) {
		  order: 1;
		}

		.b-order-label__item:after {
		  content: unset;
		}

		.b-order-label__create-date {
		  order: 2;
		  // margin-left: 15px;
		}

		.b-order-price {
		  order: 5;
		  margin-left: auto;
		}

		.b-order-label__item-simple {
		  display: none;
		}
	  }

	  .b-order__container-content {
		border: unset;

		.b-block-table {
		  text-transform: unset !important;

		  &__row-header {
			display: none !important;
		  }

		  &__row {
			display: flex;
			flex-direction: column;
			position: relative;

			&:last-of-type {
			  padding: 10px 0 0 0;
			}
		  }

		  &__cell {
			font-size: 14px;
			padding: 0;
			border-top: none !important;
			border-bottom: none !important;
			order: 3;

			& > div:not(.b-order__info-footer-container), & > span:not(.b-order__info-status) {
			  padding: 5px 12px;
			}

			&-type-checkbox {
			  padding: 5px 12px;
			  order: 0;
			}

			&--checkobx-with-caption {
			  width: 100%;
			  display: flex;
			  justify-content: flex-start;
			  align-items: center;
			}

			&.mobile-cell {
			  display: flex;
			  position: relative;
			  padding: 0;
			  overflow: unset;
			  justify-content: flex-end;

			  .btn {
				border: none;
			  }

			  & > div, & > span {
				padding: 0 !important;
			  }

			  .b-payment__cancel {
				background: #EDEEF0;
				color: #A4ABB3;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 0;
				border: none;
				padding: 0;
				.border-radius(~'0px 0px 0px 4px');
				flex: 1 1 50%;
			  }

			  .b-order__intention-approval {
				background: #099C9D;
				color: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				flex: 1 1 45%;
				.border-radius(0px);
			  }

			  .b-order__intention-approval-container {
				display: flex;
				flex: 1 1 45%;
			  }
			}

			.b-order__info-status {
			  background-color: #EDEEF0;
			  color: #A4ABB3;
			  border-radius: 20px;
			  padding: 5px 10px;
			  margin-right: 10px;
			  display: inline-block;
			  float: right;

			  .b-order-mco {
				display: flex;
			  }

			  &--PAYED {
				background-color: #DAE6F2;
				color: #4882BD;
			  }
			}

			.b-order-hotel__notifications {
			  margin-left: unset;
			  width: 100%;
			  max-width: unset;

			  .b-order-hotel__notification {
				padding: 10px 10px 10px 25px;
				position: relative;
			  }

			  .b-notification--type-alert {
				background-color: #D7000026;
				color: #4A5767;


				& > strong {
				  color: #D70000;
				}
			  }

			  .b-order-hotel__notification:not(.b-notification--type-alert) {
				background: #FCEFE0;
				color: #4A5767;
				padding-left: 25px;

				& > div > span {
				  color: #FF8D2E;
				}

				&:before {
				  content: '';
				  width: 17px;
				  height: 17px;
				  display: block;
				  position: absolute;
				  left: 3px;
				  top: 10px;
				  background: url("~assets/img/icons/icon-coin-orange.svg") no-repeat center;
				}
			  }

			  .b-order-hotel__notification + .b-order-hotel__notification {
				margin: 20px 0;
			  }
			}
		  }

		  &__cell-header-mobile {
			color: #A4ABB3;
			margin-right: 5px;
		  }

		  &__cell-content-container {
			display: flex;

			& > span:not(:last-of-type), & > div:not(:last-of-type) {
			  margin-right: 5px;
			}
		  }

		  &__cell-fullname {
			text-transform: uppercase;
			color: #4A5767;
			order: 0;
		  }

		  &__cell-ticket-number {
			order: 1;
		  }

		  &__cell-status {
			order: 3;
			display: flex;
			margin-bottom: 10px;
		  }

		  &__cell-travel-policy {
			position: absolute;
			bottom: 0;
			right: 0;
			width: 100px;

			& > .b-block-table__cell-content-container {
			  display: flex;
			  justify-content: flex-end;
			  flex-flow: row wrap;
			  width: 100%;
			  margin-left: auto;

			  & > span {
				flex: 0 0 33.333333%;
				margin-bottom: 5px;
				margin-right: unset !important;
			  }
			}
		  }

		  &__cell-empty, &__cell--width-auto {
			display: none;
		  }

		  .b-order__info-expand {
			display: none;
		  }

		  .b-order__info-footer {

			&-right {
			  display: flex;
			  padding: unset;
			  margin: unset;
			}

			.mobile-hide {
			  display: none !important;
			}
		  }
		}

		.b-order__info-expand {
		  display: none !important;
		}
	  }

	  .b-order__container-expand {
		margin: 0;

		.b-order__info-expand {
		  display: none;
		}

		&-inner {
		  // avia
		  .b-leg__wrapper {
			padding: 0 10px;

			.b-leg > div:not(.b-leg__mobile) {
			  display: none;
			}

			.b-leg__mobile {
			  align-items: unset;

			  &-header {
				font-size: 16px;
				line-height: 19px;
				font-weight: 500;
			  }

			  &-info {
				display: flex;
				justify-content: flex-start;

				.b-leg__mobile-flight-number {
				  margin-left: auto;
				}
			  }

			  &-carrier-info {
				display: flex;
				flex-direction: column;
				gap: 7px;
			  }

			  &-company-icon-wrapper {
				width: 50px;
				height: 50px;
				margin-right: 12px;
				display: flex;
				overflow: hidden;
				.border-radius(50%);
			  }

			  &-company-icon {
				background-repeat: no-repeat;
				background-position: center;
				background-size: 80%;
				width: 50px;
				height: 50px;
			  }
			}
		  }

		  // hotel
		  .b-hotel-order-info {
			padding: 10px;

			.b-hotel-order_column {
			  margin-bottom: 10px;
			  display: flex;
			  flex-direction: column;

			  .b-hotel-order__column-container {
				padding: 10px 0;
				margin-right: 0;

				.b-hotel-order__line {
				  display: flex;
				  flex-direction: column;

				  & > .b-hotel-order__name {
					margin-right: 0;
					font-size: 16px;
					order: 1;
				  }
				}
			  }

			  .b-hotel-order_slider {
				padding: 0;
				max-width: unset;
				display: flex;
				align-items: center;
				justify-content: center;
			  }

			  .b-hotel-order__description {
				display: flex;
				flex-direction: column;
				gap: 7px;

				.b-hotel-order__link, .b-hotel-order__important {
				  border-bottom: 1px dashed;
				  width: max-content;
				}

				.b-hotel-order__link {
				  border-bottom-color: #467EB7;
				}

				.b-hotel-order__important {
				  border-bottom-color: #D70000;
				}
			  }

			  .b-hotel-order__standart-info {
				display: flex;
				flex-direction: column;
				gap: 7px;
			  }
			}

			.b-hotel-order__column-container {

			  &:first-of-type {
				order: 0;
			  }

			  &:last-of-type {
				order: 1;
			  }
			}

			.b-hotel-order_column-wrapper {
			  display: flex;
			  flex-flow: row wrap;

			  &.mobile-wrap {
				order: 2;

				& .b-hotel-order_column {
				  flex: 1 1 100%;
				}
			  }
			}
		  }

		  // railway
		  .b-route-info_railways {
			padding: 12px;

			.b-train-ticket {
			  padding: 20px 0 0;

			  .b-info-train__number:before {
				content: unset;
			  }

			  &__header {
				flex-flow: row wrap;
			  }

			  &__direction {
				font-weight: 600;
				font-size: 14px;
			  }
			}

			.b-train-ticket__mobile-reservation-info {

			  .b-train-ticket__mobile-row {
				display: flex;
				gap: 5px;
				font-size: 12px;

				&.train-info .info-caption {
				  margin-right: 5px;
				}

				.info-caption {
				  color: #A4ABB3;
				}

				.info-value {
				  color: #4A5767;
				  font-weight: 500;
				}
			  }
			}

			.b-train-ticket__map {
			  display: none;
			}
		  }

		  // transfer
		  .b-transfer-order-info {

			&__wrapper {
			  padding: 10px 0;
			  display: flex;
			  flex-direction: column;
			}

			&__car-info {
			  flex-flow: row wrap;

			  &-photo {
				width: 50%;
				height: auto;
			  }

			  &-element {
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;
			  }
			}

			&__trip-info {
			  padding: 0 10px;
			}
		  }
		}
	  }
	}

	.b-cabinet-order__notes .toggle-order__notes-container {
	  padding: 0 12px;
	}

	.b-order__payment {
	  padding: 0 12px;

	  .b-block-container__footer {
		padding: 0 !important;
	  }
	}

	.b-order-reservation[data-reservation-subject="hotel_reservation"] {
	  .b-order__container-header {
		background: url("~assets/img/img-hotel-reservation-background.jpg") center no-repeat;
	  }
	}

	.b-order-reservation[data-reservation-subject="air"] {
	  .b-order__container-header {
		background: url("~assets/img/img-avia-reservation-background.jpg") center no-repeat;
	  }
	}

	.b-order-reservation[data-reservation-subject="railway"] {
	  .b-order__container-header {
		background: url("~assets/img/img-railway-reservation-background.jpg") center no-repeat;
	  }
	}

	.b-order-reservation[data-reservation-subject="transfer"] {
	  .b-order__container-header {
		background: url("~assets/img/img-transfer-reservation-background.jpg") center no-repeat;
	  }
	}

	&__info-footer-controls-popup {
	  background-color: #5f6c80;
	  width: calc(~'100% - 40px');
	  display: flex;
	  flex-direction: column;
	  position: absolute;
	  .box-shadow(~'0 0 10px #5f6c80');
	  right: 40px;
	  bottom: 0;
	}

	&__info-footer-controls-mobile--mobile-btn {
	  width: 100%;
	  height: 30px;
	  padding: 10px;
	  border-bottom: 1px solid #2c353d;
	  align-items: center;
	  background-color: #5f6c80;
	  color: #fff;

	  & > span {
		pointer-events: none;
	  }

	  &.disabled {
		pointer-events: none;
		opacity: .5;
	  }

      .b-order__info-footer-controls-mobile-oneline-content {
        white-space: nowrap;
        text-overflow: ellipsis;
      }
	}

	&__info-footer-controls-mobile {
	  display: flex;
	  width: 40px;
	  height: 30px;
	  background-color: #4A5767;
	  align-items: center;
	  z-index: 100;
	  justify-content: center;
	  .border-bottom-right-radius(4px);
	  .border-top-right-radius(4px);

	  .g-icon-more-options-w;
	  background-size: 50% 50%;

	  & > span {
		pointer-events: none;
	  }

	  &.expand-only {
		.g-icon-arrow-down_w;
	  	background-size: 30% 30%;

	  }

	  &.expand-only--reverse {
		.g-icon-arrow-up_w;
	  	background-size: 30% 30%;

	  }
	}

	&__footer {
	  flex-flow: column;

	  .b-order__notes {
		.b-cabinet-order__notes-create {
		  width: 30px;
		  height: 30px;
		  display: flex;
		  align-items: center;
		  justify-self: center;
		  padding: 0;
		  margin-bottom: 4px;
		  background: #4A5767;
		  border: 0;

		  & > .g-icon-send {
			display: inline-block;
			width: 60%;
			height: 60%;
			margin: auto;
		  }
		}

		.b-order__notes-attachment-btn {
		  width: 30px;
		  height: 30px;
		  display: flex;
		  align-items: center;
		  justify-self: center;
		  padding: 0;
		  margin-bottom: 4px;
		  background: #4A5767;
		  border: 0;

		  & > .g-icon-file {
			display: inline-block;
			width: 60%;
			height: 60%;
			margin: auto;
		  }
		}

		&-container {
		  display: flex;
		  align-items: center;
		  justify-content: space-between;

		  .b-textarea {
			width: 95%;
			height: min-content;
		  }

		  .b-textarea__value {
			width: 100%;
			min-height: 30px;
			height: 30px;
			max-height: 100px;
			padding: 5px 10px;
		  }
		}

		&-container-footer {
		  margin-top: unset;
		  display: flex;
		  gap: 10px;
		}
	  }

	  .b-block-container__footer-controls {

	  }

	  .b-payment__authorization,
	  .b-payment__approval,
	  .b-order__payment-pay {
		background-color: #099C9D;
		flex: 1 1 50%;
		height: 30px;
		font-size: 14px;
		min-width: 40%;
		.box-shadow(~'0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12)');
	  }
	}

	&__payment {
	  display: flex;
	  flex-direction: column;
	}

	.b-order__additional-select {
	  width: 100%;

	  & .select-styled {
		.box-shadow(~'0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12)');
		padding: 0 15px;
		line-height: 30px;
		border: none;
	  }
	}

	&__container-title {
	  width: 100%;
	  font-size: 14px !important;
	  color: #FFFFFF !important;
	}

	.b-block-container__footer {
	  flex-flow: row wrap;
	  position: relative;

	  &-rightbar {
		flex-flow: row nowrap;
		justify-content: flex-end;
		align-items: center;
		gap: unset;

		& > .b-block-container {
		  flex: 1 1 50%;
		}

		.b-order__info-footer-controls-mobile {
		  background-color: #4A5767;
		  color: #FFFFFF;
		  width: 40px;
		  height: 30px;
		  order: 2;

		  &.second-btn {
			order: 0;
			flex: 1 1 50%;
			width: unset;
			background-image: none;
			.border-radius(~'4px 0px 0px 4px');
		  }
		}

		.b-order__info-footer-controls-popup {
		  left: 0;
		  bottom: 33px;
		  min-width: 50%;
		  width: fit-content;
		  z-index: 10;
		}

		.b-order__additional-select {
		  margin-right: unset;
		  background-color: #EDEEF0;
		  min-width: 50%;
		  flex: 1 1;

		  &:after {
			content: '';
		  }

		  & .border-right {
			.border-radius(~'0px 4px 4px 0px');
		  }
		}
	  }

	  &-controls {
		display: none;
	  }
	}

	.b-order-issue__post-content {

	  & > pre {
		white-space: pre-wrap;
	  }
	}
  }

  .b-popup__approval-booking {
	width: 100% !important;

	.b-button-container__footer {
	  width: 100%;
	  position: absolute;
	  bottom: 0;
	  left: 0;
	  display: flex;
	  flex-flow: row nowrap;
	  gap: unset !important;
	  z-index: 10;
	  padding: 12px;

	  span.b-popup__approval-booking__action-back,
	  span.b-popup__approval-booking__action-send {
		padding: 9px 10px;
		width: 50%;
		min-width: unset !important;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 38px;
		margin: 0 !important;
		border: 0;
	  }

	  span.b-popup__approval-booking__action-back {
		color: #A4ABB3 !important;
		background: #EDEEF0 !important;
		.border-radius(~'4px 0px 0px 4px');
	  }

	  span.b-popup__approval-booking__action-send {
		.border-radius(~'0px 4px 4px 0px');
		background: #099C9D !important;
		color: #FFFFFF !important;
	  }
	}
  }
}

@media (min-width: 769px) and (max-width: 1240px) {
  .b-order {
	&__footer {
	  .b-block-container__footer {
		font-size: 12px;

		.b-order__additional-select .select-styled {
		  font-size: 12px;
		  padding: 7px 15px;
		}

		.btn {
		  font-size: 12px;
		}
	  }
	}

	&__info-footer-controls {
	  font-size: 12px;
	  gap: 10px;
	  display: flex;
	  align-items: center;
	  flex-flow: row wrap;

	  .b-order__info-footer-text {
		margin-left: unset;
	  }
	}
  }
}

@media (max-width: 900px) {
  @media (min-width: 769px) {
	.b-order__info .b-block-table__row-header .b-block-table__cell {
	  padding: 7px 0 14px;
	}

	.b-block-table__cell {
	  padding: 12px 0 12px;
	}
  }

  .b-order__container-header-label .b-order-label__list {
	.b-order-label__tab-button {
	  width: 40px;
	  padding: 5px;

	  &:before {
		margin-right: unset !important;
	  }

	  & > span {
		display: none;
	  }
	}
  }
}

@media (min-width: 1440px) {
  .toggle-order__docodoca-container,
  .b-order__container-expand {
	margin: 0 23px;
  }

  .b-order-reservation[data-reservation-subject="hotel_reservation"] {
	.toggle-order__additional-services-container,
	.b-order__container-expand {
	  margin: 0;
	}
  }

  .b-order__additional-services-container {
	padding: 0 115px 20px;
  }
}

@media (min-width: 1920px) {
  .toggle-order__docodoca-container,
  .b-order__container-expand {
	margin: 0;
  }

  .b-order-reservation[data-reservation-subject="hotel_reservation"] {
	.toggle-order__additional-services-container,
	.b-order__container-expand {
	  margin: 0 10%;
	}
  }

  .b-order__container-header-label {
	font-size: 16px;
  }
}

.border-right-none {
  .border-bottom-right-radius(0);
  .border-top-right-radius(0);
}

.border-left-none {
  .border-bottom-left-radius(0);
  .border-top-left-radius(0);
}
