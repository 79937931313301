.b-ticket-header {
	.clearfix;
	float: left;

  .b-ticket-header__options:before {
	background: @field2-elements-color;
	margin-top: -14px;
  }

}

	.b-ticket-header__compay-logo {
		width: 85px;
		height: 37px;
		//.g-icon-logo-s7;
		float: left;
		top: 3px;
		position: relative;
	}

	.b-ticket-header__tripartite-contract {
	  width: 18px;
	  height: 18px;
	  float: right;
	  top: 13px;
	  position: relative;
	}

	.b-ticket-header__options {
		padding: 0;
		margin: 4px 0 0 10px;
		float: left;
		.clearfix;
		position: relative;
	}

		.b-ticket-header__options:before {
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			margin-top: 4px;
			height: 26px;
			width: 1px;
			display: block;
			background: @field1-elements-color;
		}

		.b-ticket-header__option {
			margin: 2px 6px;
			float: left;
			list-style: none;
			width: 32px;
			height: 28px;
		}

			.b-ticket-header__option:before {
				display: none;
			}

	.b-ticket-header__black {



	}
