@import (reference) "~assets/css/imports";

.b-order {
	margin-bottom: 3px;
	color: @field1-H2-color;

  .b-order-hotel-common-info__wrapper {
    display: flex;
    gap: 8px;

    &:not(:last-child) {
      border-bottom: 1px solid #A4ABB3;
      padding-bottom: 8px;
    }

    .b-order-hotel-common-infos {
      display: inline-flex;
      gap: 16px;

      span {
        display: inline-block;
        width: 30px;
        height: 30px;
      }
    }
  }

  .missing-info-warning {
	display: inline-block;
	width: 22px;
	height: 22px;
	vertical-align: bottom;
	background-size: 22px;
	background-repeat: no-repeat;
	.g-icon-warning;
  }

	&__important {
		color: #E50000;
	}

	&-reservation {
		margin-bottom: 16px;
		&.disabled {
			opacity: .75;
			pointer-events: none;

			.action-link {
				pointer-events: all;
			}
		}
	}

	.b-block-container {
		&.b-order__info .b-block-container__footer,
		&.b-order__notes .b-block-container__footer {
			padding: 13px;
			border-top: 1px solid rgba(0, 0, 0, 0.1);
		}

		&.b-order__info .b-block-container__header {
			.g-icon-service {
				margin-right: 10px;
			}
			span {
				color: rgba(255, 255, 255, .8);
				text-transform: uppercase;

				&:not(:last-child):before {
					display: none;
				}
				&:before {
					content: '';
					margin: 0 13px;
					display: inline-block;
					vertical-align: middle;
					height: 20px;
					width: 1px;
					background: @field2-elements-color;
				}
			}
		}
	}

	&__docodoca-container {
		& > td {
			padding: 0;
		}
		.b-visa-info__info,
		.b-input__label:after,
		.b-input__select-value:before {
			background-color: #FBFBFB;
		}

		.b-visa-info__info {
			border-radius: 0;
			border: 1px solid rgba(0, 0, 0, 0.1);
		}
	}

	&__passengers-list {
		.b-block-table__cell-type-checkbox {
			width: 50px;
			padding: 0;
			text-align: center;
			vertical-align: middle;

			.b-checkbox {
				height: 18px;
			}

			.b-checkbox__view {
				height: 18px;
				width: 18px;
				padding: 0;
			}
		}
	}

	&__service-tags {
		margin-left: 8px;

		& > span {
			border-radius: 10px;
			padding: 0 7px;
			height: 18px;
			line-height: 18px;
			font-weight: 700;
			font-size: 14px;
			color: #aaaaa9;
			background: #e2e2de;
			margin-right: 5px;
			white-space: nowrap;

			&.red-tag {
				color: #f15f6c;
				background: #ffcece;
			}
		}
	}

	&__editing-type {
		&-container {
			display: flex;
			flex-direction: row;
			width: 100%;
		    position: relative;
		    align-items: center;
		    min-height: 30px;
		}

		.b-radiobutton {
			border: 2px solid #deddda;
			border-radius: 3px;
			font-weight: 700;
			color: #aaaaa9;
			text-align: center;
			position: relative;
			margin: 0;

			padding: 13px 30px;
			line-height: normal;
			overflow: hidden;
			text-overflow: ellipsis;

			display: flex;
			align-items: center;

			&.active {
				border: 2px solid #cececc;
				color: #060604;
				border-radius: 2px!important;
				box-shadow: none;
				-webkit-box-shadow: none;
				-moz-box-shadow: none;
				z-index: 1;
			}

			&:first-child:not(:last-child) {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}

			&:not(:first-child) {
				margin-left: -3px;
			}

			&__label {
				position: static;
			}
		}

		.b-radiobutton__view {
			display: none;
		}
	}

  	&-reservation__authorization-status {
	  display: inline-block;
	  vertical-align: bottom;
	  width: 18px;
	  height: 18px;

	  @media (@tablet) {
		margin-left: auto;
		margin-top: 4px;
	  }
	}
}

.b-order {

	.g-icon-service {
		display: inline-block;
		vertical-align: middle;
		background-position: center;
		background-repeat: no-repeat;
		background-size: 17px;
		width: 20px;
		min-width: 20px;
		height: 20px;

		&.large {
		  width: 30px;
		  height: 30px;
		}

		&--type-INSURANCE_ACCIDENT {
		  background-image: url("~assets/img/icons/icon-med-insurance-white.svg");
		}
		&--type-TAXI {
		  background-image: url("~assets/img/icons/icon-taxi-white.svg");
		}
		&--type-OTHER {
		  background-image: url("~assets/img/icons/icon-universal-white.svg");
		}
		&--type-AEROEXPRESS {
		  background-image: url("~assets/img/icons/icon-air-express-white.svg");
		}
		&--type-INSURANCE_TOURISTIC {
		  background-image: url("~assets/img/icons/icon-tour-insurance-white.svg");
		}
		&--type-CAR_RENTAL {
		  background-image: url("~assets/img/icons/icon-rent-white.svg");
		}
		&--type-VISA {
		  background-image: url("~assets/img/icons/icon-visa-white.svg");
		}
		&--type-BUS {
		  background-image: url("~assets/img/icons/icon-bus-white.svg");
		}
		&--type-air {
			background-image: url("~assets/img/icons/bc-avia.svg");
		}

		&--type-railway {
			background-image: url("~assets/img/icons/bc-railways.svg");
		}

		&--type-hotel_reservation {
			background-image: url("~assets/img/hotels/bc-hotels.svg");
		}

	  	&--type-transfer {
			background-image: url("~assets/img/icons/bc-transfers.svg");
	  	}

		&--type-unknown {
			background-image: url("~assets/img/icons/bc-unknown.svg");
		}

		&--color-black {
			&.g-icon-service--type-railway {
				background-image: url("~assets/img/icons/bc-railways-black.svg");
			}
			&.g-icon-service--type-air {
				background-image: url("~assets/img/icons/bc-avia-black.svg");
			}
			&.g-icon-service--type-hotel_reservation {
				background-image: url("~assets/img/hotels/bc-hotels-black.svg");
			}

		  	&.g-icon-service--type-transfer {
			  background-image: url("~assets/img/icons/bc-transfers-black.svg");
			}
		}

		&--size-small {
			height: 15px;
			width: 15px;
			min-width: 15px;
			background-size: 13px;
		}
	}

	.b-block-container {
		margin-bottom: 16px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.b-block-container__content {
		padding: 0;
		border-top: 0;
	}

	.b-block-container__default {
		padding: 9px 13px;

		&--no-padding {
			padding: 0;
		}
	}

	.b-block-container__content-title {
		padding: 9px 13px;
		align-items: center;
	}

	.b-order__route {
		.b-block-container__content-title {
			.g-icon-service	{
				margin-right: 9px;
			}
			.b-block-container__title-rightbar {
				white-space: nowrap;
			}
		}
		.b-order__route-location {
			font-weight: 500;
		}
		.b-order__route-status {
			margin-left: 5px;
			text-transform: uppercase;

			&--TO_BE_PAYED {
				color: @layover-color-medium;
			}

			&--PAYED, &--ISSUED, &--BOOKING {
				color: @layover-color-little;
			}

			&--CANCELED, &--ERROR {
				color: @layover-color-long;
			}
		}
	}

	.b-order__refund-exchange {
		&-container {
			.b-standard-services {
				border-top: 0;
				flex-direction: column;
				color: rgba(74, 87, 103, 0.8);
			}
		}
	}

	.b-order__docodoca {
		&-container {
			.b-booking__visa-info.filled {
				position: relative;
				pointer-events: none;

				&:after {
					content: '';
					position: absolute;
					left: 0; right: 0;
					top: 0; bottom: 0;
					background: #fff;
					opacity: .25;
					z-index: 3;
				}
			}
		}
	}

	.b-block-container__footer {
		display: flex;
		flex-direction: row;
		position: relative;

		.b-block-container__footer-leftbar {
			margin-right: auto;
		}

		.b-block-container__footer-rightbar {
			margin-left: auto;
		}

	}

  	&__copy-to-clipboard {
	  margin-right: 25px;
	}
}

.b-order__inner {
	border-left: @field1-border;
	border-right: @field1-border;
	border-bottom: @field1-border;
	padding: 0 10px 0 10px;
	.rounded;
	background: @field1-background-color;


}

.b-order__uid {
	//float: left;
	color: @field1-H1-color;
	font-size: 20px;
	font-weight: normal;
	display: table-cell;
}

.b-order__status {
	display: table-cell;
	text-transform: uppercase;
	font-size: @base-H1-size;
	width: 250px;
	text-align: right;
	vertical-align: middle;
	line-height: 16px;
}

.b-order__status-BOOKING {
	color: @field1-alert-color;
}

.b-order__status-CANCELED {
	color: @field1-warning-color;
}

.b-order__status-ERROR {
	color: @field1-warning-color;
}

.b-order__status-ISSUED {
	color: @field1-active-color;
}

.b-order__status-FINISHED {
	color: @field1-font-color;
}

.b-order__status-until {
	font-size: 12px;
	color: @field1-font-color;
	text-transform: lowercase;
}

.b-order__footer-inner {
	width: 100%;
	display: table;
}

.b-order__footer-button {
	.rounded-bottom;
	background: @button2-color;
	color: @button2-font-color;
	font-size: @base-H1-size;
	text-align: center;
	height: 40px;
	line-height: 40px;
	width: auto;
	white-space: nowrap;
	display: table-cell;
	text-transform: uppercase;
	cursor: pointer;
	.transition-standard;

	&:hover {
		background: @button2-color-act;
	}

	&:active {
		background: @button2-color-act;
	}
}

.b-order__branded-fares {
	background: @button2-color;
	color: @button2-font-color;

}

.b-order__leg-extended {
	display: none;
}

.b-order__leg {
	margin: 0 -10px;
}

.b-order__leg-inner {
	padding: 10px 5px;
	line-height: 20px;
	border-bottom: 1px solid @field1-elements-color;
	.clearfix;
	color: @field1-H1-color;
	font-size: @base-H2-size;
	margin: 0 0;
}

.b-order__opened {

	.b-order__leg-inner {
		.shadow;
		.rounded;

		&:first-child {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}

	.b-order__open {

		&:after {
			.g-icon-arrow-up;
			background-size: 14px 14px;
		}

	}

}

.b-order__leg-places {
	float: left;
}

.b-order__leg-place {
	color: @field1-H1-color;
	font-size: @base-H2-size;
}

.b-order__leg-place-iata {
	color: @field1-object-color;
	text-transform: uppercase;
	font-size: @base-H1-size;
}

.b-order__leg-date {
	float: right;
	font-size: @base-H1-size;
}

.b-order__passengers-preview {
	padding: 10px 5px;
	margin-right: 20px;
	border-bottom: 1px solid @field1-elements-color;
}

.b-order__bottom {
	height: 30px;
	position: relative;
}

.b-order__open {
	position: absolute;
	top: 0;
	right: -10px;
	padding-right: 30px;
	line-height: 35px;
	text-decoration: none;
	color: @field1-H1-color;
	font-size: @base-font-size;
	cursor: pointer;

	&:after {
		content: "";
		display: block;
		border-top: 1px solid @field1-elements-color;
		border-left: 1px solid @field1-elements-color;
		.rounded-top-left;
		position: absolute;
		right: 0;
		top: 5px;
		width: 25px;
		height: 25px;
		.g-icon-arrow-down;
		background-size: 14px 14px;
	}
}

.b-cost-codes {
	&__item {
		border-bottom: 3px dotted rgba(0, 0, 0, 0.1);

		&-name {
			color: #2F3842;
			font-weight: 500;
			margin-bottom: 13px;
		}
		&:last-child {
			border-bottom: 0;
		}
	}
}


/* NEW STYLE */
.b-order {
	&__header {
		background: #4A5767;
		border-radius: 3px 3px 0 0;
		margin-bottom: 20px;
		padding: 10px;
		position: relative;
		z-index: 1;

		&-title {
			color: #fff;
			font-size: 18px;
			text-transform: uppercase;
		}

	  	&-date {
		  color: #fff;
		  font-size: 18px;
		  margin-right: 10px;

		  &:before {
			content: '';
			margin: 0 17px;
			display: inline-block;
			vertical-align: middle;
			height: 20px;
			width: 1px;
			background: #2F3842;
		  }
		}

		&-wrapper {
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
			position: relative;

			&:after {
				content: '';
				position: absolute;
				bottom: -4px;
				left: 47px;
				border-top: 10px solid #E50000;
				border-right: 30px solid transparent;
			}

			&:before {
				content: '';
				position: absolute;
				top: 0;
				bottom: -4px;
				left: -3px;
				background: #E50000;
				border-radius: 3px 0 0;
				width: 50px;
			}
		}
	}

  	&__header-info {
	  margin-left: 5px;

	  &_right {
		float: right;
		display: inline-block;
		max-width: 50%;
		overflow-x: hidden;
	  }
	  &-client {
		font-size: 18px;
		color: #fff;
	  }
	}

	&__container {
		margin-bottom: 30px;
		position: relative;

		&--type-simple {
			.b-order__container-header,
			.b-order__container-content {
				background: none;
				border: none;
				box-shadow: none;
				border-radius: 0;
				height: unset;
			}
		}

		&-header {
			background: #4A5767;
			.rounded-top;
			display: flex;
			padding: 0 14px;
			align-items: center;
			height: 40px;
			position: relative;
			z-index: 1;
			white-space: nowrap;
			flex-wrap: nowrap;

			&-label {
        display:flex;
        flex-grow: 1;

				font-size: 14px;
				color: #FFFFFF;
				text-transform: uppercase;

				.g-icon-service {
					background-size: contain;
				}

				.b-order-label__list {
          @media (min-width: 768px) {
            display: flex;
            flex-grow: 1;
          }
					
					margin-left: 14px;
					vertical-align: middle;

					.b-order-label__route {
						overflow: hidden;
						max-width: 30vw;
						text-overflow: ellipsis;
						display: inline-block;
						vertical-align: middle;
					}

					.b-order-label__item {
            &.b-order-label__pnr {
              text-align: right;
              flex-grow: 1;
            }

            &-extra:after {
							content: '';
							margin: 0 17px;
							display: inline-block;
							vertical-align: middle;
							height: 20px;
							width: 1px;
							background: @field2-elements-color;
						}

						&-simple {
							font-weight: 400;
						}
						&:after {
							content: '';
							margin: 0 17px;
							display: inline-block;
							vertical-align: middle;
							height: 20px;
							width: 1px;
							background: @field2-elements-color;
						}
					}

				  	.b-order-label__tab-button {
					  background: #4A5767;
					  color: #FFFFFF;
					  font-family: "Roboto", "Segoe UI", "Arial", sans-serif;
					  border: 1px solid #FFFFFF;
					  border-radius: 4px;
					  padding: 5px 20px;
					  line-height: 12px;
					  display: inline-block;
					  margin-right: 10px;
					  text-transform: inherit;
					  font-weight: 500;

					  &.active {
						color: #4A5767;
						background: #FFFFFF;

						&[data-tab="TICKETS"]:before {
						  background-image: url("~assets/img/icons/bc-avia-black.svg");
						}
						&[data-tab="ANC_FEES"]:before {
						  background-image: url("~assets/img/icons/icon-room-service-black.svg");
						}
					  }

					  &[data-tab="TICKETS"]:before, &[data-tab="ANC_FEES"]:before {
						content: ' ';
						display: inline-block;
						margin-right: 10px;
						width: 14px;
						height: 14px;
						.g-icon-service;
						background-size: cover;
						background-repeat: no-repeat;
					  }
					  &[data-tab="ANC_FEES"]:before {
						background-image: url("~assets/img/icons/icon-room-service-w.svg");
					  }
					  &[data-tab="TICKETS"]:before {
						background-image: url("~assets/img/icons/bc-avia.svg");
					  }
					}
				}
			}

			&-right {
				margin-left: auto;
				display: flex;
				align-items: center;
			  	gap: 10px;

				.b-checkbox {
					height: 13px;
					margin: 0 5px 0 10px;
					line-height: unset;
				}

				.b-checkbox__view {
					height: 13px;
					width: 13px;
					padding: 0;

					&:before {
						border-color: #fff !important;
						height: 13px;
						width: 13px;
					}

					&:after {
						background: #fff !important;
						height: 7px;
						width: 7px;
						border-radius: 1px;
						left: 3px;
						top: 3px;
					}
				}
			}

			.b-order-price {
				font-size: 20px;
				font-weight: 700;
				color: #fff;
			}

			.b-order-price__taxs {
				font-size: 15px;
				line-height: 18px;
				color: rgba(255, 255, 255, 0.8);
			}
		}

		&-content {
			background: #fff;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
			border: 1px solid #A9A9A9;
			.rounded-bottom;

			position: relative;
			z-index: 2;

		  &[data-tab-content="ANC_FEES"].empty {
			padding: 7px 12px 14px;
			min-height: 130px;
			display: flex;
			flex-direction: column;
		  }
		}

		&-expand {
			background: #fff;
			box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
			border: 1px solid #A9A9A9;
			.rounded-bottom;

			margin: 0 50px 0 26px;
			padding: 0;

			position: relative;
			bottom: 5px;
			z-index: 1;
			display: none;
		}

		.b-leg__wrapper {
			&:first-child {
				.b-leg__title {
					padding-top: 20px;
				}
			}
		}
	}

  	&__none-ancillary-fees-text {
	  margin-top: auto;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	}

  	&__ancillary-fees-buttons {
	  margin-top: auto;
	}

	&-reservation {
		&--status-error {
			.b-order__container-header {
				.g-icon-service {
					background: #E50000;
					-webkit-mask-repeat: no-repeat;
					-o-mask-repeat: no-repeat;
					mask-repeat: no-repeat;
					-webkit-mask-position: center;
					-o-mask-position: center;
					mask-position: center;

					&--type-air {
						-webkit-mask-image: url("~assets/img/icons/bc-avia.svg");
						mask-image: url("~assets/img/icons/bc-avia.svg");
					}

					&--type-railway {
						-webkit-mask-image: url("~assets/img/icons/bc-railways.svg");
						mask-image: url("~assets/img/icons/bc-railways.svg");
					}

					&--type-transfer {
						-webkit-mask-image: url("~assets/img/icons/bc-transfers.svg");
						mask-image: url("~assets/img/icons/bc-transfers.svg");
					}

					&--type-hotel_reservation {
						-webkit-mask-image: url("~assets/img/hotels/bc-hotels.svg");
						mask-image: url("~assets/img/hotels/bc-hotels.svg");
					}
					&--type-INSURANCE_ACCIDENT {
					  background-image: url("~assets/img/icons/icon-med-insurance-red.svg");
					}
					&--type-TAXI {
					  background-image: url("~assets/img/icons/icon-taxi-red.svg");
					}
					&--type-OTHER {
					  background-image: url("~assets/img/icons/icon-universal-red.svg");
					}
					&--type-AEROEXPRESS {
					  background-image: url("~assets/img/icons/icon-air-express-red.svg");
					}
					&--type-INSURANCE_TOURISTIC {
					  background-image: url("~assets/img/icons/icon-tour-insurance-red.svg");
					}
					&--type-CAR_RENTAL {
					  background-image: url("~assets/img/icons/icon-rent-red.svg");
					}
					&--type-VISA {
					  background-image: url("~assets/img/icons/icon-visa-red.svg");
					}
					&--type-BUS {
					  background-image: url("~assets/img/icons/icon-bus-red.svg");
					}
				}
			}

			.b-order__container-header-right,
			.b-order-label__list {
				opacity: .5;
			}

			.b-order__container-content {
				opacity: .5;
				pointer-events: none;
			}

			.b-order__info-expand,
			.b-order__info-footer {
				display: none;
			}
		}

		&.is-open {
			.b-order__container-content {
				.b-expand {
					display: none;
				}
			}
		}

		&.is-selected {
			.b-order__container {

				@keyframes move {
					from {
						top: 20px;
					}
					to {
						top: -11px;
					}
				}

				&:before {
					content: '';
					display: block;
					left: 0;
					right: 0;
					top: -11px;
					height: 20px;
					background: #4882BD;
					position: absolute;
					z-index: 0;
					animation: move .2s ease-in-out;
				}
			}
			.b-order__container-content {
				transition: box-shadow .2s;
				box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25), 0 4px 20px rgba(0, 0, 0, 0.45);
			}
		}

	  	&__travel-policy {
		  & > span {
			display: inline-block;
			width: 18px;
			height: 18px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		  }
		  &--3D {

		  }
		}
	}

  	.b-order__reservation-add-ancillary-fees {
	  font-size: 14px;
	  display: flex;
	  flex-direction: row;
	  align-items: center;
	  float: right;
	  padding: 6px 20px;
	  gap: 10px;
	  background: #4882BD;
	  border: 1px solid #4882BD;
	  color: #FFFFFF;
	  border-radius: 3px;
	  cursor: pointer;

	  &:before {
		content: "+";
		display: block;
		z-index: 100;
		width: 20px;
		height: 20px;
		font-size: 14px;
		border: 2px solid #FFFFFF;
		border-radius: 50%;
		color: #FFFFFF;
	  }

	  &.disabled {
		opacity: .7;
	  }
	}

  .b-order__reservation-remove-ancillary-fees {
	background: #fff;
	border: 1px solid #a9a9a9;
	border-radius: 3px;
	float: left;
	color: #000;
	padding: 6px 20px;
	cursor: pointer;
	display: inline-block;
	margin-right: 5px;

	&.disabled {
	  background: rgba(0, 0, 0, 0.2);
	  color: rgba(255, 255, 255, 0.2);
	  border: 1px solid #CCCCCC;
	  pointer-events: none;
	}
  }

	&__info {
		.b-order-mco {
			margin-top: 7px;
		}
		.b-block-table {
			border-collapse: separate;
			text-transform: uppercase;
			table-layout: auto;

			&__row {
				&.with-mco {
					.b-block-table__cell:not(.b-block-table__cell-type-checkbox) {
						vertical-align: top;
					}
				}
				&[data-service-status="EXCHANGE"] {
					.b-block-table__cell:nth-child(2) {
						overflow: visible;
						.b-order__info-ticket-number {
							position: relative;
							color: rgba(74, 87, 103, .5);
							&:after {
								content: '';
								display: block;
								width: 16px;
								height: 14px;
								background: #fff url('~assets/img/icons/icon-exchange.svg') center no-repeat;
								position: absolute;
								bottom: -22px;
								left: calc(~'50% - 6px');
								z-index: 9;
							}
						}
					}
				}
				&[data-service-status="SELL"] {
					.b-order__info-ticket-number {
						color: #4882BD;
						border-bottom: 1px dashed #4882BD;
					}
				}
				&[data-voucher="true"] {
					.b-order__info-ticket-number {
						color: #6f7884;
						border-bottom: 0;
					}
				}
				.b-block-table__cell {
					border-top: 1px dashed rgba(0, 0, 0, .3);
					border-bottom: 1px solid transparent;

					.b-order__info-status-container {
						display: inline-flex;
						flex-direction: column;

						.b-order__show-detailed {
							color: #4882BD;
							border: none;
							background: transparent;
							font-size: 14px;
							font-weight: 400;
							line-height: normal;
							text-align: left;
							padding: 0;
							cursor: pointer;
							outline: 0;
						}
					}
	
					.b-order__info-status-container > * + * {
						margin-top: 4px;
					}

					&-type-checkbox {
						border-top-color: transparent;
						width: 45px;

						.b-checkbox {
							height: 13px;
							line-height: unset;
						    vertical-align: middle;
						}

						.b-checkbox__view {
							height: 13px;
							width: 13px;
							padding: 0;

							&:before {
								width: 13px;
								height: 13px;
							}

							&:after {
								width: 9px;
								height: 9px;
								top: 2px;
								left: 2px;
								border-radius: 1px;
							}
						}
					}

					&--width-auto {
						width: 1px;
						overflow: unset;
						white-space: nowrap;
					}

					&-empty:last-child {
						width: 50px;
					}

					&:after {
						display: none;
					}
				}
				&:first-child {
					.b-block-table__cell {
						border: none;
					}
				}
			}

			&__row-header {
				font-size: 14px;
				font-weight: 500;

				.b-block-table__cell {
					text-align: left;
					padding: 7px 12px 14px;
				}
			}

		  	&__cell.mobile-cell {
			  display: none;
			}
		}

		&-footer {
			text-transform: none;

			.g-icon-download {
				height: 15px;
				width: 15px;
				display: inline-block;
				vertical-align: middle;
				margin-left: 5px;
			}

			.b-route-receipt-link {
				text-transform: uppercase;
				cursor: pointer;
				font-size: 12px;
				white-space: nowrap;
			}

			&-right {
				margin-left: auto;
				text-align: right;
			}

			&-top {
				vertical-align: top;
			}

			&-controls,
			&-services {
				display: inline-block;
				vertical-align: middle;
			}

			&-services {
				margin-left: 25px;
			}

			&-text {
				cursor: pointer;
				color: #000;
				border-bottom: 1px dashed rgba(0, 0, 0, .3);
				margin-left: 20px;

				&.disabled {
					opacity: 0.5;
					pointer-events: none;
					cursor: default;
				}
			}

			&-button {
				background: #FFFFFF;
				border: 1px solid #A9A9A9;
				border-radius: 3px;
				color: #000;
				padding: 5px 12px;
				cursor: pointer;
				display: inline-block;
				margin-right: 5px;

				&.btn-disabled {
					opacity: 0.5;
					pointer-events: none;
					cursor: default;
				}

				.b-radiobutton {
					margin: 0;
					height: unset;

					&__label {
						position: static;
					}

					.b-radiobutton__view {
						display: none;
					}
				}

				&:last-child {
					margin-right: 15px;
				}
			}

		  &-controls-mobile {
			display: none;
		  }

		  &-controls-popup {
			display: none;
		  }
		}

		&-limitdate {
			color: #E50000;
			font-weight: bold;
		}

		&-expand {
			position: absolute;
			right: 0;
			bottom: 0;
			z-index: 2;
			background-color: #fff;
		}

		&-error {
			text-transform: uppercase;
			color: #E50000;
			font-weight: 500;
			margin: 0 5px;
		}

		&-status {
			&--SELL {
				color: #4882BD;
			}
		}
	}

	&__intention {
		&-approval{
			&:hover {
				background: #D70000;
			}
		}
	}

	&__payment {
		.b-order__container-header {
			font-weight: 400;
			font-size: 22px;
			text-transform: uppercase;
			padding: 0;
			margin-bottom: 20px;
		}

		.b-block-container__footer-rightbar {
			display: flex;
		}

		.b-block-container__footer-controls {
			display: inline-flex;
			vertical-align: middle;
			align-items: center;
		}

		.g-icon-download {
			height: 15px;
			width: 15px;
			display: inline-block;
			vertical-align: middle;
			margin-left: 5px;
		}


		.b-block-container__footer-control {
			margin-right: 10px;

		  & > span {
			white-space: nowrap;
		  }
		}

	  	.b-order__info-footer-item {

		  &--finance-docs, &--send-booking-docs {
			white-space: nowrap;
		  }
		}

		.b-order-pricing {
			width: 80%;
			margin: 30px 0 0 auto;

			&__section {
				border: none;

				& + .b-order-pricing__section {
					& > td {
						padding-top: 5px;
					}
				}

				&-title {
					width: 1px;
					white-space: nowrap;
					padding-right: 30px;
				}

				&-passenger {
					margin-left: 5px;
				}

				&-pricing {
					width: unset;
				}

				&-info {
					&:nth-child(2n + 1) {
						background: #FAFAFA;
					}
					&:nth-child(2n) {
						background: #fff;
					}
					&:only-child {
						background: #fff;
						border-top: 1px dashed rgba(0,0,0,.3);
						margin-top: 5px;
					}
				}
			}

			&__total-section {
				display: none;
			}
		}

		.b-block-container__footer {
			margin-top: 30px;
		}

		&-approval,
		&-authorization,
		&-pay {
			background: #E50000;
			color: #fff;
			font-size: 20px;
			font-weight: 500;
			padding: 0 58px;
			line-height: unset;
			border-radius: 4px;
			border-color: transparent;
			height: 30px;

			&.disabled,
			&:disabled {
				pointer-events: none;
				opacity: .8;
			}
		}

		&-approval, &-authorization {
			padding: 0 28px;
			font-size: 16px;
		}

		.b-radiobutton .b-radiobutton__input:checked + .b-radiobutton__view {
			border: none;
			background: #099C9D;
		}

		.b-radiobutton__view {
			background: #FAFAFA;
			box-shadow: inset -1px 1px 2px rgba(0, 0, 0, 0.25);
			border: none;

			&:before {
				display: none;
			}
		}

		.b-order__payment-reservations-prn {
			font-size: 14px;
			margin-left: 2px;

			.g-icon-service {
				margin-left: 15px;
			}

			& > .b-order__payment-reservation-cancel {
				margin-left: 5px;
				vertical-align: middle;

				&:before {
					content: '';
					visibility: hidden;
					display: inline-block;
					height: 8px;
					width: 8px;
					background: url('~assets/img/icon-cancel.svg') center no-repeat;
					margin-right: 4px;
					vertical-align: middle;
					position: relative;
					bottom: 1px;
				}

				&.is-deletable {
					cursor: pointer;
					&:hover {
						color: #E50000;
						&:before {
							visibility: visible;
						}
					}
				}
			}
		}
	}

	&__docodoca-status {
		display: inline-block;
		vertical-align: middle;

		&-item {
			text-transform: uppercase;
			font-weight: normal;
			display: inline-block;
			vertical-align: middle;

			&--fail {
				color: #E50000;

				& > span {
					border-bottom: 1px dashed rgba(0, 0, 0, .3);
				}
			}

			&--ok {
				color: #099C9D;
			}
		}
	}

	&__additional-select {
		width: 205px;
		display: flex;
		align-items: center;
		margin-right: 25px;

		.select-hidden {
			display: none;
			visibility: hidden;
			padding-right: 10px;
		}

		.select {
			cursor: pointer;
			display: inline-block;
			position: relative;
			font-size: 14px;
			color: #000;
			width: 100%;
			height: 30px;
		}

		.select-styled {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: #fff;
			padding: 6px 15px;
			transition: all 0.2s ease-in;
		  	white-space: nowrap;

			border: 1px solid #A9A9A9;
			border-radius: 4px;

			&:after {
				content:"";
				background: url('~assets/img/icons/icon-add-black.svg') center no-repeat;
				background-size: 10px;
				position: absolute;
				top: calc(~'50% - 5px');
				right: 16px;
				height: 10px;
				width: 10px;
			}
		}

		.select-options {
			display: none;
			position: absolute;
			top: 100%;
			right: 5px;
			left: 5px;
			z-index: 999;
			margin: 0;
			padding: 0;
			list-style: none;
			background-color: #fff;
			border: 1px solid #A9A9A9;
			border-top: 0;
			border-radius: 0 0 4px 4px;

			li {
				margin: 0;
				padding: 5px 0;
				text-indent: 15px;
				color: #4A5767;

				.g-icon-service {
					margin-right: 15px;
					background: #4A5767;
					-webkit-mask-repeat: no-repeat;
					-o-mask-repeat: no-repeat;
					mask-repeat: no-repeat;
					-webkit-mask-position: center;
					-o-mask-position: center;
					mask-position: center;
					-webkit-mask-size: contain;

					height: 18px;

					&--type-avia {
						-webkit-mask-image: url("~assets/img/icons/bc-avia.svg");
						mask-image: url("~assets/img/icons/bc-avia.svg");
					}

					&--type-rail {
						-webkit-mask-image: url("~assets/img/icons/bc-railways.svg");
						mask-image: url("~assets/img/icons/bc-railways.svg");
					}

					&--type-hotel {
						-webkit-mask-image: url("~assets/img/hotels/bc-hotels.svg");
						mask-image: url("~assets/img/hotels/bc-hotels.svg");
					}

				  	&--type-transfer {
						-webkit-mask-image: url("~assets/img/icons/bc-transfers.svg");
						mask-image: url("~assets/img/icons/bc-transfers.svg");
				  	}
				}

				&:hover {
					color: #fff;
					background: #4A5767;

					.g-icon-service {
						background: #fff;
					}
				}

				&[rel="hide"] {
					display: none;
				}
			}
		}

		.b-input__select-value-label {
			padding-left: 15px;
		}

		.b-input__value {
			border: 1px solid #A9A9A9;
			color: #000;
			border-radius: 4px;

			&:before {
				border-left: none;
				background-image: url('~assets/img/icons/icon-add-black.svg');
				background-size: 10px;
			}
		}
	}

	&__prn-tooltip {
		max-width: 600px;
		&-title {
			display: inline-block;
			width: auto;
			border-bottom: 1px solid #fff;
			padding-bottom: 10px;
			margin-bottom: 10px;
		}

		&-item {
			margin-bottom: 10px;
			& + .b-order__prn-tooltip-item {
				padding-top: 10px;
				border-top: 1px dashed rgba(255,255,255,.3);
			}
		}
	}

  &__prn-caption, &__gds-caption {
	  color: #FFFFFF;
    font-size: 16px;
    text-transform: uppercase;
	}

  &__gds-caption:after {
    content: '';
    margin: 0 17px;
    display: inline-block;
    vertical-align: middle;
    height: 20px;
    width: 1px;
    background: #2F3842;
  }



	&__info-notifications {
		display: inline-flex;
		flex-flow: column;
		max-width: 650px;
	}

  	&__transfer-notifications {
	  display: flex;
	  flex-direction: column;
	  padding: 7px 0;

	  .b-notification__cancellation-policy {
		font-weight: 600;
		font-size: 14px;
		display: flex;

		&:before {
		  content: '';
		  display: block;
		  width: 1.2em;
		  height: 1.2em;
		  margin-right: 10px;
		  .g-icon-transfer-warning;
		}
	  }
	}

	&-hotel__notifications {
		display: flex;
		flex-flow: column;
		margin-left: 10px;
		max-width: 650px;
    
    gap: 8px;
	}

	&-hotel__notification {
		background-position: left top;
		color: #2F3842;

		& + .b-order-hotel__notification{
			margin-top: 10px;
		}
	}

	&-info__notification {
		background-position: left top;
		color: #2F3842;

		& + .b-order-info__notification{
			margin-top: 10px;
		}
	}


	&__additional-services-container {
		padding: 0 60px 15px 45px;
	}

	&__info-footer-container {
		display: flex;
		flex-flow: row nowrap;
		align-items: flex-start;

		.b-order__info-footer-controls {
			display: flex;
			flex-flow: row nowrap;
			white-space: nowrap;
		}
	}
	
	.limit-date--with-m-top {
		margin-top: 10px;
	}
}

.toggle-order__additional-services-container,
.toggle-order__docodoca-container {
	background: #fff;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
	border: 1px solid #A9A9A9;
	.rounded-bottom;

	margin: 0 50px 0 26px;
	padding: 0;

	position: relative;
	bottom: 5px;
	z-index: 1;

	.b-order__additional-services-container,
	.b-order__docodoca-container {
		padding-top: 25px;
		.b-visa-info__info {
			background: none;
			border: none;
		}
	}
}

.toggle-order__additional-services-container {
	&.show + .b-order__container-expand {
		bottom: 10px;
		z-index: 0;
	}
}

.b-order-reservation[data-reservation-subject="hotel_reservation"],
.b-order-reservation[data-reservation-subject="railway"] {
	.b-order__container-expand {
		margin: 0;
	}
}

.b-order-reservation[data-reservation-subject="hotel_reservation"] {
	.b-order__container-header {
		min-height: 50px;
		height: unset;
	}

	.b-order-price {
		text-align: right;
	}
}

.b-popup__approval-booking {
  	width: 1500px!important;

  	.b-button-container__footer {
	  margin: 0 auto;
	  width: 700px;
	}

  	.b-offers-avia-modal {
	  text-align: left;
	}
	.b-popup__content {
		background: #fff;
		padding: 30px;
		position: relative;
	}

	.b-cabinet-order-approval-popup__form-control {
		margin-bottom: 10px;
		text-align: left;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.b-payment__block-header-status-ok {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.b-textarea,
	.b-input {
		padding: 0;
	}

	.b-textarea__value,
	.b-input__value {
		border-color: rgba(164, 171, 179, 0.5);
		color: #2F3842;
		.placeholder(#6E7985);
	}

	.b-input {
		//max-width: 385px;
	}

	.b-input__value {
		height: 35px;
	}

	.b-textarea__value {
		max-width: 635px;
	}
}

.b-order__payment .b-order__container-header {
	.b-toggle {
		text-transform: none;
	}
}
.b-order__footer {
  	display: flex;
  	flex-flow: row nowrap;

  	&.b2c-view {
	  justify-content: flex-end;

	  & > .b-order__payment {
		width: 50%;
		flex: unset;
	  }
	}

  	& > div {
		flex: 1 1 50%;
  	}

  	.b-block-container__footer-rightbar {
	  display: flex;
	  flex-flow: row wrap-reverse;
	  gap: 20px;
	  justify-content: flex-end;

	  & > .b-block-container {
		margin-bottom: unset;
		display: flex;
	  }
	}

  	.b-create-finance-documents {
	  border-color: #A9A9A9;
	}

	.b-order__container-header {
		flex-wrap: wrap;
	}
	.b-toggle {
		height: 25px;
		line-height: 25px;
		margin: 10px 0;
		white-space: nowrap;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
	}
	.b-toggle__label {
		color: #A5ABB3;
		font-size: 18px;
		line-height: 21px;
		margin-right: 15px;
	}
	.b-toggle__view:before {
		width: 17px;
		height: 17px;
		left: 4px;
		transition: all 0.3s;
	}
	.b-toggle .b-toggle__input:checked + .b-toggle__label .b-toggle__view:before,
	.b-toggle.first .b-toggle__label .b-toggle__view:before {
		background: #4A5767;
		border: 1px solid #A5ABB3;
	}
	.b-toggle .b-toggle__input:checked + .b-toggle__label .b-toggle__view:before {
		left: unset;
		right: 4px;
	}
	.b-toggle.first .b-toggle__label:first-child,
	.b-toggle .b-toggle__input:checked + .b-toggle__label {
		color: #4A5767;
	}
	.b-toggle .b-toggle__input:checked + .b-toggle__label .b-toggle__view,
	.b-toggle.first .b-toggle__label .b-toggle__view {
		border: 1px solid #A5ABB3;
		width: 48px;
		height: 25px;
		border-radius: 12.5px;
		margin-right: 15px;
	}
}

.b-order__cancelled {
	.b-order__container-title {
		display: inline-block;
		text-transform: uppercase;
		font-size: 18px;
		font-weight: 500;

		&:after {
			width: 18px;
			height: 10px;
			top: calc(~'50% - 5px');
		}
	}
}

.b-order__info-footer .flight-receipt-action-link-wrapper {
	margin-right: 10px;

  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  white-space: nowrap;
}

.order-reservation__travel-policy-container {
  display: flex;
  gap: 10px;
}


@import "./style.mobile";
