@media @tablet {
  .b-traveller {
	&__title {
	}

	&__title-contact {
	  float: left;
	  margin: 20px 0;
	}

	&__block-header {
	  width: 100%;
	}

	&__passport-number {
	}

	&__passport-expired-date {
	}



	.b-middle-name__without,
	.b-middle-name__without-lat {
	  color: #099C9D;
	  border-bottom: none;
	}
  }

  .b-traveller-number {
  }

  .b-header-border {
  }

  .b-traveller-tariff {
  }

  .selected {
  }

  .b-traveller-info {
  }

  .b-traveller-info_language {
	background: #FCEFE0;
	color: #FF8D2E;
	position: relative;
	padding: 5px 40px;
	margin: 12px;

	&:before {
	  width: 22px;
	  height: 22px;
	  content: ' ';
	  position: absolute;
	  left: 10px;
	  top: 15px;
	  .g-icon-attention-orange;
	}

	& > strong {
	  font-weight: normal;
	}
  }

  .b-input__label {
	position: relative;
  }

  .b-input__label,
  .p-search__direction-date-label {
	  top: -10px;
	  margin: unset;
	  padding: unset;
  }

  .p-search__direction-date-label {
	font-size: 14px;
	background: transparent;
  }

  .l-grid-container {
	height: fit-content;
  }

  .l-grid-layout-33 {
	height: fit-content;
	margin-top: 30px;
  }

  .p-search {
	&__options-date-with-label {
	}
  }

  .l-line {
	margin-top: 27px;
	margin-bottom: 17px;
  }

  .b-traveller-cost-codes {
	&__container {
	}
  }
}
