@media (min-width: 1440px) {
	.p-cabinet-passengers {
		.p-search__submit {
			width: 355px;
		}
	}
}

@media (min-width: 1920px) {
	.p-cabinet-passengers {
		.b-block-container__element {
			flex: 1 1 20%;

			&:nth-child(3n) {
				margin-right: 12px;
			}
		}
	}
}

@media @tablet {
  .p-cabinet-passengers {

	.b-block-table__cell:not(.b-block-table__cell-fullName, .b-block-table__cell-birthDate) {
	  background: #FFFFFF !important;
	}

	.b-block-table {
	  table-layout: fixed !important;

	  &__row {
		padding: 0;
		margin: 12px 0;
		height: fit-content !important;
		position: relative;
		background: #FFFFFF !important;
		.box-shadow(~'0px 8px 18px -6px rgba(24, 39, 75, 0.05), 0px 12px 42px -4px rgba(24, 39, 75, 0.05)');

		&-header {

		}
	  }

	  &__cell {

		&-icon {
		  display: flex;
		}
	  }
	}

	.b-block-container {

	  &__content-passengers {
		box-shadow: none !important;
		border: none !important;
	  }

	  &__header-label:before {
		.g-icon-passenger-black;
	  }

	  &__top-controls {
		flex-flow: column wrap !important;
		align-items: unset !important;

		.b-block-container__element {
		  margin-right: unset;

		  &:first-of-type {
			margin-top: 10px;
		  }

		  & .b-input__label {
			display: none;
		  }
		}

		& > .b-block-container__element:last-of-type {
		  margin-top: 20px;
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  background: #FAFAFA;
		}
	  }

	  &__footer {
		background: transparent !important;
	  }
	}
  }

  .b-passenger-add, .b-employees-add {
	color: #FF8D2E !important;
	background: none !important;
	border: none !important;

	&:after {
	  .g-icon-add-orange;
	  width: 1.2em;
	  height: 1.2em;
	  content: ' ';
	  margin-left: 10px;
	}
  }
}
