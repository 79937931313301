@media @tablet {
  	.b-header__blocks {
	  position: relative;
	}

	.b-breadcrumbs {
	  width: 100%;
	  overflow-x: scroll;
	  -ms-overflow-style: none;
	  scrollbar-width: none;

	  &__mobile-arrows {
		display: none;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		pointer-events: none;

		&:before, &:after {
		  content: ' ';
		  position: absolute;
		  top: 5px;
		  width: 20px;
		  padding: 5px;
		  height: 100%;
		  z-index: 10;
		  pointer-events: none;
		  background-color: #fff;
		}

		&:before {
		  left: 0;
		  .g-icon-arrow-left;
		  background-position: 60% 50%;
		  background-size: 50% 50%;
		}
		&:after {
		  right: 0;
		  .g-icon-arrow-right;
		  background-position: 40% 50%;
		  background-size: 50% 50%;
		}
	  }

	  &::-webkit-scrollbar {
		display: none;
	  }

	  &__item {
		white-space: nowrap;
		font-size: 12px;
	  }

	  &__item-active.last {
		&:before {
		  height: 2px;
		  width: calc(~'100% - 20px');
		  bottom: 0;
		  left: 5px;
		  border-radius: 0;
		  background: #099C9D;
		}
	  }
	}
  	.b-header__blocks.fixed {
	  .box-shadow(~'0 0 10px #4A5767');
	  .b-breadcrumbs {
		background: #fff;
		margin: 0;
		padding: 12px;
		border-radius: 0;

		&__mobile-arrows {
		  &:before, &:after {
			top: 0;
			width: 13px;
		  }
		  &:before {
			background-size: 70% 70%;

		  }

		  &:after {
			background-size: 70% 70%;

		  }
		}


		.b-breadcrumbs__item {
		  color: rgba(74, 87, 103, 0.3);
		  &-active {
			color: #4A5767;
		  }
		}
	  }
	}
}

@media (max-width: 561px) {
  	.b-breadcrumbs__mobile-arrows {
	  display: block;
	}
}
