@media @tablet {
  .b-cabinet-passenger {
	&__content {
	}

	&__person {
	}

	&__save {
	  float: right;
	  width: 50%;
	  text-transform: capitalize;
	  background: #4882BD !important;
	  .border-radius(~'0 4px 4px 0');
	}
  }

  .b-block-container {
	&__header {
	}

	&__header-label {

	}

	&__header-right {
	}

	&__content {
	}
  }

  .b-cabinet-collection__content:not(:only-child) {
	width: fit-content;
	left: 50%;
	transform: translateX(-50%);
  }

  .b-cabinet-collection__content .b-cabinet-collection__add {
	border: unset;
	background-image: unset;
	.g-icon-add-orange;
	height: 28px;
	width: 28px;
  }

  .b-cabinet-person__emails,
  .b-cabinet-person__phones {

	.l-grid-layout-33:nth-last-child(2) {
	  width: 85%;
	}

	.b-input__remove,
	.b-input-phone__remove {
	  background-image: url("~assets/img/icons/icon-close-red.svg");
	  background-repeat: no-repeat;
	  background-position: 90% 0;
	  background-size: 50% 50%;
	  border-left: none;
	}

	.b-cabinet-collection__content:not(:only-child) {
	  position: unset;
	  width: 15%;
	  float: right;
	  left: unset;
	  transform: unset;

	  .b-cabinet-collection__add {
		float: right;
		height: 20px;
		width: 20px;
		margin: 20px 11px 20px 0;
	  }
	}
  }

  .b-person-bonus-card-railways__remove,
  .b-person-bonus-card__remove,
  .b-passport__remove {
	background-image: unset !important;
	.g-icon-close-red !important;
	border: none !important;
	width: 28px !important;
	height: 28px !important;
	margin-right: 6px !important;
  }

  .b-person-bonus-card,
  .b-person-bonus-card-railways {

	.l-grid-container {
	  display: flex;
	  flex-direction: column;

	  & > .l-grid-layout-33:last-of-type {
		order: -1;

		& > span {
		  float: right;
		}
	  }
	}
  }

  .btn {
  }

  .btn-alarm {
  }

  .btn-icon {
  }

  .btn-icon-remove {
  }

  .b-passenger-remove {
	float: left;
	width: 50%;
	height: 45px;
	padding: 0 !important;
	border: none !important;
	color: #d70000 !important;
	background: #EDEEF0 !important;
	.border-radius(~'4px 0 0 4px');

	&:after {
	  content: unset;
	}

	&.show {

	}
  }

  .ml-auto {
  }
}
