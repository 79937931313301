.g-icon {
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: 50% 50%;
}

.g-icon-clock-black {
	background-image: url("../img/icon-clock-black.svg");
	.g-icon;
}

.g-icon-clock-white {
	background-image: url("../img/icon-clock-white.svg");
	.g-icon;
}

.g-icon-clock-green {
	background-image: url("../img/icon-clock-green.svg");
	.g-icon;
}

.g-icon-clock-red {
	background-image: url("../img/icon-clock-red.svg");
	.g-icon;
}

.g-icon-clock-yellow {
	background-image: url("../img/icon-clock-yellow.svg");
	.g-icon;
}

.g-icon-arrow-up {
	background-image: url("../img/icon-arrow-up-black.svg");
	.g-icon;
}

.g-icon-arrow-up_w {
	background-image: url("../img/icon-arrow-up-white.svg");
	.g-icon;
}

.g-icon-arrow-white-down {
	background-image: url("../img/icon-arrow-white-down.svg");
	.g-icon;
}

.g-icon-arrow-down_w {
	background-image: url("../img/icon-arrow-down.svg");
	.g-icon;
}

.g-icon-arrow-down {
	background-image: url("../img/icon-arrow-down-black.svg");
	.g-icon;
}

.g-icon-arrow-left {
	background-image: url("../img/icon-arrow-left.svg");
	.g-icon;
}

.g-icon-arrow-right {
	background-image: url("../img/icon-arrow-right.svg");
	.g-icon;
}

.g-icon-arrow-left-white {
	background-image: url("../img/icon-arrow-left-white.svg");
	.g-icon;
}

.g-icon-arrow-right-white {
	background-image: url("../img/icon-arrow-right-white.svg");
	.g-icon;
}

.g-icon-plus {
	background-image: url("../img/icon-plus.svg");
	.g-icon;
}

.g-icon-plus-green {
  background-image: url("../img/icon-plus-green.svg");
  .g-icon;
}

.g-icon-plus-black {
	background-image: url("../img/icon-plus-black.svg");
	.g-icon;
}

.g-icon-plus-grey {
	background-image: url("../img/icon-plus-grey.svg");
	.g-icon;
}

.g-icon-option-baggage-FREE {
	background-image: url("../img/icon-baggage.svg");
	.g-icon;
}

.g-icon-option-baggage-FREE-black,
.g-icon-option-baggage-PAID-black {
	background-image: url("../img/icon-baggage-black.svg");
	.g-icon;
}

.g-icon-option-baggage-grey {
	background-image: url("../img/icon-baggage-grey.svg");
	.g-icon;
}

.g-icon-option-cabin-luggage-FREE-black,
.g-icon-option-cabin-luggage-PAID-black {
	background-image: url("../img/icon-cabin-luggage-black.svg");
	.g-icon;
}

.g-icon-option-cabin-luggage-OFF-black {
	background-image: url("../img/icon-cabin-luggage-off-black.svg");
	.g-icon;
}

.g-icon-option-cabin-luggage-grey {
	background-image: url("../img/icon-cabin-luggage-grey.svg");
	.g-icon;
	background-position: 0 0 !important;
}

.g-icon-option-baggage-PAID {
	background-image: url("../img/icon-baggage-paid.svg");
	.g-icon;
}

.g-icon-option-baggage-OFF {
	background-image: url("../img/icon-baggage-off.svg");
	.g-icon;
}

.g-icon-option-baggage-OFF-black {
	background-image: url("../img/icon-baggage-off-black.svg");
	.g-icon;
}

.g-icon-option-exchange-FREE {
	background-image: url("../img/icon-exchange.svg");
	.g-icon;
}

.g-icon-option-exchange-FREE-black {
	background-image: url("../img/icon-exchange-black.svg");
	.g-icon;
}

.g-icon-option-exchange-PAID {
	background-image: url("../img/icon-exchange-paid.svg");
	.g-icon;
}

.g-icon-option-exchange-PAID-black {
	background-image: url("../img/icon-exchange-paid-black.svg");
	.g-icon;
}

.g-icon-option-exchange-OFF {
	background-image: url("../img/icon-exchange-off.svg");
	.g-icon;
}

.g-icon-option-exchange-OFF-black {
	background-image: url("../img/icon-exchange-off-black.svg");
	.g-icon;
}

.g-icon-option-refund-FREE {
	background-image: url("../img/icon-refund.svg");
	.g-icon;
}

.g-icon-option-refund-FREE-black {
	background-image: url("../img/icon-refund-black.svg");
	.g-icon;
}

.g-icon-option-refund-PAID {
	background-image: url("../img/icon-refund-paid.svg");
	.g-icon;
}

.g-icon-option-refund-PAID-black {
	background-image: url("../img/icon-refund-black-paid.svg");
	.g-icon;
}

.g-icon-option-refund-OFF {
	background-image: url("../img/icon-refund-off.svg");
	.g-icon;
}

.g-icon-option-refund-OFF-black {
	background-image: url("../img/icon-refund-off-black.svg");
	.g-icon;
}

.g-icon-option-refund-exchange-FREE-black,
.g-icon-option-refund-exchange-PAID-black {
	background-image: url("../img/icon-refund-exchange-black.svg");
	.g-icon;
}

.g-icon-option-refund-exchange-OFF-black {
	background-image: url("../img/icon-refund-exchange-off-black.svg");
	.g-icon;
}

.g-icon-option-refund-exchange-grey {
	background-image: url("../img/icon-refund-exchange-grey.svg");
	.g-icon;
}

.g-icon-option-refund-exchange-full {
  background-image: url("../img/icon-refund-exchange-full.svg");
  .g-icon;
}

.g-icon-option-baggage-full {
  background-image: url("../img/icon-baggage-full.svg");
  .g-icon;
}

.g-icon-option-cabin-luggage-full {
  background-image: url("../img/icon-cabin-luggage-full.svg");
  .g-icon;
}

.g-icon-cancel {
	background-image: url("../img/icon-cancel.svg");
	.g-icon;
}

.g-icon-w-cancel {
  background-image: url("../img/icon-cancel-w.svg");
  .g-icon;
}

.g-icon-b-cancel {
  background-image: url("../img/icon-cancel-b.svg");
  .g-icon;
}

.g-icon-delete {
	background-image: url("../img/icon-delete.svg");
	.g-icon;
}

.g-icon-delete-collection {
	background-image: url("../img/icon-collection-delete.svg");
	.g-icon;
}

.g-icon-delete-collection--color-gray {
	background-image: url("../img/icon-collection-delete-w.svg");
	.g-icon;
}

.g-icon-add-collection {
	background-image: url("../img/icon-collection-add.svg");
	.g-icon;

	&--color-gray {
		background-image: url("../img/icon-collection-add-w.svg");
	}
}

.g-icon-add-collection--color-gray {
	background-image: url("../img/icon-collection-add-w.svg");
	.g-icon;
}

.g-icon-ok {
	background-image: url("../img/icon-ok.svg");
	.g-icon;
}

.g-icon-features-3D-contract {
	background-image: url("../img/icon-features-3D-contract.svg");
	.g-icon;
}

.g-icon-visa-info {
	background-image: url("../img/icon-visa-info.svg");
	.g-icon;
}

.g-icon-visa-info--color-green {
	background-image: url("../img/icon-visa-info-green.svg");
	.g-icon;
}

.g-icon-travel-policy-rules-HIDE {
	display: none;
}

.g-icon-travel-policy-rules-RECOMMENDED {
	background-image: url("../img/icon-travel-policy-recommended.svg");
	.g-icon;
}

.g-icon-travel-policy-rules-VIOLATION {
	background-image: url("../img/icon-travel-policy-violation.svg");
	.g-icon;
}

.g-icon-travel-policy-rules-STRICT_VIOLATION {
	background-image: url("../img/icon-travel-policy-strict-violation.svg");
	.g-icon;
}

.g-icon-travel-policy-rules-AUTHORIZATION_REQUIRED {
	background-image: url("../img/icon-travel-policy-auth-required.svg");
	.g-icon;
}

.g-icon-show-password-button {
	background-image: url("../img/icon-show-password-button.svg");
	.g-icon;
}

.g-icon-help {
	background-image: url("../img/icon-help.svg");
	.g-icon;
}

.g-icon-download {
	background-image: url("../img/icons/icon-download.svg");
	.g-icon;
}

.g-icon-reload {
	background-image: url("../img/icons/icon-reload.svg");
	.g-icon;
}

.g-icon-basket {
	background-image: url("../img/icons/icon-basket.svg");
	.g-icon;
}

.g-icon-edit {
	background-image: url("../img/icons/icon-edit.svg");
	.g-icon;
}

.g-icon-attention {
	background-image: url("../img/icons/icon-attention.svg");
	.g-icon;
}

.g-icon-transfer-warning {
  background-image: url("../img/transfers/icons/icon-transfers-warning.svg");
  .g-icon;
}

.g-icon-transfer-passenger {
  background-image: url("../img/transfers/icons/icon-passenger.svg");
  .g-icon;
}

.g-icon-transfer-passenger-green {
  background-image: url("../img/transfers/icons/icon-passenger-green.svg");
  .g-icon;
}

.g-icon-information {
  background-image: url("../img/icons/icon-information.svg");
  .g-icon;
}

.g-icon-warning {
  background-image: url("../img/icons/icon-warning.svg");
}

.g-icon-briefcase {
  background-image: url("../img/icons/icon-briefcase.svg");
  .g-icon;
}

.g-icon-clipboard {
  background-image: url("../img/icons/icon-clipboard.svg");
  .g-icon;
}

.g-icon-clipboard-black {
  background-image: url("../img/icons/icon-clipboard-black.svg");
  .g-icon;
}

.g-icon-dollar-sign {
  background-image: url("../img/icons/icon-dollar-sign.svg");
  .g-icon;
}

.g-icon-exit {
  background-image: url("../img/icons/icon-exit.svg");
  .g-icon;
}

.g-icon-file {
  background-image: url("../img/icons/icon-file.svg");
  .g-icon;
}

.g-icon-file-text {
  background-image: url("../img/icons/icon-file-text.svg");
  .g-icon;
}

.g-icon-schedule {
  background-image: url("../img/icons/icon-schedule.svg");
  .g-icon;
}

.g-icon-shopping-bag {
  background-image: url("../img/icons/icon-shopping-bag.svg");
  .g-icon;
}

.g-icon-user {
  background-image: url("../img/icons/icon-user.svg");
  .g-icon;
}
.g-icon-user-black {
  background-image: url("../img/icons/icon-user-black.svg");
  .g-icon;
}

.g-icon-users {
  background-image: url("../img/icons/icon-users.svg");
  .g-icon;
}

.g-icon-seat {
  background-image: url("../img/icons/icon-seat.svg");
  .g-icon;
}

.g-icon-close-red {
  background-image: url("../img/icons/icon-close-red.svg");
  .g-icon;
}

.g-icon-add-orange {
  background-image: url("../img/icons/icon-add-orange.svg");
  .g-icon;
}

.g-icon-arrow-left-blue {
  background-image: url("../img/icons/icon-arrow-left-blue.svg");
  .g-icon;
}

.g-icon-attention-green {
  background-image: url("../img/icons/icon-attention-green.svg");
  .g-icon;
}

.g-icon-attention-orange {
  background-image: url("../img/icons/icon-attention-orange.svg");
  .g-icon;
}

.g-icon-attention-red {
  background-image: url("../img/icons/icon-attention-red.svg");
  .g-icon;
}

.g-icon-dollar-sign-black {
  background-image: url("../img/icons/icon-dollar-sign-black.svg");
  .g-icon;
}

.g-icon-passenger-black {
  background-image: url("../img/icons/icon-passenger-black.svg");
  .g-icon;
}

.g-icon-contact-info-black {
  background-image: url("../img/icons/icon-contact-info-black.svg");
  .g-icon;
}

.g-icon-transfer-black {
  background-image: url("../img/icons/icon-transfer-black.svg");
  .g-icon;
}

.g-icon-comments {
  background-image: url("../img/icons/icon-comments.svg");
  .g-icon;
}

.g-icon-additional-services {
  background-image: url("../img/icons/icon-additional-services.svg");
  .g-icon;
}

.g-icon-documents {
  	background-image: url("../img/icons/icon-passport.svg");
    .g-icon;

}

.g-icon-phones {
  	background-image: url("../img/icons/icon-phone.svg");
    .g-icon;

}

.g-icon-emails {
  	background-image: url("../img/icons/icon-email.svg");
    .g-icon;

}

.g-icon-search {
  background-image: url("../img/icons/icon-search.svg");
  .g-icon;
}

.g-icon-coin-orange {
  background-image: url("../img/icons/icon-coin-orange.svg");
  .g-icon;
}

.g-icon-user-blue {
  background-image: url("../img/icons/icon-user-blue.svg");
  .g-icon;
}

.g-icon-schedule-blue {
  background-image: url("../img/icons/icon-schedule-blue.svg");
  .g-icon;
}

.g-icon-organization-blue {
  background-image: url("../img/icons/icon-organization-blue.svg");
  .g-icon;
}

.g-icon-more-options-w {
  background-image: url("../img/icons/icon-more-options.svg");
  .g-icon;
}

.g-icon-send {
  background-image: url("../img/icons/icon-send.svg");
  .g-icon;
}

.g-icon-segment-aircraft {
  background-image: url("../img/icons/icon-segment-aircraft.svg");
  .g-icon;
}

.g-icon-segment-aircraft--white {
  background-image: url("../img/icons/icon-segment-aircraft-w.svg");
  .g-icon;
}

.g-icon-anc-fees-BAGGAGE {
  background-image: url("../img/icons/icon-baggage.svg");
  .g-icon;
}

.g-icon-anc-fees-MEALS {
  background-image: url("../img/icons/icon-meals.svg");
  .g-icon;
}

.g-icon-anc-fees-TRAVEL_SERVICES {
  background-image: url("../img/icons/icon-room-service-black.svg");
  .g-icon;
}

.g-icon-user-edit {
  background-image: url("../img/icons/icon-user-edit.svg");
  .g-icon;
}

.g-icon-user-edit-green {
  background-image: url("../img/icons/icon-user-edit-g.svg");
  .g-icon;
}

.g-icon-user-edit-red {
  background-image: url("../img/icons/icon-user-edit-r.svg");
  .g-icon;
}
