@import (reference) "~assets/css/imports";

@media (max-width: 769px) {
  .b-cabinet-order-auth-steps {
    width: 80%;
    height: fit-content;
    max-height: 50vh;

    &__modal-content-container {
      max-height: 50vh;
    }
  
    &__modal-content {
      height: 50vh;
    }
  }
}

@media (max-width: 1024px) and ( min-aspect-ratio: 1.5 / 1) {
  .b-cabinet-order-auth-steps {
    aspect-ratio: 2 / 1;

  }
}
