@import (reference) "~assets/css/imports";

.b-transfer {
  display: none;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
  background: #FFFFFF;
  border: 1px solid #A9A9A9;
  border-radius: 3px;
  z-index: 5;

  &__visible {
	display: block;
  }

  &__unavailable {
	opacity: 0.8;
	pointer-events: none;
  }

  &__wrapper {
	padding: 33px;
	display: flex;
	flex-flow: nowrap row;
	position: relative;
	overflow: hidden;
	@media (max-width: 981px) {
	  overflow: unset;
	}
  }

  &__section {
	display: flex;
	flex: 1 1;
  }

  &__travel-policy {
	position: absolute;
	right: 4px;
	top: 4px;
  }
  
  &__info {
	flex-direction: row;
	justify-content: flex-start;
	flex: 1 1 80%;
	@media (min-width: 769px) and (max-width: 981px) {
	  flex-wrap: wrap;
	}

	&-section:not(:first-child) {
	  margin-top: 20px;
	  margin-left: 23px;
	}

	&-section {
	  display: flex;
	  flex: 1 1;
	}

	&-row {
	  display: flex;
	  align-items: center;
	  flex-flow: nowrap row;
	}

	&-photo {
	  flex-flow: column;
	  width: 180px;
	  height: 150px;
	  background-size: contain;
	  background-position: center;
	  background-repeat: no-repeat;

	  &--standard {
		background-image: url("~assets/img/transfers/car-class-img/standard.png");
	  }

	  &--comfort {
		background-image: url("~assets/img/transfers/car-class-img/comfort.png");
	  }

	  &--business {
		background-image: url("~assets/img/transfers/car-class-img/business.png");
	  }

	  &--minivan {
		background-image: url("~assets/img/transfers/car-class-img/minivan.png");
	  }

	  &--minibus {
		background-image: url("~assets/img/transfers/car-class-img/minibus.png");
	  }
	}

	&-details {
	  flex-basis: 30%;
	  flex-direction: column;
	  color: #4A5767;
	}

	&-restrictions {
	  flex-basis: 20%;
	  flex-flow: column;


	  .b-transfer__info-capacity {
		white-space: nowrap;

		&-icon {
		  .g-icon-transfer-passenger;
		  width: 22px;
		  height: 22px;
		  display: block;
		  margin-right: 15px;

		  &.green {
			.g-icon-transfer-passenger-green;
		  }
		}

		&-text {
		  font-weight: 500;
		  font-size: 15px;
		  line-height: 33px;
		}
	  }
	}

  }

  &__info-capacity--exceeded {
	gap: 15px;

	&-icon {
	  .g-icon-information;
	  width: 22px;
	  height: 22px;
	  display: block;
	}

	&-text {
	  font-size: 11px;
	  line-height: 13px;
	  max-width: 230px;
	  color: #FF8D2E;
	}
  }

  &__car-class {

	&-text {
	  font-size: 28px;
	  line-height: 33px;
	  @media (max-width: 981px) {
		font-size: 20px;
	  }
	}
  }

  &__model-name {

	&-text {
	  font-size: 13px;
	  line-height: 15px;
	  @media (max-width: 981px) {
		font-size: 12px;
	  }
	}
  }

  &__minimal-rent-price {

  }


  &__price-block {
	display: flex;
	flex-basis: 15%;
	flex-direction: column;
	margin-top: 20px;
	margin-left: 23px;
	font-size: 16px;

	.b-transfer__select-container.price-only {
	  .b-transfer__select-button-price {
		white-space: nowrap;
		font-size: 18px;
		font-weight: bold;
		color: #4A5767;
		margin-bottom: 5px;
		text-align: center;
	  }
	}

	.b-transfer__select-button,
	.b-transfer__select-button--offer {
	  background: #4882BD;
	  height: 36px;
	  width: 100%;
	  padding: 4px 0;
	  border-radius: 3px;
	  display: flex;
	  flex-flow: column nowrap;
	  align-items: center;
	  justify-content: center;
	  color: #fff;
	  cursor: pointer;
	  position: relative;
	  margin-bottom: 15px;
	  margin-right: 0;
	}

	.b-transfer__select-button--offer {
	  position: relative;
	  background: none;
	  height: 36px;
	  color: #4882BD;

	  &::before {
		content: "+";
		position: absolute;
		left: 20px;
		top: 7px;
		z-index: 100;

		display: block;
		width: 20px;
		height: 20px;

		text-align: center;
		font-size: 14px;
		border: 2px solid #4882BD;
		border-radius: 50%;

		transition: transform 0.2s;
	  }

	  &:hover {
		color: #D70000;
		background: none;

		&::before {
		  border-color: #D70000;
		}

	  }

	  &.b-ticket-offer__cancel {
		color: #D70000;

		&::before {
		  border-color: #D70000;
		  transform: rotate(45deg);
		}
	  }
	}
  }
}

.b-transfer__select-button--offer-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.b-transfer__select-button, .b-transfer__select-button--offer {
  min-width: 180px;
}

.b-transfer__select-button--offer-price {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #4882BD;
  flex: 1;
}


@import "./style.mobile";
