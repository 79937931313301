@import (reference) "~assets/css/imports";


.b-search-progress-bar {
  padding: 0 6px;
  display: flex;
  width: 100%;
  height: 50px;
  flex-flow: column nowrap;


  &__spinner-container {
	display: flex;
	justify-content: space-between;
	width: 120px;
	height: 30px;
	gap: 10px;
	align-items: center;
  }

  &__spinner {
	display: block;
	height: 20px;
	width: 20px;
	min-width: 20px;
	border: 2px dotted;
	border-radius: 50%;
	animation: spin 2s linear infinite;
  }

  &__spinner-text {
	white-space: nowrap;
  }

  &__bar {
	box-sizing: content-box;
	height: 10px;
	width: 100%;
	position: relative;
	background: #E6E6E6;
	border-radius: 10px;
	box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);

	& > span {
	  display: block;
	  height: 100%;
	  width: 10%;
	  border-radius: 20px 8px 8px 20px;
	  background: linear-gradient(90deg, #72C6EF 0%, #4882BD 100%);
	  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
	  inset 0 -2px 6px rgba(0, 0, 0, 0.4);
	  position: relative;
	  overflow: hidden;

	  &:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-image: linear-gradient(
				-45deg,
				rgba(255, 255, 255, 0.2) 25%,
				transparent 25%,
				transparent 50%,
				rgba(255, 255, 255, 0.2) 50%,
				rgba(255, 255, 255, 0.2) 75%,
				transparent 75%,
				transparent
		);
		z-index: 1;
		background-size: 50px 50px;
		animation: move 2s linear infinite;
		border-radius: 20px 8px 8px 20px;
		overflow: hidden;
	  }
	}
  }
}

@keyframes move {
  0% {
	background-position: 0 0;
  }
  100% {
	background-position: 50px 50px;
  }
}

@keyframes spin {
  0% {
	transform: rotate(0deg);
  }
  100% {
	transform: rotate(360deg);
  }
}
