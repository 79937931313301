.b-train__seats {
	.b-messages {
		padding: 20px;
		background: #f0f0f0;
		border-radius: 3px;
	}
}

.b-train__carriage {
	&.open {
		.b-train__carriage-number {
			span {
				color: #a5abb3;
			}
			span.open {
				color: #4a5767;
			}
		}
	}

	&-services-group {
		display: none;
		flex-flow: row wrap;
		margin-bottom: -10px;

		&.open {
			display: flex;
		}
	}

	&-gender {
		display: none;
		font-size: 13px;
		color: #E50000;

		&.open {
			display: block;
		}
	}

	&-refundable {
		width: 21px;
		height: 21px;
		margin-left: 15px;
	}
}

.b-train__carriages {
	&-refundable-group {
		display: none;
	}

	&-refundable-group.open {
		display: block;
	}
}

.b-train-carriage-border-text {
	padding-left: 20px;
}

.b-carriage-maps {
	&__container {
		display: none;
		flex-direction: row;
		justify-content: space-around;
		margin: 12px 0;
		position: relative;

		&.open {
			display: flex;
		}
	}

	&__arrow {
		min-width: 42px;
		width: 100%;
		align-items: center;
		position: relative;
		display: none;
	}

	&__area {
		.debug-container {
			padding: 20px;
			background: #fff;

			& > .l-grid-container {
				display: flex;
				align-items: center;
			}

			textarea {
			    width: 100%;
			    height: 200px;
			    margin-top: 30px;
			}

			.js-check-seats {
				margin-right: 20px;
				margin-bottom: 20px;
			}
		}
	}

	&__controls {
		padding-left: 20px;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
	}

	&__control {
		width: 30px;
		height: 30px;
		border: 1px solid #4A5767;
		border-radius: 3px;
		margin-right: 8px;
		display: inline-block;
		cursor: pointer;
		background-size: 9px;

		&:last-child {
			margin-right: 0;
		}

		&.disabled {
			pointer-events: none;
			opacity: .25;
		}

		&.g-icon-arrow-left {
			background-position-x: 47%;
		}

		&.g-icon-arrow-right {
			background-position-x: 53%;
		}
	}
}

.b-car-place {
	&-tooltip {
		text-align: center;

		.tooltip__header {
			color: #fff;
			display: inline-block;
			width: auto;
			padding: 0;
		}

		.tooltip__body {
			text-align: center;
			border: 0 !important;
			position: relative;
			margin-top: 5px;
			padding: 5px 0 0 !important;

			&:before {
				content: '';
				display: block;
				border-top: 1px solid #fff;
				position: absolute;
				left: 25%;
				right: 25%;
				top: 0;
				opacity: .3;
			}
		}
	}
}

.b-map-description__messages {
	margin: 18px auto;
	max-width: 921px;

	.b-message {
		margin-left: 0;
	}

	.b-message + .b-message {
		margin-top: 10px;
	}
}

.b-carriage__selector-wrapper {
	display: flex;
	flex-direction: column;
}

.b-carriage__selector-compartment {
	color: black;
}

.b-carriage__selector-seats {
	display: none;
	flex: 1;
}

.b-carriage-map__description {
	display: none;
	background: #fff;
	margin: 18px auto;
	max-width: 921px;

	&.open {
		display: flex;
		justify-content: space-between;
	}

	.carriage-map-description__notifications {
		margin-top: 10px;
	}

	.b-map-description {

		&__genders {
			flex-direction: column;

			.b-gender {
				display: flex;
				margin-bottom: 9px;

				&-caption {
					line-height: 20px;
				}
			}
		}

		&__block {
			display: flex;

			.b-car-place {
				text-align: center;
				font-size: 14px;
				cursor: pointer;
				display: flex;
			  	justify-content: flex-end;

				&__caption {
					color: #4A5767;
					text-align: right;
					min-width: 250px;
				}

				&__quantity {
					color:#4A5767;
					text-align: right;
					margin-right: 18px;
					min-width: 60px;

					&-count {
						border-bottom: 1px dashed #4A5767;
					}
				}

				&__price {
					color: #E50000;
				}

				&:hover,
				&.hovered {
					.b-car-place__caption {
						font-weight: bold;
					}
				}
			}
		}

		&__prices {
			flex-direction: column;
		}
	}
}

.b-car-info__gender-desc, .b-carriage-map__description .b-map-description__block {
  .b-gender-icon {
	width: 20px;
	height: 20px;
	margin-right: 8px;
	font-size: 10px;
	font-weight: bold;
	line-height: 20px;
	text-align: center;
	border-radius: 2px;

	&-MALE {
	  border: 1px solid #4882BD;
	  background: rgba(72, 130, 189, 0.15);
	}

	&-FEMALE {
	  border: 1px solid #D70000;
	  background: rgba(215, 0, 0, 0.15);
	}

	&-MIX {
	  border: 1px solid #FFCE3C;
	  background: rgba(255, 206, 60, 0.15);
	}

	&-NOT_SELECTED {
	  color: #fff;
	  border: none;
	  background: rgba(9, 156, 157, .5);
	}
  }
}

.b-carriage__selected-variant {
	.b-selected-variant__controls .b-selected__gender {
		.b-input__value {
			&::before {
				font-family: 'Roboto', sans-serif;
				font-size: 14px;
				color: #fff;
				border: 1px solid #A4ABB3;
				border-left: none;
				border-radius: 3px;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				height: 30px;
				box-sizing: border-box;
				display: flex;
				align-items: center;

				background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDAuNjQzNjE2TDYgNS42NDM2MkwxMSAwLjY0MzYxNiIgc3Ryb2tlPSJ3aGl0ZSIvPgo8L3N2Zz4K);
				background-color: #4A5767;
				background-repeat: no-repeat;
				background-position: center;
				width: 14px;
				padding: 13px;
			}
		}

		.b-input {
			width: 100%;
			border: 1px solid #A4ABB3;
			display: block;
			color: #4A5767;
			cursor: pointer;
			text-align: left;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			position: relative;
			border-radius: 3px;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			height: 30px;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			padding-left: 8px;
			background-color: #fff;
		}

		.b-input__select-value-label {
			top: 5px;
			left: 0;
		}

		.b-input__select-value {
			height: 30px;
			background: none;
			margin-top: 0;
		}
	}

  	&.border-top {
	  border-top: 1px solid #B8B8B8;
	  padding: 14px 18px !important;
	}
}

@media (min-width: 1920px) {
	.b-carriage__selected-variant .b-selected-variant__controls .b-selected__gender .b-input__value {
		font-size: 14px;
	}
}
