@media (min-width: 1920px) {
	.b-hotels {
		&__results {
			padding: 0 6px;
		}
	}
}

@media @tablet {
  .b-hotels__map--fullscreen {
	left: 0;
	top: 0 !important;
	overflow: unset !important;
	width: 100vw;
  }
  .b-hotels__map-wrapper.dn {
	display: none !important;
  }
}
