.b-travellers {
  background: @field1-background-color;
  .rounded-top;
}

.b-travellers__header, .b-traveller-cost-codes__header, .b-booking__price-info-header {
  background: @field2-background-color;
  padding: 0 15px;
  height: 47px;
  line-height: 45px;
  color: @field2-H1-color;
  border-left: @field2-border;
  border-right: @field2-border;
  border-top: @field2-border;
  font-size: @base-H1-size;
  text-transform: uppercase;
  .rounded-top;
}

.b-booking__price-info {
  display: none;
}

@media @tablet {
  .b-booking__price-info {
	display: flex;
	flex-direction: column;
  }

  .b-travellers__header, .b-traveller-cost-codes__header, .b-booking__price-info-header, .b-customer__header {
	font-weight: 500;
	font-size: 14px;
  }

  .b-flight-info__pricing {
	color: #4A5767 !important;

	.b-pricing {
	  margin-top: 10px;
	}

	.b-pricing__header {
	  display: none;
	}
  }

  .b-booking__price-info-header,
  .b-travellers__header {
	display: flex;
	align-items: center;

	&:before {
	  content: ' ';
	  width: 22px;
	  display: block;
	  height: 22px;
	  margin-right: 10px;
	}
  }

  .b-booking__price-info-header:before {
	.g-icon-dollar-sign-black;
  }

  .b-travellers__header:before {
	.g-icon-passenger-black;
  }
}

