@import (reference) "~assets/css/imports";

.b-cabinet-person-cost-codes-wrapper {
  margin: 5px 0;

  &__header {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 5px;
  }
}

.b-cabinet-person-cost-codes-wrapper + .b-cabinet-person-cost-codes-wrapper {
  margin-top: 10px
}

@import "./style.mobile";