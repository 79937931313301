@media (max-width: 1239px) {
  .b-booking-docs-modal {
	.b-booking-docs-modal__emails {
	  .b-emails-collection {
		margin-right: 0;

		.b-search-email-row {
		  width: calc(~'100% - 45px');
		}
	  }
	}

	.b-block-table {

	  &__cell {

		& > span {
		  max-width: 250px;
		}
	  }
	}
  }
}

@media @tablet {
  .b-booking-docs-modal {
	padding-right: 0 !important;

	&__content {
	  max-height: 100vh;
	  height: 100%;
	}

	&__table {
	  .b-block-table {
		text-transform: unset !important;

		&__row-header {
		  display: none !important;
		}

		&__row {
		  display: flex;
		  flex-direction: column;

		  &:last-of-type {
			padding: 10px 0 0 0;
		  }
		}

		&__cell {
		  max-width: unset;
		  font-size: 14px;
		  padding: 0;
		  border-top: none !important;
		  border-bottom: none !important;
		  order: 3;

		  &:last-child {
			margin-bottom: 20px;
		  }

		  & > span, & > div {
			padding: 5px 12px;
		  }

		  &-type-checkbox {
			padding: 5px 12px;
			order: 0;
		  }

		  &--checkobx-with-caption {
			width: 100% !important;
			padding: unset !important;
			display: flex;
			justify-content: flex-start;
			align-items: center;
		  }

		  &-content-container {
			display: flex;
			justify-content: unset;

			& > span:not(:last-of-type), & > div:not(:last-of-type) {
			  margin-right: 5px;
			}
		  }

		  &.mobile-cell {
			display: flex;
			position: relative;
			padding: 0;
			overflow: unset;
			justify-content: flex-end;

			.btn {
			  border: none;
			}

			& > div, & > span {
			  padding: 0 !important;
			}

		  }
		}

		&__cell-header-mobile {
		  color: #A4ABB3;
		  margin-right: 5px;
		}
	  }
	}
  }
}
