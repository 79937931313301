@import (reference) "~assets/css/imports";

.b-password__show {
	.g-icon-show-password-button;
	cursor: pointer;
	display: block;
	background-size: 14px auto;
	background-position: 50% 50%;
	width: 30px;
	height: 30px;
	bottom: 5px;
	position: absolute;
	right: 0;
	z-index: 5;
	border-left: 1px solid #A4ABB3;
}
