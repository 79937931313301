@import (reference) "~assets/css/imports";

.b-transfer-order-info {

  &__wrapper {
	display: flex;
	padding: 20px;
	margin: 0;
  }

  &__row {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	width: 100%;
  }

  &__car-info {
	width: 100%;
	display: flex;
	flex-flow: row;
	flex: 1 1;

	&__passengers-icon {
	  .g-icon-transfer-passenger;
	  width: 15px;
	  height: 15px;
	  margin-right: 8px;
	}

	&__passengers-text {
	  white-space: nowrap;
	  font-weight: 500;
	  font-size: 16px;
	  margin-top: 5px;
	}

	&-photo {
	  background-size: contain;
	  background-repeat: no-repeat;
	  margin-right: 30px;
	}

	&-class {
	  font-weight: 500;
	  font-size: 20px;
	  line-height: 23px;
	}

	&-element {
	  margin-top: 33px;

	  & > .b-transfer-order-info__row {
		flex-flow: nowrap;
		white-space: nowrap;
	  }
	}
  }

  &__trip-info {
	display: flex;
	flex-flow: column;
	width: 100%;
	flex: 1 1;
	justify-content: space-between;

	&-time {
	  font-weight: bold;
	  font-size: 18px;
	  line-height: 23px;
	  margin-bottom: 8px;
	}

	&-route {
	  line-height: 16px;
	  font-size: 16px;
	  display: flex;
	  align-items: baseline;
	  flex-flow: row;
	  gap: 5px;
	  margin-bottom: 5px;

	  & > span {
		text-transform: uppercase;
		font-weight: bold;
		min-width: 60px;
	  }
	}

	&-element {
	  width: 100%;
	  margin-top: 33px;
	}
  }

  &__trip-sub-addresses {
	align-items: flex-start;
	margin-top: 10px;
	flex-flow: column;

	& > div:first-of-type {
	  text-transform: uppercase;
	  font-weight: bold;
	}

	& > div:not(:first-of-type, :last-child) {
	  margin-bottom: 5px;
	}
  }
}

@import './style.mobile';
