@import (reference) "~assets/css/imports";

@media @tablet {
  .l-layout.show-filters {

	.b-checkbox {

	}

	.b-checkbox__view {
	  color: #4A5767;

	  &:before {
		border: 1px solid #099C9D;
	  }
	}

	.b-filters .b-checkbox .b-checkbox__input:checked + .b-checkbox__view {
	  color: #4A5767 !important;
	}

	.b-selection__label {
	  color: #4a5767;
	}
  }
}

.b-checkbox {
	display: inline-block;
	cursor: pointer;
	position: relative;
	height: 25px;
	line-height: 21px;
	&.disabled {
		& .b-checkbox__view {
			color: #6b7685 !important;
		}
		& .b-checkbox__input[disabled="disabled"]:checked + .b-checkbox__view:before {
			background: #f8f8f8;
    		border-color: #bbb;
		}
		& .b-checkbox__input[disabled="disabled"]:checked + .b-checkbox__view:after {
			background: #bbb;
    		border-color: #bbb;
		}
	}
}

.b-checkbox__view {
	position: relative;
	display: inline-block;
	padding-left: 31px;

	&:before {
		content: "";
		color: @field1-active-color;
		.rounded;
		border: 1px solid @field1-object-color;
		display: inline-block;
		width: 20px;
		height: 20px;
		position: absolute;
		background: none;
		top: 0;
		left: 0;
	}

	&:after {
		content: "";
		display: block;
		position: absolute;
		width: 12px;
		height: 12px;
		top: 4px;
		left: 4px;
		color: @field1-active-color;
		background: @field1-active-color;
		opacity: 0;
		.transition-fast(transform);
		.transform(scale(0,0));
		.rounded;
		border-radius: 3px;
	}

	a {
		color: @field1-link-color;
		text-decoration: underline;
	}

}

.b-checkbox__input {
	display: none;
}

.b-checkbox .b-checkbox__input:checked + .b-checkbox__view {
	color: @field1-active-color;
}

.b-checkbox .b-checkbox__input:checked + .b-checkbox__view:before {
	border: 1px solid @field1-active-color;
}

.b-checkbox .b-checkbox__input:checked + .b-checkbox__view:after {
	.transform(scale(1,1));
	opacity: 1;
}

.b-checkbox.validation-error .b-checkbox__view:before {
	border-color: @field1-warning-color;
}

.b-filters .b-checkbox .b-checkbox__input:checked + .b-checkbox__view {
	color: @field2-active-color;
}

.b-checkbox .b-checkbox__input:disabled + .b-checkbox__view:before {
	background: #f8f8f8;
	border-color: #bbb;
}

.b-checkbox .b-checkbox__input[disabled="disabled"]:checked + .b-checkbox__view:before {
  background: rgb(9, 156, 157, 40%);
  border-color: rgb(9, 156, 157,40%);
}

.l-layout:not(.show-filters) .b-filters .b-checkbox .b-checkbox__input:checked + .b-checkbox__view:before {
	border: 1px solid @field2-active-color;
}

.l-layout:not(.show-filters) .b-filters .b-checkbox .b-checkbox__input:checked + .b-checkbox__view:after{
	background: @field2-active-color;
}

.l-layout:not(.show-filters) .b-filters .b-checkbox__view:before {
	border: 1px solid @field2-object-color;
}

.b-login__rules .b-checkbox__view:before {
	border: 1px solid @field2-font-color;
}

.b-login__rules .b-checkbox__view {
	color: @field2-font-color;
}
