@import (reference) "~assets/css/imports";

.b-loader {
	position: fixed;
	z-index: 999999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: none;
	background: rgba(0, 0, 0, 0.5);
}

	.open.b-loader {
		display: block;
	}

	.b-loader:before {
		content: '';
		display: block;
		background: url("~assets/img/icon-loader.svg") no-repeat;
		background-size: 100% 100%;
		width: 40px;
		height: 40px;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -20px 0 0 -20px;
		.animation(rotate 0.7s infinite linear);
	}

.keyframes(~'rotate, from { transform: rotate(0deg); } to { transform: rotate(360deg);  }');
