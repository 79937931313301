@media @tablet {
  .b-booking {
	&__transfer-ride-details {
	}

	&__customer {
	}

	&__price-info {
	}

	&__price-info-header {
	}

	&__price-info-container {

	  .b-transfer-pricing__header {
		display: none;
	  }

	  .b-transfer-pricing__service {
		margin: 0;
	  }

	  .b-transfer-pricing__direction,
	  .b-transfer-pservice__element {
		color: #4A5767 !important;
	  }
	}

	&__footer {
	}

	&__back {
	}

	&__submit {
	}
  }

  .b-booking-transfers {
	&__additional-services {
	}

	&__additional-services-header {
	  margin-bottom: unset;

	  &:before {
		.g-icon-additional-services;
		margin-right: 10px;
	  }
	}

	&__block-header {
	}

	&__additional-services-wrapper {
	}

	&__passengers {
	}

	&__passengers-header {
	  &:before {
		.g-icon-user-black;
		margin-right: 10px;
	  }
	}

	&__passengers-wrapper {
	}

	&__notes {
	  padding: 0 !important;
	}
  }

  .b-booking-notes {
	&__caption {
	  margin-bottom: unset;
	  &:before {
		.g-icon-comments;
		margin-right: 10px;
	  }
	}
  }

  .b-textarea {
  }


  .b-block-container {
	&__footer {
	}

	&__footer-leftbar {
	}

	&__footer-rightbar {
	}
  }

  .b-booking-notes__caption,
  .b-booking-transfers__additional-services-header,
  .b-booking-transfers__passengers-header {
	display: flex;
	align-items: center;

	&:before {
	  width: 22px;
	  height: 22px;
	  display: block;
	  content: ' ';
	}
  }
}
