@import (reference) "~assets/css/imports";

.b-places {
	font-size: 16px;
	height: 40px;
	padding: 0 50px 0 0;
	position: relative;
	display: block;
}

.b-places__wrapper {
	width: 100%;
}

.b-places input.b-places__input {
	border: 0;
	background: none;
	width: 100%;
	height: 40px;
	line-height: 40px;
	padding: 0 0 0 15px;
	color: #FFFFFF;
	overflow: hidden;
	float: left;
	text-overflow: ellipsis;
	.placeholder(@__base-color-2-dark);
	font-size: @font-size-big;
	outline: none;
}

.b-places__hint {
	color: @color-text;
	opacity: 0.5 !important;
}

.b-places__code {
	text-transform: uppercase;
	color: @color-text;
	line-height: 40px;
	position: absolute;
	right: 0;
	top: 0;
}

.b-places__items {
	background: #4A5767;
	padding: 0 0;
	margin-top: 1px;
	.rounded-bottom;
	.shadow;
	min-width: 265px;
	max-height: 210px;
	overflow-y: scroll;
}

.b-places__item {
	height: 30px;
	padding: 6px 10px;
	text-align: left;
	position: relative;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	box-sizing: border-box;

	&:hover {
		background-color: #2F3842;
		& .b-places__item-city {
			color: #4882BD;
			 & span {
				color: #4882BD;
			 }
		}
		& .b-places__item-code {
			color: #4882BD;
		}

	}
}


.b-places__cursor {
	background: @background-active;
	color: @color-text-white;
}

.b-places__cursor .b-places__item-code,
.b-places__cursor .b-places__item-city {
	color: @color-text-white;
}

.b-places__cursor.b-places__item:after {
	display: none;
}

.b-places__item-city {
	color: #fff;
	overflow: hidden;
	display: block;
	text-overflow: ellipsis;
	white-space: nowrap;

	span {
		color: @color-text;
	}
}

.b-places__item-code {
	float: right;
	color: @color-text;
	text-transform: uppercase;
	font-size: @font-size-big;
	margin-right: 10px;
	line-height: 40px;
	padding-left: 15px;
	margin-left: -100%;
}

.b-places.validation-error {

	.b-places__input {
		.placeholder(@color-text-styling);
		color: @color-text-styling;
	}

}

@media @tablet {

	.b-places__items {
		max-width: 110%;
	}

}

.b-places__items {
	width: 100%;
	border: 1px solid #A4ABB3;
	border-top: 0;
	box-sizing: border-box;
}

.p-places .validations-errors__container {
	opacity: 0;
	overflow: hidden;
	.transition-fast;
}

