@import (reference) "~assets/css/imports";

.b-block-container {

	.b-block-container__header {

		background: #4A5767;
		.rounded-top;
		display: flex;

		.b-block-container__header-label {
			height: inherit;
			font-weight: 500;
			font-size: 14px;
			color: #FFFFFF;
			text-transform: uppercase;
			padding: 12px;
		}
		.b-block-container__header-right {
			margin-left: auto;
			padding: 5px 10px;
		}
	}

	.b-block-container__group {
		.p-search__submit {
			font-weight: bold;
		}
	}

	.b-block-container__top-controls {
		background: #F0F0F0;
		padding: 12px 10px 12px 12px;
		display: flex;
		flex-direction: column;
	}

	.b-block-container__content {
		background: #FFFFFF;
		border-top: 1px solid #F0F0F0;
		padding: 9px 13px;

		.b-block-container-content__header {
			color: @field1-H1-color;
			font-weight: 500;
		}
	}


	.b-block-container__content-title {
		display: flex;
		flex-direction: row;
		position: relative;
		z-index: 1;

		&--bg-dark {
			box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
			background: #F0F0F0;
			color: @field1-font-color;
		}

		.b-block-container__title-leftbar {
			margin-right: auto;
		}

		.b-block-container__title-rightbar {
			margin-left: auto;
		}

		.b-block-container__title-item {
			margin-right: 20px;
		}
	}

	.b-block-container__content.b-block-container__content-products,
	.b-block-container__content.b-block-container__content-finance {
		padding: 0;
	}

  .b-block-container__footer {
	background: #F0F0F0;

		.b-block-table-footer {
			position: relative;
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;

			.b-footer-element {
				padding: 10px 12px;
				line-height: 19px;
			}

			.b-footer-element:after {
				content: '';
				display:block;
				height: 10px;
				width: 1px;
				background: rgba(0, 0, 0, 0.1);
				margin-left: 12px;
				margin-top: 5px;
				float: right;
			}

			.b-footer-element:last-child:after {
				content: none;
			}

			.b-footer-count {
				min-width: 130px;

				span {
					font-weight: 500;
				}
			}

			.b-footer-show {
				width: 95px;
				position: relative;
			  	z-index: 100; // IBECORP-4062

				.b-footer-show-select {
					cursor: pointer;

					.b-footer-show-label {
						position: relative;
					}

					.b-footer-show-label:before {
						content: '';
						display: block;
						width: 12px;
						position: absolute;
						right: 0;
						top: 5px;
						height: 10px;
						background: url('~assets/img/arrow_down_black.svg') no-repeat center;
					}

					.b-footer-show-source {
						display:none;
					}
				}

				.b-footer-show-select.open {
					.b-footer-show-source {
						display: block;
						position: absolute;
						bottom: 38px;
						right: 0;
						background: #F0F0F0;
						z-index: 999;
						ul {
							margin: 0;
							padding: 0;
							list-style: none;
						}
						li {
							padding: 7px;
						}
						li:hover {
							background: #AEB3B9;
						}
					}
				}

			}

		  	.b-footer-show-more {
			  	display: none;
			}

			.b-footer-show:after{
				position: absolute;
				top: 9px;
				right: 0;
			}


			.b-footer-pages,
			.b-footer-pages-container {
				position: absolute;
				width: 100%;

				flex-grow: 10;
				display: flex;
				justify-content: flex-end;
				flex-wrap: nowrap;
				flex-direction: row;

				.b-footer-page {
					min-width: 20px;
					height: 20px;
					padding: 0 5px;
					margin-right: 12px;
					border: 1px solid #AEB3B9;
					box-sizing: border-box;
					border-radius: 3px;
					text-align: center;
					color: #4A5767;
					cursor: pointer;
				}

				.b-footer-page.current {
					color: #2F3842;
					background: #FFFFFF;
					border: 1px solid #6E7985;
				}


				.b-footer-page:last-of-type {
					margin-right: 0;
				}

				&:empty:after {
					content: none;
				}

			}

			.b-footer-pages {
				width: 100%;
			}

			.b-footer-total {
				margin-left: 11px;
				white-space: nowrap;

			}

			.b-footer-total.b-footer-element:after {
				display: inline-block;
				float: none;
			}
		}
	}

	&__element-dates {

		.p {
			&-search {
				&__options {
					display: flex;
					flex-direction: row;
					width: 100%;
					align-items: center;

					&-date {
					  display: flex;
					  flex-direction: row;

					  .p-search__direction-date-input {
						min-width: 119px;
						max-width: 130px;
					  }

						&-element:first-of-type {
							margin-right:13px;
						}
					}

					&-date.date-30 {
						.p-search__direction-date-calendar {
							height: 30px;
							width: 30px;
						}
						.p-search__direction-date-input {
							height: 30px;
							padding: 0 11px 0 11px;
						}
						.p-search__bridge {
							margin-top: 15px;
							width: 13px;
							height: 1px;
							background-color: #A4ABB3;
							position: absolute;
							left: 149px;
							top: 8px;
						}
					}
				}

				&__direction {
					display: flex;
					flex-direction: column;
					width: 100%;
					margin-bottom: 18px;

					&-block {
						position: relative;
					}

					&-suggest {
						text-transform: uppercase;
						line-height: 40px;
						position: absolute;
						right: 0;
						top: 0;
					}

					&-wrapper-dates {
					  display: flex;
					  flex-direction: column;
					  max-width: 355px;
					}

					&-wrapper {
						display: flex;
						flex-direction: row;
						flex-basis: 100%;
						height: 40px;

						&_no-margin {
							margin: 0;
						}

						&_width-100 {
							width: 100%;
						}

						&_width-70 {
							width: 70%;
						}

						&_width-30 {
							width: 30%;
						}
					}

					&-departure {
						width: 50%;

						&-input {
							width: 100%;
						}
					}

					&-arrival {
						width: 50%;

						&-input {
							width: 100%;
						}
					}

					&-swap {
						position: relative;
						display: flex;
						align-items: center;

						&-img {
							background: url("~assets/img/icon-swap.svg") no-repeat center;
							height: 16px;
							width: 7px;
							color: #fff;
							position: absolute;
							z-index: 1;
							cursor: pointer;
						}
					}

					&-date {
						display: flex;
						flex-direction: row;
						align-items: center;

						&-calendar {
							font-family: 'Roboto', sans-serif;
							font-size: 14px;
							color: #fff;
							background-color: #4A5767;
							border: 1px solid #A4ABB3;
							border-left: none;
							border-radius: 0 3px 3px 0;
							width: 40px;
							height: 40px;

							&-img {
								background: url("~assets/img/icon-calendar.svg") no-repeat center;
								width: 100%;
								height: 100%;
							}
						}

						&-input {
							width: 100%;
							max-width: 130px;
							border: 1px solid #A4ABB3;
							border-radius: 3px 0 0 3px;
						}

					}
				}

			}

		}

	}

}

@import "./style.mobile";
