@import (reference) "~assets/css/imports";

.b-transfers-popup-offers-modal_mask {
  padding: 50px 0;
  overflow: auto;
  display: flex;

  .break {
	flex-basis: 100%;
	height: 0;
  }

  .b-popup-mask__table {
	height: unset;
	width: unset;
	margin: auto 0 auto auto;
  }

  .b-popup-mask__cell {
	text-align: right;
  }

  .b-transfers-popup-offers-modal {
	width: 75%;
	min-width: 924px;
	max-width: 90vw;
	padding-right: 12px;
  }

  .b-popup__content {
	background: #fff;
	text-align: left;
	padding: 0;
	border-radius: 14px;
	box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
	border: 0;
  }

  .b-popup-mask-overlay {
	background: rgba(74, 87, 103, .5);
  }

  .b-button-container__top {
	box-shadow: none;
  }

  .b-popup__header,
  .b-button-container__footer {
	display: none;
  }

  .drag-sort-active {
	background: transparent;
	color: transparent;
	border: 1px solid #4ca1af;
	border-left: 0;
	border-right: 0;
  }
}

.b-offers-transfers-modal__form,
.b-offers-transfers-modal__title {
  padding: 20px 40px 20px 27px;
}

.b-offers-transfers-modal__title {
  padding-bottom: 0;
}


.b-offers-transfers-modal__close {
  background: url("~assets/img/icon-clear-dark.svg") no-repeat center;
  background-size: contain;

  width: 14px;
  height: 14px;
  display: block;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;

  &:hover {
	opacity: 0.7;
  }

  &:active {
	opacity: 1;
  }
}

.b-offers-transfers-modal__title {
  font-size: 16px;
  line-height: 19px;
  color: rgba(74, 87, 103, 0.8);

  margin-bottom: 10px;
}

.b-offers-transfers-modal__title-person {
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
}

.b-offers-transfers-modal__no-offers {
  text-align: center;
  margin: auto;
  padding: 30px 0;
  font-size: 18px;

  & > span {
	border-bottom: 1px dotted;
  }

  &.dn {
	display: none;
  }
}

.b-offers-transfers-modal__emails {
  padding: 10px 40px 20px 27px;
}

.b-offers-transfers-modal__list-item-comment {

  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 5px !important;

}

.b-offers-transfers-modal__list-item {
  position: relative;
  padding: 20px 40px 20px 57px;
  min-height: 140px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &:after {
	content: '';
	display: block;
	position: absolute;
	left: 30px;
	right: 40px;
	bottom: 0;
  }

  &__car-info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-basis: 30%;
	color: #4A5767;
  }

  &__restrictions {
	display: flex;
	flex-flow: column;
	justify-content: center;
	flex-basis: 20%;
	gap: 10px;

	.b-transfer__info-capacity {
	  white-space: nowrap;
	  display: flex;
	  align-items: center;

	  &-icon {
		.g-icon-transfer-passenger;
		width: 22px;
		height: 22px;
		display: block;
		margin-right: 15px;
	  }

	  &-text {
		font-weight: 500;
		font-size: 15px;
		line-height: 33px;
	  }
	}

	.b-transfer__info-capacity--exceeded {

	  &-icon {
		.g-icon-information;
		width: 22px;
		height: 22px;
		display: block;
		margin-right: 15px;
	  }

	  &-text {
		font-size: 11px;
		line-height: 13px;
		max-width: 230px;
		color: #FF8D2E;
	  }
	}
  }

  &__car-class {
	font-size: 28px;
	line-height: 33px;
  }

  &__models {
	font-size: 13px;
	line-height: 15px;
  }
}

.b-offers-transfers-modal__list-item-drag {
  display: block;
  width: 0;
  position: absolute;
  left: 30px;
  top: 20px;
  bottom: 20px;
  border-left: 2px solid #4882BD;
  padding: 0 30px;
}

.b-offers-transfers-modal__list-item-content {
  display: flex;
  flex: 1 1;
  gap: 10px;
}

.b-offers-transfers-modal__list-item-price {
  display: flex;
  align-items: center;
}

.b-offers-transfers-modal__list-item-price-container {
  color: #4A5767;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
}

.b-offers-transfers-modal__list-item-price-amount {
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  margin-right: 5px;
}

.b-offers-transfers-modal__list-item-remove {
  background: url("~assets/img/icon-cancel.svg") no-repeat center;
  cursor: pointer;
  display: block;
  background-size: 10px;
  width: 10px;
  height: 10px;
  margin-left: 30px;
}

.b-offers-transfers-modal__list-item-line {
  margin: 15px auto;
  width: 96%;
  border-bottom: 1px solid rgba(164, 171, 179, 0.5);
}

.b-offers-transfers-modal__form {
  padding-top: 20px;

  .b-textarea,
  .b-input {
	padding: 0;
	width: 100%;
  }

  .b-textarea__value,
  .b-input__value {
	border-color: rgba(164, 171, 179, .5);
	color: #2F3842;
	.placeholder(#6E7985);

  }

  .b-input {
	//max-width: 385px;
  }

  .b-input__value {
	height: 35px;
  }

  .b-textarea__value {
	//max-width: 635px;
	height: 38px;
	min-height: 0 !important;
  }

  .b-message {
	margin: 0 0 15px;
  }
}

.b-offers-transfers-modal__form-buttons {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.b-offers-transfers-modal__form-button {
  font-size: 14px;
  line-height: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  height: 30px;
  border-radius: 4px;

  border: 1px solid transparent;

  &.disabled {
	opacity: .5;
	pointer-events: none;
  }

  & + .b-offers-transfers-modal__form-button {
	margin-left: 10px;
  }
}

.b-offers-transfers-modal__submit {
  font-weight: bold;
  background: #4882BD;
  color: #fff;
  min-width: 220px;
  border-color: #4882BD;
}

.b-offers-transfers-modal__form-control {
  margin-bottom: 10px;
}

.b-offers-transfers-modal__order {
  color: #6E7985;
  border-color: rgba(164, 171, 179, 0.5);
  padding: 0 7px;
}

@media (max-width: 1440px) {
  .b-offers-transfers-modal__list {
	width: 100%;
  }

  .b-button-container__top {
	max-height: 500px !important;
  }
}

@import 'style.mobile';
