@import (reference) "~assets/css/imports";

.b-pricing {
  padding: 0 10px;
  .rounded-bottom
}

.b-pricing__direction {
  display: block;
  color: @field2-H2-color;
  text-transform: uppercase;
}
.b-pricing__header {
  color: @field2-H1-color;
  height: 45px;
  line-height: 45px;
  text-transform: uppercase;
  border-bottom: 1px solid @field2-elements-color;
}

.b-pricing__section {
  display: inline-block;
  line-height: 24px;
  width: 100%;
  border-bottom: 1px solid @field2-elements-color;
}

.b-pricing_commission {
	display: none;
}

.b-pricing__section-info {
	.clearfix
}

.b-pricing__section-title {
  display: block;
  color: @field2-H2-color;
}

.b-pricing__section-passenger {
   float: left;
  color: @field2-font-color;
  }

.additional-section-passenger-margin .b-pricing__section-passenger {
  margin-right: 20px;
}

.b-pricing__section-price {
   float: right;
  color: @field2-font-color;
}

.b-pricing__total-section {
	color: @field2-active-color;
  	height: 36px;
  	line-height: 32px;
}

.b-pricing__section-total-title {
	float: left;
  	font-size: @base-H1-size;
}

.b-pricing__section-total-price {
	float: right;
}

@media @tablet {
  .b-pricing__section-title {
	color: #4A5767 !important;
  }

  .b-pricing__section-passenger {
	color: #4A5767 !important;
  }

  .b-pricing__section-price {
	color: #4A5767 !important;
  }

  .b-pricing__total-section {
	color: #4A5767 !important;
  }
}
