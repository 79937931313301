@import (reference) "~assets/css/imports";

.b-cabinet-passenger {
	background: @field1-background-color;
  position: relative;
	.rounded-top;

	.b-cabinet-passenger__organizations {
		margin-bottom: 13px;
		padding-bottom: 13px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);

		.b-cabinet-collection__content {
			border-top: 3px dotted rgba(0, 0, 0, 0.1);
		}
	}


	&.edit-disabled {
	  pointer-events: none;
	}
	&__edit-disabled-mask {
	  width: 100%;
	  height: 100%;
	  position: absolute;
	  z-index: 9999;
	  background-color: rgba(74, 87, 103, 0.2);
	  top: 30px;
	  left: 0;
	}
}

.b-cabinet-passenger__save {
	height: 45px;
  	background: #4882BD;
	text-align: center;
	line-height: 45px;
	text-transform: uppercase;
	display: block;
	.rounded-bottom;
	cursor: pointer;
	color: @button1-font-color;
	font-size: @base-H1-size;
	.transition-standard;

	&:hover {
		background: @button1-color-act;
	}

	&:active {
		background: @button1-color-act;
	}
}

@import 'style.mobile';
