@import (reference) "~assets/css/imports";

.b-transfers-search {
	background: #4A5767;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
	border-radius: 3px;
	padding: 24px;
	margin-bottom: 13px;

	&__dates-container,
	&__row {
		display: flex;
		flex-flow: nowrap row;
	}

	&__dates-container {
		min-width: 230px;

	  	@media (max-width: 840px) {
		  min-width: 180px;
		}

		.b-transfers-search__options-date-element {
			flex: 1;

			&--type-datefrom {
				.p-search__direction-date-calendar {
					width: 40px;
					height: 100%;
					display: block;
					position: absolute;
					top: 0;
					right: 0;
				}

				.b-datepicker__remove {
					background: url("~assets/img/icon-clear-dark.svg") no-repeat center;
					background-size: 14px;
				}
			}
		}
	}

	&__direction {
		margin-right: 12px;
		flex: 1 1 50%;
	  	display: flex;
	  	flex-direction: row;

		.b-geocoder-autocomplete:last-child {

		  .b-input__value {
			padding-left: 16px;
		  }

		}
		.b-geocoder-autocomplete {
		  padding-bottom: 0;
		  margin-bottom: 0;
		  min-width: 150px;

		  &__value {
			height: 46px;
			padding-bottom: unset;
		  }
		}
	}

	&__options-date-element {
		& + .b-transfers-search__options-date-element {
			position: relative;
			margin-left: 12px;
		}
	}

	.p-search__direction-date {
	  	display: flex;
	  	flex-direction: row;
	  	align-items: center;
	  	position: relative;

	  &-calendar {
		font-family: 'Roboto', sans-serif;
		font-size: 14px;
		color: #fff;
		background-color: unset;
		border: 0;
		width: 34px;
		height: 34px;
		position: absolute;
		right: 0;
	  }
	}

	.p-search__input {
		color: #4A5767;
		font-size: 18px;
		background: #fff;
		border: 1px solid transparent;
		box-sizing: border-box;
		border-radius: 3px 3px 0 0;
		width: 100%;
		padding: 12px 20px;
		.placeholder(rgba(74, 87, 103, .5));
	}

	.b-transfers-search__button {
		.search-button;
	}

	.b-transfers-search__button-container {
		margin-left: 10px;
	}
  	.b-transfers-search__direction-swap {
	  	position: relative;
	  	display: flex;
	  	align-items: center;
	  	width: 10px;

	  	&-img {
			background: url("~assets/img/icon-swap-n.svg") no-repeat center;
			height: 24px;
		  	width: 24px;
		  	color: #fff;
		  	position: absolute;
		  	z-index: 999;
		  	cursor: pointer;
		  	left: -7px;
		}
	}


  &-mobile {
	display: none;

	&__route {
	  max-width: 95%;
	  overflow: hidden;
	  white-space: nowrap;
	}

	&__search-form {
	  overflow: hidden;
	}
  }
}
