@import (reference) "~assets/css/imports";

.b-person-bonus-card-railways {
	position: relative;
}

.b-person-bonus-card-railways__remove {
	.g-icon-delete-collection;
	.rounded;
	cursor: pointer;
	display: block;
	background-size: 10px 10px;
	background-position: 50% 50%;
	width: 30px;
	height: 30px;
	border: 1px solid #A4ABB3;
	margin-top: 9px;
}
