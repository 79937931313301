@media @tablet {
  .b-cabinet-finance {

	.b-block-container {

	  &__content {
		padding: 0 12px;
		border: none !important;
	  }

	  &__tab-group {
		flex-direction: row !important;
		font-size: 14px !important;
		overflow: auto;
		height: 23px;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
		  display: none;
		}

		.b-block-container__tab-btn {
		  padding: 0 !important;
		}
	  }

	  &__table-summary {
		background-color: #DAE6F2 !important;
		margin: 0;
		padding: 12px;
		display: flex;
		flex-direction: column;
		gap: 16px;

		.b-summary-element {
		  display: flex;
		  justify-content: space-between;
		}
	  }

	  &__finance-summary {
		width: 100%;

		.b-block-container__text {
		  display: flex;
		  justify-content: space-between;

		  & > span {
			margin-left: 10px;
		  }
		}
	  }

	  &__group {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;
		width: 100%;

		&_column {

		  .p-search__submit {
			height: 38px;
			line-height: 38px;

		  }

		  .b-block-container__text {
			font-size: 14px;

			.b-block-container__date-balance {
			  margin-right: 0;
			}
		  }


		}


		.b-block-container__element {
		  margin-right: 0;

		  .p-search__input {
			font-size: 14px;
		  }

		  .p-search__options-date-with-label {


			.p-search__options-date-label {
			  display: none;
			}

			.p-search__direction-date-calendar {
			  background-color: unset;
			  width: 38px;
			  height: 38px;
			}
		  }

		  .b-input {


			.b-input__label {

			}

			.b-input__value {

			}

			.b-input__select-value-label {

			}

			.b-input__select-value:before {

			}

		  }

		}

		.b-block-container__element-dates {
		  width: 100%;
		  justify-content: flex-start !important;
		}
	  }

	  &__period-balance {
		margin-left: unset;
		width: 100%;

		.b-block-container__text {
		  width: inherit;
		  display: flex;
		  justify-content: space-between;
		  margin-left: 0;
		}
	  }

	  &__footer {
		padding: 0 12px;
		margin-top: 20px;
		border: none !important;

		.l-grid-container {
		  padding: 0 12px 12px 12px;
		}

		.pos-rel {
		  height: 38px;
		}

		.b-input__search {

		  &:before {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 10px;
			width: 1.2em;
			z-index: 10;
			height: 1.2em;
			content: ' ';
			pointer-events: none;
			.g-icon-search;
		  }

		  .b-input__value {
			height: 32px;
			background: #EDEEF0;
			padding-left: 2em;
			margin-top: unset !important;

			&::placeholder {
			  color: #A4ABB3;
			}
		  }

		  .b-input__label {
			display: none;
		  }
		}

		.b-block-container__input {
		  margin-left: 0 !important;
		}
	  }

	  &__top-controls {
		border: none !important;

		.b-input {
		  padding-bottom: unset !important;
		}
	  }

	  &__debt {
		width: 100%;

		.b-block-container__text {
		  display: flex;
		  justify-content: space-between;
		  font-weight: 600;
		  width: 100%;
		}
	  }

	  &__table-footer {
		padding: 0 12px;
	  }
	}

	.b-block-table {

	  &__row {
		.box-shadow(none);
	  }

	  .b-block-table__cell {
		justify-content: space-between;
		font-size: 14px;

		&-icon {
		  display: none;
		}

		&-caption {
		  display: flex;
		  font-size: 14px;
		}
	  }
	}


	&-document-select-popup {

	  &__form-control {
		display: flex;
		flex-direction: column;
		gap: 16px;
	  }
	}

	.download-doc-link {
	  cursor: pointer;
	}
  }
}
