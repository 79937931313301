@import (reference) "~assets/css/imports";

.b-radiobutton {
	display: inline-block;
	cursor: pointer;
	position: relative;
	height: 18px;
	line-height: 18px;
  	color: @field1-H1-color;
}

	.b-radiobutton__input {
		display: none;
	}

	.b-radiobutton__view {
		.border-radius(50%);
		border: 1px solid @field1-object-color;
		display: inline-block;
		width: 18px;
		height: 18px;
		position: relative;
	}

		.b-radiobutton__view:before {
			content: "";
			display: block;
			position: absolute;
			width: 0;
			height: 0;
			top: 8px;
			left: 8px;
			.border-radius(50%);
			background: @field1-active-color;
			.transition-standard;
			opacity: 0;
		}

		.b-radiobutton .b-radiobutton__input:checked + .b-radiobutton__view {
			border: 1px solid @field1-active-color;
		}

			.b-radiobutton .b-radiobutton__input:checked + .b-radiobutton__view:before {
				width: 12px;
				height: 12px;
				top: 2px;
				left: 2px;
				opacity: 1;
			}
