@media @tablet {

  .p-search {
	border-radius: 0;
	flex-direction: column;

	&-form {

	  &__container {
		min-width: unset !important;
	  }

	  &__row-routes {
		flex-direction: column;

		.p-search-form__group:nth-child(2) {
		  display: block;

		  .p-search-form__element__dates {
			display: flex;
			flex-direction: row;
		  }
		}

		.p-search-form__element__service-params {
		  flex-direction: column;
		  align-items: flex-start !important;

		  > span {
			margin-bottom: 20px;
		  }

		  .b-search-form__param-buttons {
			justify-content: center;
			order: -1;
			position: unset;
			right: unset;
			margin-bottom: 20px;
		  }
		  .b-search-form__param-direct {
			order: 3;
		  }
		  .b-search-form__param-classes {
			order: 2;
		  }
		  .b-search-form__param-complex {
			order: 1;
		  }
		}


	  }

	  &__row-footer {
		flex-direction: column !important;

		.b-company-employees {
		  width: 100% !important;

		  .b-company-employees-service__wrapper {
			flex-direction: column !important;

			.b-search-form__params {
			  min-width: unset !important;
			  max-width: unset !important;
			  width: 100% !important;
			}

			.b-search-form__employees .b-employees-collection .b-employees-collection__content {
			  width: 10%;
			}
		  }
		}

		.b-employees-collection {
		  width: 100% !important;
		}

		.b-search-employee-row {
		  width: 100% !important;
		}

		.b-search-employee {
		  width: 90% !important;
		}

	  }

	  &__row-with-border {
		border-top: unset !important;
		margin-top: unset !important;
	  }

	  .p-search__direction {
		flex-direction: column;

		&-departure {
		  margin-bottom: 16px;
		}

		&-swap {
		  left: 50%;
		  bottom: 8px;
		}

	  }

	  &__additional-order {
		top: -45px;
		font-size: 12px;
	  }

	  &__element__dates {

	  }

	  &__row {
		.p-search-form__element__routes, .p-search-form__element__dates-container, .p-search-form__element__additional-options {
		  & .validations-errors__container {
			pointer-events: none;
			bottom: 38px;
		  }
		}
	  }

	  .b-search-form__param-airlines {
		.b-airlines__item-code {
		  height: 17px !important;
		  width: 17px;
		  padding-left: unset;
		}
		.b-airlines__popup {
		  background-color: #5f6c80;
		}
		.b-airlines__items {
		  background-color: #5f6c80;
		}
	  }

	  .b-company-employees {
		.b-search-form__params {
		  .b-company-autocomplete {
			.b-company-autocomplete__items {
			  background-color: #5f6c80;
			}
		  }
		}

		.b-search-form__employees {
		  .b-passengers__list,
		  .b-passengers__child-age,
		  .b-passengers__info {
			background-color: #5f6c80;
		  }
		  .b-passengers__popup:before {
			border-color: #5f6c80;
		  }
		}

		&__company {
		  .b-employee-autocomplete__items {
			background-color: #5f6c80;
		  }
		}
	  }

	  .b-numerable-picker-hotels {
		.b-numerable-picker__popup, .b-numerable-picker__item {
		  background-color: #5f6c80;

		  &:before {
			border-color: #5f6c80;
		  }
		}
	  }

	}

	&__direction-date-wrapper {
	  height: initial;

	  .p-search__options-date {
		flex-direction: column;

		.p-search__bridge {
		  background: none;
		}

		.p-search__options-bridge {
		  width: 2px;
		  height: 12px;
		  top: -12px;
		}

		.p-search__options-time-from,
		.p-search__options-time-to {
		  margin-top: 12px;
		}

		.p-search__direction-date-input {
		  max-width: 100%;
		  text-align: left;
		}
		.w-timerange {
		  width: 100%;
		  font-size: 18px;
		  .w-timerange__wrapper {
			width: 100%;
			.w-timerange__select {
			  width: 48px;
			  height: 36px;
			  border-width: 2px;
			  .w-timerange__select-img {
				width: 48px;
				height: 36px;
				padding: 0;
				background-size: 24px;
			  }
			}
			.w-timerange__input {
			  width: 100%;
			  height: 36px;
			  max-width: initial;
			  text-align: left;
			  border-width: 2px;
			  font-size: 18px;
			}
		  }
		}
	  }


	}

	&__options-param {
	  .b-search-form__select-type-label {
		height: 48px;
	  }
	  .b-search-form__select-type-label-select {
		width: 38px;
		height: 38px;
		border-width: 2px;
		.b-search-form__select-type-label-select-img {
		  width: 38px;
		  height: 38px;
		  padding: 0;
		}
	  }
	}

	&-form__group {
	  flex-direction: column;
	  flex-wrap: nowrap;
	  max-width: 100%;
	  flex-grow:1;
	  margin: 0;

	  &__routes {
		margin-right: 0 !important;
	  }

	  .p-search-form__element {

		.b-search-form__select-type-label {
		  font-size: 18px;
		  border-width: 2px;
		}

		.p-search__direction-date-calendar {
		  border-width: 2px;
		  width: 56px;
		  .p-search__direction-date-calendar-img {
			width: 100%;
			height: 100%;
			padding: 0;
			background-size: 28px;
		  }

		}
	  }

	  /* service icons */
	  .p-search-form-element-service-types {
		order:1;
		margin-top: 0;

		.p-search__options-type {
		  display: block;
		  flex-direction:initial;
		  align-items: initial;
		}

		.p-search__options-type-list {
		  justify-content: space-between;

		  .p-search__options-type-list-point {
			width: 48px;
			height: 48px;
			.p-search__options-type-img {
			  background-size: 48px;
			}
		  }
		  .p-search__options-type-list-point:first-of-type {
			margin-left: 0;
		  }
		}

		.p-search__options-type-text {
		  display: none;
		}
	  }
	  /* EOF service icons */

	  .p-search-form-element-passengers {
		order:4;
	  }

	  .p-search-form-element-search-type {
		order:3;
	  }

	  .p-search__direction-wrapper {
		flex-basis: initial;
		flex-grow: initial;
		flex-direction: column;
		height: initial;
		order: 2;
		position: relative;

		.p-search__direction-departure {
		  width: 100%;
		  .p-search__direction-departure-input {
			border-right: 2px solid #A4ABB3;
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
			margin-bottom: 12px;
		  }
		}


		.p-search__direction-arrival {
		  width: 100%;
		  .p-search__direction-arrival-input {
			border-left: 2px solid #A4ABB3;
			border-top-left-radius: 3px;
			border-bottom-left-radius: 3px;
			padding-left: 8px;
		  }
		}

		.p-search__direction-swap {
		  position: static;
		  margin-bottom: 12px;

		  &-img {
			position: static;
			height: 32px;
			width: 14px;
			background-size: contain;
			transform: rotate(90deg);
			margin: auto;
		  }
		}
	  }
	}

	&__options-date {
	  display: flex;
	}

	&__options-date-element {
	  width: 100% !important;

	  .w-time .b-input__value {
		background-color: #fff;
		border-color: #fff;
		color: #4A5767;
	  }

	  &:first-of-type {
		margin-right: 10px;
	  }
	}

	.p-search-form-group.p-search-form-group-dates {
	  margin: 0;
	}
  }


  .w-timerange__wrapper {

	.w-timerange__input {
	  .p-search-timerange-input() !important;
	}

	.w-timerange__select {
	  height: 38px;
	  position: absolute;
	  right: 0;
	  background: none;
	  border: 0;

	  &-img {
		background: url('~assets/img/icon-clear-dark.svg') no-repeat center;
		padding: 7px 20px;
	  }
	}
  }

  .w-timerange:not(.open) .w-timerange__select-img {
	background: url('~assets/img/icon-timerange-dark.svg') no-repeat center !important;
	width: 10px !important;
  }


  .p-search-form .p-search__direction-date-input, .p-search__direction-date-calendar-img:not(.b-datepicker__remove) {
	text-align: left;
	pointer-events: none;
  }

  .l-layout.show-pikaday-modal.p-search-form__wrapper {
	display: none;
  }

  .b-search-form__complex .p-search-form .p-search__options-date {
	margin-bottom: 20px;
	&-element {
	  width: 100% !important;
	}
  }

  .b-search-form__complex .p-search-form__element__routes {
	padding-left: 0 !important;
  }


  .b-search-form__complex .b-search-route__remove {
	display: block;
	position: absolute;
	top: -16%;
	left: 96%;
	width: 26px;
	height: 26px;
	.g-icon-close-red;
  }

  .b-attachment {
	font-size: 16px;
	padding: 0;
	width: 100%;

	.b-attachment__logo {
	  display: none;
	}

	.b-attachment-wrapper {
	  border-top: unset;
	  border-radius: 0;
	  margin-top: 0;

	  .b-search-form__param-classes:before {
		content: '';
		background-image: url("~assets/img/icon-avia.svg");
		width: 32px;
		height: 32px;
		display: block;
		background-repeat: no-repeat;
		background-size: 32px;
		margin-right: 34px;
		position: relative;
		top: -7px;
		left: 6px;
	  }

	  .b-search-form__param-classes.b-railways-custom-toggle:before {
		background-image: url("~assets/img/icon-train.svg");
	  }

	  .b-search-form__param-direct {
		margin-top: 15px;
	  }

	  .b-search-form__params {
		flex-wrap: wrap;
	  }

	}
  }

  .b-company-employees {
	font-size: 14px;

	.b-company-employees-wrapper {
	  border-top:2px solid #2F3842;
	  border-radius: 0;
	  margin-top: 0;

	  .b-company-employees-avia {
		padding: 16px;
	  }

	  .b-company-employees-service__wrapper {
		flex-direction: column;

		.b-company-employees__company, .b-company-employees__retail {
		  margin-top: 15px;
		}

		.b-employees-collection > .l-grid-layout-33 {
		  margin-bottom: 15px;

		  &:last-child {
			margin-bottom: 0;
		  }
		}

		.b-search-form__employees {
		  width: 100%;
		  margin-left: 0;
		}



	  }

	}
  }


  .p-search-form__group-button {
	width: 100% !important;
	justify-content: center !important;
	margin-top: 20px !important;

	.p-search-form__submit {
	  width: 100% !important;
	  max-width: unset !important;
	  height: 38px;
	  border-radius: 0;
	  padding: 0;
	  line-height: 38px;
	  font-size: 16px;
	}
  }

  .b-passengers__input,
  .b-input__value,
  .b-passengers__select-img,
  .p-search__input {
	height: 38px !important;
  }


  .b-passengers {
	&__input {
	  border-width: 2px;
	}

	&__select {
	  width: 38px;
	  border-width: 2px;
	}

	&__select-img {
	  height: 38px;
	  width: 38px;
	  padding: 0;
	}
  }

  .b-passengers,
  .b-passengers__wrapper {
	margin-right: 0;
	width: 100% !important;
	max-width: unset !important;
	min-width: unset !important;
  }

  .b-search-form__params,
  .b-search-form__employees {
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
	max-width: unset;
	margin-bottom: 20px;
  }

  .is-avia-search {

	.b-search-form__param-flight-number {
	  width: 100%;
	  max-width: unset;
	  min-width: unset;
	}

	.b-search-form__param-airlines {
	  max-width: unset;
	}

	.b-attachment-avia {
	  padding: 0;

	  .b-search-form__param-airlines {
		margin-top: 20px;
		width: 100%;
		min-width: initial;

		.b-airlines {
		  font-size: 12px;
		  .b-airlines__label,
		  .b-airlines__select {
			font-size: 12px;
			height: 38px;
			border-width: 2px;
		  }

		  .b-airlines__select {
			width: 38px;

			.b-airlines__select-img {
			  width: 38px;
			  height: 38px;
			  padding: 0;
			}
		  }
		}

	  }

	  .b-search-form__param-flight-number {
		width: 100%;
		margin-top: 20px;
		height: 38px;
	  }
	}
  }

  .is-hotels-search {

	.b-passengers-hotels {
	  height: 38px !important;
	  margin-bottom: 20px;
	}

	.p-search__direction {
	  margin-bottom: 20px !important;
	}

	.b-numerable-picker-hotels,
	.b-numerable-picker__wrapper {
	  width: 100% !important;
	  min-width: unset !important;
	  max-width: unset !important;
	  height: 38px !important;
	}

	.b-numerable-picker__input {
	  height: 38px !important;
	}

	.b-numerable-picker__select,
	.b-numerable-picker__select-img {
	  height: 38px !important;
	  width: 38px !important;
	  padding: 0 !important;
	}

	.b-company-employees__company {
	  margin-left: 20px;
	  margin-top: 20px;

	  .b-rooms-collection {
		margin-right: 0;
	  }
	}

	.b-rooms-collection__content {
	  display: flex;
	  justify-content: center;
	}
  }

  .is-transfer-search {

	.p-search-form {

	  .b-search-route__transfer-type {
		width: 100% !important;
	  }
	}

	.p-search {

	  &__direction .b-geocoder-autocomplete {
		margin-bottom: 11px;
	  }

	  &__direction-swap {
		height: 1px !important;
	  }
	}

	.p-search-form__element__routes {
	  display: flex;
	  flex-direction: column;

	  .p-search__direction {
		order: 1;
	  }

	  .p-search-form__element__service-params {
		margin-bottom: 20px;
		justify-content: center;
		order: 2;

		.b-search-route-address__collection__add {
		  align-self: center;
		  left: unset;
		}
	  }

	  .p-search-form__element__dates-container {
		order: 3;
	  }
	}
  }
}

@media (max-width: 977px) {
  .is-hotels-search.p-search-form__row .p-search-form__group .p-search-form__element__dates .p-search__options-date .p-search__options-date-element {
	width: 130px !important;
  }

  .p-search-form__group-button {
	margin-top: 20px;
	width: 100%;

	.p-search-form__submit {
	  width: 100% !important;
	  max-width: unset !important;
	}
  }
  .p-search-form__element__service-params,
  .b-search-form__params {
	& > span {
	  font-size: 12px;

	  &:last-of-type {
		margin-right: unset !important;
		min-width: unset !important;
	  }
	}
  }
}

@media (min-width: 769px) and (max-width: 1308px) {
  .p-search-form__container {
	min-width: unset !important;
  }

  .p-search-form__row-footer {
	flex-wrap: wrap;
	justify-content: flex-end !important;
  }
}

@media (max-width: 769px) {
    .p-search-form__wrapper {
        &.is-hotels-search {
            .p-search-form {
                .b-company-employees-service__wrapper {
                    .b-passengers,
                    .b-passengers__wrapper,
                    .b-numerable-picker,
                    .b-numerable-picker__wrapper,
                    .b-search-form__params {
                       margin-right: 0;
                    }
                }
            }
        }
    }

}
