@import (reference) "~assets/css/imports";

.b-cabinet-holder {
	background: @field1-background-color;
	.rounded-top;

	.b-cabinet-holder__organizations {
		pointer-events: none;
		margin-bottom: 13px;
		padding-bottom: 13px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);

		.b-cabinet-collection__content, 
		.b-person-organization__remove {
			display: none;
		}
	}
}

.b-cabinet-holder__save {
	height: 45px;
	background: @button1-color;
	text-align: center;
	line-height: 45px;
	text-transform: uppercase;
	display: block;
	.rounded-bottom;
	cursor: pointer;
	color: @button1-font-color;
	font-size: @base-H1-size;
	.transition-standard;

	&:hover {
		background: @button1-color-act;
	}

	&:active {
		background: @button1-color-act;
	}
}

@import "./style.mobile";
