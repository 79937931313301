@import (reference) "~assets/css/imports";

.p {
	&-login {
		width: 420px;
		border-radius: 3px;
		font-family: 'Roboto', sans-serif;
		color: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;

	  	&.is-b2c {
		  width: 265px;

		  .p-login {

			&__form {
			  background-color: @field2-background-color;
			  padding: 0;

			  .b-login__rules {

				.b-checkbox__view {
				  font-size: 12px;
				}

				&-more-btn {
				  display: none;
				}
			  }

			  &-notify-email-header-text,
			  &-notify-email-info-text {
				color: #fff;
			  }
			  &-notify-email {
				background-color: rgb(8, 90, 90);
			  }
			}

			&__button {
			  display: none !important;
			}

			&__form-button-link {
			  border-bottom-color: #FFFFFF;
			  font-size: 12px;
			  color: #FFFFFF;
			}

			&__form-restore-info {
			  color: #FFFFFF;

			  &-text {
				color: #FFFFFF;
				font-size: 12px;
			  }
			}
		  }
		}

	  	.is-b2c-login-input {
		border: 1px solid rgba(164, 171, 179, 0.3) !important;
		background-color: #FFFFFF !important;
		color: #4A5767 !important;

		&::placeholder {
		  color: #4A5767 !important;
		  opacity: .3 !important;
		}
	  }

	  	.p-login__header-wrapper {
		background-color: #4A5767;
		position: relative;

		.w-title {
		  margin-left: 40px;
		  display: block;
		  padding: unset;
		  color: #fff;

		  &__text {
			font-size: 20px;
		  }
		}

		&:before {
		  content: '';
		  position: absolute;
		  left: 0;
		  top: 0;
		  border-right: 25px solid transparent;
		  border-top: 55px solid #d70000;
		}
		&:after {
		  content: '';
		  position: absolute;
		  left: -115px;
		  right: 0;
		  top: 0;
		  border-top: 5px solid #d70000;
		}
	  }

		&__container {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			display: none;
			background-color: @__base-color-2-light;
			z-index: 9999;
		}

		&__wrapper {
			width: 100%;
		}

		&__header {
			display: flex;
			flex-direction: row;
			width: 100%;
			border-top-right-radius: 3px;
			border-top-left-radius: 3px;

			&-title {
				&-text {
					font-family: 'Roboto Slab', sans-serif;
					font-size: 16px;
					font-weight: bold;
					line-height: 21px;
				}
			}

			&-wrapper {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-end;
				background-color: #E50000;
				width: 100%;
			}

			&-logo {
				background-color: #d70000;
				padding: 15px 12px;

				&-img {
					background: url("~assets/img/logos/IBE.svg");
					background-repeat: no-repeat;
					background-position: center;
					width: 100px;
					height: 24px;
				}
			}

			&-language {
				padding-left: 12px;
				padding-right: 12px;
				border-right: 1px solid rgba(0, 0, 0, 0.1);
				position: relative;
				display: flex;
    			height: 100%;
				&-ru {
					background-image: url("~assets/img/flag.svg");
				}

				&-en {
					background-image: url("~assets/img/icon-eu.svg");
				}

				&-wrapper {
					display: flex;
					flex-direction: row;
					align-items: center;
					cursor: pointer;
				}

				&-dropdown {
					margin: 0;
					padding: 0;
					width: 100% + 1px;
					display: none;
					flex-direction: column;
					align-items: center;
					background-color:#4A5767;
					list-style-type: none;
					position: absolute;
					top: 54px;
					left: 0;
					z-index: 1;
					&-point {
						width: 100%;
						background-color:#4A5767;
						height: 40px;
						display: flex;
						justify-content: center;
						align-items: center;
						cursor: pointer;
						&:hover {
							background: #2f3842;
						}
					}
					&-flag {
						&-img {
							background-repeat: no-repeat;
							background-position: center;
							width: 20px;
							height: 12px;
						}
					}
				}

				&-flag {
					margin-right: 11px;

					&-img {
						background-repeat: no-repeat;
						background-position: center;
						width: 20px;
						height: 12px;
					}
				}

				&-select {
					&-img {
						background-repeat: no-repeat;
						background-position: center;
						width: 10px;
						height: 5px;
					}
				}
			}
		}

		&__form {
			/*padding: 42px 37px 56px 37px;*/
			padding: 37px;
			background-color: #fff;

			&-auth {
				&-company {
					display: none;
				}
			}

			&-block {
				max-width: 345px;
				position: relative;
				margin-bottom: 18px;
			}

		  	.w-title {
			  display: none;
			}

			&-input {
				width: 100%;
				border-radius: 3px;
				border: 1px solid #A4ABB3;
				padding: 8px 9px 5px 9px;
				box-sizing: border-box;

				&::placeholder {
					color: rgba(0, 0, 0, 0.1);
				}

				&:disabled {
					background-color: #fff;
					opacity: 0.2;
				}
			}

			&-title {
				font-family: 'Roboto Slab', sans-serif;
				font-size: 14px;
				line-height: 16px;
				font-weight: 500;
				color: rgba(74, 87, 103, 0.8);
				padding: 3px;
				background-color: #fff;
				box-sizing: border-box;
				position: absolute;
				left: 4px;
				top: -12px;
				z-index: 1;
			}

			&-button {
				&-link {
					text-decoration: none;
					font-family: 'Roboto', sans-serif;
					font-size: 14px;
					line-height: 16px;
					font-weight: 500;
					color: #4A5767;
					border-bottom: 1px dashed #4A5767;
				}
			}
			&-restore {
			  	display: flex;
			  	flex-direction: column;

				&-info {
					margin-bottom: 12px;
					&-text {
						font-family: Roboto;
						line-height: 16px;
						font-size: 14px;
						color: #4A5767;
					}
				}
			}
			&-notify {
				&-email {
					display: none;
					background-color: rgba(9, 156, 157, 0.15);
					border-radius: 3px;
					padding: 18px 18px 12px 18px;
					&-header {
						display: flex;
						align-items: center;
						&-text {
							color: #099C9D;
							font-size: 14px;
							font-family: Roboto;
							line-height: 16px;
							font-weight: 500;
							margin-left: 15px;
						  	text-transform: uppercase;
						}
						&:before {
							content: url('~assets/img/icon-correct.svg');
							width: 24px;
							height: 24px;
							display: flex;
    						align-items: center;
						}
					}
					&-info {
						margin-top: 15px;
						&-text {
							color: #4A5767;
							font-family: Roboto;
							font-style: normal;
							font-weight: normal;
							line-height: normal;
							font-size: 14px;
						}
					}
				}
			}
		}

		&__button {
			width: 98%;
			background-color: rgba(0, 0, 0, 0.2);
			display: flex;
			justify-content: center;
			align-items: center;
			padding-top: 10px;
			padding-bottom: 10px;
			border-bottom-right-radius: 3px;
			border-bottom-left-radius: 3px;
			cursor: pointer;
			border: none;
			&-container {
				width: 100%;
				display: flex;
				justify-content: center;
			}

			&:not([disabled]) {
				background-color: #D70000;
				& > * {
					color: #fff;
				}
			}
			&:hover:not([disabled]) {
				background-color: #2F3842;
				& > * {
					color: #4882BD;
				}
			}
			&:active:not([disabled]) {
				background-color: #4A5767;
			}

			&-link {
				text-decoration: none;
				font-family: 'Roboto', sans-serif;
				font-size: 14px;
				line-height: 16px;
				font-weight: 500;
				color: rgba(255, 255, 255, 0.2);
			}
		}

		.p-login__button {
			display: none;
		}
	  	.p-login__form-button-container {
		  display: flex;
		  justify-content: space-between;
		  flex-direction: row;
		  align-items: center;
		}
	}
}

@media @tablet {
  .p {
	&-login {
	  font-size: 18px;
	  width: 100%;
	  height: 100%;

	  &__wrapper {
		height: 100%;
		background-color: #FFFFFF;
	  }

	  &__container {
		align-items: baseline;
		position: fixed;
	  }

	  &__header-logo {
		background-color: #d70000;
	  }

	  .p-login__header-language-dropdown {
		z-index: 10;
		right: 10px;
		width: initial;
		padding: 7px;
	  }

	  .p-login__header-language.opened {
		background: none;
	  }

	  .p-login__header-language-select-img {
		display: none;
	  }

	  .p-login__header-language-flag-img {
		width: 25px;
		height: 25px;
		background-size: 25px;
	  }

	  .p-login__header-language-dropdown-flag-img {
		width: 40px;
		height: 35px;
		background-size: 25px;
	  }

	  .p-login__button-container {
		position: absolute;
		padding: 0 13px 10px 10px;

		&.static-flow {
		  position: static;
		  padding: 0;
		  margin-bottom: 20px;
		}

		.p-login__button {
		  .border-radius(4px);
		  width: 100%;
		  height:54px;

		  .p-login__button-link {
			font-size: 18px;
		  }
		}
	  }

	  .p-login__form {
		padding: 0 13px 10px 10px;
		margin-bottom:54px;
		margin-top:54px;
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;

		.w-title {
		  display: block;
		  text-align: center;
		  padding: 28px 12px 48px;

		  .w-title__text {
			font-size: 19px;
			color: #283444;
		  }
		}

		&-auth,
		&-registration,
		&-restore-wrapper,
		&-company {
		  margin-top: 50px;
		  display: flex;
		  flex-direction: column;

		  .b-input__value {
			height: 54px !important;
		  }
		  .b-input__select-value-label {
			line-height: 54px !important;
		  }
		}

		.b-input__label {
		  font-size: 18px;
		  margin-left: unset;
		  margin-right: unset;
		  top: -12px;
		  padding: 3px;
		}

		.validations-errors__container {
		  bottom: -30px;
		  color: #e50000;
		  background-color: unset;
		}

		.b-login__rules-container {
		  margin-bottom: 40px;
		}
	  }

	  .p-login__form-button-container {
		flex-direction: column;
		align-items: unset;
		order: 4;

		.p-login__form-button {
		  margin-bottom: 20px;
		}

		.p-login__form-button-link {
		  border-bottom: unset;
		}
	  }

	  .p-login__form.hide-buttons {
		margin-bottom:0;
	  }

	  .p-login__form-block {
		max-width: initial;
		margin-bottom: 43px;
	  }

	  .validation-error.p-login__form-block {
		margin-bottom: 63px !important;
	  }

	  .p-login__form-title {
		font-size: 18px;
		top: -30px;
	  }

	  .p-login__form-input {
		padding: 13px;
		border-radius: 5px;
		border: 2px solid #A4ABB3;
	  }

	  .p-login__form-button-link {
		font-size: 18px;
		text-decoration: none;
		color: #4882BD;
	  }

	  .p-login__form-restore-info {
		margin-bottom: 25px;

		.p-login__form-restore-info-text {
		  font-size: 18px;
		  line-height: 21px;
		}
	  }

	  .p-login__form-notify-email-header-text {
		font-size: 18px;
	  }

	  .p-login__form-notify-email-info-text {
		font-size: 18px;
	  }

	}
  }
}

.b-login-two-factor__popup {
	width: 450px;

	.b-login-two-factor__input {
		margin-bottom: 15px;

		.b-input__value {
			height: 40px;
		}
	}

	.validations-errors__container {
		bottom: 45px;
	}

	.b-login-two-factor__tip {
		font-style: italic;
		color: #aaaaa9;
		font-size: .9em;
	}

	.b-popup__content {
	    padding: 30px;
	    background: #fff;
	}
}
