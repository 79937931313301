@import (reference) "~assets/css/imports";


.b-ticket-section {
	padding: 12px 10px 10px;
	position: relative;

	&:last-child {
		margin-bottom: 20px;
	}
}


	.b-ticket-section__decoration {
		display: block;
		width: 1px;
		position: absolute;
		top: 17px;
		bottom: 14px;
		left: -18px;
		background: @field2-active-color;

		&:before, &:after {
			content: "";
			border: 1px solid @field2-active-color;
			background: @field2-background-color;
			width: 15px;
			height: 15px;
			display: block;
			.border-radius(50%);
			position: absolute;
			left: -7px;
			top: 0;
			z-index: 100;
		}

		&::before {
			bottom: 0;
			top: auto;
		}

	}

	.b-ticket-section__travel-time {
		position: absolute;
		left: -86px;
		top: 50%;
		margin-top: -8px;
		color: @field2-active-color;
		.g-icon-clock-white;
		padding-left: 20px;
		background-position: 0 50%;
		background-size: 14px 14px;
		font-size: @base-H1-size;
	}

	.b-ticket-section__place {
		position: relative;
		font-size: @base-H2-size;
		line-height: 22px;
		color: @field1-H1-color;
	}

		.b-ticket-section__place-iata {
			color: @field1-font-color;
			font-size: @base-H1-size;
		}

		.b-ticket-section__date {
			position: absolute;
			top: 0;
			left: -95px;
			color: @field2-font-color;
			font-size: @base-font-size;
			white-space: nowrap;
			text-align: right;
		}

		.b-ticket-section__time {
			color: @field2-H2-color;
		  	font-size: @base-H2-size+2px;
			display: block;
		}

		.b-ticket-section__info {
			border: 1px solid @field1-elements-color;
			.rounded;
			padding: 0 10px;
			margin: 10px 0;
		}

			.b-ticket-section__info-logo {
				display: table-cell;
				vertical-align: middle;
				padding: 0 5px;
			}

			.b-ticket-section__info-data {
				overflow: hidden;
				padding-left: 25px;
				position: relative;
				display: table-cell;
				vertical-align: top;

				&:before {
					width: 1px;
					content: " ";
					display: block;
					position: absolute;
					top: 15px;
					bottom: 15px;
					background-color: @field1-elements-color;
					left: 5px;
				}

				.b-ticket-section__info-flight-item {
					width: 100%;
				}
			}

		.b-ticket-section__info-line {
			.clearfix;
			border-top: 1px solid @field1-elements-color;

			&:first-child {
				border-top: 0;
				margin-bottom: 0;
			}

			.b-ticket-leg__opairline {
				position: relative;
				left: initial;
				bottom: initial;
				font-size: unset;
				padding-bottom: 10px;
				padding-left: 10px;
			}
		}

			.b-ticket-section__info-flight {
				.clearfix;
				margin: 5px 0;
				padding: 0;
				color: @field1-H1-color;
			}

				.b-ticket-section__info-flight-item {
					width: 50%;
					float: left;
					margin: 0 0 0 0;
					padding: 0;
					list-style: none;
					font-size: @base-H1-size;
				}

				.b-ticket-section__info-flight-item-warning {
					font-size: @base-H1-size;
					color: @field1-alert-color;
					margin-left: 0;
					display: block;
					padding-bottom: 8px;

				}

@media @tablet {

	.b-ticket-section__info-logo, .b-ticket-section__info-data {
		display: block;
		width: 100%;
		float: none;
		.clearfix
	}

	.b-ticket-section__info-data {
		padding-left: 0;

		&:before {
			display: none;
		}
	}

}
