@import (reference) "~assets/css/imports";

.b-nearest-dates-matrix {
	.clearfix;
	.rounded;
	.shadow;
	background: @field2-background-color;
	margin-bottom: 15px;
}

	.b-nearest-dates-matrix__table {
		width: 100%;
		border-collapse: collapse;

		td, th {
			text-align: center;
			padding: 10px 0;
			font-weight: normal;
		}

		.b-nearest-dates-matrix__table-nodata {
			color: @field1-warning-color;
			font-size: @base-H1-size;
		}
	}


		.b-nearest-dates-matrix__row-prices.current {
			.current {
				pointer-events: none;
			}
		}

		.b-nearest-dates-matrix__row-prices {

			.b-nearest-dates-matrix__table-price {
				border-right: 1px solid @field1-elements-color;
				border-bottom: 1px solid @field1-elements-color;
			  	font-size: 18px;
				color: @field1-H1-color;
				background: @field1-background-color;
				cursor: pointer;

				&:last-child {
					border-right: 0;
				}
			}

			.cheapest {
				font-weight: bold;
			}

			&:last-child .b-nearest-dates-matrix__table-price {
				border-bottom: 0;
				background-clip: border-box;
			}

			.b-nearest-dates-matrix__table-date {
				border-top: 1px solid #E1E1E1;
			  	border-right: 1px solid #E1E1E1;
				padding-left: 5px;
				padding-right: 5px;
			  	color: #4A5767;
			  	background: #F0F0F0;
			}

			&:first-child .b-nearest-dates-matrix__table-date {
				border-top: 0;
			}

		}

		.b-nearest-dates-matrix__row-direction{
			th {
				border: 0;
			}

		  	th.b-nearest-dates-matrix__table-from {
			  font-size: 14px;
			  color: @__base-color-2;
			  font-weight: bold;
			}
		}


		.b-nearest-dates-matrix__row-dates {
			th {
			  border-right: 1px solid #E1E1E1;
			  border-bottom: 1px solid #E1E1E1;
				font-size: 13px;
				color: #4A5767;
				&:last-child {
					border-right: 0;
				}
			  background: #F0F0F0;
			}
			.current {
			}

		  .b-nearest-dates-matrix__row-dates-left {
			background: none;
			border-bottom: 0;
			border-right: 0;
		  }

		}


			.b-nearest-dates-matrix__table-to {
				transform: rotate(-90deg);
			  color: @field2-H1-color;
			}

			.b-nearest-dates-matrix__table-empty {
				background: darken(@field2-background-color, 10%);
				border-top-left-radius: @base-round-radius;
			}

.b-nearest-dates-matrix__oneway {

	.b-nearest-dates-matrix__table-price0 {
		.rounded-bottom-left;
		border-right: 0;
	}

	.b-nearest-dates-matrix__table-price1 {
		border-left: 1px solid @field1-elements-color;
	}

	.b-nearest-dates-matrix__table-price6 {
		.rounded-bottom-right;
	}

	.b-nearest-dates-matrix__row-dates th {
		width: 14.3%;
	  	color: #4A5767;
	}

}

.b-nearest-dates-matrix__roundtrip {

	.b-nearest-dates-matrix__table-from {
		border-bottom: 1px solid @field2-elements-color;
	  color: @field2-H1-color;
	}

	.b-nearest-dates-matrix__row-prices6 {

		.b-nearest-dates-matrix__table-price0 {
			border-bottom-left-radius: @base-round-radius;
		}

		.b-nearest-dates-matrix__table-price6 {
			border-bottom-right-radius: @base-round-radius;
		}

	}

	.b-nearest-dates-matrix__row-prices0 {

		.b-nearest-dates-matrix__table-price0 {
			border-top-left-radius: @base-round-radius;
		}

		.b-nearest-dates-matrix__table-price6 {
			border-top-right-radius: @base-round-radius;
		}

	}

	.current .b-nearest-dates-matrix__table-price{
		background: mix(@field1-active-color, @field1-background-color, 5%);

	}

	.current.b-nearest-dates-matrix__table-price {
		background: mix(@field1-active-color, @field1-background-color, 10%);
	}

	.current .current.b-nearest-dates-matrix__table-price {
		background: mix(@field1-active-color, @field1-background-color, 20%);
	}

	.b-nearest-dates-matrix__table-date.current {
	}
}


.b-nearest-dates-matrix__table-price-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
	span {
	  display: inline-block;
	}
}
