@import (reference) "~assets/css/imports";

.b-order {
  &-pricing {
    padding: 0 10px;
    width: 100%;
    border-collapse: collapse;
    .rounded-bottom;

    &__header {
      height: 45px;
      line-height: 45px;
      text-transform: uppercase;
      border-bottom: 1px solid #A4ABB3;
    }

    &__section {
      line-height: 24px;
      width: 100%;
      vertical-align: top;
      border-bottom: 1px solid #A4ABB3;

      &-total-title {
        float: left;
        font-size: @base-H1-size;
      }

      &-total-price {
        float: right;
      }

      &__total-section {
        line-height: 22px;
        font-weight: 500;
      }

      &-info {
        .clearfix
      }

      &-title {
        font-weight: 500;
        flex: 4 1 auto;
      }

      &-pricing {
        width: 30%;

        &:last-child {
          margin-right: 0;
        }
      }

      &-passenger {
        float: left;
      }

      &-price {
        float: right;
      }
    }

    &_commission {
      display: none;
    }
  }

  &-income {
    width: auto;
    font-size: 1rem;

    .b-block-table__row {
      .b-block-table__cell {
        color: #fff;
        text-align: right;
        padding: 0;
        border: 0;
        border: 0;

        &:last-child {
          width: 1px;
          white-space: nowrap;
          padding-left: 15px;
        }
        &:after {
          display: none;
        }
      }

      &:last-child {
        .b-block-table__cell { 
          padding-top: 5px;
        }
      }
    }
  }
}
