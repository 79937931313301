@media @tablet {
	.b-booking {
		.b-datepicker__field {
			height: 48px;
			line-height: 48px;
			font-size: 16px;
		}

		.p-search__options-date-with-label {
			.p-search__direction-date-calendar {
				height: 48px;
				width: 48px;
			}
		}
	}


	.b-customer .b-customer__header, .b-travellers .b-travellers__header {
		margin-bottom: 10px;
	}

	.b-booking__travellers, .b-customer__info {
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.b-traveller {
		margin-bottom: 15px;
	}

	.b-booking__customer {
	  margin-bottom: 20px;
	}

	.b-block-container {

	  &__footer {
		justify-content: unset !important;
		height: 36px;
		padding: unset !important;
	  }

	  &__footer-leftbar,
	  &__footer-rightbar {
		height: 100%;
		width: 100%;

		.b-booking__submit {
		  background-color: #099C9D;
		  border-radius: 0 4px 4px 0;
		  height: 100%;
		  width: 100%;
		  border: none;
		}

		.b-booking__back {
		  background-color: #EDEEF0;
		  border-radius: 4px 0 0 4px;
		  height: 100%;
		  width: 100%;
		  color: #A4ABB3;
		  display: flex;
		  border: none;
		  justify-content: center;
		  align-items: center;
		  margin: 0 auto;

		  &:before {
			width: 1em;
			height: 1em;
			margin-right: 10px;
			display: inline-block;
			content: ' ';
			.g-icon-arrow-left;
		  }
		}
	  }
	}
}
