@import (reference) "~assets/css/imports";

@media @tablet {
  .b-train-offer-preview {
	background: #FFFFFF;
	position: relative;

	&__header {
	  margin-bottom: unset;
	  position: fixed;
	  z-index: 9999;
	  top: 0;
	  left: 0;
	}

	&__train-info {
	  color: #FFFFFFFF;
	  display: flex;
	  position: relative;
	  top: 40px;
	  left: 0;
	  font-size: 16px;
	  flex-direction: column;
	  padding: 10px 16px 16px 16px;
	  width: 100%;
	  height: 120px;
	  background: #4882BD;
	  .box-shadow(~'0px 8px 8px -6px rgba(24, 39, 75, 0.12), 0px 12px 10px -4px rgba(24, 39, 75, 0.12)');
	}

	&__controls {
	  display: none;
	  gap: 10px;
	  align-items: center;
	  margin-bottom: 20px;
	}

	&__train-info-row {
	  display: flex;
	  gap: 10px;

	  &:not(:last-of-type) {
		margin-bottom: 10px;
	  }
	}

	&__train-info-date:before {
	  .g-icon-briefcase;
	}

	&__train-info-time:before {
	  .g-icon-schedule;
	}

	&__train-info-number:before {
	  background: url("~assets/img/icons/bc-railways.svg") center no-repeat;
	  background-size: 80% !important;
	}

	&__train-info-date,
	&__train-info-time,
	&__train-info-number {
	  font-size: 14px;
	  display: flex;
	  align-items: center;

	  &:before {
		content: ' ';
		width: 1.2em;
		height: 1.2em;
		margin-right: 10px;
		background-size: 100%;
	  }
	}

	&__train-info-indicators {
	  overflow-x: auto;
	  -ms-overflow-style: none;
	  scrollbar-width: none;

	  &::-webkit-scrollbar {
		display: none;
	  }

	  .carriage-indicator {
		display: flex;
		align-items: center;
		white-space: nowrap;
		justify-content: center;
		padding: 6px 8px;
		background: transparent;
		color: #FFFFFF;
		border: 1px solid #FFFFFF;
		.border-radius(15px);

		&:not(:last-of-type) {
		  margin-right: 20px;
		}

		&.active {
		  background: #FFFFFF;
		  color: #4882BD;
		}
	  }
	}

	&__wrapper {
	  position: relative;
	  top: 40px;
	  overflow-x: hidden;
	  height: calc(~'100vh');
	}

	&__car-info {
	  margin-top: 20px;
	  font-size: 14px;
	  display: flex;
	  flex-direction: column;
	  align-items: flex-start;
	  width: 100%;
	  padding-left: 16px;
	}

	.b-car-info {

	  &__number {

	  }

	  &__service-class {
		color: #4882BD;
	  }

	  &__type {

	  }

	  &__prices {
		width: 100%;

		.carriage-map-description {
		  text-align: left !important;
		}

		.b-car-place {
		  width: 100%;

		  &__quantity {
			width: 100%;
		  }

		  &__price {
			width: 100%;
			text-align: left !important;
			color: #4882BD;
		  }
		}
	  }

	  &__services {
		margin: 10px 0;
		display: flex;
		flex-flow: row wrap;

		& .train-service__img {
		  background-size: 100% 100%;
		  width: 20px;
		  height: 20px;
		}
	  }

	  &__messages {
		font-size: 12px;
		margin-bottom: 10px;

		.b-message {
		  margin-left: unset !important;
		}
	  }

	  &__gender-desc {
	  }

	  &__seats-selector,
	  &__compartment-selector,
	  &__gender-selector {
		width: 100%;
		margin-bottom: 10px;
	  }
	}

	.b-carriage-map__description {
	  display: none !important;
	}

	.b-carriage__selected-variant {
	  display: none !important;
	}

	.b-map-description__messages {
	  display: none !important;
	}

	.b-carriage-maps__container.open {
	  overflow-x: auto;
	  padding-top: 20px;
	  justify-content: unset;
	  margin: unset;
	}

	.b-carriage-maps__controls {
	  padding: unset;
	  display: none !important;
	}

	&__carriages {
	  position: relative;
	  width: 100%;
	  max-width: 100%;

	  .b-train__carriage {
		display: none;
	  }

	  .b-train__carriage-map {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: inherit;
		margin-left: auto;

		& > svg text {
		  white-space: nowrap !important;
		}

		&__car-number {
		  display: none;
		}
	  }
	}

	&__footer {
	  padding: 16px;
	  margin-top: 20px;
	  border-top: 1px solid #EDEEF0;
	  font-size: 14px;
	}

	&__buttons {
	  width: 100%;
	  margin-top: 10px;

	  .b-selected-variant {
		.b-selected__price,
		.b-selected__places {
		  text-align: left !important;
		  width: 100%;
		}

		.b-selected__price .b-selected__price-value {
		  color: #4882BD;
		}

		.b-selected-variant__booking,
		.b-selected-variant__offer {
		  width: 50%;
		  height: 38px;

		  & > button {
			padding: 7px 10px;
			color: #FFFFFF;
		  }
		}

		.b-selected-variant__buttons-container {
		  display: flex;
		  margin-top: 10px;
		}

		.b-selected-variant__booking {
		  order: 2;
		}

		.b-selected-variant__offer button {
		  width: 100%;
		  height: 100%;
		  background: #4882BD;
		  border: none;
		  .border-radius(~'4px 0px 0px 4px');
		}

		.b-selected-variant__offer .b-ticket-offer__cancel {
		  background: #D70000;
		}

		.b-selected-variant__booking button {
		  width: 100%;
		  height: 100%;
		  background: #099C9D;
		  .border-radius(~'0px 4px 4px 0px');
		}
	  }
	}
  }
}
