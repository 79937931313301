@import (reference) "~assets/css/imports";

.b-search-email-row {
  	display: inline-block;
	width: 38%;
  	margin-right: 15px;
  	margin-top: 0!important;
  	margin-bottom: 15px;
	.b-search-email-header {
		display: flex;
		align-items: center;
		min-height: 23px;

		& > div {
			flex: 0 1 auto;

			&.b-search-email-border {
				flex-grow: 1;
				margin-left: 10px;
				height: 0;
				border-top: 1px dashed #fff;
			}

			&.b-search-email-delete {
				margin-left: 15px;
			}

			&.b-search-email-label {
				font-size: 16px;
			}
		}
	}

	& + .b-rooms-collection__content,
	& + .b-search-email-row {
		margin-top: 30px;
	}

	.b-search-email__remove {
		cursor: pointer;
		background: url("~assets/img/icon-cancel-w.svg") center no-repeat;
		display: inline-block;
		background-size: 9px;
		width: 23px;
		height: 23px;
		border-radius: 100%;
		border: 1px solid #fff;
		vertical-align: middle;
	}
}
