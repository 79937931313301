.b-route-info_railways {

  .b-tooltip-like-link {
    color: #4882bd;
  }

	.b-route__arrival-notification {
		margin-left: 0;
	}

	.b-train-ticket {
		background: none;
		border: none;
		box-shadow: none;

		padding: 21px 18px 20px 18px;

		&__direction {
			margin-bottom: 10px;
			color: #6E7985;
		}

		&__header {
			align-items: flex-start;
			.b-train-ticket__info-train {
				flex-grow: 0;
			}
		}

		&__info {
			&-common {
				display: flex;
				flex-flow: row nowrap;
				margin: 3px 10px 0 0;
			}
			&-services {
				margin-top: 3px;
			}
		}

		&:after {
			display: none;
		}

		&__wrapper {
			& + .b-train-ticket__wrapper {
				position: relative;
				&:before {
					content: '';
					display: block;
					border-bottom: 1px solid #A9A9A9;
					box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
				}
			}
		}

		&__route-map {
			display: flex;
			flex-direction: column;
		}
	}

	.b-route {
		min-width: 840px;
		margin: 30px auto 0 auto;

		&__path {
			display: flex;
			flex-flow: row nowrap;
		}
	
		&__terminal {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		&__departure, 
		&__info {
			margin-right: 50px;
		}
		
		&__departure,
		&__arrival { 
			width: 145px;
		}


		&__arrival {
			margin-right: 25px;
		}

		&__info {
			text-align: center;

			&-route {
				white-space: nowrap;
			}
		}
	}

	.b-info-train {
		font-size: 18px;
	}
}

@import "./style.mobile";
