@import (reference) "~assets/css/imports";


.b-employees-collection {
	.l-grid-layout-33 {
		margin-top: 15px;
		&:nth-child(1), &:nth-child(2), &:nth-child(3) {
			margin-top: 0;
		}
		&:nth-child(3n + 1) {
			clear: both;
			.clearfix;
		}

		&:not(.b-employees-collection__content):only-child {
			background: red;
		}
	}
}

.b-employees-collection__header {
	height: 40px;
	border-left: @field1-border;
	border-right: @field1-border;
	border-bottom: @field1-border;
	font-size: @base-H1-size;
	text-transform: uppercase;
	color: @field2-H2-color;
	line-height: 40px;
	padding: 0 15px;
	position: relative;
}

.b-employees-collection__add {
	.g-icon-add-collection;
	.rounded;
	cursor: pointer;
	display: block;
	background-size: 14px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 30px;
	height: 30px;
	line-height: 30px;
	border: 1px solid #A4ABB3;
}

.b-employees-collection:first-child .b-employees-collection__header {
	height: 50px;
	line-height: 52px;
}

.b-employees-collection__block {
	border-left: @field1-border;
	border-right: @field1-border;
	background: @field1-background-color;
	.rounded;
	.shadow;
	.clearfix;
	padding: 0 10px;
}

.b-employees-collection__separator {
	margin: 10px 0;
	height: 1px;
	display: block;
	overflow: hidden;
	background: @field1-elements-color;
}

.b-employees-collection-small {

	.b-employees-collection__add {
		top: 10px;
		position: relative;
		margin-bottom: 10px;
	}

	.b-employees-collection__content {
		position: relative;
	}

	.b-input__label + .b-employees-collection__add {
		margin-left: 0;
	}
}

.b-emails-collection__add {
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-image: url("~assets/img/icon-collection-add.svg");
  color: rgba(74, 87, 103, 0.1);
  display: block;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  right: 10px;
  top: 10px;
}

@media @tablet {

	.b-employees-collection-small .b-employees-collection__add {
		margin-top: 10px;
	}

}
