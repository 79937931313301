@import "../w-toggle/style";


.b-toggle.first .b-toggle__label:first-child {
	color: @field2-active-color;
}

.b-toggle:not(.as-single).first .b-toggle__label .b-toggle__view {
	border: 1px solid @field2-active-color;
}

.b-toggle.first .b-toggle__label .b-toggle__view:before {
	border: 1px solid @field2-active-color;
	background: @field2-lements-color-lower;
}

.b-toggle__label.dn {
  display: none;
}
