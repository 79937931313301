/* mobile-search-form all services */

.mobile-search-form {
  .l-layout__top {
	.b-avia, .b-train, .b-hotels, .b-transfers {
	  &-search {
		background-color: #fff;
		.box-shadow(~'0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12)');

		&__row {
		  display: none;
		}
	  }

	  &-search-mobile {
		display: flex;
		justify-content: flex-start;
		width: 100%;
		min-height: 50px;

		&__search-form {
		  background-color: #EDEEF0;
		  margin-right: 20px;
		  flex-grow: 2;
		  display: flex;
		  flex-direction: column;
		  justify-content: center;
		  font-size: 12px;
		  height: 50px;
		  width: 100%;
		  padding: 5px 10px;
		  .border-radius(4px);
		}

		&__filters-trigger,
		&__map-trigger {
		  height: 50px;
		  width: 50px;
		  background-color: #099C9D;
		  .border-radius(4px);

		  &:after {
			content: " ";
			display: block;
			width: 50px;
			height: 100%;
			pointer-events: none;
			background: url('~assets/img/icons/icon-filters-mobile.svg') center no-repeat;
		  }
		}

		&__map-trigger {
		  margin-right: 10px;

		  &:after {
			background: url("~assets/img/hotels/icon-map.svg") center no-repeat;
		  }
		}
	  }
	}
  }
}

/* EOF mobile-search-form all services */

/* mobile-card-route avia railway */

.mobile-card-route-info {
  .b-leg,
  .b-train-ticket {
	&__mobile-route-line {
	  width: 100%;
	  height: 2px;
	  background-color: #099c9d;
	  position: relative;

	  &:after,
	  &:before {
		content: " ";
		display: block;
		position: absolute;
		width: 6px;
		height: 6px;
		background-color: #099c9d;
		.border-radius(50%);
		top: -2px;
	  }

	  &:after {
		left: 0;
	  }

	  &:before {
		right: 0;
	  }
	}

	&__mobile-route-info {
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	  width: 100%;
	}

	&__mobile-route-location {
	  min-width: 70px;
	  text-transform: uppercase;
	  font-size: 12px;

	  &:last-of-type {
		text-align: right;
	  }
	}

	&__mobile-route-stops {
	  color: #099c9d;
	  white-space: nowrap;
	}

	&__mobile-dates {
	  display: flex;
	  justify-content: space-between;
	  width: 100%;
	}

	&__mobile-date-time {
	  min-width: 70px;
	  font-size: 18px;
	  font-weight: 600;

	  &:last-of-type {
		text-align: right;
	  }
	}

	&__mobile-duration {
	  color: #A4ABB3;
	  white-space: nowrap;
	}
  }
}

/* EOF mobile-card-route avia railway */

/* mobile-offer-preview all services */

.mobile-offer-preview {
  .b-avia, .b-train, .b-hotel, .b-transfer {

	&-offer-preview {
	  background-color: #FAFAFA;
	  width: 100%;
	  height: 100%;
	  display: flex;
	  flex-direction: column;
	  color: #4A5767;

	  &__header {
		height: 40px;
		width: 100%;
		background-color: #FFFFFF;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 30px;
	  }

	  &__close-btn {
		.b-navigate-back-button();
		height: 100%;
	  }

	  &__price-buttons {
		display: flex;

		.b-avia, .b-train, .b-hotel, .b-transfer {
		  &-offer-preview {

			&-price__top:first-of-type {
			  order: 2;
			}

			&-price__top {
			  width: 100%;

			  .btn {
				height: 38px;
				padding: 9px 10px;
				text-align: center;
				.box-shadow(~'0px 8px 18px -6px #18274b1f, 0px 12px 42px -4px #18274b1f')
			  }
			}

			&-price__button {
			  background-color: #099C9D;
			  color: #FFFFFF;
			  .border-radius(~'0px 4px 4px 0px');
			}

			&-price__button--offer {
			  background: #4882BD;
			  color: #FFFFFF;
			  .border-radius(~'4px 0px 0px 4px');

			  &:before {
				top: 8px;
				left: unset;
				right: 10%;
				color: #FFFFFF;
				border-color: #FFFFFF;
			  }

			  &.b-ticket-offer__cancel {
				background-color: #D70000 !important;
			  }
			}
		  }
		}
	  }

	  &__travel-policy {
		right: unset;
		top: unset;
		position: static;
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
		gap: 20px;
	  }

	  .b-travel-policy__line {
		display: flex;
		gap: 12px;

		& > span {
		  width: 22px;
		  height: 22px;
		}

		.b-travel-policy__description {
		  font-size: 12px;
		  display: flex;
		  flex-direction: column;
		  justify-content: center;
		}
	  }
	}
  }
}

/* EOF mobile-offer-preview all services  */

/* mobile table */

.mobile-table {
  .b-block-table {
	table-layout: fixed !important;

	&__row {
	  display: flex;
	  flex-direction: column;

	  padding: 0;
	  margin: 12px 0;
	  height: fit-content !important;
	  position: relative;
	  background: #FFFFFF !important;
	  .box-shadow(~'0px 8px 18px -6px rgba(24, 39, 75, 0.15), 0px 12px 42px -4px rgba(24, 39, 75, 0.15)');

	  &-header {

	  }
	}

	&__row-header, &__cell-empty, &__cell:after {
	  display: none !important;
	}

	&__cell {
	  display: flex;
	  justify-content: flex-start;
	  padding: 0 12px !important;
	  align-items: center;
	  position: static;
	  margin-bottom: 10px !important;
	  text-align: unset !important;

	  &-label {
		padding: unset !important;
		margin: unset !important;
	  }

	  &.dn {
		display: none !important;
	  }

	  &-caption {
		display: none;
	  }

	  &-value {
		max-width: unset !important;
		text-align: left;
		float: left;
		white-space: nowrap;
		padding: 0 !important;
	  }

	  &-label-wrapper {
		display: flex;
		gap: 10px;

		&:not(:last-of-type) {
		  margin-bottom: 10px;
		}
	  }

		&--width-auto {
		  display: none !important;
		}

		&-fullName {
		  font-size: 16px;
		  min-height: 36px;
		  background: #DAE6F2 !important;
		  padding: 3px 12px;
		  border-bottom: 1px solid #4882BD !important;

		  .b-block-table__cell-value {
			font-size: inherit;
		  }
		}

		&-documents {

		  .b-block-table__cell-value {
			width: 100%;

			& > div {
			  display: flex !important;
			  justify-content: space-between;
			  width: 100%;
			}

			.document-type {
			  max-width: 60%;
			  text-overflow: ellipsis;
			  overflow-x: hidden;
			}
		  }
		}

		&-birthDate {
		  font-weight: 600;
		  background: transparent !important;
		  position: absolute !important;
		  top: 10px;
		  padding: 0;
		  right: 0;
		}

		&-fullName,
		&-birthDate {

		  .b-block-table__cell-icon {
			display: none;
		  }
		}
	  }

	&__cell-icon {
		float: left;
		display: flex;
		margin-right: 10px;
		width: 1.2em;
		padding: 10px;
		height: 1.2em;
		background-size: 70% 70% !important;
		background-repeat: no-repeat;
		background-position: center;
		.border-radius(50%);
		background-color: #DAE6F2;
		align-items: center;
		justify-content: center;
	  }


	&-footer {
	  justify-content: flex-end;
	  display: flex;

	  .b-footer-count {
		display: none;
	  }

		.b-footer-total {
		  display: none;
		}

		.b-footer-show {
		  width: unset !important;
		}

	  .b-footer-show-select {
		display: none;
	  }

	  .b-footer-pages {
		justify-content: flex-start !important;
	  }

	  .b-footer-element {
		padding: 2px 0 !important;
	  }

	  .b-footer-show .b-footer-show-more {
		font-size: 12px;
		background: #4882BD;
		color: #FFFFFF;
		.border-radius(2px);
		white-space: nowrap;
		height: 30px;
		padding: 4px 8px;
		display: flex;
		align-items: center;
	  }
	}
  }
}

/* EOF mobile table */

/* mobile table pages */

.mobile-table-pages {
  .p-cabinet-passengers, .p-cabinet-orders, .p-cabinet-employees, .p-cabinet-products, .p-search-form__additional-orders {

	.mobile-table;

	.b-block-container {

	  &__content-orders, &__content-passengers, &__content-employees, &__content-products {
		border: none !important;
		box-shadow: unset !important;
	  }

	  &__content {
		padding: 0 12px !important;
	  }

	  &__header-label {
		display: flex;
		align-items: center;
		align-self: center;

		&:before {
		  width: 22px;
		  height: 22px;
		  margin-right: 10px;
		  content: ' ';
		}
	  }

	  &__top-controls {
		flex-flow: column wrap !important;
		align-items: unset !important;
		box-shadow: unset !important;
		border: unset !important;
		padding: 10px 12px !important;


		.b-block-container__element {
		  margin-right: unset;

		  & .b-input__label {
			display: none;
		  }

		  &--search {
			display: flex;
			gap: 10px;
		  }

		  & .b-input__value {
			height: 30px !important;
			font-size: inherit;
			margin-top: unset;
			background: #EDEEF0;
			border: unset !important;
			padding-left: 2em;

			&::placeholder {
			  color: #A4ABB3;
			}
		  }

		  & .b-input__search {
			font-size: 14px;

			&:before {
			  position: absolute;
			  top: 50%;
			  transform: translateY(-50%);
			  left: 7px;
			  width: 1.2em;
			  z-index: 10;
			  height: 1.2em;
			  content: ' ';
			  pointer-events: none;
			  .g-icon-search;
			}
		  }

		}
	  }

	  &__scroll-btn {
		position: fixed;
		right: 5%;
		opacity: .8;
		z-index: 100;
		bottom: 16%;
		width: 42px;
		height: 42px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #4882BD;
		.border-radius(4px);

		&:before {
		  display: block;
		  width: 15px;
		  height: 15px;
		  content: ' ';
		  .g-icon-arrow-down_w;
		}

		&.top:before {
		  transform: rotate(180deg);
		}
	  }

	  &__footer {
		background: transparent !important;
	  }

	  &__filters-trigger {
		display: flex;
		width: 32px;
		height: 32px;
		background: #EDEEF0;
		border-radius: 4px;
		padding: 7px;

		&:before {
		  content: ' ';
		  display: block;
		  width: 15px;
		  height: 18px;
		  background: url("~assets/img/icons/icon-filters-mobile-black.svg") no-repeat center;
		  background-size: 90%;
		}
	  }
	}
  }
  .b-cabinet-finance {
	.mobile-table;
  }
}

/* EOF mobile table pages */

/* mobile approval-popup */

.mobile-approval-popup {
  div.b-ticket-popup-offers-modal_mask {
	padding: 0;

	.b-popup-mask__table {
	  width: 100%;
	  height: 100%;
	}

	.b-ticket-popup-offers-modal {
	  width: 100% !important;
	  height: 100%;
	  padding: 0 !important;
	  max-width: unset;
	  min-width: unset;
	}

	.b-button-container__top {
	  width: 100%;
	  height: 100%;
	  overflow: unset;
	  overflow-y: unset;
	}

	.b-popup__content {
	  padding: 0;
	  height: 100%;
	  width: 100%;
	}

	.b-offers {
	  &-avia, &-train, &-hotels, &-transfers {
		&-modal {
		  width: 100%;
		  height: 100%;
		  position: relative;

		  &__title {
			font-size: 14px;
			display: flex;
			flex-direction: column;
		  }

		  &__list-item-line, &__list-item-drag {
			display: none;
		  }

		  &__list-item {
			background-color: #FFFFFF;
			margin: 22px 18px;
			padding: 0;
			.border-radius(4px);
			.box-shadow(~'1px 1px 10px rgba(0, 0, 0, 0.15)');

			&-legs {
			  padding: 14px 10px 0 10px;
			}

			&-price {
			  position: relative;
			  bottom: 0;
			  left: 0;
			  width: 100%;
			  background-color: #EDEEF0;
			  border-bottom-left-radius: 4px;
			  border-bottom-right-radius: 4px;
			  display: flex;
			  justify-content: center;
			  align-items: center;
			  margin-top: 10px;
			  margin-bottom: 0;
			}

			&-price-amount {
			  font-size: 14px;
			}

			&-remove {
			  position: absolute;
			  right: 12px;
			  margin-left: unset;
			}
		  }

		  &__form {
			position: relative;
			bottom: 0;
			width: 100%;
		  }

		  &__form-control {
			position: static;
			margin: 0;
		  }

		  &__form-buttons {
			display: flex;
			align-items: center;
		  }

		  &__form-button {
			padding: 9px 10px;
			width: 50%;
			min-width: 50%;
			height: 38px;
			margin: 0 !important;
			border: 0;
		  }

		  &__submit {
			order: 1;
			.border-radius(~'0px 4px 4px 0px');
			background-color: #099C9D;
			color: #FFFFFF;
		  }

		  &__order {
			order: 0;
			color: #A4ABB3;
			background-color: #EDEEF0;
			.border-radius(~'4px 0px 0px 4px');
		  }

		  &__form, &__title, &__emails {
			padding: 22px 18px;
		  }
		  &__emails {

			.b-emails-collection {
			  margin-right: 0;
			  display: flex;
			  flex-flow: row wrap;

			  & > div:last-child(-1) {
				width: 90% !important;
			  }


			  &__add {
				width: 20px;
				top: unset;
				right: unset;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 20px;
				.g-icon-add-orange;
			  }

			  &__content {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 38px;
				height: 38px;
			  }

			  .b-search-email-row {
				margin-right: 0;
				width: 100%;

				.b-input__remove {
				  .g-icon-close-red;
				  width: 20px;
				  height: 20px;
				  position: absolute;
				  top: -5px;
				  border-left: unset;
				  right: -5px;
				}
			  }

			}
		  }
		}
	  }
	}
	.b-emails-collection > div:nth-last-child(2) {
	  width: calc(~'100% - 38px') !important;
	}

  }
}

/* EOF mobile approval-popup */

@media @tablet {
  .mobile-search-form;
  .mobile-card-route-info;
  .mobile-offer-preview;
  .mobile-table-pages;
  .mobile-approval-popup;

  .b-tickets-container {
	&-avia, &-rail, &-hotels, &-transfers {
	  padding: 0 12px;
	}
  }
}

@media (max-width: 425px) {
  .p-cabinet-passengers, .p-cabinet-orders, .p-cabinet-employees, .p-cabinet-products, .p-search-form__additional-orders {

	.b-block-table {

	  &-footer {
		justify-content: flex-start;

		.b-footer-show .b-footer-show-more {
		  margin-top: 40px;
		}

		&-page {
		  padding: 0;
		}
	  }
	}
  }
}
