@import (reference) "~assets/css/imports";

.l-layout__footer {
    flex-grow: 2;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.b-footer-info {
    width: 100%;

    &__bottom-block-container {
        padding: 10px;
    }

    &__copyright {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        width: 100%;
        height: max-content;
        background-color: #fff;
        border-top: 1px solid #eee;
        padding: 12px;
        font-size: 10px;
        line-height: 12px;
        color: #797979;
    }
   
}

@import "./style.mobile";