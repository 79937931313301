@import (reference) "~assets/css/imports";

.p-cabinet-employees {
	margin-bottom: 30px;

  .b-block-table__row:not(.b-block-table__row-header) {
    .b-block-table__cell {
      &.b-block-table__cell-disabled {
        &::after {
          border: 0px;
        }
        .b-block-table__cell-value {
          padding: 0px;
          display: flex;
          justify-content: end;

          .deleted-employee {
            width: 24px;
            height: 24px;
            background: url('~assets/img/cabinet/deleted_user.svg') no-repeat center;
          }
        }
        
      }
    }
  }

	.b-employees-add {
		color: #fff;
		background: none;
		border-color: #fff;
	}

	.b-block-container__header {
	    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
	    position: relative;
	    z-index: 2;
	}

	.b-input {
		padding-bottom: 0;
	}

	.b-company-autocomplete__wrapper {
		display: block !important;
	}

	.b-block-container__element {
		margin-right: 12px;
		flex: 1 1 25%;

		&:last-child,
		&:nth-child(3n) {
			margin-right: 0;
		}
	}

	.p-search__direction-date-input,
	.p-search__direction-date-calendar,
	.b-input__value {
		border-color: #4A5767;
	}

	.b-block-container .b-block-container__top-controls {
		background: #FFFFFF;
		border: 1px solid #A9A9A9;
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
		border-radius: 0 0 3px 3px;
		border-top: none;
		padding: 10px 10px 12px 12px;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		position: relative;
		z-index: 2;
	}

	.p-search__submit {
		width: 300px;
		height: 30px;
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
		border-radius: 3px;
		align-items: center;
		padding: 0;
		margin-left: auto;
		margin-top: 8px;
	}

	.b-block-container__content-employees {
		background: #FFFFFF;
		border: 1px solid #A9A9A9;
		box-sizing: border-box;
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
		border-radius: 3px;
		margin-top: -5px;
		padding: 5px 0 0;
		position: relative;
		z-index: 1;
		overflow: hidden;

		& > .b-block-container__content {
			padding: 0;
			border-top: 0;
		}
	}

	.b-block-table {
		table-layout: auto;
	}

	.b-block-table__row {
		text-align: center;
		background: #fff;

		@media (min-width: 769px) {
		  &:nth-child(2n) {
			background: #F0F0F0;
			.b-block-table__cell {
			  background: #F0F0F0;
			}
		  }
		}

		&.b-block-table__row-header {
			background: #fff;

			.b-block-table__cell {
				background: #fff;
			}
		}

		@media (min-width: 769px) {
		  &.clickable:hover {
			background: #c0d5ed;
			.b-block-table__cell {
			  background: #c0d5ed;
			}
		  }
		}
	}

	.b-block-table__cell {
		padding: 17px 0;
		border-bottom: 0;
		background: #fff;
		line-height: 18px;

		&-fullName {
			text-align: left;
			.first-name {
				font-weight: 500;
			}
		}

		&-birthDate {
			.b-block-table__cell-value {
				min-width: 50%;
			}
		}

		&-documents {
			.document-type {
				font-weight: 500;
			}

			.b-block-table__cell-value {
				& > div {
					display: none;

					&:first-child {
						display: block;
					}
				}
			}
		}

		&:last-child:after {
			display: none;
		}
	}

	.b-block-table__row-header .b-block-table__cell {
		border-bottom: 1px solid #4A5767;
		padding: 10px 12px;

		&:nth-last-child(2):after {
			display: none;
		}
	}

	.b-block-table__row:not(.b-block-table__row-header) {
		height: 54px;
		.b-block-table__cell {
			position: relative;
			color: rgba(74, 87, 103, 0.8);
			font-size: 13px;

			&:after {
				content: '';
				border-top: 1px dashed #4A5767;
				position: absolute;
				top: 50%;
				left: 0;
				right: 0;
				width: unset;
				height: unset;
			}

			&:nth-child(2):after {
				left: 50%;
			}

			&:nth-last-child(2):after {
				right: 50%;
			}
		}
	}

	.b-block-table__cell-value {
		position: relative;
		z-index: 2;
		background: inherit;
		padding: 0 12px;
		display: inline-block;
		min-width: 80%;
		vertical-align: middle;
		max-width: 13vw;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.b-block-table__cell-empty {
		display: none;
	}
}

@import "./style.mobile";
