.travel-policy-status {
  padding: 8px 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid #D8D8D8;
  background: #FAFAFA;

  &__icon {
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px;
  }

  &__label {
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
  }

}

.travel-policy-status--AUTHORIZATION_NEEDED {
  color: #FF8D2E;

  .travel-policy-status__icon {
    background-image: url("~assets/img/icons/travel_policy_icons/auth_required_icon/auth-required-orange.svg");
  }

}

.travel-policy-status--AUTHORIZATION_REQUESTED {
  color: #4A5767;

  .travel-policy-status__icon {
    background-image: url("~assets/img/icons/travel_policy_icons/auth_required_icon/auth-required-dark.svg");
  }

}

.travel-policy-status--AUTHORIZATION_APPROVED {
  color: #099C9D;

  .travel-policy-status__icon {
    background-image: url("~assets/img/icons/travel_policy_icons/auth_required_icon/auth-required-aqua.svg");
  }

}

.travel-policy-status--AUTHORIZATION_NOT_APPROVED {
  color: #D70000;

  .travel-policy-status__icon {
    background-image: url("~assets/img/icons/travel_policy_icons/auth_required_icon/auth-required-red.svg");
  }

}


@import "./style.mobile";
