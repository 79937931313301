.b-notifications {
	margin-top: 12px 0px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}

.b-notifications-org-name {
	font-weight: bold;
	font-size: 14px;
	line-height: 16px;
	margin-bottom: 5px;
  margin-top: 12px;
}
