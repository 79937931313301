@import (reference) "~assets/css/imports";

.b-train__options {
    width: 100%;

    .b-train-options-container {
        background: @field1-background-color;
        border-left: @field1-border;
        border-right: @field1-border;
        padding: 25px 10px;
        margin-top: -2px;
        margin-bottom: 0;
        .clearfix;
        .rounded-top;
        .shadow;
    }

    .l-grid-layout-33 {
        display: flex;
        align-items: center;
    }
    .b-train__options-header {
        background: @field2-background-color;
        padding: 12px 15px;
        height: auto;
        line-height: 22px;
        color: @field2-H1-color;
        font-size: @base-H1-size;
        border-left: @field2-border;
        border-right: @field2-border;
        border-top: @field2-border;
        text-transform: uppercase;
        .shadow;
    }
    .b-toggle {
        height: unset;
        line-height: unset;


        .b-toggle__input:checked + .b-toggle__label {
            color: @field1-font-color;
        }
        .b-toggle__input:checked + .b-toggle__label .b-toggle__view {
            border-color: @field1-font-color;

            &:before {
                border-color: @field1-font-color;
                left: unset;
                right: 2px;
            }
        }

        &__view {
            width: 33px;
            height: 16px;
            border-color: rgba(74, 87, 103, 0.2);

            &:before {
                top: 2px;
                left: 2px;
                background: rgba(74, 87, 103, 0.8);
                width: 10px;
                height: 10px;
                border-color: @field1-font-color;
            }
        }

        &__label {
            display: flex;
            align-items: center;
            color: rgba(74, 87, 103, 0.6);
        }
    }
}