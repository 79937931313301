@media @tablet {
  .b-additional-services {
	&__header {
	}

	&__content {
	}

	&__table-service {
	  flex-direction: column;
	}

	&__service {
	  align-items: unset;

	  .b-toggle {
		width: 100%;
	  }

	  .b-toggle__label {
		color: #4A5767;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		float: unset !important;
	  }

	  .b-toggle__view {
		order: 2;
		border: 1px solid #A4ABB3;
		background-color: #A4ABB3;
		margin-right: unset !important;
	  }

	  .b-toggle__view:before {
		background: #FFFFFF;
		border: 1px solid #FFFFFF;
	  }

	  .b-toggle__input:checked +.b-toggle__label .b-toggle__view {
		background: #099C9D;
		border: 1px solid #099C9D;

		&:before {
		  background: #FFFFFF;
		  border: 1px solid #FFFFFF;
		}
	  }

	  .b-toggle .b-toggle__input:disabled + .b-toggle__view:before {
		background: #bdbdbd;
		border-color: #bdbdbd;
	  }

	  .b-toggle .b-toggle__input:disabled + .b-toggle__label {
		color: #4a576799 !important;
	  }

	  .b-toggle .b-toggle__input[disabled="disabled"]:checked + .b-toggle__view:before {
		background: rgb(9, 156, 157, 40%);
		border-color: rgb(9, 156, 157,40%);
	  }

	  .b-toggle .b-toggle__input:checked + .b-toggle__label {
		color: #4A5767 !important;
	  }
	}

	&__seat-services.open {
	  flex-flow: column;
	}

	&__table-service__checkbox-container {
	  margin-right: unset;
	}

	&__service-price {
	  position: absolute;
	  right: 80px;
	  margin-top: 3px;
	}

	&__table-service__table-caption-container {
	  margin: 10px 0;
	}

	&__seat-services-checkbox {
	}

	&__seat-services {
	  &--to {
	  }

	  &--from {
	  }
	}

	&__seat-services__service {
	  padding-left: unset;
	}

	&__seat-services__service-row {
	}

	&__seat-services__service-description {
	  padding-left: unset;
	}
  }

  .flex-grid-container {
  }

  .flex-grid-layout {
  }
}
