@media @tablet {
  .b-traveller {
	&__title {
	  .b-traveller-tariff-container {
		display: none;
	  }
	}

	&__title-contact {
	  display: block;
	}
  }

  .b-traveller-tariff-container {
	margin-top: 20px;
	flex-direction: column;
	gap: 10px;
	font-size: 14px;
  }

  .b-traveller-tariff-title {
	display: none;
  }
  .b-traveller-info {

	&__tariff {

	  &--mobile {
		width: 100%;
		display: flex;
		flex-direction: column;
	  }
	}

	&__tariff-title {
	  display: none;

	  &--mobile {
		white-space: nowrap;
		display: flex;
		justify-content: space-between;
		font-size: 14px;
	  }
	}

	&__tariff-text {
	  font-weight: 500;
	}

	&__tariff-caption {
	  color: #099C9D;
	  display: flex;
	  justify-content: center;
	}

	&__tariff-container {
	  &--mobile {
	  }
	}
  }

  .b-traveller-cost-codes {
	&__container {
	}
  }

  .b-travellers__header-add-infant {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;

	& > button {
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  color: #FF8D2E !important;
	  border: none !important;
	  background-color: transparent !important;
	  font-weight: 400;
	  font-size: 16px;
	  line-height: 18px;
	}

	& > button:after {
	  margin-left: 10px;
	  width: 1em;
	  height: 1em;
	  content: '+';
	  background: none;
	  .g-icon-add-orange;
	}
  }
}
