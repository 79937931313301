@media @tablet {

	.b-cabinet-collection-small .b-cabinet-collection__add {
		top: 0;
		margin-top: 10px;
	}

	.b-cabinet-collection__add {
		height: 48px;
		width: 48px;
	}

}