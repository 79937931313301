@import (reference) "~assets/css/imports";

.b-current-date-matrix {
	display: none;
  	font-size: @base-H2-size;
  	margin-bottom: 17px;
}

.b-current-date-matrix__table {
  width: 100%;
  .rounded;
  .shadow;
  background: @field2-background-color;
  border-collapse: collapse;
}

.b-current-date-matrix__table tr td {
  text-align: center;

}

.b-current-date-matrix__table tr td:first-child {
  font-size: @base-H1-size;
  color: @field2-font-color;
}

.b-current-date-matrix__table tr:nth-child(1) td, .b-current-date-matrix__table tr:nth-child(2) td {
	border-bottom: 1px solid @field1-elements-color;
}

.b-current-date-matrix__table tr:nth-child(1) td:first-child, .b-current-date-matrix__table tr:nth-child(2) td:first-child {
  border-bottom: 1px solid @field2-elements-color;
}

.b-current-date-matrix__table tr td {
  border-left: 1px solid @field1-elements-color;
}

.b-current-date-matrix__table tr td:nth-child(1),.b-current-date-matrix__table tr td:nth-child(2) {
  border-left: none;
}

.b-current-date-matrix__table tr:first-child td:nth-child(2) {
 	.rounded-top-left;
	.background-clip(border-box);
}

.b-current-date-matrix__table tr:first-child td:nth-child(8) {
 	.rounded-top-right;
 	.background-clip(border-box);
}

.b-current-date-matrix__table tr:last-child td:nth-child(2) {
  	.rounded-bottom-left;
  	.background-clip(border-box);
}

.b-current-date-matrix__table tr:last-child td:nth-child(8) {
 	.rounded-bottom-right;
 	.background-clip(border-box);
}

.b-current-date-matrix__table-info {
  width: 12.5%;
}

.b-current-date-matrix__table-price {
  background: @field1-background-color;
  width: 12.5%;
  cursor: pointer;
  color: @field1-H1-color;
  padding: 10px 0;
}

.b-current-date-matrix__table-price.no-pointer {
  cursor: default;
}

.b-current-date-matrix__table-price-airline {
  width: 100px;
  height: 30px;
  margin-top: 4px;
  display: inline-block;
}


.b-current-date-matrix__table-price-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  span {
	display: inline-block;
  }
}
