.fade {
  -webkit-transition: @transition-fade;
  -o-transition: @transition-fade;
  transition: @transition-fade;

  &:not(.show) {
    opacity: 0;
  }
}

.collapse {
  &:not(.show) {
    display: none;
  }
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: @transition-collapse;
  -o-transition: @transition-collapse;
  transition: @transition-collapse;
}
