.b-flight-ticket__branded-fares {
	&.without-arrows {
		.b-branded-fares__controls {
			display: none;
		}
	}
}

.b-branded-fares {
	overflow: hidden;
	font-size: 14px;
  	position: relative;

      &__extra-info-container {
        position: absolute;
		left: 0;
	  	top: 7px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;

          &.b-travel-policy {
			.b-travel-policy-rule {
			  	width: 24px!important;
			  	height: 30px!important;
				&:last-child {
					margin-left: 0;
				}
			}
		}
      }

	&__controls {
		text-align: right;
		padding: 0 20px 20px;
	}

	&__control {
		width: 30px;
		height: 30px;
		border: 1px solid #4A5767;
		border-radius: 3px;
		margin-left: 8px;
		display: inline-block;
		cursor: pointer;
		background-size: 9px;

		&.disabled {
			pointer-events: none;
			opacity: .25;
		}

		&.g-icon-arrow-left {
			background-position-x: 47%;
		}

		&.g-icon-arrow-right {
			background-position-x: 53%;
		}
	}

	&__container {
		width: 100%;
		position: relative;
		display: flex;
		flex-direction: row;
	}

	&__column {
		display: inline-flex;
		flex-direction: column;

		& > .b-branded-fares__row {
			border-bottom: 1px dashed rgba(0, 0, 0, .3);
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		  	width: 100%;
			text-align: center;
			min-height: 90px;

			.is-complex-ticket .b-branded-services__item-container.last &,
			.is-roundtrip-ticket .b-branded-services__item-container.last & {
				display: none;
			}

		  	.b-branded-fares__row-caption {
			  display: inline-block;
			  width: 100%;
			  text-align: left;
			}

			&:last-child,
			&:first-child {
				min-height: unset;
			}

			&:first-child {
				padding: 0 5px 10px;
			}

			&:last-child {
				border-bottom: 0;

				.is-roundtrip-ticket &,
				.is-complex-ticket & {
					display: none;
				}

				.b-branded-services__item-container.last & {
					display: flex;
				}
			}

			&:after {
				content: '';
				display: block;
				opacity: 0.2;
				border: 1px solid #4A5767;
				box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
				position: absolute;
				right: -1px;
				top: 0;
				bottom: 0;
			}
		}

		&:not(.b-branded-fares__column--type-caption) {
			& > .b-branded-fares__row {
				&:not(:first-child):not(:last-child) {
					padding: 5px;
				}
			}
		}

		&--type-caption {
			width: 136px;
			flex: 0 1 auto;
			background: #fff;
			position: relative;
			z-index: 1;

			& > .b-branded-fares__row {
				padding: 0 5px 0 14px;
				align-items: start;
			}
		}

		&.action-cell {
			position: absolute;
			height: 100%;
		}
	}

	.action-columns {
		display: inline-flex;
		flex-direction: row;
		flex: 1 1 auto;
		position: relative;

		& > .b-branded-fares__column {
			flex: 1 1 auto;

			&:last-child {
				& > .b-branded-fares__row {
					&:after {
						display: none;
					}
				}
			}
		}
	}

	&__company-logo {
		width: 74px;
		height: 40px;
		margin: 0 auto;
	}

	&__name {
		font-size: 16px;
		text-transform: uppercase;
		margin: 0 auto;

		&:not(:empty) + .b-branded-fares__price {
			padding-top: 15px;
		}
	}

	&__price {
		font-size: 14px;
		font-weight: 500;
		margin-top: auto;
		padding-top: 10px;
	}

	&__basis {
		text-align: center;
		color: rgba(74, 87, 103, 0.8);
	}

	&__seats {
		text-align: center;
		display: block;
		margin: auto 0 5px;
		padding-top: 10px;
	}

	.b-feature {
		&--type-negative {
			color: #E50000;
		}
	}

	&__select--offer,
	&__select {
		min-width: 130px;
		min-height: 30px;
        margin: 50px auto 0;
		padding: 5px 40px;
		cursor: pointer;
		font-size: 16px;
        margin-bottom: 15px;

		&:hover {
			border-color: #D70000;
			background: #D70000;
		}
	}

	&__select--offer, &__select--offer--select-all {
		position: relative;
        margin-top: 0;
		color: #4A5767;
		background: none;
		border: none;
		height: unset;
		width: unset;

		&::before {
			content: "+";
			position: absolute;
			left: 15px;
			top: 3px;
			z-index: 100;
			display: block;
			width: 20px;
			height: 20px;
			font-size: 14px;
			border: 2px solid #4A5767;
			border-radius: 50%;
			transition: transform 0.2s;
		}

		&:hover {
			color: #D70000;
			background: none;

			&::before {
				border-color: #D70000;
			}

		}
	}

  	&__select--offer--select-all {
        margin-top: auto;
	  	min-height: 30px;
	  	min-width: 130px;
	  	display: flex;
	  	justify-content: center;
	  	margin-bottom: 15px;
	  	align-items: center;
	  	cursor: pointer;
	  	padding-left: unset;

		&::before {
		  left: unset !important;
		}
	}

	&__select-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&__select-price {
		font-weight: bold;
		font-size: 18px;
		color: #4A5767;
		text-align: center;
		margin: 24px 0 7px;
	}

  	&__commission-section {
		margin-top: 10px;
		display: flex;
		flex-direction: column;
		gap: 5px;

	  .b-branded-fares__commission-row {
		  display: flex;
		  justify-content: space-between;
		  align-items: center;
		  gap: 10px;

		& > i {
			width: 14px;
			height: 14px;
			display: inline-block;
		}
	  }
  }

	&__perfom {
		margin-top: auto;
		text-align: left;
		&-airlines {
			color: #E50000;
		}
	}

	.b-ticket-refund-exchange {
		margin: 0;
		padding: 0;
		color: inherit;

		&__item {
			margin: 0;
			&-title {
				display: none;
			}
		}

		.standard-services_dash {
			text-indent: -9999px;
			display: block;
			height: 0;
		}

		&__element + .b-ticket-refund-exchange__element {
			margin-top: 8px;
		}

		&__splitter {
			display: none;
		}

		&__cost {
			font-weight: 500;
		}

		&__impossible {
			color: #E50000;;
		}
	}

	.b-ticket-luggage-val-block {
		padding: 0;
	}
	.b-ticket-luggage-title {
		font-weight: normal;
		margin-bottom: 0;
	}
	.b-ticket-refund-exchange__item-rule {
		margin-bottom: 0;

		& + .b-ticket-refund-exchange__item-rule {
			margin-top: 3px;
		}
	}
}
