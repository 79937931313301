@import (reference) "~assets/css/imports";

.b-transfer-info__wrapper {
  display: flex;
  flex-flow: column;
  background: #4A5767;
  .shadow;
  border-radius: 3px 3px 0 0;
  margin-bottom: 40px;

  .b-transfer-info {

	.b-transfer-info__name {
	  font-weight: 600;
	  padding: 13px 13px 0 13px;
	  font-size: 14px;
	  color: #ffffff;

	  .b-travel-policy {
		float: right;
	  }
	}

	.b-transfer-info__car-class {
	  font-weight: 600;
	  padding: 13px;
	  color: #ffffff;
	  flex-wrap: wrap;

	  .train-service__img {
		margin-bottom: 13px;
	  }

	}

	.b-ticket-stops-info {
	  position: relative;
	  display: inline-block;
	  padding-left: 26px;

	  a {
		color: #4A5767;
		text-decoration: none;
		border-bottom: 1px dashed #4A5767;
		font-weight: normal;
	  }
	}

	.b-ticket-stops-info:before {
	  content: '';
	  position: absolute;
	  top: 8px;
	  display: block;
	  width: 20px;
	  height: 1px;
	  transform: rotate(90deg);
	  left: 0;
	  background: rgba(0, 0, 0, 0.1);
	}

	.b-ticket-info__legs-leg-info {
	  border-bottom-left-radius: 3px;
	  border-bottom-right-radius: 3px;
	}

  }

  .b-transfer-pricing {
	padding: 0 14px 14px;

	.separator-dotted {
	  border: 1px dashed rgba(0, 0, 0, 0.1);
	  background: none;
	}

	.b-pricing__total {
	  color: #FFFFFF;
	  font-weight: 500;
	  display: flex;
	  flex-direction: row;
	  justify-content: space-between;
	}
  }

  .ticket-info__time {

  }
}
