.b-error-popup {
	&.is-user-error,
	&.is-admin-error,
	&.is-developer-error {
		.b-popup__header {
			display: none;
		}
	}

	&.b-details__is-open {
		.b-error-popup__message {
			position: relative;
			&:before {
				content: '';
				display: block;
				position: absolute;
				left: -1px;
				right: -1px;
				bottom: 0;
				height: 4px;
				border-radius: 0 0 3px 3px;
				border: solid 1px #B60A0A;
				border-top: 0;
			}

		}
	}

	.b-popup__content {
		padding: 0;
		text-align: left;
		border-color: #B60A0A;
	}

	&__message {
		padding: 60px 40px;
		text-align: center;
	}

	&__more-wrapper {
		line-height: 1.5em;
		.toggle-b-error-popup__more {
			background-color: #F2F2F2;
			padding: 30px 35px;
			max-height: 40vh;
			overflow: auto;
			overflow-y: auto;
			overflow-x: hidden;
			position: relative;
		}
	}
}
