@import (reference) "~assets/css/imports";

.hotels-map-closed {

  .hotels-map-zoom-in,
  .hotels-map-zoom-out,
  .hotels-map-close,
  .hotels-map-expand {
    display: none;
  }
}

.hotels-map-expanded {
  .hotels-map-fix {
    display: none;
  }

  .hotels-map-expand {
    background-image: url('~assets/img/hotels/map/map_collapse.png');
    border-radius: 3px;
    border-bottom: none;

    &:hover {
      background-image: url('~assets/img/hotels/map/map_collapse_h.png');
    }

    &:active {
      background-image: url('~assets/img/hotels/map/map_collapse_a.png');
    }
  }
}

.hotels-map-zoom-in {
  background-color: rgba(93, 93, 91, 0.5);
  background-image: url('~assets/img/hotels/map/map_zoom_in.png');
  background-repeat: no-repeat;
  background-position: center;
  width: 32px;
  height: 32px;
  border-radius: 3px 3px 0 0;
  border-bottom: 1px solid #fff;
  cursor: pointer;
  transition: all 0.2s;
}

.hotels-map-zoom-in:hover {
  background-image: url('~assets/img/hotels/map/map_zoom_in_h.png');
  background-color: #5d5d5b;
}

.hotels-map-zoom-in:active {
  background-image: url('~assets/img/hotels/map/map_zoom_in_a.png');
  background-color: #fff;
}

.hotels-map-zoom-out {
  background-color: rgba(93, 93, 91, 0.5);
  background-image: url('~assets/img/hotels/map/map_zoom_out.png');
  background-repeat: no-repeat;
  background-position: center;
  width: 32px;
  height: 32px;
  border-radius: 0 0 3px 3px;
  cursor: pointer;
  transition: all 0.2s;
}

.hotels-map-zoom-out:hover {
  background-image: url('~assets/img/hotels/map/map_zoom_out_h.png');
  background-color: #5d5d5b;
}

.hotels-map-zoom-out:active {
  background-image: url('~assets/img/hotels/map/map_zoom_out_a.png');
  background-color: #fff;
}

.hotels-map-close {
  background-color: rgba(93, 93, 91, 0.5);
  background-image: url('~assets/img/hotels/map/map_close.png');
  background-repeat: no-repeat;
  background-position: center;
  width: 32px;
  height: 32px;
  margin-top: 9px;
  margin-right: 9px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s;
}

.hotels-map-close:hover {
  background-image: url('~assets/img/hotels/map/map_close_h.png');
  background-color: #5d5d5b;
}

.hotels-map-close:active {
  background-image: url('~assets/img/hotels/map/map_close_a.png');
  background-color: #fff;
}

.hotels-map-expand {
  background-color: rgba(93, 93, 91, 0.5);
  background-image: url('~assets/img/hotels/map/map_expand.png');
  background-repeat: no-repeat;
  background-position: center;
  width: 32px;
  height: 32px;
  margin-top: 9px;
  margin-right: 9px;
  border-radius: 3px 3px 0 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  transition: all 0.2s;
}

.hotels-map-expand:hover {
  background-image: url('~assets/img/hotels/map/map_expand_h.png');
  background-color: #5d5d5b;
}

.hotels-map-expand:active {
  background-image: url('~assets/img/hotels/map/map_expand_a.png');
  background-color: #fff;
}

.hotels-map-fix {
  width: 32px;
  height: 32px;
  border-radius: 0 0 3px 3px;
  background-color: rgba(93, 93, 91, 0.5);
  background-image: url('~assets/img/hotels/map/map_fixed.png');
  background-repeat: no-repeat;
  background-position: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  transition: all 0.2s;

  &.active {
    background-image: url('~assets/img/hotels/map/map_float.png');
  }
}

.hotels-map-fix:hover {
  background-image: url('~assets/img/hotels/map/map_fixed_h.png');
  background-color: #5d5d5b;

  &.active {
    background-image: url('~assets/img/hotels/map/map_float_h.png');
  }
}

.hotels-map-fix:active {
  background-image: url('~assets/img/hotels/map/map_fixed_a.png');
  background-color: #fff;

  &.active {
    background-image: url('~assets/img/hotels/map/map_float_a.png');
  }
}

.hotels-map-marker {
  position: absolute;
  cursor: pointer;
  border: 2px solid rgba(255, 255, 255, 0.7);
  border-radius: 18px;
  color: #fff;
  z-index: 1;
  transform: translateY(-26px) translateX(-50%);
}

.hotels-map-marker>div {
  font-size: 14px;
  font-family: "PT Sans", Helvetica, Arial, sans-serif;
  font-weight: bold;
  opacity: 0.95;
  background-color: #535090;
  border-radius: 18px;
  line-height: 18px;
  padding: 0 6px;
  white-space: nowrap;
}

.hotels-map-marker:before {
  content: '';
  display: block;
  position: absolute;
  bottom: -6px;
  left: 50%;
  border-right: 8px solid rgba(0, 0, 0, 0);
  border-top: 6px solid #4d4a7d;
}

.hotels-map-marker:hover>div {
  background-color: #6ca8e3 !important;
}

.hotels-map-marker:hover:before {
  border-top-color: #6ca8e3 !important;
}

.hotels-map-marker[data-stars="1"]>div {
  background-color: #535090;
}

.hotels-map-marker[data-stars="1"]:before {
  border-top-color: #4d4a7d;
}

.hotels-map-marker[data-stars="2"]>div {
  background-color: #844359;
}

.hotels-map-marker[data-stars="2"]:before {
  border-top-color: #754052;
}

.hotels-map-marker[data-stars="3"]>div {
  background-color: #b02c2c;
}

.hotels-map-marker[data-stars="3"]:before {
  border-top-color: #972d2c;
}

.hotels-map-marker[data-stars="4"]>div {
  background-color: #db3c1c;
}

.hotels-map-marker[data-stars="4"]:before {
  border-top-color: #bb3717;
}

.hotels-map-marker[data-stars="5"]>div {
  background-color: #f74000;
}

.hotels-map-marker[data-stars="5"]:before {
  border-top-color: #d03d08;
}

.hotels-map-marker[data-type="office"]>div {
  background-color: #27BA77;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hotels-map-marker[data-type="office"]:before {
  border-top-color: #25955f;
}

.hotels-map-object-marker {
  width: 20px;
  height: 18px;
  display: block;
  position: absolute;
  cursor: pointer;
  transform: translate(-50%);
}

.hotels-map-object-marker[data-type="city"] {
}

.hotels-map-object-marker[data-type="аэропорт"] {
}

.hotels-map-object-marker[data-type="ж/д вокзал"] {
}

.hotels-map .tooltip {
  z-index: 999;
}

.hotels-cluster {
  z-index: 998;
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0.7);
}

.hotels-cluster:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background-color: rgba(64, 81, 97, 0.95);
  z-index: -1;
}

.hotels-cluster:hover:after {
  background-color: #6ca8e3;
}


.hotels-map-infobox {
  width: 300px;
  height: 225px;
  margin-top: -100px;
  position: absolute;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.075);
  z-index: 99;
}

.hotels-map-infobox-head {
  background: #f8f8f6;
  position: absolute;
  width: 100%;
  z-index: 2;
  border-radius: 2px 2px 0 0;
  margin: -5px 0;
}

.hotels-map-infobox-head h3 {
  font-size: 16px;
  padding: 12px 5px 12px 12px;
  margin: 0;
  line-height: 22px;
  font-family: "PT Sans", Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #8a8a89;
  white-space: nowrap;
  max-width: 65%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hotels-map-infobox-head-container {
	display: flex;
	flex-flow: row;
	align-items: center;
	position: relative;
}

.hotels-map-infobox-head .b-hotel-stars {
	white-space: nowrap;
	margin: 0 25px 3px 5px;
}

.hotels-map-infobox-head .b-hotel-star {
    height: 17px;
    width: 17px;
}

.hotels-map-infobox-head .hotels-map-infobox-close {
  background: url("~assets/img/icon-cancel-black.svg") center no-repeat;
  background-size: 12px;
  width: 14px;
  height: 14px;
  display: block;
  position: absolute;
  right: 6px;
  top: 6px;
  cursor: pointer;
}

.hotels-map-infobox-body {
  top: 0;
  width: 100%;
  border-radius: 3px;
}

.hotels-map-infobox-slider-wrap {
  white-space: nowrap;
  overflow: hidden;
  background-color: #f8f8f6;
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(142, 142, 142, 0.3);
}


.hotels-map-infobox-slider-wrap .b-slider__list {
	width: 100%;
	height: 190px;
}
.hotels-map-infobox-slider-wrap .b-slider {
	margin: auto;
}

.hotels-map-infobox-slider-wrap .b-slider .b-slider__image {
	margin: 40px auto 0;
	height: unset;
	width: unset;
}

.hotels-map-infobox-button-wrap {
  width: 100%;
  position: absolute;
  z-index: 2;
  bottom: 18px;
}

.hotels-map-infobox-button {
  border-radius: 3px;
  background: #8F8876;
  font-size: 16px;
  font-family: "PT Sans", Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #fff;
  border: 0;
  margin: 0 auto;
  padding: 12px 18px;
  line-height: 12px;
  display: block;
  outline: none !important;
}

.hotels-map-infobox-button:hover,
.hotels-map-infobox-button:active {
  background-color: #67a6e7 !important;
}

.hotels-map-infobox[data-stars="1"] .hotels-map-infobox-button {
  background: #535090;
}

.hotels-map-infobox[data-stars="2"] .hotels-map-infobox-button {
  background: #844359;
}

.hotels-map-infobox[data-stars="3"] .hotels-map-infobox-button {
  background: #b02c2c;
}

.hotels-map-infobox[data-stars="4"] .hotels-map-infobox-button {
  background: #db3c1c;
}

.hotels-map-infobox[data-stars="5"] .hotels-map-infobox-button {
  background: #f74000;
}
