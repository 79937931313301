.b-pdf-view {
	width: 100%;
	background-color: #404040;
	text-align: center;

	.pdf-container-docs {
		overflow: auto;
		min-height: 350px;
		max-height: 75vh;
	}

	.pdf-container-doc,
	.html-container-doc {
		box-shadow: 0 0 5px 3px #222;
		margin:5px;
		padding:0px;
		display: inline-block;

		-webkit-transform: translate3d(0,0,0);
		-moz-transform: translate3d(0,0,0);
		-ms-transform: translate3d(0,0,0);
		transform: translate3d(0,0,0);
	}

	.not-scallable .pdf-toolbar .pdf-toolbar-center > * {
		display: none;
	}

	.pdf-toolbar {
		position: relative;
		height: 32px;
		overflow: hidden; /* for preventing pdf container centering problem */
		background-color: #474747; /* fallback */
		box-shadow: 0 1px 3px black;
		text-align: left;
		font-size: 13px;
		line-height: 14px;
		padding-left: 10px;
		color: hsl(0,0%,95%);
	}

	.pdf-toolbar-field {
		padding: 3px 6px;
		margin: 4px 0 4px 0;
		border: 1px solid transparent;
		border-radius: 2px;
		background-color: hsla(0,0%,100%,.09);
		background-image: linear-gradient(hsla(0,0%,100%,.05), hsla(0,0%,100%,0));
		background-clip: padding-box;
		border: 1px solid hsla(0,0%,0%,.35);
		border-color: hsla(0,0%,0%,.32) hsla(0,0%,0%,.38) hsla(0,0%,0%,.42);
		box-shadow: 0 1px 0 hsla(0,0%,0%,.05) inset,
		0 1px 0 hsla(0,0%,100%,.05);
		color: hsl(0,0%,95%);
		font-size: 13px;
		line-height: 14px;
		outline-style: none;
		transition-property: background-color, border-color, box-shadow;
		transition-duration: 150ms;
		transition-timing-function: ease;
	}

	.pdf-toolbar-field.pdf-toolbar-field-pageNumber {
		-moz-appearance: textfield; /* hides the spinner in moz */
		min-width: 16px;
		text-align: right;
		width: 40px;
	}

	.pdf-toolbar .split-toolbar-button .toolbarButton {
		outline: none;
	}

	.pdf-toolbar .split-toolbar-button .zoomOut::before {
		content: '–';
		position: relative;
		bottom: 1px;
		font-size: 20px;
	}

	.pdf-toolbar .split-toolbar-button .zoomIn::before {
		content: '+';
		position: relative;
		bottom: 2px;
		font-size: 20px;
	}

	.pdf-toolbar .split-toolbar-button {
		margin: 3px 2px 4px 0;
		display: inline-block;
	}

	.pdf-toolbar .split-toolbar-button.pdf-zoom-button {
		margin-top: 0;
	}


	.toolbarButton,
	.dropdownToolbarButton {
		min-width: 16px;
		padding: 2px 6px 0;
		border: 1px solid transparent;
		border-radius: 2px;
		color: hsla(0,0%,100%,.8);
		font-size: 12px;
		line-height: 14px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		/* Opera does not support user-select, use <... unselectable="on"> instead */
		cursor: default;
		-webkit-transition-property: background-color, border-color, box-shadow;
		-webkit-transition-duration: 150ms;
		-webkit-transition-timing-function: ease;
		transition-property: background-color, border-color, box-shadow;
		transition-duration: 150ms;
		transition-timing-function: ease;
	}

	.pdf-toolbar .split-toolbar-button .toolbarButton {
		float: left;
		border: 0 none;
		background: none;
		width: 32px;
		height: 25px;
		cursor: pointer;
	}

	.toolbarButton > span {
		display: inline-block;
		width: 0;
		height: 0;
		overflow: hidden;
	}

	.toolbarButton[disabled] {
		opacity: .5;
		pointer-events: none;
	}

	.toolbarButton.group {
		margin-right: 0;
	}

	.pdf-toolbar .pdf-toolbar-left {
		float:left;
	}

	.pdf-toolbar .pdf-toolbar-center {
		position: relative;
		margin: 3px auto;
		width: 220px;
		height: 30px;
	}

	.pdf-toolbar .change-page-number {
		float:left;
		position: relative;
	}

	.pdf-toolbar .split-toolbar-button {
		float:left;
		position: relative;
		padding-top:2px;
	}

	.pdf-toolbar .split-toolbar-button > .toolbarButton {
		position: relative;
	}

	.pdf-toolbar .split-toolbar-button > .toolbarButton:first-child,
	.pdf-toolbar .split-toolbar-button > .toolbarButton:last-child {
		position: relative;
		margin: 0;
		margin-right: -1px;
		border-top-left-radius: 2px;
		border-bottom-left-radius: 2px;
		border-right-color: transparent;
	}
	.pdf-toolbar .split-toolbar-button > .toolbarButton:last-child,
	.pdf-toolbar .split-toolbar-button > .toolbarButton:first-child {
		position: relative;
		margin: 0;
		margin-left: -1px;
		border-top-right-radius: 2px;
		border-bottom-right-radius: 2px;
		border-left-color: transparent;
	}

	.pdf-toolbar .split-toolbar-button .splitToolbarButtonSeparator {
		float:left;
		padding: 8px 0;
		width: 1px;
		background-color: hsla(0,0%,0%,.5);
		z-index: 99;
		box-shadow: 0 0 0 1px hsla(0,0%,100%,.08);
		display: inline-block;
		margin: 5px 0;
	}


	.pdf-toolbar .dropdownToolbarButton {
		padding: 3px 2px 2px;
		overflow: hidden;
	}

	.dropdownToolbarButton > select {
		-webkit-appearance: none;
		-moz-appearance: none; /* in the future this might matter, see bugzilla bug #649849 */
		min-width: 140px;
		font-size: 12px;
		color: hsl(0,0%,95%);
		margin: 0;
		padding: 0;
		border: none;
		background: rgba(0,0,0,0); /* Opera does not support 'transparent' <select> background */
	}

	.dropdownToolbarButton > select > option {
		background: hsl(0,0%,24%);
	}

	.dropdownToolbarButton {
		background-color: hsla(0,0%,0%,.12);
		background-image: linear-gradient(hsla(0,0%,100%,.05), hsla(0,0%,100%,0));
		background-clip: padding-box;
		border: 1px solid hsla(0,0%,0%,.35);
		border-color: hsla(0,0%,0%,.32) hsla(0,0%,0%,.38) hsla(0,0%,0%,.42);
		box-shadow: 0 1px 0 hsla(0,0%,100%,.05) inset,
					0 0 1px hsla(0,0%,100%,.15) inset,
					0 1px 0 hsla(0,0%,100%,.05);
	}

	.pdf-toolbar .dropdownToolbarButton {
		margin: 2px 2px 4px 0;
		display: block;
		float: left;
		background-position: 95%;
	}
}
