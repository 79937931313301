@media @tablet {
	.p-schedule-wrapper {
		.p-search {
			position: relative;

			.p-search-form-group {
				.p-search-form-element {
					margin-right: 0;

					&:last-of-type {
						.p-search__submit {
							border-radius: 0;
							height: 54px;
							line-height: 54px;
							position: absolute;
							width: 100%;
							left: 0;
							border-radius: 0;
							bottom: -54px;
						}
					}

					.b-search-form__param-type {
						.b-search-form__select-type-label-select,
						.b-search-form__select-type-label {
							height: 40px;
						}
					}

					.p-search__options-date {
						.p-search__options-date-element {
							display: block;

							.p-search__options-time-from {
								margin-top: 12px;
							}
						}

						.p-search__direction-date-input {
							height: 48px;
						}

						.p-search__direction-date-calendar {
							height: 48px;
							.p-search__direction-date-calendar-img {
								height: 100%;
							}
						}
					}
				}
			}
			&__direction-swap {
				position: static;
				margin-bottom: 12px;

				&-img {
					position: static;
					height: 32px;
					width: 14px;
					background-size: contain;
					transform: rotate(90deg);
					margin: auto;
				}			
			}
		}
	}

	.p-schedule-results {
		position: relative;
		top: 54px;
	}
}