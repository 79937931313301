@import (reference) "~assets/css/imports";


.tooltip-options-check {
	text-align: left;
}

.b-hotel {

	&-room__additional-services {
		background: #FFFFFF;
		box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
		border-radius: 0 0 3px 3px;
		padding: 34px 16px 13px 18px;
		margin-bottom: 10px;

		.w-time .b-input {
			padding: 0;
			border: 0;
			width: 100%;
			height: 100%;
		}

		.important {
			color: #E50000;
		}
	}

	&-additional-services__list {
		margin: 40px 40px 22px 10px;

		.l-grid-layout-33 {
			margin-bottom: 18px;
			padding-right: 15px;

			&:nth-child(3n) {
				padding-right: 0;
			}
		}
	}

  	&-additional-services__mobile-reprice-warning {
	  display: none;
  	}

	&-additional-services__caption {
		font-size: 16px;
		font-weight: 500;

		& + .b-hotel-additional-services__list {
			margin-top: 40px;
		}
	}

	&-additional-services__notification {
		margin: 7px 0 15px;
		max-width: 500px;
	}

	&-additional-services__footer {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: flex-end;
	}

	&-additional-services__total {
		margin-left: auto;
		margin-right: 20px;
		font-size: 20px;
		font-weight: 500;
	}

	&-additional-services__reprice {
		font-size: 14px;
		color: #fff;
		padding: 7px 17px;
		cursor: pointer;
		height: unset;
		display: flex;
		align-items: center;
	}
}

@import "./style.mobile";
