@import (reference) "~assets/css/imports";


.b-multiple-select__default-label {
  display: block;
  max-height: 7px;

  &:after {
	top: 7px;
  }
}
