@import (reference) "~assets/css/imports";

.b-hotel-travellers__wrapper {
	.lg-visible-block {
		display: none;
	}
	.b-traveller__title {
		color: #4A5767;
		background: #F0F0F0;
		display: flex;
		flex-direction: row;
		height: 35px;
		padding: 0 18px;
		font-size: 13px;
		font-weight: 500;
		align-items: center;

		.b-checkbox {
			height: unset;
		}
	}

	.b-traveller-number {
		text-transform: capitalize;
	}

	.b-traveller-info {
		padding: 14px 42px 20px 20px;

		.b-input {
			padding-bottom: 0;
		}
	}

	.b-traveller__block-header {
		display: flex;
		align-items: center;
		width: 100%;
		height: unset;
		line-height: unset;
		margin: 25px 0 20px;

		position: relative;

		&:after {
			content: '';
			height: 1px;
			margin-left: 25px;
			flex: 1;
			display: block;
			border-top: 1px solid rgba(0, 0, 0, 0.1);
		}
	}
}

@import "./style.mobile";
