@import (reference) "~assets/css/imports";

.b-booking-docs-modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 999;
  padding-right: 12px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);

  &__content {
	background: #fff;
	padding: 20px;
	max-height: 90vh;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	.border-radius(10px);

	& .b-input__value {
	  border-color: rgb(163, 170, 178, .5);
	}
  }

  &__header {
	position: relative;
	margin-bottom: 20px;
	font-size: 16px;
  }

  &__close-btn {
	position: absolute;
	top: 0;
	cursor: pointer;
	right: 0;
	width: 14px;
	height: 14px;
	text-decoration: none;
	display: flex;
	justify-content: center;
	align-content: center;
  }

  &__table {
	max-height: 500px;
	overflow-y: auto;
	margin-bottom: 50px;
	.box-shadow(~'0 2px 4px rgba(0, 0, 0, 0.25)');
	border: 1px solid #A9A9A9;
  }

  .b-block-table {

	&__cell {
	  &-content-container {
		display: flex;
		justify-content: center;
		align-items: center;
	  }
	}
  }

  .b-docs-table {

	&__download {
	  width: 20px;
	  height: 20px;
	  display: inline-block;
	  cursor: pointer;
	}
  }

  &__footer {
	margin-top: auto;
  }

  &__send-btn {
	background-color: #4882BD;
	color: #fff;
	border: none;
	min-width: 220px;
	font-weight: 700;
  }

  &__download-btn {

  }

  &__comment {
	margin-bottom: 20px;
  }

  &__emails {
	.b-input {
	  padding-bottom: 0 !important;
	}
  }

  .b-block-table__row .b-block-table__cell {
	border-top: 1px dashed rgba(0,0,0,.3);
	border-bottom: 1px solid transparent;
  }

  .b-block-table__row-header .b-block-table__cell {
	border-bottom: none;
  }

  .b-block-table {
	text-transform: uppercase;
  }
}

@import "style.mobile";
