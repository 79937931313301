@media @tablet {
  .b-offers-transfers-modal__list-item {

	&__car-info {
	  flex: 1 1 30%;
	}

	&__car-class {
	  font-size: 16px;
	  line-height: 19px;
	  margin-bottom: 10px;
	}
  }
}
