@import (reference) "~assets/css/imports";

.b-payment-cash {
  background: @field2-elements-color;
  .rounded;
}

.b-payment-cash__header {
  background: @field2-background-color;
  padding: 0 15px;
  height: 55px;
  line-height: 55px;
  color: @field2-H2-color;
  font-size: @base-H1-size;
  text-transform: uppercase;
  .rounded-top;
  .shadow;
}

.b-payment-cash__content {
  background: @field1-background-color;
  padding: 25px;
}

.b-payment-cash__content-header {
  font-size: @base-H1-size;
  color:@field1-H1-color;
}

.b-payment-cash__content-header-number {
  font-size: @base-H1-size;
  color:@field1-warning-color;
}

.b-payment-cash__content-price {
  font-size: @base-font-size;
}

.b-payment-cash__content-price-amount {
  font-size: @base-font-size;
  color:@field1-alert-color;
}
