@media (min-width: 1920px) {
  .b-transfers__ride-details {


	&-content {
	  padding: 20px;
	}

	.p-search__direction-date-input {
	  height: 30px;
	}
  }

  .b-transfer__trip-container {
	margin-bottom: 18px;
  }

}

@media (min-width: 1440px) {
  .b-transfers__ride-details {


	&-content {
	  padding: 12px;
	}

	.p-search__direction-date-input {
	}
  }

  .b-transfer__trip-container {
  }
}

@media (min-width: 1220px) {

}

@media @tablet {
  .b-transfers {
	&__ride-details {
	}

	&__ride-details__header {
	  display: flex;
	  align-items: center;

	  &:before {
		width: 22px;
		height: 22px;
		display: block;
		content: ' ';
		.g-icon-transfer-black;
		margin-left: 10px;
	  }
	}

	&__ride-details__header-label {
	}

	&__ride-details__wrapper {
	}

	&__ride-details__trips {

	  .b-transfer__trip {
		padding: 0 10px;
	  }
	}

	&__ride-details__warning {
	  padding: 0 10px;
	  background-color: #FCEFE0;
	  color: #FF8D2E;
	}

	&__ride-details__warning-icon {
	  min-width: 22px;
	  min-height: 22px;
	}

	&__ride-details__warning-text {
	  font-weight: normal;
	}
  }

  .b-transfer__trip-row {
	width: 100%;
	margin-right: 0;
	padding-right: 0;
  }
  .b-transfer__passengers-amount {
	padding: 18px 0 0 0;
	min-width: unset !important;
  }
  .b-transfer__trip-container {
	padding: 0;
  }
}


