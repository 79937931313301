@import (reference) "~assets/css/imports";

.b-person-email {
	position: relative;

	.b-input__value {
		padding-right: 30px;
	}
}

	.b-person-email__remove {
		background-color: @field1-background-color;
		.g-icon-delete;
		display: block;
		background-size: 15px 15px;
		cursor: pointer;
		position: absolute;
		width: 30px;
		height: 36px;
		top: 50%;
		margin-top: -13px;
		right: 2px;
		padding: 0;
		z-index: 10;
	}
