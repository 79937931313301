@media (min-width: 1440px) {
	.b-route-info_railways {
		.b-train-ticket {
			padding: 15px 24px 20px 12px;
		}

		.b-info-train {
			font-size: 20px;
		}
		.b-info-train__description {
			font-size: 16px;
		}
	}
}

@media (min-width: 1920px) {
	.b-route-info_railways {
		.b-train-ticket {
			padding: 20px 24px 20px 30px;

			&__route-map {
				display: flex;
				flex-flow: row nowrap;
				margin-top: 15px;

				.b-train-ticket__map,
				.b-train-ticket__route {
					flex: 1;

				}
			}

			&__map {
				margin: 0;
				margin-right: 20px;
			}
		}

		.b-route {
			margin: 0 10px 0 0;
			flex-direction: column;
			min-width: unset;
		
			&__departure,
			&__arrival {
				width: 195px;
			}

			&__terminal {
				overflow: visible;
				white-space: normal;
			}

			&__carriage,
			&__path {
				flex: 1;
			}			

			&__carriage {
				border-left: none;
				border-top: 1px dashed rgba(0, 0, 0, .3);
				align-items: flex-start;

				&-row {
					justify-content: unset;
					margin-top: 15px;
					& > span {
						margin-bottom: 5px;

						&:last-child {
							margin-bottom: 0;
						}
					}
					&:first-child {
						margin-left: 0;
					}
				}
			}
		}
	}
}