@import (reference) "~assets/css/imports";

@media @tablet {
  .l-layout.show-filters {

	.b-toggle {
	  width: 100%;
	}

	.b-toggle__label {
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	  width: 100%;
	  float: unset !important;
	}

	.b-toggle__input {

	}

	.b-toggle__view {
	  order: 2;
	  border: 1px solid #A4ABB3;
	  background-color: #A4ABB3;
	}

	.b-toggle__view:before {
	  background: #FFFFFF;
	  border: 1px solid #FFFFFF;
	}
	.b-toggle__input:checked+.b-toggle__label .b-toggle__view {
	  background: #099C9D;
	  border: 1px solid #099C9D;

	  &:before {
		background: #FFFFFF;
		border: 1px solid #FFFFFF;
	  }
	}

	.b-toggle .b-toggle__input:checked + .b-toggle__label {
	  color: #4A5767 !important;
	}
  }
}

.b-toggle {
	display: inline-block;
	cursor: pointer;
	position: relative;
	height: 22px;
	line-height: 22px;
}

.b-toggle__input {
	display: none;
}

.b-toggle__label {
	color: @field2-font-color;
	float: left;
	margin: 0 8px 0 0;
}

.b-toggle__view {
	.border-radius(11px);
	border: 1px solid @field2-object-color;
	width: 38px;
	height: 20px;
	margin-right: 12px;
	float: left;
	position: relative;

	&.position-right {
		margin-left: 12px;
		margin-right: 0;
	}
}

.b-toggle__view:before {
	content: "";
	display: block;
	position: absolute;
	width: 12px;
	height: 12px;
	top: 3px;
	left: 4px;
	.border-radius(50%);
	border: 1px solid #fff;
	background: @field2-object-color;
	.transition-fast();
}

.b-toggle .b-toggle__input:checked + .b-toggle__label {
	color: @field2-active-color;
}

.b-toggle .b-toggle__input:checked + .b-toggle__label .b-toggle__view {
	border: 1px solid @field2-active-color;
}
.b-toggle .b-toggle__input:disabled + .b-toggle__view:before {
  background: #bdbdbd;
  border-color: #bdbdbd;
}

.b-toggle .b-toggle__input:disabled + .b-toggle__label {
  color: #4a576799 !important;
}

.b-toggle.disable-color-white {
  .b-toggle__input:disabled + .b-toggle__label {
	color: #fff !important;
  }
}

.b-toggle .b-toggle__input:checked + .b-toggle__label .b-toggle__view:before {
	border: 1px solid @field2-active-color;
	background: @field2-lements-color-lower;
	left: 20px;
}

.b-traveller__lang-toggle {
	height: 40px;
	margin-top: 13px;

	.b-toggle__label {
		color: #6E7985 !important;
	}
	.b-toggle .b-toggle__input:checked + .b-toggle__label .b-toggle__view, .b-toggle__view {
		border-color: #4882BD !important;
	}
	.b-toggle .b-toggle__input:checked + .b-toggle__label .b-toggle__view:before, .b-toggle__view:before {
		border-color: #4882BD !important;
		background: #4882BD !important;
	;
	}
}
