@import (reference) "~assets/css/imports";
@import "../../../../node_modules/nouislider/distribute/nouislider.min.css";


html:not([dir=rtl]) .noUi-horizontal .noUi-handle {

}

@media @tablet {
  .l-layout.show-filters {
	.b-range {

	}

	.b-range__label {
	  color: #4a5767;
	}

	.b-range__input {

	}

	.noUi-connect {
	  background-color: #099C9D !important;
	}

	.noUi-handle {
	  border: 1.5px solid #099C9D !important;
	  background-color: #099C9D !important;
	}

	.noUi-lower-upper,
	.noUi-lower-value {
	  color: #4A5767;
	  border: 1px solid #099C9D;
	  background-color: #EDEEF0;
	}
  }
}

.b-range {
	display: block;
	width: 100%;
	padding: 0 0 27px;
}

.b-range__label {
	display: block;
	font-size: @base-H1-size;
	padding-bottom: 40px;
	color: @field2-H2-color;
}

.b-range__input {
	position: relative;
	margin: 0 30px 0 28px;
}

.b-selection__label {
	color: @field2-H2-color;
}


.noUi-target {
	height: 1px;
	background: @field2-elements-color;
	border: 0;
	border-radius: 0;
	.box-shadow(none);
}

.noUi-target:after, .noUi-target:before {
	content: "";
	display: block;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: @field2-elements-color;
	position: absolute;
	top: -4px;
	left: 0;
}

.noUi-target:after {
	right: -6px;
	left: auto;
}

.noUi-background {
	background: @field2-elements-color;
	.box-shadow(none);
}

.noUi-connect {
	background: @field2-active-color;
	.box-shadow(none);

}

.noUi-base {
}

.noUi-lower-value, .noUi-lower-upper {
	position: absolute;
	top: 100%;
	width: 60px;
	left: -24px;
	color: #fff;
	text-align: center;
	display: block;
	margin-top: 5px;
	font-size: @base-font-size;
	border: 1px solid @field2-active-color;
	background: @field2-lements-color-lower;
	.rounded;
}

.noUi-lower-upper {
	top: auto;
	bottom: 100%;
	margin-top: 0;
	margin-bottom: 4px;
}

.noUi-handle,
.noUi-horizontal .noUi-handle {
	outline: none !important;
	width: 12px;
	height: 12px;
	top: -6px;
	border-radius: 50%;
	display: block;
	border: 1px solid #FFFFFF;
	background: #FFFFFF;
	.box-shadow(none);
	z-index: 12;
	cursor: pointer;
	right: -10px !important;
}


.noUi-horizontal .noUi-handle-lower {
	left: -4px;
}

.noUi-horizontal .noUi-handle-upper {
	left: -4px;
}

.noUi-handle:before,
.noUi-handle:after {
	display: none;
}
