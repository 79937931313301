@import (reference) "~assets/css/imports";

.b-payment-types__container {
    background: @field1-background-color;
    border-left: @field1-border;
    border-right: @field1-border;
    padding: 15px 10px;
    margin-top: -2px;
    margin-bottom: 0;
    .clearfix;
    .rounded-top;
    .shadow;
}