@import (reference) "~assets/css/imports";

@header-height: 50px;
@control-el-base-height: 38px;

.b-datepicker-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #4A5767;
  z-index: 9999;

  &__header {
	position: relative;
	height: @header-height;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: 2px solid #222222;
  }

  &__wrapper {
	height: ~'calc(100vh - @{header-height})';
	overflow-y: auto;
	position: relative;
  }

  &__title {
	font-size: 16px;
	color: #fff;
  }

  &__close-button {
	width: 40px;
	height: 20px;
	position: absolute;
	top: 13px;
	left: 5px;
	.g-icon-arrow-left-white;
  }

  &__controls {
	display: flex;
	justify-content: center;
	align-self: center;
	flex-direction: column;
	position: fixed;
	z-index: 10000;
	top: @header-height;
	height: @control-el-base-height;
	width: 100%;
  }

  .p-search__direction-date-input {
	.p-search-date-input();
  }

  .dateTo, .dateFrom {
	width: 100%;
  }

  &__pikaday-container {
	padding: 0 12px 12px 12px;
	margin-right: 22px;
	display: flex;
	margin-top: @control-el-base-height;
	margin-bottom: @control-el-base-height;
	justify-content: center;
	align-items: center;
	flex-direction: column;
  }

  &__one-way-button {
	.search-button();
	height: 38px;
	position: fixed;
	z-index: 10000;
	bottom: 0;
  }

  &__pikaday-container > .pika-single {
	display: none;
  }
  &__pikaday-container.show-date-to > .pika-single:nth-child(1) {
	display: flex;
  }
  &__pikaday-container.show-date-from > .pika-single:nth-child(2) {
	display: flex;
  }

  .pika-single {
	width: 100%;
	flex-direction: column;
  }

  .pika-lendar {
	width: 100%;
  }

  .pika-button {
	margin: 0 auto;
  }

  .pika-table td {
	height: 30px;
  }
}
