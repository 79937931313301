.b-input-light {
  .b-input__label {
    background-color: #fff;
  }
}

.b-input-dark {
  .b-input__label {
    background-color: #fff;
  }
	.b-input__value:disabled,
	.b-input__value[disabled],
	.b-input__value[readonly] {
		background-color: #dadada;
		border: 1px solid #4a576766;
		color: #7a7a7a;
	}
}
