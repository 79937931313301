.b-attachment {
	&__logo {
		&-img {
			&-transfer {
                background-image: url("~assets/img/icons/icon-transfer.svg");
            }
		}

	}
}
