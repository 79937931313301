@media (min-width: 1440px) {
	.b-hotel {
		&-additional-services__list {
			margin: 0 10px 40px;

			.l-grid-container .l-grid-layout-33 {
				width: 25%;
				padding-right: 15px;

				&:nth-child(4n) {
					padding-right: 0;
				}
			}
		}
	}
}

@media (min-width: 1920px) {
	.b-hotel {
		&-room__additional-services {
			margin-bottom: 0;
			border: none;
			box-shadow: none;
			border-radius: 0;
		}


		&-additional-services__list-wrapper {
			display: flex;
			.b-hotel-additional-services__list {
				flex: 1;
			}
		}
		&-room__info-item:first-child {
			padding-left: 0;
			border: none;
		}
		&-additional-services__list {
			margin: 0 0 -18px;
		}
	}
}

@media @tablet {
  .b-hotel-additional-services {
	padding: 20px 0;
	&__caption {
	  color: #fff;
	  font-size: 16px;
	  background: #4A5767;
	  border-radius: 3px 3px 0 0;
	  padding: 12px;
	  position: relative;
	  text-transform: uppercase;
	  font-weight: normal;
	  z-index: 1;
	  display: flex;
	  align-items: center;

	  &:before {
		content: ' ';
		display: block;
		margin-right: 10px;
		width: 22px;
		height: 22px;
		.g-icon-additional-services;
	  }
	}

	&__notification {
	}

	&__list-wrapper {
	}

	&__list {
	  margin: 10px;

	  .l-grid-container {
		display: flex;
		flex-flow: row wrap;

		& > .l-grid-layout-33:not(:last-of-type) {
		  flex-basis: 50%;
		  flex-grow: 1;
		}

		& > .l-grid-layout-33 {
		  margin-top: 10px;

		  & > .w-time .b-input__label:after {
			content: ' ';
			margin-left: 10px;
			width: 1.5em;
			height: 1.5em;
			background: url("~assets/img/icon-info.svg") no-repeat center;
			background-size: 90%;
			right: -25px;
			top: -2px;
			left: unset;
		  }
		}
	  }
	}

	&__footer {
	  display: none;
	}

	&__total {
	}

	&__reprice {
	}

	&__mobile-reprice-warning {
	  display: block;
	  padding: 8px 11px 7px 35px;
	  .g-icon-attention-orange;
	  background-size: 1.3em 1.3em;
	  background-position: 10px 7px;
	  color: #FF8D2E;
	  background-color: #FCEFE0;

	  & > div:last-of-type {
		font-weight: 500;
		text-decoration: underline;
	  }
	}
  }

  .b-hotel-additional-services__notification.b-notification--type-alert {

  }

  .btn {
  }

  .btn-dark {
  }

  .btn-disabled {
  }
}
