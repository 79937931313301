@media @tablet {

	.p-search__input {
		height: 48px;
		// line-height: 48px;
		font-size: 16px;
	}

	.p-search__direction-date {
	  position: relative;

	  &.active:before {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background-color: #4882bd;
		bottom: -2px;
		left: 0;
		position: absolute;
	  }
	}

	.is-startrange .pika-button {
	  color: #2f3842;
	  background: #fff;
	}

	.is-endrange .pika-button {
	  color: #2f3842;
	  background: #fff;
	}

}
