@import (reference) "~assets/css/imports";

.b-addition-orders {
	.no-wrap {
		white-space: nowrap;
	}

	.more {
		margin-top: 5px;
	}

	&__item {
		.service-type {
			display: inline-block;
			vertical-align: middle;
			width: 23px;
			height: 23px;

			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;

			&--type-INSURANCE_ACCIDENT {
			  background-image: url("~assets/img/icons/icon-med-insurance.svg");
			  &--ISSUED, &--PAYED {
				background-image: url("~assets/img/icons/icon-med-insurance-colored.svg");
			  }
			}
			&--type-INSURANCE_ACCIDENT-white {
			  background-image: url("~assets/img/icons/icon-med-insurance-white.svg");
			}
			&--type-TAXI {
			  background-image: url("~assets/img/icons/icon-taxi.svg");
			  &--ISSUED, &--PAYED {
				background-image: url("~assets/img/icons/icon-taxi-colored.svg");
			  }
			}
			&--type-TAXI-white {
			  background-image: url("~assets/img/icons/icon-taxi-white.svg");
			}
			&--type-OTHER {
			  background-image: url("~assets/img/icons/icon-universal.svg");
			  &--ISSUED, &--PAYED {
				background-image: url("~assets/img/icons/icon-universal-colored.svg");
			  }
			}
			&--type-OTHER-white {
			  background-image: url("~assets/img/icons/icon-universal-white.svg");
			}
			&--type-AEROEXPRESS {
			  background-image: url("~assets/img/icons/icon-air-express.svg");
			  &--ISSUED, &--PAYED {
				background-image: url("~assets/img/icons/icon-air-express-colored.svg");
			  }
			}
			&--type-AEROEXPRESS-white {
			  background-image: url("~assets/img/icons/icon-air-express-white.svg");
			}
			&--type-INSURANCE_TOURISTIC {
			  background-image: url("~assets/img/icons/icon-tour-insurance.svg");
			  &--ISSUED, &--PAYED {
				background-image: url("~assets/img/icons/icon-tour-insurance-colored.svg");
			  }
			}
			&--type-INSURANCE_TOURISTIC-white {
			  background-image: url("~assets/img/icons/icon-tour-insurance-white.svg");
			}
			&--type-CAR_RENTAL {
			  background-image: url("~assets/img/icons/icon-rent.svg");
			  &--ISSUED, &--PAYED {
				background-image: url("~assets/img/icons/icon-rent-colored.svg");
			  }
			}
			&--type-CAR_RENTAL-white {
			  background-image: url("~assets/img/icons/icon-rent-white.svg");
			}
			&--type-VISA {
			  background-image: url("~assets/img/icons/icon-visa.svg");
			  &--ISSUED, &--PAYED {
				background-image: url("~assets/img/icons/icon-visa-colored.svg");
			  }
			}
			&--type-VISA-white {
			  background-image: url("~assets/img/icons/icon-visa-white.svg");
			}
			&--type-BUS {
			  background-image: url("~assets/img/icons/icon-bus.svg");
			  &--ISSUED, &--PAYED {
				background-image: url("~assets/img/icons/icon-bus-colored.svg");
			  }
			}
			&--type-BUS-white {
			  background-image: url("~assets/img/icons/icon-bus-white.svg");
			}

			&--type-RAILWAY {
				background-image: url("~assets/img/icons/icon-train.svg");

			  &--ISSUED, &--PAYED {
				background-image: url("~assets/img/icons/icon-train-colored.svg");
			  }
			}
		  	&--type-RAILWAY-white {
				background-image: url("~assets/img/icons/bc-railways.svg");
		  	}
			&--type-AIR {
				background-image: url("~assets/img/icons/icon-avia.svg");

			  &--ISSUED, &--PAYED {
				background-image: url("~assets/img/icons/icon-avia-colored.svg");
			  }
			}
		  	&--type-AIR-white {
				background-image: url("~assets/img/icons/bc-avia.svg");
		  	}
			&--type-HOTEL_RESERVATION {
				background-image: url("~assets/img/icons/icon-hotel.svg");

			  &--ISSUED, &--PAYED {
				background-image: url("~assets/img/icons/icon-hotel-colored.svg");
			  }
			}
		  	&--type-HOTEL_RESERVATION-white {
				background-image: url("~assets/img/icons/icon-hotel-active.svg");
		  	}
		  	&--type-TRANSFER {
			  background-image: url("~assets/img/icons/icon-transfer-s.svg");

			  &--ISSUED, &--PAYED {
				background-image: url("~assets/img/icons/icon-transfer-s-colored.svg");
			  }
			}
		 	&--type-TRANSFER-white {
				background-image: url("~assets/img/icons/icon-transfer-s-active.svg");
		 	}
			&--type-UNKNOWN {
				background-image: url("~assets/img/icons/icon-unknown.svg");
				background-size: auto;
			}
		  	&--type-UNKNOWN-white {
				background-image: url("~assets/img/icons/bc-unknown.svg");
				background-size: auto;
		  	}
		}
	}

	&__item-add {
		display: inline-block;
		width: 15px;
		min-height: 1.22em;
		background: url('~assets/img/icons/icon-add-black.svg') center no-repeat;
		background-color: inherit;
		background-size: 15px;
		cursor: pointer;
		vertical-align: middle;
		padding: 0 20px;
		position: relative;
		z-index: 3;
	}

  	&__item-add-caption {
	  display: none;
	}

	&__item-number {
		color: #4882BD;
		border-bottom: 1px dashed #4882BD;
		cursor: pointer;
	}

	.b-block-table {
		table-layout: auto;
		border-collapse: collapse;
		&__cell {
			border: none;
			text-align: center;
			color: #4A5767;
			padding: 13px;

			&-empty {
				display: none;
			}

			&--width-auto {
				text-align: right;
			}

			&-controls {
				width: 1px;
				overflow: unset;
				white-space: nowrap;
				padding-right: 0;
				text-align: right;
				line-height: 19px;
			}

			&:after {
				content: '';
				display: block;
				height: 1px;
				border-bottom: 1px dashed rgba(0,0,0,.3);
				background: none;
				position: absolute;
				left: 0;
				right: 0;
				top: 50%;
				width: 100%;
				z-index: 0;
			}

			&.b-block-table__cell--width-auto,
			&.b-block-table__cell-empty {
				&:after {
					display: none;
				}
			}
		}

		&__row-header {
			.b-block-table__cell {
				font-size: 14px;
				font-weight: normal;
				border: none;
				text-align: center;

				&:after {
					display: none;
				}
			}
		}

		tbody {
			.b-block-table__cell {
				.b-block-table__cell-label {
					background: inherit;
					padding: 0 10px;
					position: relative;
					z-index: 1;
					min-width: 70%;
					display: flex;
					flex-flow: column;
					vertical-align: middle;
					justify-content: center;
					width: fit-content;
					margin: 0 auto;

					line-height: 19px;
					&:first-line {
						line-height: 18px;
					}

					.more {
						align-self: center;
					}

					.overflow-field {
						max-width: 12.5vw;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}

				.currency {
					margin-left: 5px;
				}
			}

			@media (min-width: 769px) {
			  .b-block-table__row {
				&:nth-child(2n + 1) {
				  background: #F0F0F0;

				  .b-block-table__cell {
					background: #F0F0F0;
				  }
				}
				&:nth-child(2n) {
				  background: #fff;

				  .b-block-table__cell {
					background: #fff;
				  }
				}
			  }
			}
		}
	}
}

.service-type__tooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.canceled-reservation-text-color {
  opacity: .8;
}


@import "./style.mobile";
