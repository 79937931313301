@import (reference) "~assets/css/imports";

@media @tablet {
  .b-ticket-popup-offers-modal_mask {

	.b-offers-avia-modal {
	  .b-leg__mobile-controls {
		display: none;
	  }
	}
  }
}

@media (min-width: 769px) and (max-width: 925px) {
  .b-ticket-popup-offers-modal_mask {

	.b-ticket-popup-offers-modal {
	  width: 100% !important;
	  min-width: unset;
	  max-width: unset;
	}
  }
}
