@media @tablet {
	.b-block-container {
		&__content {
			.b-block-container-content__header {
				margin: 10px 0;
			}
		}

		.b-block-container__content-title {
			flex-direction: column;
			padding: 13px;

			.b-block-container__title-leftbar {
				margin-right: 0;
			}

			.b-block-container__title-rightbar {
				margin-left: 0;
			}

			.b-block-container__title-leftbar + .b-block-container__title-rightbar {
				margin-top: 10px;
			}
		}
		.b-block-container__footer {
			.b-block-table-footer {
				flex-wrap: wrap;

				.b-footer-pages-container .b-footer-page {
					height: 30px;
					min-width: 30px;
					line-height: 30px;
				}

				.b-footer-element:after {
					display: none;
				}

				.b-footer-total {
					width: 100%;
					flex-direction: column;

					.b-footer-total__item {
						margin-left: 0;
					}
				}
			}
		}
	}
}
