@import (reference) "~assets/css/imports";

.b-railway-carriers-input {
	font-size: @base-H1-size;
	position: relative;
	display: block;
}

.b-railway-carriers-input__wrapper {
  display: block !important;
}

.b-railway-carriers-input__hint {
	color: @field1-font-color;
	opacity: 0.5 !important;
}

.b-railway-carriers-input__items {
	background: @field1-background-color;
	padding: 0 0;
	margin-top: 1px;
	.rounded-bottom;
	.shadow;
	min-width: 265px;
	left: auto !important;
	right: 0;
	max-height: 240px;
	overflow: auto;
}

.b-railway-carriers-input__item {
	.clearfix;
	line-height: 40px;
	padding: 0 10px;
	position: relative;
	cursor: pointer;
}

.b-railway-carriers-input__item_selected {
	.clearfix;
	color: @field1-active-color;
	line-height: 40px;
	padding: 0 30px 0 10px;
	position: relative;
	display: block;
	height: 40px;
	width: 265px;
}

.b-railway-carriers-input__item_selected_text {
	width: 220px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	display: inline-block;
	position: relative;
}

.b-railway-carriers-input__item_selected:after, .b-railway-carriers-input__item:after {
	content: ' ';
	background: @field1-elements-color;
	height: 1px;
	left: 3px;
	right: 3px;
	bottom: -1px;
	display: block;
	position: absolute;
}

.b-railway-carriers-input__cursor {
	background: @field1-active-color;
	color: @field1-link-color;
}

.b-railway-carriers-input__cursor.b-railway-carriers-input__item:after {
	display: none;
}

.b-railway-carriers-input__item-city {
	overflow: hidden;
	display: block;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.b-railway-carriers-input__item-code {
	position: absolute;
	right: 0;
	float: right;
	margin-right: 10px;
	padding-left: 15px;
	.g-icon-cancel;
	height: 14px;
	margin-top: 13px;
	cursor: pointer;
}

.b-railway-carriers__item:hover {
  background: @field1-elements-color;
}

.b-railway-carriers-input.validation-error {

	.b-railway-carriers-input__input {
		.placeholder(@field1-warning-color);
		color: @field1-warning-color;
	}
}

.b-search-form__params {
  .b-railway-carriers-input__hint {
	color: @field2-font-color;
  }

  .b-railway-carriers-input__items {
	background: @field2-background-color;
  }

  .b-railway-carriers__item:hover {
	background: @field2-active-color;
	color: @field2-background-color;
  }

  .b-railway-carriers-input__item_selected {
	color: @field2-active-color;
  }

  .b-railway-carriers-input__item_selected:after, .b-railway-carriers-input__item:after {
	background: @field2-elements-color;
  }

  .b-railway-carriers-input__cursor {
	background: @field2-active-color;
	color: @field2-link-color;
  }

  .b-railway-carriers-input.validation-error {

	.b-railway-carriers-input__input {
	  .placeholder(@field2-warning-color);
	  color: @field2-warning-color;
	}
  }

  .b-railway-carriers__item:hover {
	background: @field2-elements-color;
  }
}

.l-grid-layout-33 .b-railway-carriers-input__items{
  width: 100%;
}

