@import (reference) "~assets/css/imports";

.b-train-route-popup {
	width: 1024px;

	.b-popup__content {
		background-image: none;
		padding: 0;
	}

	.b-train-route__stops {
		width: 20px;
		height: 100%;
		left: 10px;
		display: flex;
		flex-direction: column;
		margin-top: 41px;
		border-top: 1px solid;

		.b-train-route__stop {
			width: 10px;
			height: 10px;
			margin-bottom: 32px;
			position: relative;
			top: 15px;
		}

		.b-train-route__stop:before {
			content: " ";
			display: block;
			border: 2px solid #4A5767;
			background: #4A5767;
			border-radius: 50%;
			width: 8px;
			height: 8px;
			position: absolute;
			top: 50%;
			margin-top: -8px;
			left: 4px;
		}

		.b-train-route__stop.corner-stops:before {
			background: #ffffff;
			width: 15px;
			height: 15px;
			top: 50%;
			margin-top: -8px;
			left: 0;
		}

		.b-train-route__stop:after {
			content: '';
			position: absolute;
			top: 22px;
			display: block;
			width: 30px;
			height: 1px;
			transform: rotate(90deg);
			background: #000;
			left: -7px;
		}

		.b-train-route__stop:last-of-type:after {
			top: 23px;
			width: 32px;
			left: -8px;
		}

		.b-train-route__stop.corner-stops:after {
			width: 26px;
			left: -5px;
			top: 24px;
		}

		.b-train-route__stop.corner-stops:last-of-type:after {
			display:none;
		}

	}

	.b-train-route__header {
		line-height: 35px;
		background: #F0F0F0;
		font-weight: 500;
        font-size: 14px;
        padding-left: 13px;
		border-bottom:1px solid #e2e2e2;
	}

	.b-train-route__info {
		padding: 12px;
		overflow: auto;
		max-height: 50vh;
		min-height: 300px;
		display: flex;
		flex-direction: row;
	}
}

@media @tablet {
  .b-train-route-popup {
	width: 100%;

	.b-train-route__info {
	  max-height: 80vh;

	  .b-block-table {
		display: flex;
		flex-direction: column;
		gap: 20px;

		&__row {
		  display: flex;
		  flex-direction: column;
		  height: 72.5px;
		}

		&__row:last-child .b-block-table__cell {
		  border-bottom: 1px solid #e5e5e5;
		}

		&__cell {
		  padding: 0;
		  display: flex;
		}

		&__mobile-header {
		  display: flex;
		  flex: 0 1 40%;
		}
	  }
	}

	div.b-train-route__stops {
	  display: flex;
	  flex-direction: column;
	  gap: 20px;
	  margin-top: 3px;
	  border-top: none;

	  .b-train-route__stop, .b-train-route__stop.corner-stops{
		height: 72.5px;
		top: 0;
		margin-bottom: 0;

		&:before {
		  top: 0;
		  margin-top: 0;
		}

		&:after {
		  height: 94px;
		  width: 1px;
		  left: 7px;
		  top: 0;
		  transform: none;
		}

		&:first-child:after {
		  width: 1px;
		  left: 7px;
		  top: 0;
		}

		&:first-child:before, &:last-child:before {
		  background: #fff;
		  z-index: 10;
		  margin-top: 0;
		}

		&:last-child:after {
		  display: none;
		}

		&:last-child:before {
		  top: -5px;
		}

		&:last-of-type:after {
		  width: 1px;
		  left: 7px;
		  top: 0;
		}
	  }
	}
  }
}
