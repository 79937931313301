@media @tablet {
  .b-offers-hotels-modal {

	.b-modal-hotel {
	  background-color: #f5f5f5;

	  &__description-container {
		padding: 22px 18px;
		flex-direction: column;
		align-items: normal;
		position: relative;

		.b-modal-hotel__description {
		  position: relative;
		  flex-direction: column;
		  align-items: normal;

		  &-stars {
			margin-left: 0;
			order: 0;
		  }
		  &-name {
			order: 1;
		  }
		  .b-modal-hotel__remove-btn-container {
			width: unset;
			position: absolute;
			right: 0;
			top: 0;
		  }
		}
	  }

	  &__address {
		width: 90%;
	  }
	}

	&__list-item-description {
	  padding: 14px 10px 0 10px;
	  flex-direction: column;
	  align-items: normal;

	  .b-offer-modal__name {
		font-size: 16px;
	  }
	  .b-offer-modal__essential {
		font-size: 14px;
	  }
	  .b-route__element {
		text-align: left;
		white-space: normal;
	  }
	}
  }
}
