.m-relative {
	position: relative;
}

.m-absolute {
	position: absolute !important;
}

//rounded corners
.rounded {
	.border-radius(@base-round-radius);
}

.rounded-top-left {
	.border-top-left-radius(@base-round-radius);
}

.rounded-top-right {
	.border-top-right-radius(@base-round-radius);
}

.rounded-bottom-left {
	.border-bottom-left-radius(@base-round-radius);
}

.rounded-bottom-right {
	.border-bottom-right-radius(@base-round-radius);
}

.rounded-top {
	.border-top-left-radius(@base-round-radius);
	.border-top-right-radius(@base-round-radius);
}

.rounded-bottom {
	.border-bottom-right-radius(@base-round-radius);
	.border-bottom-left-radius(@base-round-radius);
}

.clearfix{

	&:before, &:after {
		content:"";
		display:table;
	}

	&:after{
		clear: both;
	}

}

.scrollbar {
	&::-webkit-scrollbar {
		width: 12px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
		.border-radius(10px);
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		.border-radius(10px);
		background: rgba(255,0,0,0.8);
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
	}

	&::-webkit-scrollbar-thumb:window-inactive {
		background: rgba(255,0,0,0.4);
	}
}

.transition-standard(@properties: all) {
	.transition(@properties 300ms cubic-bezier(0.645, 0.045, 0.355, 1););
}

.transition-fast(@properties: all) {
	.transition(@properties 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86););
}

.shadow {
	.box-shadow(@base-shadow);
}

.base-width {
	min-width: @base-width;
}

.g-process {
	opacity: 0.5;
}

.search-is-complete.g-process2 {
	opacity: 0.5;
	pointer-events: none;
}

.hidden {
	display: none;
	visibility: hidden;
}

.search-button {
	background: #4882BD;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	color: #fff;
	font-size: 16px;
	font-weight: 500;
	height: 100%;
	width: 100%;
	padding: 0 72px;
	border-radius: 3px;
}

.collection-add-icon {
	display: inline-block;
	width: 18px;
	height: 18px;
	line-height: 18px;
	border-radius: 100%;
	text-align: center;
	margin-left: 10px;
	font-size: 1.2em;
  .g-icon-add-orange;
}

.p-search-date-input {
  width: 100%;
  height: 33px;
  border-radius: 3px 3px 0 0;
  background: #fff;
  color: #4a5767;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  border: 1px solid #A4ABB3;
  padding: 8px;
  outline: none;
  box-sizing: border-box;
}

.p-search-timerange-input {
  width: 100%;
  height: 38px;
  background: #fff;
  border-color: #fff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  text-align: center;
  color: #4a5767;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
}

