@import (reference) "~assets/css/imports";

.b-traveller__title {
  color: @field1-H2-color;
  display: block;
  padding: 5px 0;
}

.b-traveller {
  header {
    background: #F0F0F0;
    display: flex;
    flex-direction: row;
    padding: 10px;
    font-size: 13px;
    font-weight: 500;
    align-items: center;

    .b-header-border {
      width: 10px;
      height: 1px;
      background: rgba(0, 0, 0, 0.1);
      transform: rotate(90deg);
      top: 0;
      position: relative;
      margin: 0 10px;
    }

    .b-traveller-contact {
      flex-grow: 10;
    }
  }

  .b-traveller-cost-codes {
    margin-bottom: 20px;
	margin-top: 20px;
  }

  &__bonus-cards {

	.b-airlines-input {

	  .b-airlines-input__wrapper {

		.b-airlines-input__input {
		  border: 1px solid rgba(74, 87, 103, 0.4);
		  height: 30px;
		}
	  }
	}
  }
}
.b-traveller-info {
  padding: 0 10px;
}

.b-traveller-info_language {
  margin: 15px 0 15px;
}

.b-traveller__controls,
.b-traveller__title-contact {
  float: right;
  color: @field1-font-color;
  font-weight: normal;
  margin-left: auto;
}

.b-traveller__discount-header, .b-traveller__block-header {
  height: 40px;
  display: inline-block;
  line-height: 40px;
  color: @field1-H1-color;
  font-weight: 500;
}

.b-traveller__discount-open {
  height: 30px;
  width: 30px;
  display: block;
  bottom: 0;
  float: right;
  background-size: 14px 14px;
  background: url("~assets/img/icon-arrow-up-black.svg") no-repeat 50% 50%;
  stroke: @field1-H1-color;
  border-top: 1px solid @field1-elements-color;
  border-bottom: 1px solid @field1-elements-color;
  border-left: 1px solid @field1-elements-color;
  .rounded-bottom-right;
  .rounded-top-left;
  cursor: pointer;
  margin-right: -10px;
  margin-top: -1px;
}

.b-traveller__discount-open.b-traveller__discount-is-closed {
  background-size: 14px 14px;
  background: url("~assets/img/icon-arrow-down-black.svg") no-repeat 50% 50%;
}

@import "./style.mobile";

