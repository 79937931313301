@import (reference) "~assets/css/imports";

.b-ticket-popup-offers-modal_mask.b-ticket-popup-offers-modal_mask--train .b-ticket-popup-offers-modal {
	width: 100%;
}

.b-offers-train-modal__form,
.b-offers-train-modal__title {
	padding: 20px 40px 20px 27px;
}

.b-offers-train-modal__title {
	padding-bottom: 0;
}

.b-offers-train-modal__emails {
  padding: 10px 40px 20px 27px;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.b-offers-train-modal__list-item-comment {
  width: 100%;
  padding-top: 0!important;
  padding-bottom: 5px!important;
}

.b-offers-train-modal__list-item-line {
  margin: 15px auto;
  width: 96%;
  border-bottom: 1px solid rgba(164, 171, 179, 0.5);
}

.b-offers-train-modal__close {
	background: url("~assets/img/icon-clear-dark.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;

	width: 14px;
	height: 14px;
	display: block;
	position: absolute;
	right: 20px;
	top: 20px;
	cursor: pointer;

	&:hover {
		opacity: 0.7;
	}

	&:active {
		opacity: 1;
	}
}

.b-offers-train-modal__title {
	font-size: 16px;
	line-height: 19px;
	color: rgba(74, 87, 103, 0.8);

	margin-bottom: 10px;
}

.b-offers-train-modal__title-person {
	font-weight: bold;
	font-size: 20px;
	line-height: 23px;
}

.b-offers-train-modal__no-offers {
	text-align: center;
	margin: auto;
	padding: 30px 0;
	font-size: 18px;

	& > span {
		border-bottom: 1px dotted;
	}

	&.dn {
		display: none;
	}
}

.b-offers-train-modal__list-item {
	position: relative;
	padding: 20px 40px 20px 57px;
    min-height: 140px;
    display: flex;
    align-items: center;
  	flex-wrap: wrap;

    &:after {
    	content: '';
    	display: block;
    	position: absolute;
    	left: 30px;
    	right: 40px;
    	bottom: 0;
    }
}

.b-offers-train-modal__list-item-drag {
	display: block;
	width: 0;
	position: absolute;
	left: 30px;
	top: 20px;
	bottom: 20px;
	border-left: 2px solid #4882BD;
	padding: 0 30px;
}

.b-offers-train-modal__list-item-legs {
	flex: 1;
}

.b-offers-train-modal__list-item-legs {
	.b-train-ticket {
		border: 0;
		min-width: unset;
		box-shadow: none;
		border-radius: 0;
		background: none;
		padding: 0;

		&:after {
			display: none;
		}
	}

	.b-train-ticket__route {
		margin-left: 0;
		margin-top: 7px;
	}

	.b-route__info-duration {
		text-align: center;
		font-size: 14px;
	}

	.icon-arrow-long {
		background: url(~assets/img/icons/icon-arrow-long.svg) center no-repeat;
		background-size: 100%;
		width: 90px;
		height: 10px;
		display: block;
	}

	.b-route__element {
		white-space: nowrap;
		flex: 1;
		text-align: center;
	}

	.b-train__carriage-service-class {
		color: #4882BD;
	}
}

.b-offers-train-modal__list-item-price {
	display: flex;
	align-items: center;
	white-space: nowrap;
	margin-bottom: 15px;
}

.b-offers-train-modal__list-item-price-container {
	color: #4A5767;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
}

.b-offers-train-modal__list-item-price-amount {
	font-weight: bold;
	font-size: 20px;
	line-height: 23px;
	margin-right: 5px;
}

.b-offers-train-modal__list-item-remove {
	background: url("~assets/img/icon-cancel.svg") no-repeat center;
	cursor: pointer;
	display: block;
	background-size: 10px;
	width: 10px;
	height: 10px;
	margin-left: 30px;
}

.b-offers-train-modal__form {
	padding-top: 20px;

	.b-textarea,
	.b-input {
		padding: 0;
	   	width: 100%;
	}

	.b-textarea__value,
	.b-input__value {
		border-color: rgba(164, 171, 179, 0.5);
		color: #2F3842;
		.placeholder(#6E7985);
	}

	.b-input__value {
		height: 35px;
	}

	.b-textarea__value {
	  	height: 38px;
	  	min-height: 0!important;
	}

	.b-message {
		margin: 0 0 15px;
	}
}

.b-offers-train-modal__form-buttons {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}

.b-offers-train-modal__form-button {
	font-size: 14px;
	line-height: 16px;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
	height: 30px;
	border-radius: 4px;

	border: 1px solid transparent;

	&.disabled {
		opacity: .5;
		pointer-events: none;
	}

	& + .b-offers-train-modal__form-button {
		margin-left: 10px;
	}
}

.b-offers-train-modal__submit {
	font-weight: bold;
	background: #4882BD;
	color: #fff;
	min-width: 220px;
	border-color: #4882BD;
}

.b-offers-train-modal__form-control {
	margin-bottom: 10px;
}

.b-offers-train-modal__order {
	color: #6E7985;
	border-color: rgba(164, 171, 179, 0.5);
	padding: 0 7px;
}

.b-offer-seats-tooltip-wrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;

  .b-offer-seats-tooltip {
    color: #A4ABB3
  }
}

