.b-ticket-luggage {
	&-table {
		display: table;
		width: 100%;
	}

	&-row {
		display: table-row;

		& + .b-ticket-luggage-row {
			& > div {
				padding-top: 10px;
			}
		}
	}

	&-val {
		display: table-cell;
		width: auto;

		&-block {
			padding: 0 9px 0;
		}
	}

	&-title {
		margin-bottom: 3px;

		&.red {
			color: #E50000;
		}
	}

	&-name {
		padding-right: 9px;
		display: table-cell;
		vertical-align: middle;
		position: relative;
		white-space: nowrap;

		&:after {
			content: '';
			height: 60%;
			position: absolute;
			right: 1px;
			top: 20%;
			border-right: 1px solid #eeeeed;
		}
	}
}