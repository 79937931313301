@import (reference) "~assets/css/imports";

.p-schedule-wrapper {

  .p-search {
	flex-direction: column;

	.p-search-form-group {

	  margin-bottom: 18px;

	  .p-search-form-element {
		flex-grow: 1;
		margin-top: 0;
		margin-right: 14px;

		.b-search-route {
		  margin: 0;
		}

		.b-search-form__param-type {
		  .b-search-form__select-type-label {
			/*height: 40px;*/
			height: 30px;
		  }
		  .b-search-form__select-type-label-select {
			/*height: 40px;*/
			height: 30px;
		  }
		}

		/* date */
		.p-search__options-date {

		  .p-search__options-bridge {
			position: relative;
			width: 13px;
			height: 1px;
			top: 15px;
			left: 0;
		  }

		  .p-search__direction-date-input {
			height: 30px;
		  }

		  .p-search__direction-date-calendar {
			height: initial;
			.p-search__direction-date-calendar-img {
			  height: 28px;
			}
		  }

		  .p-search__options-date-element {
			display: flex;
			flex-direction: row;

			.p-search__options-time-from {
			  margin: 0;
			}

			.p-search__options-time-to {
			  margin: 0;
			}

		  }
		}

	  }

	  .p-search-form-element:last-of-type {
		margin-right: 0;
	  }

	  .p-search__wrapper-route-type {
		.b-search-form__select-type-label-wrapper {
		  .b-search-form__select-type-label {
			height: 30px;
		  }
		  .b-search-form__select-type-label-select {
			height: 30px;
		  }
		}
	  }

	  .p-search-form-element-locations {
		flex-grow: 2;
	  }

	  .p-search-form-element-search-type {

	  }

	  .p-search__direction-wrapper-dates {
		max-width: initial;
		flex-grow: initial;
	  }

	  .p-search__submit {
		border-radius: 5px;
		height: 30px;
		line-height: 30px;
		padding: 0;
		cursor: pointer;
	  }
	}

	.p-search-form-group-dates {
	  margin-bottom: 0;
	}

  }
}


/*.p-schedule-wrapper {
  .p-search__direction {
	flex-direction: row;
	.p-search__options-param {
	  width: 30%;
	  margin-right: 12px;
	}

	.b-search-form__select-type-label {
		height: 40px
	}
	.b-search-form__select-type-label-select {
	  height: 40px;
	}

  }
  .p-search__direction-wrapper {
	width: 100%;
  }

  .p-search__options-bridge {
	display: none;
  }
}

.p-container-wrapper {
  display: flex;

  justify-content: space-around;
  align-items: center;

  .p-container-col {
	flex-grow: 2;
	width: 100%;

	.p-search__options-param {
	  width: 100%;
	}

	.b-search-form__param-type {
	  width: 100%;
	}
  }

  .p-container-col-date {
	display: flex;
	align-items: center;
	margin-right: 12px;

		.p-search__options-time {
		  align-items: center;
		}

		.p-search__options-time-from {
		  	margin:0;
		}

		.w-timerange__input {
		  height: auto;
		  padding: 5px;
		}

		.p-search__direction-date-calendar-img {
			padding: 8px;
		}

		.p-search__input {
		  	padding: 6px;
		  	min-width: 100px;
		}

  }

  .p-search__submit {
	border-radius: 5px;
  }

  .p-container-col-button {
	flex-grow: 1;
  }

}
*/

@import "./style.mobile";
