.b-hotel-pricing {
	color: #fff;
	font-size: 14px;

	&__header {
		font-weight: 500;
		text-transform: uppercase;
		padding: 11px 0;
		border-bottom: 1px solid #2F3842;
	}

	&__section {
		padding: 5px 0 7px;
		border-bottom: 1px solid #2F3842;

		&:last-child {
			border: none;
		}
	}

	&__service {
		display: flex;
		flex-flow: column;
		margin: 8px 10px;

		&:first-child {
			margin-top: 0;
		}
	}

	&__caption {
		font-weight: 500;
	}
}

.b-hotel-pservice {
	&__caption {
		color: #fff;
		font-weight: 500;

		&:first-letter {
			text-transform: capitalize;
		}
	}

	&__caption-tax, &__caption-mr {
		color: #fff;
		font-weight: 500;
		margin: 12px 0;
	}

	&__element {
		display: flex;
		flex-flow: row nowrap;
		color: rgba(255, 255, 255, 0.8);
		align-items: center;
		justify-content: space-between;
		margin: 4px 0;

	  	&-price-text {
			display: flex;
			justify-content: flex-end;
	  	}

	  	&-vat {
		  	margin-top: 2px;
		  	display: flex;
		  	justify-content: flex-end;
			color: rgba(255, 255, 255, 0.8);
		  	font-weight: normal;
		  	font-size: 10px;
	  	}
	}
}

.b-hotel-pricing__section.with-taxs {
	.b-hotel-pricing__total {
		border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
		padding-bottom: 12px;
	}
}

.b-hotel-pricing__room {
	margin: 12px 0;
	font-size: 15px;
	font-weight: bold;
}

.b-hotel-pricing__total {
	color: #fff;
	font-weight: 500;
	border-top: 1px dashed rgba(0, 0, 0, 0.1);
	padding-top: 12px;
}
