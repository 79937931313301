@import (reference) "~assets/css/imports";
@import '../styles';

.b-booking-hotels {
	.b-customer__header {
		padding: 12px;
		font-weight: 500;
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
		line-height: unset;
		border-radius: 3px 3px 0 0;
	}

	.b-customer__info {
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
		border-radius: 0 0 3px 3px;
		margin: 0;
		padding: 14px 16px 20px 16px;

		.l-grid-container {
			margin-bottom: 12px;
		}

		.l-grid-layout-33 {
			padding-right: 12px;
		}

		.b-input {
			padding: 0;
		}
	}

	.b-booking__footer {
		background: none;
		padding: 0;
		margin: 20px 0;
	}

	.b-booking__back,
	.b-booking__submit {
		font-weight: 500;
		padding: 9px 35px;
		height: unset;
	}
}

.b-booking-hotels__notes {
	background: #FFFFFF;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
	border-radius: 0 0 3px 3px;
	margin-bottom: 10px;
}

.b-booking-notes {
	padding: 13px 18px 26px 18px;

	&__caption {
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 18px;
	}

	.b-textarea__value {
		border: none;
		background: #FFFFFF;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
		border-radius: 0 0 3px 3px;
		color: #4A5767;
		padding: 12px 10px;
		min-height: 110px;
		resize: none;

		.placeholder(rgba(47, 56, 66, .5));
	}
}

.b-booking__two-selector-wrapper {
  padding: 10px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
  margin: 30px 0;
  border: 1px solid #f8f8f8;
}

@import "./style.mobile";
