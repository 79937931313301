.r-important-news {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 8px;

  .r-important-news-item__title {
		color: #099c9d;
		font-size: 16px;
		font-weight: 500;
  }

  .r-important-news-item__date {
		display: flex;
		flex-direction: row;
		gap: 16px;
		font-size: 12px;
		font-weight: 700;
    color: #4a5767;
  }

  .r-important-news-item__text {
    width: 100%;
    text-align: left;
  }
}