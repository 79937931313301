@import (reference) "~assets/css/imports";

.b-filters {
	width: auto;
	padding: 0 13px;
	.clearfix;
	font-size: @base-H1-size;
	color: #FFFFFF;
	border: @field2-border;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
}

.b-filters {
	.b-filters__leg,
	.b-filters__train_leg {
		padding: 0 12px;

		.b-range {
			margin-bottom: 15px;
			&__input {
				margin: 0 35px 0 24px;
			}
		}
		.b-filters__line {
			margin-top: 0;
			border-bottom: 1px solid #2F3842;

			&:last-child {
				border-bottom: 0;
			}
		}
		.b-filters__block.b-selection {
			border-top: 0;
			padding: 0;
		}
		&-first {
			background: rgba(0, 0, 0, 0.1);
			margin: 0;
		}
		&-last {
			margin: 0;
		}
	}

	.b-filters__leg + .b-filters__select {
		.b-filters__line  {
			border-bottom: 1px solid #2F3842;

			&:last-child {
				border-bottom: 0;
			}
		}
		.b-filters__block.b-selection {
			border-top: 0;
			padding: 0;
		}
	}
}

.b-filters__line {
	//.clearfix;
	margin: 10px 0 15px;
}

.b-filters__block {
	border: 1px solid @field2-elements-color;
	.rounded;
	padding: 10px 0;
}

.b-filters__range, .b-filters__select, .b-filters__common {
	border: 0;
	margin-bottom: 0;
	margin-top: 0;
	padding-top: 0;
}

.b-filters__mobile-common-filters-title {
  display: none;
}

.b-filters__select {
	.b-filters__block {
		padding-bottom: 0;
	}
}

.b-filters__input, .b-filters__common.b-filters__block {
	.b-filters__line .b-input {
		padding-top: 2px;
		padding-bottom: 0;
	}

	.b-filters__line:last-child {
		padding-bottom: 0;
		border-bottom: 0;
		border-top: 1px solid #2F3842;
	}
}

.b-filters__input .b-input__value,
.b-filters__common .b-input__value,
.b-filters__leg .b-input__value {
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	color: #fff;
	background-color: #4A5767;
	border: 1px solid #A4ABB3;
	border-radius: 3px;
	padding: 11px;
	outline: none;
	box-sizing: border-box;

	&[disabled] {
		color: #aaa;
		&::placeholder {
			color: #aaa;
		}
	}
	&::placeholder {
		color: #fff;
	}
}

.b-filters__line.b-filters__toggles {
	.b-filters__line {
		margin: 6px 0;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.b-filters__range, .b-filters__common {

	.b-range__input {
		margin: 0 20px 0 10px;
		padding: 0 15px;
	}
	.b-filters__line {
		border-top: 1px solid @field2-elements-color;
		border-radius: 0;
		padding-top: 8px;
	}
}

.b-filters__block.b-selection {
	border: 0;
	margin: 0;
	border-top: 1px solid @field2-elements-color;
	border-radius: 0;
}

.b-filters__block .b-filters__line:first-child {
	margin-top: 0;
}

.b-filters__block .b-filters__line:last-child {
	margin-bottom: 0;
	border-bottom: 1px solid #2F3842;
	padding-bottom: 15px;
}

.b-filters__leg {
	display: none;
}

.b-filters__train_leg {
	display:none;
	padding-bottom: 10px;
}

.b-filters__leg .b-selection__value .b-checkbox__view,
.b-filters__train_leg .b-selection__value .b-checkbox__view {
	width: 130px;
}

.b-filters__line-title {
	font-weight: normal;
	margin: 7px 0 10px 0;
	color: @field2-H1-color;
	font-size: 15px;
}

.b-filters__mobile-buttons {
	display: none;
}

.b-filters__icon-baggage {
	.g-icon-option-baggage-FREE;
	background-position: 100% 50%;
	background-size: 24px 24px;
}

.b-filters__icon-exchange {
	.g-icon-option-exchange-FREE;
	background-position: 100% 50%;
	background-size: 24px 24px;
}

.b-filters__icon-refund {
	.g-icon-option-refund-FREE;
	background-position: 100% 50%;
	background-size: 24px 24px;
}

.b-filter-empty-result {
	.rounded;
	background: @field1-background-color;
	padding: 20px;
	font-size: @base-H1-size;
	color: @field1-H1-color;
	display: none;
}

.b-filters__drop-filters-button {
	.rounded-bottom;
	background: @field2-font-color;
	color: @button2-font-color;
	font-size: @base-H1-size;
	text-align: center;
	height: 40px;
	border: @button2-color-border;
	line-height: 40px;
	width: auto;
	margin-top: -2px;
	white-space: nowrap;
	display: block;
	cursor: pointer;
	.transition-standard;

	&:hover {
		background: @button2-color-act;
		color: @button2-font-color-act;
	}

	&:active {
		background: @button2-color-act;
		color: @button2-font-color-act;
	}

}

.b-filters__drop-filters.fixed {
	position: fixed;
	bottom: 0;
	width: 283px;
	z-index: 2;
}

@media @tablet {

	.b-filters__drop-filters {
		display: none;
	}

	.b-filters__trigger {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 40px;
		height: 40px;
		display: block;
		.rounded-top-right;
		.g-icon;
		background-image: @header-background-color url("~assets/img/icon-filters.svg");
		background-size: 20px 20px;
		.transition-fast;
	}

	.b-filters__open {

		.b-filters__trigger {
			left: -50px;
		}

		.l-sidebar {
			padding-top: 60px;
			width: 100%;
			top: 0;
			height: 100%;
			overflow: auto;
			position: fixed;
			padding-bottom: 40px;
			left: 0;
			z-index: 10;
			background: @field2-background-color;
			margin: 0;
		}

		.l-content {
			position: fixed;
			z-index: 1;
		}

		.l-block__back {
			background: none;
			padding: 0;
			.box-shadow(none);
		}

		.b-filters__mobile-buttons {
			display: block;
			z-index: 100;
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
		}

	}

	.b-filters__mobile-reset, .b-filters__mobile-apply {
		text-transform: uppercase;
		height: 35px;
		line-height: 35px;
		padding: 0 10px;
	}

	.b-filters__mobile-reset {
		float: left;
		background: @field1-background-color;
		color: @field1-H1-color;

		&:after {
			.g-icon-cancel;
			content: " ";
			display: inline-block;
			width: 13px;
			height: 13px;
			margin-left: 5px;

		}
	}

	.b-filters__mobile-apply {
		float: right;
		background: @field2-active-color;
		color: @field2-H2-color;

		&:before {
			content: " ";
			display: inline-block;
			width: 16px;
			height: 16px;
			margin-right: 5px;
		}
	}

}

.b-filters__travel_policy {
	.b-selection__value-price {
		display: none;
	}

	.b-selection__value {
		padding-right: 0;
		.b-checkbox__view {
			width: 100%;
		}
	}
}

@import "style.mobile";
