@media @tablet {
    .b {
        &-header {

            &__logo {

            }

			&__additional-logo {
			  width: 3em;
			}

            &__info {
                padding: 0;
                justify-content: flex-end;
			  	// background: #d70000;
			  	// color: #fff;

			  	&::before {
				  // border-right-color: #d70000;
				}

			  	&-account {

				  	&::after {
					  	border-right-color: #fff;
					}
				}

                &-currency-text {
                    font-size: 18px;
                    line-height: 18px;
                }

                &-currency-text_color {

                }

			  	&-button {
				  	content: '';
				  	display: block;
				  	width: 50px;
				  	height: 50px;
				  	cursor: pointer;
				  	flex-grow: initial;
				}

			  	&-search-button {
				  	background: url(~assets/img/icon-top-search.svg) no-repeat center;
				}

                &-menu-button {
                    background: url(~assets/img/icon-top-menu.svg);
                }

            }
        }
    }

    .show-menu .b-header__info-menu-button {
        background: url(~assets/img/icon-top-menu-close.svg);
    }

  	.show-menu .l-layout__header .b-header {

	&__info {
	  background-color: #4A5767;

	  &::before {
		background: #4A5767;
		border-right-color: transparent;
	  }

	  &-wrapper {
		display: none;
	  }

	  &-search-button {
		display: none;
	  }
	}
  }

  	.show-menu .b-header__info-language .p-login__header-language-select-img {
	  background: #fff;
	}
}

@media (max-width: 1279px) {
  	.show-menu .b-header__info-menu-button {
		background: url(~assets/img/icon-top-menu-close-black.svg) no-repeat center;
  	}

  	.b-header__info-menu-button {
	  	width: 50px;
	  	height: 50px;
	  	cursor: pointer;
		background: url(~assets/img/icon-top-menu-black.svg) no-repeat center;
  	}

  	.b-header__info {
		&-account, &-currency, &-language {
		  &-text {
			font-size: 12px;
		  }
		}
  	}
}
