@import (reference) "~assets/css/imports";

.b-airlines {
	font-size: @base-H1-size;
	position: relative;
	display: block;
  	width: 100%;

}

.active .b-airlines__label {
	color: @field2-active-color;
}

.b-airlines__wrapper {
	display: flex;
	align-items: center;
}

.b-airlines__label {
	white-space: nowrap;
	position: relative;
	cursor: pointer;
	font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #fff;
    background-color: #4A5767;
	border: 1px solid #A4ABB3;
	box-sizing: border-box;
    display: flex;
    align-items: center;
	padding-left: 8px;
	height: 30px;
	border-radius: 3px 0 0 3px;
	width: 100%;
}

.b-airlines__select {
	font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #fff;
    background-color: #4A5767;
    border: 1px solid #A4ABB3;
    border-left: none;
	border-radius: 0 3px 3px 0;
	height: 30px;
    box-sizing: border-box;
    display: flex;
	align-items: center;
	&-img {
		background: url('~assets/img/icon-arrow-big-down.svg') no-repeat center;
		width: 14px;
		padding: 13px;
	}
}


.open.b-airlines:before {
	.g-icon-arrow-up_w;
}

.b-airlines__wrapper {
	width: 100%;
}

.b-airlines__input {
	background: transparent;
	width: 100%;
	height: 40px;
	line-height: 40px;
	color: @field2-active-color;
	overflow: hidden;
	float: left;
	text-overflow: ellipsis;
	.placeholder(@field2-object-color);
	border: 1px solid @field2-object-color;
	.rounded;
	cursor: pointer;
	.clearfix;
	outline: none;
	padding-left: 8px;
}

.b-airlines__hint {
	color: @field2-font-color;
	opacity: 0.5 !important;
}

.b-airlines__items {
	background: @field2-background-color;
	padding: 0 0;
	margin-top: 1px;
	.rounded-bottom;
	.shadow;
	min-width: 265px;
	left: auto !important;
	right: 0;
	max-height: 240px;
	overflow: auto;
	width: 100%;
}

.b-airlines__item {
	.clearfix;
	line-height: 40px;
	padding: 0 10px;
	position: relative;
	cursor: pointer;
	&:hover {
		background-color: #2F3842;
	}
}

.b-airlines__item_selected {
	.clearfix;
	color: @field2-active-color;
	line-height: 40px;
	padding: 0 30px 0 10px;
	position: relative;
	display: block;
	height: 40px;
	width: 100%;
	box-sizing: border-box;
}

.b-airlines__item_selected_text {
	width: 220px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	display: inline-block;
	position: relative;
}

.b-airlines__item_selected:after, .b-airlines__item:after {
	content: ' ';
	background: @field2-elements-color;
	height: 1px;
	left: 3px;
	right: 3px;
	bottom: -1px;
	display: block;
	position: absolute;
}

.open .b-airlines__select-img {
	transform: rotate(180deg);
}

.b-airlines__cursor {
	background: @field2-elements-color;
	color: @base-hover-color !important;
}
.b-airlines__cursor .b-airlines__item-city {
  color: @base-hover-color;
}

.b-airlines__cursor.b-airlines__item:after {
	display: none;
}

.b-airlines__item-city {
	overflow: hidden;
	display: block;
	text-overflow: ellipsis;
	white-space: nowrap;
  	color: @field2-font-color;

  &:hover {
	color: @base-hover-color;
  }
}

.b-search-form__params {

  &:hover {
	color: @base-hover-color;
  }

}

.b-airlines__item-code {
	position: absolute;
	right: 0;
	float: right;
	margin-right: 10px;
	padding-left: 15px;
	.g-icon-cancel;
	height: 14px;
	margin-top: 13px;
	cursor: pointer;
}

.b-airlines.validation-error {

	.b-airlines__input {
		.placeholder(@field2-warning-color);
		color: @field2-warning-color;
	}
}



.b-airlines__popup {
	background: @field2-background-color;
	position: absolute;
	top: 100%;
	z-index: 100;
	display: none;
	min-width: 265px;
	width: 100%;
}

