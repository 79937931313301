.p-login__header-language-select-img {
  background-image: url("~assets/img/icon-arrow-big-down.svg");
}

.p-login__header-language.opened {
  background: #4a5767;

  .p-login__header-language-dropdown {
	display: flex;
  }

  .p-login__header-language-select-img {
	background-image: url("~assets/img/icon-arrow-big-up.svg");
  }

  .p-login__header-language-dropdown-point {
	padding: 0;
  }

}
