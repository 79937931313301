@import (reference) "~assets/css/imports";

.b-carriage-maps__wrapper.without-arrows {
	.b-train__carriage-map__car-number {
		display: none;
	}
	.b-carriage-maps__container .b-carriage-maps__arrow {
		display: none;
	}
}

.b-train__carriage-map {
	background: #FFFFFF;
	text-align: center;

	&__car-number {
		font-weight: 500;
		font-size: 14px;
		text-transform: uppercase;
		padding: 0 0 10px 135px;
		text-align: left;
		line-height: 30px;
	}

	.place {
		cursor: pointer;
	}

	.place.readonly {
		cursor: default;
		pointer-events: none;
	}

  	rect, path {
	  &.place {
	  	stroke: #099C9D;
	  	opacity: 1;
	  	stroke-width: 2px;
	  }
  	}

	rect, path {
	  &.place.selected {
		fill: #4A5767;
		stroke: #4A5767;
		opacity: 1;
	  }
	}

	text.place.selected,
  text.place.selected tspan {
		fill: #FFFFFF;
	}

	.place.unavailable {
    	cursor: initial;
	}

	text.unavailable,
  text.unavailable tspan {
		fill: #CCCCCC;
	}

	text.place-sold_out,
  text.place-sold_out tspan {
		fill: #CCCCCC;
	}

	/* GENDER */

	rect, path, .st1, .st2, .st3, .st4, .st5, .st6 {
	  &.place.unavailable.MALE,
	  &.place.unavailable.FEMALE,
	  &.place.unavailable.MIX {
		stroke: none;
	  }
	}

	text.place.unavailable.MALE,
	text.place.unavailable.FEMALE,
	text.place.unavailable.MIX {
		stroke: none;
		fill: #FFFFFF;
	}

	rect, path, .st1, .st2, .st3, .st4, .st5, .st6 {
	  &.place.NOT_SELECTED:not(.selected) {
		stroke: none;
		fill: rgba(9, 156, 157, 0.5);
	  }
	}

	text.place.NOT_SELECTED:not(.selected),
	text.place.NOT_SELECTED:not(.selected):not(.MALE),
	text.place.NOT_SELECTED:not(.selected):not(.FEMALE),
	text.place.NOT_SELECTED:not(.selected):not(.MIX) {
		fill: #fff;
	}


	rect, path, .st1, .st2, .st3, .st4, .st5, .st6 {
	  &.place.FEMALE,
	  &.place.NOT_SELECTED.FEMALE {
		stroke: #D70000;
		fill: rgba(215, 0, 0, 0.15);
	  }
	}

	rect, path, .st1, .st2, .st3, .st4, .st5, .st6 {
	  &.place.selected.FEMALE {
		stroke: #D70000;
		fill: #D70000;
	  }
	}

	rect, path, .st1, .st2, .st3, .st4, .st5, .st6 {
	  &.place.MALE,
	  &.place.NOT_SELECTED.MALE {
		stroke: #4882BD;
		fill: rgba(72, 130, 189, 0.15);
	  }
	}

	rect, path, .st1, .st2, .st3, .st4, .st5, .st6 {
	  &.place.MALE.selected {
		fill: #4882BD;
	  }
	}


	rect, path, .st1, .st2, .st3, .st4, .st5, .st6 {
	  &.place.MIX,
	  &.place.NOT_SELECTED.MIX {
		stroke: #FFCE3C;
		fill: rgba(255, 206, 60, 0.15);
	  }
	}

	rect, path, .st1, .st2, .st3, .st4, .st5, .st6 {
	  &.place.MIX.selected {
		fill: #FFCE3C;
	  }
	}

	rect, path, .st1, .st2, .st3, .st4, .st5, .st6 {
	  &.place.hovered {
		stroke: rgba(0, 0, 0, 0.2) !important;
		fill: #099C9D;
		opacity: 1;
	  }
	}

	text.place.hovered {
		stroke: none;
		fill: #FFFFFF !important;
	}

  	.inmob-custom-styles {
	  & > path {
		stroke-width: 4px !important;
	  }
	  & > text {
		font-weight: bold;
		fill-opacity: 1;
	  }
	  & > text:not(.place) {
		fill: #d7d7d7;
	  }
	}

	/* EOF GENDER */
}
