@media @tablet {
  .l-layout.show-filters {

	.b-filters-hotels {

	  .b-filters__drop-apply-filters-buttons.fixed {
		width: 100%;
		display: flex;

		& > div:not(:only-child) {
		  width: 50%;
		}
	  }

	  .b-filters__drop-filters-button {
		order: 0;
		background: #EDEEF0;
        color: #D70000;
		.border-radius(~'4px 0px 0px 4px');
	  }

	  .b-filters-hotels__apply {
		order: 1;
		background: #099C9D;
		.border-radius(~'0px 4px 4px 0px');
	  }

	  .b-selection__value {

		.b-checkbox__view:before {
		  border-color: #099C9D;
		}


		.b-checkbox .b-checkbox__input:disabled {
		  + .b-checkbox__view {
			&:before {
			  background: none;
			  border-color: #099C9D;
			}
			&:after {
			  background: #099C9D;
			}
		  }
		}
	  }

	  &__class {
		.b-hotel-star {
		  background: url('~assets/img/hotels/icon-star.svg') center -1px no-repeat;
		}
	  }

	  .b-filters-hotels__categories {

		.b-categories__label {
		  color: #4a5767;
		}

		.b-categories__selected {
		  color: #099C9D;
		}

		.b-categories__drop-down-item {
		  color: #099C9D;
		}
	  }

	  .b-filters-hotels__distance,
	  .b-filters-hotels__price {
		.noUi-lower-value,
		.noUi-lower-upper {
		  white-space: nowrap;
		  width: unset;
		  font-size: 13px;
		  padding: 3px;
		  margin-bottom: 7px;
		}

		.noUi-lower-value {
		  top: unset;
		  bottom: 100%;
		  margin-top: 0;
		  left: 0;
		  right: unset;
		}

		.noUi-lower-upper {
		  right: 0;
		  left: unset;
		}

		.b-range {
		  padding-bottom: 18px;
		}

		.b-range__input {
		  margin: 0 20px 0 12px;
		}

		.b-filters__range-input-container {
		  display: flex;
		  align-items: center;
		  justify-content: space-between;
		  font-size: 13px;
		  margin: 0 10px 12px 12px;
		}

		.b-filters__range-input {

		  .b-input__value {
			border: 1px solid #099C9D;
			color: #4a5767;

			&:focus {
			  color: #099C9D;
			}
		  }
		}
	  }
	}
  }
}
