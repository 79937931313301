@import (reference) "~assets/css/imports";


.b-additional-services {
  box-shadow: 1px 1px 4px #F0F0F0;

  &__header {
	background: #F0F0F0;
	font-weight: 500;
	font-size: 13px;
	line-height: 15px;
	color: #4A5767;
	padding: 18px 9px;
  }

  &__content {
	padding: 23px 16px;
	flex-direction: column;
  }

  &__table-service {

	&__checkbox-container {
	  	display: flex;
	  	align-items: baseline;
	  	min-width: 300px;
	  	margin-right: 20px;
	}

	&__table-caption-container {
	  	display: flex;
	  	flex: 1 1;
	}

  }

  &__seat-services {
	display: none;
	margin-top: 10px;

	&__service {
	  display: flex;
	  flex-flow: column wrap;
	  padding-left: 20px;
	  margin: 15px 0;
	  flex: 1 1;

	  &-row {
		display: flex;
		flex-flow: row nowrap;
		align-items: baseline;
	  }

	  &-description {
		line-height: 16px;
		color: #808A94;
		padding-left: 32px;
	  }
	}

	&.open {
	  display: flex;
	  flex-flow: row wrap;
	}

	&-checkbox {
	  display: flex;
	  align-items: baseline;
	}
  }

  &__service {
	display: flex;
	white-space: nowrap;
	min-height: 50px;
	align-items: baseline;

	&-price {
	  font-weight: 500;
	  line-height: 16px;
	  display: flex;
	  align-items: center;
	  color: #4A5767;
	  margin-left: 10px;

	  > .currency {
		margin-left: 5px;
	  }
	}

	&.fc {
	  flex-direction: column;
	}
  }
}

@import 'style.mobile';
