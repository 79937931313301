@import (reference) "~assets/css/imports";

.p-cabinet-orders {
	margin-bottom: 30px;

	.b-block-table__cell-controls {
		display: none;
	}
	.b-block-container__header {
	    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
	}

	.b-block-container__element-dates {
		display: flex;
		flex-flow: row nowrap;
		position: relative;
		padding-top: 8px;
		max-width: 350px;

		.p-search__options-date-element {
			&:first-child {
				margin-right: 15px;

				.p-search__direction-date {
					position: relative;
					&:after {
						content: '';
						display: block;
						height: 0;
						width: 15px;
						top: 50%;
						right: -15px;
						position: absolute;
						border-top: 1px solid #A4ABB3;
					}
				}
			}
		}

		.p-search__options-date-label {
			position: absolute;
			padding: 0 4px;
			background: #fff;
			top: 0;
			left: 4px;
			z-index: 2;
		}

		.p-search__direction-date-input {
			height: 30px;
			padding: 0 11px;
		}

		.p-search__direction-date-calendar {
			background-color: unset;
			height: 30px;
			width: 30px;
			min-width: 30px;
		}

		.b-datepicker__icon {
			background-image: url("~assets/img/icon-calendar-black-1.svg");
		}
	}

	.b-input {
		padding-bottom: 0;
	}

	.b-company-autocomplete__wrapper {
		display: block !important;
	}

	.b-block-container__element {
		margin-right: 12px;
		flex: 1 1 20%;

		&:last-child,
		&:nth-child(4n) {
			margin-right: 0;
		}
	}

	.p-search__direction-date-input,
	.p-search__direction-date-calendar,
	.b-input__value {
		border-color: #4A5767;
	}

	.b-block-container .b-block-container__top-controls {
		background: #FFFFFF;
		border: 1px solid #A9A9A9;
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
		border-radius: 0 0 3px 3px;
		border-top: none;
		padding: 10px 20px 12px 12px;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		position: relative;
		z-index: 1;
	}

	.p-search__submit {
		width: 300px;
		height: 30px;
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
		border-radius: 3px;
		align-items: center;
		padding: 0;
		margin-left: auto;
		margin-top: 18px;
	}

	.b-block-container__content-orders {
		background: #FFFFFF;
		border: 1px solid #A9A9A9;
		box-sizing: border-box;
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
		border-radius: 3px;
		margin-top: -5px;
		padding-top: 5px;
		position: relative;
		overflow: hidden;
		z-index: 0;

		& > .b-block-container__content {
			padding: 0;
			border-top: 0;
		}
	}

	@media (min-width: 769px) {
	  .b-block-table__row:not(.b-block-table__row-header) {
		cursor: pointer;
		&:hover {
		  background: #c0d5ed !important;
		  .b-block-table__cell {
			background: #c0d5ed !important;
		  }
		}
	  }
	}

	.b-addition-orders__item-add {
		display: none;
	}

	.b-block-table__row-header {
		.b-block-table__cell[data-sort] {
			cursor: pointer;

			.b-block-table__label {
				position: relative;

				&:after {
					content: '';
					border: 4px solid;
					border-color: #4A5767 transparent transparent;
					position: absolute;
					right: -15px;
					display: none;
				}
			}

			&[data-order="ASC"], &:not([data-order]):hover, &[data-order="DESC"]:hover {
				.b-block-table__label:after {
					display: block;
					top: calc(~'50% - 6px');
					transform: rotate(180deg);
				}
			}

			&[data-order="DESC"], &[data-order="ASC"]:hover  {
				.b-block-table__label:after {
					display: block;
					top: calc(~'50% - 2px');
					transform: none;
				}
			}
		}
	}
}

@import "./style.mobile";
