@import (reference) "~assets/css/imports";

.b-search-employee {
	position: relative;

    .b-employee-autocomplete {
        padding-bottom: 0;
        .b-input__value {
            border-color: #A4ABB3;
            color: #fff;
            padding-right: 30px;
        }

        .b-input__remove {
        	background-image: url("~assets/img/icon-collection-delete-w.svg");
        	bottom: 0;
        }
    }	
}

.b-search-employee__remove {
	background-color: @field1-background-color;
	.g-icon-delete;
	display: block;
	background-size: 15px 15px;
	cursor: pointer;
	position: absolute;
	width: 30px;
	height: 36px;
	top: 50%;
	margin-top: -13px;
	right: 2px;
	padding: 0;
	z-index: 10;
}
