@media @tablet {

  	.b-input {
		padding-bottom: unset;
  	}

	.b-input__value {
		height: 38px;
	}

	.b-input__label, .b-input__value {
		font-size: 14px;
	}

	.b-input__remove {
		height: 38px;
		width: 38px;
	}

}
