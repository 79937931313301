@media (min-width: 1440px) {
	.b-hotel-travellers__wrapper {
		.b-traveller-info {
			padding: 14px 16px 20px 16px;
		}
	}
}

@media (min-width: 1920px) {
	.b-hotel-travellers__wrapper {
		.lg-visible-block {
			display: block;
		}
		.lg-hidden-block {
			display: none;
		}
	}
}

@media @tablet {
  .b-hotel-travellers__wrapper {
	.b-traveller {
	  .b-traveller-info {
		padding: 20px 12px;

		.flex-grid-container {
		  .flex-grid-layout {
			width: 100%;
			padding-right: 0;
		  }
		}
	  }
	}
  }
}
