@import (reference) "~assets/css/imports";

.b-transfer-travellers__footer {
  display: flex;
  margin: 10px auto;
  width: 100%;
  min-height: 50px;
  align-items: center;
  justify-content: center;
}

.b-transfer-travellers__add {
  display: flex;
  cursor: pointer;
  color: #099C9D;
  align-items: center;
  position: relative;
  width: 190px;
  height: 31px;
  padding: 0 12px;
  border: 1px solid #A9A9A9;
  border-radius: 4px;

  &:after {
	content: '';
	display: block;
	position: absolute;
	top: 8px;
	right: 13px;
	width: 15px;
	height: 15px;
	.g-icon-plus-green;
	cursor: pointer;
  }
}

@media @tablet {
  .b-transfer-travellers__add {
	color: #FF8D2E;
	border: none;

	&:after {
	  right: -7px;
	  width: 1em;
	  height: 1em;
	  background-image: unset;
	  .g-icon-add-orange;
	}
  }
}
