@import (reference) "~assets/css/imports";

*, *:before, *:after {
	box-sizing: border-box;
}

html, body {
	height: 100%;
	font-size: @base-font-size;
	font-family: @base-font;
	color: @field1-font-color;
	line-height: 1.22;
}

body.b-popup-overlay-fixed {
	overflow: hidden;
}

input, textarea, a {
	outline: none;
	.box-sizing(border-box);
}

a {
	text-decoration: none;
}

h1 {
	color: @field1-active-color;
	font-size: @base-H1-size;
	text-transform: uppercase;
	margin: 15px 0;
}

h2 {
	color: @field1-active-color;
	font-size: @base-H1-size;
	margin: 12px 0;
}

h3 {
	color: @field1-H1-color;
	font-size: @base-H1-size;
	margin: 10px 0;
}

p {
	margin: 10px 0;
}

input, textarea, select {
	.placeholder(@field1-object-color);
	outline: none;
}

.vertical-resize textarea{
  resize: vertical !important;
}


ul li {
	padding-left: 20px;
	position: relative;
}

ul li:before {
	content: "";
	position: absolute;
	width: 7px;
	height: 7px;
	display: block;
	border-radius: 50%;
}

button {
	cursor: pointer;
}

span.currency {
	font-family: @base-font-currency !important;
}

.clearfix{
  zoom: 1;
  &:before, &:after {
  	content: "";
  	display: table;
  }
  &:after { clear: both; }
}

.action-link {
	color: @field1-H2-color;
	border-bottom: 1px dashed @field2-background-color;
	cursor: pointer;


	&:hover {
		color: @field1-H2-color;
	}

	&--color-blue {
		display: inline-block;
		color: #4882BD;
		border-color: #4882BD;

		&:hover {
			color: #4882BD;
		}
	}
}

.nowrap {
  white-space: nowrap;
}

.max-width-unset {
  max-width: unset;
}

/* bootstrap control customize */

[data-toggle="collapse"] {
	cursor: pointer;
	position: relative;

	&.collapse--with-anchor,
	&.collapse--with-anchor--white {

		padding-right: 40px !important;

		&:after {
			content: '';
			position: absolute;
			right: 13px;
			width: 1em;
			height: .5em;
			top: calc(~'50% - 0.25em');
			transform: rotate(180deg);
			margin-top: -1px;
			.g-icon-arrow-down
		}

		&.collapsed {
			&:after {
				margin: 0;
				transform: none;
			}
		}
	}

  	&.collapse--with-anchor--white:after {
		.g-icon-arrow-down_w;
	  }

}

.tooltip {
  pointer-events: none;

  .tooltip-inner {
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
	}
	&__header {
		padding: 3px 0 13px;
		text-align: left;
		color: rgba(255, 255, 255, 0.8);
	}
	&__body {
		color: #fff;
		text-align: left;
		& > div {
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	&__header + &__body:not(:empty) {
		border-top: 1px solid #2F3842;
		padding: 13px 0;
	}
}

/* EOF bootstrap control customize */

.dn {
  display: none !important;
}

/* button, button-icons */

.btn {
	background: #FFFFFF;
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 3px;
    height: 30px;
    color: #4A5767;
    font-weight: 500;
    font-size: 14px;
    padding-left: 26px;
    padding-right: 26px;
  	white-space: nowrap;

    &:focus {
	    outline: none;
    }
}

.btn.btn-white-gray {
	background-color: #FFFFFF!important;
	color: #4A5767!important;

	&:hover {
	  background-color: #4A5767!important;
	  color: #FFFFFF!important;
	}
}

.btn.btn-dark {
	background: #4A5767;
	color: #FFFFFF;
}

.btn.btn-green {
	background: #099C9D;
	color: #FFFFFF;
}

.btn.btn-alarm {
	background: #D70000;
	border: 1px solid #D70000;
	color: #FFFFFF;
}

.btn.btn-danger {
	color: @button-color;
}

.btn.btn-icon {
	position: relative;
	padding-right: 38px;
}

.btn-icon-plus:after {
	content: '';
	display: block;
	width: 15px;
	height: 15px;
	background: url('../img/icons/icon-button-plus.svg') no-repeat center;
	position: absolute;
	top: 7px;
	right: 12px;
}

.btn-icon-remove {
	padding-right: 0;
	padding-left: 44px;
}

.btn-icon-remove:after {
	content: '';
	display: block;
	width: 16px;
	height: 16px;
	background: url('../img/icons/icon-button-remove.svg') no-repeat center;
	position: absolute;
	top: 5px;
	left: 12px;
}

.btn.btn-disabled {
	background: rgba(0, 0, 0, 0.2);
	color: rgba(255, 255, 255, 0.2);
	border: 1px solid #CCCCCC;
	pointer-events: none;
}

.b-message {
	background: rgba(9, 156, 157, 0.15);
	color: #099C9D;
	font-weight: 500;
    border-radius: 3px;
    margin-left: 10px;
    line-height: 30px;
    padding: 0 10px;
}

.b-message.b-message-warning {
	background: rgba(255, 141, 46, 0.15);;
	color: #FF8D2E;
}
.b-message.b-message-error {
	background: rgba(215, 0, 0, 0.15);
    color: #D70000;
}


.icon-notification-arrow:after {
	content: '';
	display: block;
	width: 38px;
	height: 16px;
	background: url('../img/b-notification-arrow.png') no-repeat center;

}

.b-navigate-back-button {
  color: #4882BD;
  position: relative;
  display: flex;
  height: 40px;
  align-items: center;
  width: 100%;
  border: none;
  outline: none;

  &:before {
	content: " ";
	display: block;
	width: 50px;
	height: 18px;
	.g-icon-arrow-left-blue;
  }
}


.p-search__submit {
	background-color: #E50000;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
	padding: 5px 0;
	display: flex;
	justify-content: center;
	width: 100%;
	height: 31px;

	&-button {
		color: #fff;
		text-decoration: none;
	}
}

.collapse:not(.show) {
	display: none !important;
}

.b-notification {
	font-size: 14px;
  	position: relative;

	&--type-alert {
		padding-left: 25px;

	  &:before {
		content: '';
		background: url('~assets/img/icons/icon-notification-alert.svg') no-repeat center;
		width: 17px;
		height: 17px;
		display: block;
		position: absolute;
		left: 3px;
	  }
	}
}

.pos-rel {
	position: relative;
	display: block;
}

.text-align-center {
	text-align: center;
}

.text-align-right {
	text-align: right;
}

.d-ib {
	display: inline-block;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0)
}

/* EOF button, button-icons */

.grid-container {
  display: grid;

  &.col-2 {
	grid-template-columns: max-content max-content;
  }
  &.col-3 {
	grid-template-columns: max-content max-content max-content;
  }
}

/* autocomplete-common-styles */

@media (min-width: 769px) {
  .desktop-hide {
	display: none !important;
  }
}

@media @tablet {
  .mobile-hide {
	display: none !important;
  }
}

.b, .b-company, .b-email, .b-employee, .b-passenger, .b-finance, .b-geocoder, .b-booking-form-contacts {
  &-autocomplete {

	&__wrapper {
	  width: 100%;
	  display: block !important;
	}

	&__items {
	  background: @field2-background-color;
	  padding: 0 0;
	  margin-top: 1px;
	  .rounded-bottom;
	  .shadow;
	  min-width: 265px;
	  width: 100%;
	  max-height: 230px;
	  overflow-y: auto;
		z-index: 101 !important;
	}

	&__item {
	  .clearfix;
	  height: 42px;
	  line-height: 40px;
	  z-index: 150 !important;
	  padding: 0 10px;
	  text-align: left;
	  position: relative;
	  cursor: pointer;

	  &:after {
		content: ' ';
		background: @field2-elements-color;
		height: 1px;
		left: 0;
		right: 0;
		bottom: -1px;
		display: block;
		position: absolute;
	  }

	  &:last-child:after {
		display: none;
	  }

	  &:hover {
		background: #2F3842;
	  }
	}

	&__item-caption {
	  color: #fff;
	  overflow: hidden;
	  display: block;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	}

	&.validation-error {
	  .b, .b-company, .b-email, .b-employee, .b-passenger, .b-finance, .b-geocoder {
		&-autocomplete {
		  &__input {
			.placeholder(@field1-warning-color);
			color: @field1-warning-color;
		  }
		}
	  }
	}
  }
}

/* EOF autocomplete-common-styles */

/* multiple-select-common-styles */

.b-multiple-select, .b-finance-select, .b-airlines-input {
	font-size: 12px;
	position: relative;
	display: block;
	width: 100%;

  .active &__label {
	color: @field1-H1-color;
  }

  &__wrapper {
	display: flex;
	align-items: center;
  }

  &__label {
	white-space: nowrap;
	position: relative;
	color: @field1-H1-color;
	cursor: pointer;
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	background-color: @field1-background-color;
	border: 1px solid #A4ABB3;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	padding-left: 8px;
	padding-right: 10px;
	height: 30px;
	border-radius: 3px 0 0 3px;
	width: 100%;
	overflow-x: hidden;
	text-overflow: ellipsis;
  }

  &__select {
	font-family: 'Roboto', sans-serif;
	font-size: 12px;
	color: #fff;
	background-color: @field1-background-color;
	border: 1px solid #A4ABB3;
	border-left: none;
	border-radius: 0 3px 3px 0;
	height: 30px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	&-img {
	  background: url('~assets/img/icon-arrow-big-down_black.svg') no-repeat center;
	  width: 14px;
	  padding: 13px;
	}
  }

  .open&:before {
	.g-icon-arrow-up;
  }

  &__wrapper {
	width: 100%;
  }

  &__input {
	background: transparent;
	width: 100%;
	height: 40px;
	line-height: 40px;
	color: @field2-H1-color;
	overflow: hidden;
	float: left;
	text-overflow: ellipsis;
	.placeholder(#A4ABB3);
	border: 1px solid @field2-object-color;
	.rounded;
	cursor: pointer;
	.clearfix;
	outline: none;
	padding-left: 8px;
  }

  &__hint {
	color: @field2-font-color;
	opacity: 0.5 !important;
  }

  &__items {
	background: @field2-background-color;
	padding: 0 0;
	margin-top: 1px;
	.rounded-bottom;
	.shadow;
	min-width: 265px;
	left: auto !important;
	right: 0;
	max-height: 240px;
	overflow: auto;
	width: 100%;
  }

  &__item {
	.clearfix;
	line-height: 40px;
	padding: 0 10px;
	position: relative;
	cursor: pointer;
	&:hover {
	  background-color: #2F3842;
	}
	&.dn {
	  display: none;
	}
  }

  &__item_selected {
	.clearfix;
	color: @field2-active-color;
	line-height: 40px;
	padding: 0 30px 0 10px;
	position: relative;
	display: block;
	height: 40px;
	width: 265px;
	width: 100%;
	box-sizing: border-box;
  }

  &__item_selected_text {
	width: 220px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	display: inline-block;
	position: relative;
  }

  &__item_selected:after, &__item:after {
	content: ' ';
	background: @field2-elements-color;
	height: 1px;
	left: 0;
	right: 0;
	bottom: -1px;
	display: block;
	position: absolute;
  }

  &__item:last-child:after {
	display: none;
  }

  .open &__select-img {
	transform: rotate(180deg);
  }

  &__cursor {
	background: @field2-elements-color;
	color: @base-hover-color !important;
  }
  &__cursor &__item-city {
	color: @base-hover-color;
  }

  &__cursor&__item:after {
	display: none;
  }

  &__item-city {
	overflow: hidden;
	display: block;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: @field2-font-color;

	&:hover {
	  color: @base-hover-color;
	}
  }

  &__item-code {
	width: 8px;
	position: absolute;
	right: 0;
	float: right;
	margin-right: 10px;
	padding-left: 15px;
	.g-icon-w-cancel;
	height: 8px;
	margin-top: 13px;
	cursor: pointer;
  }

  &.validation-error &__input {
	.placeholder(@field2-warning-color);
	color: @field2-warning-color;
  }

  &__popup {
	background: @field2-background-color;
	position: absolute;
	top: 100%;
	z-index: 100;
	display: none;
	min-width: 265px;
	width: 100%;
  }

}

/* EOF multiple-select-common-styles*/

.scroll-top-button {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 35px;
  bottom: 95px;
  height: 50px;
  width: 50px;
  z-index: 999;
  opacity: .7;
  background: #4882BD;
  border: none;
  .g-icon-arrow-up_w;
  background-size: 40% 40%;
  box-shadow: 6px 6px 14px rgba(0,0,0,20%);
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  padding: 15px 30px 15px 20px;
  cursor: pointer;
}

@media (min-width: 769px) {
  .desktop-hide {
	display: none !important;
  }
}

@media @tablet {
  .mobile-hide {
	display: none !important;
  }
}

.content-blur > * {
  filter: blur(3px);
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.copy-content {
  display: none !important;
}

.copy-btn {
  color: #6E7985;
  border-color: rgba(164, 171, 179, 0.5) !important;
  padding: 0 7px;
}
