@import (reference) "~assets/css/imports";

.b-cabinet-collection {
	.b-cabinet-collection__content {
		padding-top: 11px;
	}
}

.b-cabinet-collection__header {
	height: 40px;
	border-left: @field1-border;
	border-right: @field1-border;
	border-bottom: @field1-border;
	font-size: @base-H1-size;
	text-transform: uppercase;
	color: @field2-H2-color;
	line-height: 40px;
	padding: 0 15px;
	position: relative;
}

.b-cabinet-collection__add {
	.g-icon-add-collection;
	.rounded;
	cursor: pointer;
	display: block;
	background-size: 14px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 30px;
	height: 30px;
	line-height: 30px;
	border: 1px solid #A4ABB3;
}

.b-cabinet-collection:first-child .b-cabinet-collection__header {
	height: 50px;
	line-height: 52px;
}

.b-cabinet-collection__block {
	border-left: @field1-border;
	border-right: @field1-border;
	background: @field1-background-color;
	.rounded;
	.shadow;
	.clearfix;
	padding: 0 10px;
}

.b-cabinet-collection__separator {
	margin: 10px 0;
	height: 1px;
	display: block;
	overflow: hidden;
	background: @field1-elements-color;
}

.b-cabinet-collection-small {

	.b-cabinet-collection__add {
		top: 10px;
		position: relative;
		margin-bottom: 10px;
	}

	.b-cabinet-collection__content {
		position: relative;
	}

	.b-input__label + .b-cabinet-collection__add {
		margin-left: 0;
	}

}

@import "./style.mobile";