@media @tablet {
  .b-cabinet-wrapper {
	align-items: initial;
	margin: 0;
	max-width: initial;
	padding: 16px 12px 0;

	.b-cabinet {
	  border-radius: 0;
	  padding: 0;

	  &__title {
		display: none;
	  }

	  .w-sections {
		&__section {
		  justify-content: left;
		  background-color: transparent;
		  padding: 10px 12px;
		  width: 100%;
		  border-radius: 3px;
		  margin-bottom: 12px;
		  font-size: 18px;
		  font-weight: normal;

		  &.w-sections__section_current {
			color: #fff;
		  }

		  &.search-section {
			display: none;
		  }
		}

		&__section-icon {
		  display: block;
		  width: 20px;
		  height: 20px;
		}

		&__section-name {
		  margin-left: 10px;
		  font-size: 14px;
		}
	  }

	  &__action-button {
		border-radius: unset;
		justify-content: left;
		max-width: initial;
		padding: 0;
		height: 36px;
	  }

	  &__action-icon {
		display: block;
		width: 20px;
		margin-right: 10px;
		margin-left: 12px;
	  }

	  &__action-text {
		font-size: 14px;
		opacity: 1;
		margin:0;
		line-height: 36px;
	  }

	  &__additional-client,
	  &__additional-order,
	  &__language,
	  &__profile,
	  &__info-currency {
		display: flex;
		height: 40px;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;
		padding: 0 12px;
	  }

	  &__info-currency {
		flex-direction: column;
		align-items: flex-start;
	  }

	  &__login-container {
		padding: 0 20px;
		height: 30vh;
		position: relative;

		.p-login__wrapper {
		  background-color: transparent;
		}

		.p-login {
		  width: 100%;
		}

		.p-login__form {
		  position: static;
		}

		.p-login__form-button-container {
		  padding-left: unset;
		  margin-top: 30px;

		  .p-login__form-button-link {
			font-size: 18px;
			color: #4882BD;
		  }
		}

		.p-login__button-container {
		  display: none;
		}
	  }

	  .validations-errors__container {
		bottom: -30px;
		color: #e50000;
		background-color: unset;
	  }

	  .validation-error.p-login__form-block {
		margin-bottom: 63px;
	  }

	  .p-login__form-block {
		margin-bottom: 43px;
	  }
	}

	.b-cabinet__action-button-container {
	  padding: 0 20px;

	  &.static-flow {
		position: static;
		padding: 0;
		margin-bottom: 20px;
	  }

	  &.is-b2c .b-cabinet__action-button {
		.border-radius(4px);
		height: 50px;
		align-items: center;
		border: none;
		background-color: #4882bd;
		justify-content: center;

		& > p {
		  font-size: 18px;
		}
	  }
	}
  }
}

