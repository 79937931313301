@media @tablet {
  .p-cabinet-employees {
	.b-block-container {
	  &__header-label:before {
		background: url("~assets/img/icons/icon-users-circle.svg") center no-repeat;
		background-size: 100% 100%;
	  }
	  &__element {
		&--passenger-add {
		  margin-top: 20px;
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  background: #FAFAFA;
		}
	  }
	}
	.b-block-table {
	  &__cell {
		&-fullName {
		  .b-block-table__cell-value {
			display: flex;
			gap: 5px;
		  }
		}
	  }
	}
  }
}

@media (min-width: 1440px) {
	.p-cabinet-employees {
		.p-search__submit {
			width: 355px;
		}
	}
}

@media (min-width: 1920px) {
	.p-cabinet-employees {
		.b-block-container__element {
			flex: 1 1 20%;

			&:nth-child(3n) {
				margin-right: 12px;
			}
		}
	}
}
