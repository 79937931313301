@media @tablet {
	.b-attachment {
		&-avia {
			.b-search-form__param-classes:before {
				display: none !important;
			}
			&__wrapper {
				flex-direction: column;
			}
			&__group {
				display: flex;
				align-items: start;
			}
			&__additional-params-header {
				color: #ECECEC;
				padding: 12px 10px;
			}
			&__group-airlines {
				background-color: #434D5B;
				border-radius: 3px;
				margin-right: 10px;
				color: #FFFFFF;
				width: 100%;
	
			 
				&--container {
					align-items: flex-end;
					.b-search-form__param-flight-number {
						flex-grow: 1;
						max-width: 33%;
					}
					.b-search-form__param-airlines {
						flex-grow: 1;
						max-width: 33%;
					}

					.w-timerange__input {
						height: 30px;
						padding: 10px;
						background: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff) !important;
						background-size: 1px 50%, 1px 50% !important;
						background-position: bottom left, bottom right !important;
						background-repeat: no-repeat !important;
						border: none !important;
						border-bottom: 1px solid #fff !important;
						color: #fff !important;
					}
					.w-timerange__select {
						width: 20px;
						margin-left: -21px;
						border: none;
						border-bottom: 1px solid #fff;
						background-color: #434D5B;
						.w-timerange__select-img {
							width: 100%;
							height: 100%;
						}
					}
					.w-timerange__wrapper .w-timerange__select {
						right: 20px;
					}
				}
			}
			&__group-checkboxes {
				background-color: #434D5B;
				border-radius: 3px;
				padding: 18px 16px;
				width: 100%;
				display: flex;
				flex-direction: column;
			   
				& .b-toggle__label {
					 color: #FFFFFF;
				}
				&--container {
					display: flex;
					margin-bottom: 15px;
					& > span {
						width: 50%;
						margin: 0;
					}
					.b-search-form__param-direct {
						margin-top: 0 !important;
					}
					
				}
			}
		}

	}
	
}

@media (max-width: 977px) {
	.b-attachment {
		&-avia {
			&__group {
				display: flex;
				align-items: start;
			}
			&__additional-params-header {
				color: #ECECEC;
				padding: 12px 10px;
			}
			&__group-airlines {
				background-color: #434D5B;
				border-radius: 3px;
				margin-right: 10px;
				color: #FFFFFF;

				  
				&--container {
					display: flex;
					.b-search-form__param-flight-number {
						flex-grow: 1;
					}
				
					.p-search__options-time {
						flex-grow: 2;
					}

					.b-input__select__remove {
						background: #434D5B url("~assets/img/icon-clear.svg") no-repeat center;
						height: 21px;
						right: 1px;
					}


				
					.w-timerange__input {
						width: 80px;
						height: 30px;
						padding: 10px;
						background: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
						background-size: 1px 50%, 1px 50%;
						background-position: bottom left, bottom right;
						background-repeat: no-repeat;
						border: none;
						border-bottom: 1px solid #fff;
					}
					.w-timerange__select {
						width: 20px;
						margin-left: -21px;
						border: none;
						border-bottom: 1px solid #fff;
						background-color: #434D5B;
						.w-timerange__select-img {
							width: 100%;
							height: 100%;
						}
					}
			}
				
			}
		}
	}
}

@media (max-width: 400px){
	.b-attachment {
		&-avia {
			
			&__group-airlines {
	 
				&--container {
					flex-direction: column;
					align-items: flex-start;
					.b-search-form__param-flight-number {
						max-width: 100%;
					}
					.b-search-form__param-airlines {
						max-width: 100%;
					}
					.p-search__options-time {
						width: 100%;
						margin-top: 20px;
					}
					
				}
			}
			&__group-checkboxes {
				&--container {
					flex-direction: column;
					margin-bottom: 0;
					& > span {
						width: 100%;
						margin-bottom: 15px;
					}
					.b-search-form__param-direct {
						margin-top: 0 !important;
					}
					
				}
			}
		}

	}
	
}
