@import "imports";
@import "layout";


#railways_app {

	.b-search-form {
		top: 0;
		bottom: 0;
		position: relative;
	}

	.l-page {
		padding: 0;
	}

	.l-main-container {
		min-height: 500px;
		max-width: 952px;
		width: 100%;
	}

	.b-search-form__wrapper2 {
		padding: 0;
	}

	.b-search-form__wrapper3 {
		width: 100%;
	}

	.l-wrapper {
		width: 100%;
	}

	.b-destination__to {
		width: 45%;
	}

}


