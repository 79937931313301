@import (reference) "~assets/css/imports";

.b-numerable-picker {
	font-size: @base-H1-size;
	height: 30px;
	margin-right: 10px;
	width: 50%;
	position: relative;
	.rounded;
	.clearfix;

	&.disabled:not(.has-value) {
		opacity: .5;
		.b-numerable-picker__wrapper {
			pointer-events: none;
		}
		.b-numerable-picker__item {
			display: none;
		}
	}

	&.validation-error {
		border-color: @field1-warning-color;
	}

	.placeholder {
		color: rgba(74, 87, 103, .5);
	}

	&:before {
		display: none;
	}

	&__wrapper {
		display: flex;
	}

	&__select {
		font-family: 'Roboto', sans-serif;
		font-size: 14px;
		color: #fff;
		background-color: #4A5767;
		border: 1px solid #A4ABB3;
		border-left: none;
		border-radius: 0 3px 3px 0;
		height: 30px;
		box-sizing: border-box;
		display: flex;
	    align-items: center;

		&-img {
			background: url('~assets/img/icon-arrow-big-down.svg') no-repeat center;
			width: 14px;
			padding: 13px;
		}
	}
}

.open.b-numerable-picker .b-numerable-picker__input:before {
	.g-icon-arrow-up_w;
}
.open.b-numerable-picker .b-numerable-picker__select-img {
	transform: rotate(180deg)
}

.b-numerable-picker__input {
	width: 100%;
	border: 1px solid #A4ABB3;
	background: none;
	color: @field2-active-color;
	cursor: pointer;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	position: relative;
	border-radius: 3px 0 0 3px;
	height: 30px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 8px;
}

.b-numerable-picker__popup {
	position: absolute;
  	pointer-events: none;
	top: 100%;
	right: 0;
	float: right;
	z-index: 5;
	opacity: 0;
	max-height: 0;
	.transition-fast;
	border: 1px solid #A4ABB3;
	overflow: visible;
}

.open .b-numerable-picker__popup {
	opacity: 1;
	max-height: 10000px;
  	pointer-events: all;
}

.b-numerable-picker__item {
	padding: 13px 25px;
	display: block;
	list-style: none;
	text-align: left;
	position: relative;
	color: @field2-font-color;
	white-space: nowrap;
	z-index: 6;
	background: #4A5767;
	border-radius: 3px;

	&:last-child {
		border: 0;
	}
}

.b-numerable-picker__item-controls {
	display: flex;
	justify-content: space-between;
	color: #fff;
}

.b-numerable-picker__item-plus {
	cursor: pointer;
	display: block;

	&.disabled {
		pointer-events: none;
	}
}

.b-numerable-picker__item-minus {
	cursor: pointer;
	display: block;
}

.b-numerable-picker__item-value {
	cursor: pointer;
	width: 25px;
	text-align: center;
	display: block;
	color: @field2-active-color;
}

.b-numerable-picker__validation {
	position: absolute;
	bottom: 100%;
	right: -1px;
	margin-bottom: -3px;
	left: -1px;
	width: auto;
}

.b-numerable-picker__validation-visible {
	opacity: 1 !important;
	display: block;
	height: auto !important;
}
