@media @tablet {
  .flex-grid-layout {
	width: 100% !important;
	padding-right: 0 !important;
  }
}

@media (min-width: 1440px) {
	.flex-grid-layout {
		padding-right: 40px;
		&:nth-child(3n) {
			padding-right: 0;
		}
	}
}

@media (min-width: 1920px) {
	.flex-grid-layout {
		width: 25%;
		padding-right: 18px;
		&:nth-child(3n) {
			padding-right: 18px;
		}
		&:nth-child(4n) {
			padding-right: 0;
		}
	}
}
