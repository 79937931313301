@import (reference) "~assets/css/imports";

.b-booking-form-contacts-autocomplete__wrapper {
  display: block !important;
}

.b-booking-form-contacts-autocomplete__items {
  right: 0;
}

.b-booking-form-contacts-autocomplete__item-city {
  color: #fff;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.b-booking-form-contacts-autocomplete__item-code {
  color: #fff;
  float: left;
  font-size: @base-H1-size;
  margin-right: 10px;
  line-height: 40px;
  padding-left: 0;
}

.b-booking-form-contacts-autocomplete.validation-error {
  .b-booking-form-contacts-autocomplete__input {
   .placeholder(@field1-warning-color);
   color: @field1-warning-color;
 }
}

