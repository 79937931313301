@import (reference) "~assets/css/imports";

.b-selection {
  margin: 10px 0 10px 0;
}

.b-selection__value {
  margin: 10px 0 10px 0;
  position: relative;
  display: block;
  padding-right: 50px;
}

.b-selection__value .b-checkbox__view {
  display: block;
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.b-selection__value.active .b-selection__value-price {
  color: @field2-active-color;
}

.b-selection__value-price {
  position: absolute;
  top: 0;
  right: 0;
  font-size: @base-font-size;
}

.b-selection__service-icon {
  position: absolute;
  right: 0;
  top: -2px;
  margin-right: 0;
}

.b-selection__open {
  font-size: 12px;
  color: @field2-link-color;
  position: relative;
  height: 30px;
  margin-top: 10px;
  line-height: 30px;
  display: none;

  &:before {
	content: " ";
	border: 1px solid @field2-elements-color;
	border-bottom: 0;
	.rounded-top-left;
	display: block;
	position: absolute;
	width: 30px;
	height: 30px;
	right: 0;
	bottom: 0;
	.g-icon-arrow-down_w;
	background-size: 15px 15px;
  }
}

@media @tablet {
  .b-selection__value.active .b-selection__value-price {
	color: #099C9D !important;
  }
}
