@import (reference) "~assets/css/imports";

.b-search-form__params {
  .b-airlines-input__hint {
	color: @field2-font-color;
  }

  .b-airlines-input__items {
	background: @field2-background-color;
  }

  .b-airlines__item:hover {
	background: @field2-active-color;
	color: @field2-background-color;
  }

  .b-airlines-input__item_selected {
	color: @field2-active-color;
  }

  .b-airlines-input__item_selected:after, .b-airlines-input__item:after {
	background: @field2-elements-color;
  }

  .b-airlines-input__cursor {
	background: @field2-active-color;
	color: @field2-link-color;
  }

  .b-airlines-input.validation-error {

	.b-airlines-input__input {
	  .placeholder(@field2-warning-color);
	  color: @field2-warning-color;
	}
  }

  .b-airlines__item:hover {
	background: @field2-elements-color;
  }
}

.l-grid-layout-33 .b-airlines-input__items{
  width: 100%;
}

