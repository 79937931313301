@import (reference) "~assets/css/imports";
@import '../styles';


.b-booking-trains {

	.b-travellers__header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.b-traveller {
		padding: 0;
	}

	.b-booking__common-validation {
		margin: 30px 0;
	}

	.b-booking__common-validation .b-message {
		padding: 5px 10px;
		margin: 5px 0;
	}

}

.b-booking__two-selector-wrapper {
  padding: 10px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
  margin: 30px 0;
  border: 1px solid #f8f8f8;
}

@media @tablet {
  .b-booking-trains {
	.b-travellers__header {
	  justify-content: unset !important;
	}

	.b-booking__submit {
	  .border-radius(4px) !important;
	}
  }
}

