.w {
	&-title {
		padding: 15px 12px;
		&__text {
			font-family: 'Roboto Slab', sans-serif;
			font-size: 16px;
			font-weight: bold;
			line-height: 21px;
		}
	}
}
