.b-passengers-block {
	.rounded;
	background: @field1-background-color;
	padding: 0 10px 10px 10px;
	.clearfix;
	margin: 2px 0;
}

	.b-passengers-block__title {
		text-transform: uppercase;
		color: @field1-active-color;
		font-size: @base-H1-size;
		margin: 5px 0 5px;
		font-weight: normal;
	}
