@import (reference) "~assets/css/imports";

.b-filters-transfers {
	box-shadow: none;
	margin: 0 0 50px;

	.b-filters__drop-filters.fixed {
		position: fixed;
		bottom: 0;
		width: 285px;
		z-index: 2;
	}

	.b-filters__block.b-selection {
		padding: 0;
		border: 0;
	}

	.b-selection__values {
		padding-left: 8px;
	}

	.b-selection__value {
		margin: 12px 0 0;
		padding-right: 0;
		height: 20px;
		position: relative;

		.b-checkbox {
			height: unset;
			line-height: unset;
		}

		.b-checkbox__view {
			line-height: 20px;
			min-height: 20px;
			padding-left: 36px;
			width: unset;

			&:before {
				border-color: #fff;
			}
		}

		.b-checkbox .b-checkbox__input:disabled {
			+ .b-checkbox__view {
				opacity: .5;
				cursor: default;
				&:before {
					background: none;
					border-color: #fff;
				}
				&:after {
					background: #fff;
				    transform: scale(1, 1);
				    opacity: 1;
				}
			}
		}
	}

	.b-selection__more {
		margin: 12px 0 0 8px;
		cursor: pointer;
		display: inline-block;
		position: relative;

		padding-right: 30px;

		&:after {
			content: '';
			position: absolute;
			right: 8px;
			width: 1em;
			height: .5em;
			top: calc(~'50% - 0.25em');
			transform: none;
			background-image: url("~assets/img/hotels/icon-arrow-down-white.svg");
			background-repeat: no-repeat;
			background-size: 100% 100%;
			background-position: 50% 50%;
		}

		&.collapsed {
			&:after {
				transform: rotate(180deg);
			}
		}

		&.dn {
			display: none;
		}
	}

	.b-selection__attribute {
		display: block;
		position: absolute;
		right: 10px;
		height: 20px;
		top: calc(~'50% - 8px');

		&.dn {
			display: none;
		}
	}
}

.b-filters-transfers__sorting {
	padding: 11px 0;

	.b-sorting__label,
	.b-sorting__selected {
		padding-top: 0;
		padding-bottom: 0;
	}

	.b-sorting__selected {
		padding-right: 15px;
		&:before {
			top: calc(~'50% - 3px');
		}
	}
}

.b-filters-transfers__sorting + .b-filters-transfers__categories {
    border-top: 1px solid #2F3842;
}

.b-filters-transfers__categories {
	padding: 11px 0;

	.b-categories__label,
	.b-categories__selected {
		padding-top: 0;
		padding-bottom: 0;
	}

	.b-categories__selected {
		padding-right: 15px;
		&:before {
			top: calc(~'50% - 3px');
		}
	}
}


.b-filters-transfers__item {
	margin: 0;
	padding: 18px 0;
	border-top: 1px solid #2F3842;
}

.b-filters-transfers__searchString {
	.b-input {
		padding: 0;
	}
	.b-input__value {
		height: unset;
	    border-radius: 3px;
	    border: none;
	    padding: 11px 30px 11px 10px;
	    background: #fff url('~assets/img/hotels/icon-search.svg') calc(~'100% - 12px') center no-repeat;
		background-size: 12px;

		&[disabled] {
			background-color: rgba(0,0,0,.2);
		}
	}
}

.b-filters-transfers__distance,
.b-filters-transfers__price {
	.noUi-lower-value,
	.noUi-lower-upper {
		white-space: nowrap;
		width: unset;
		font-size: 13px;
		padding: 3px;
		margin-bottom: 7px;
	}

	.noUi-lower-value {
		top: unset;
		bottom: 100%;
		margin-top: 0;
		left: 0;
		right: unset;
	}

	.noUi-lower-upper {
		right: 0;
		left: unset;
	}

	.b-range {
		padding-bottom: 18px;
	}

	.b-range__input {
		margin: 0 20px 0 12px;
	}

	.b-filters__range-input-container {
		display: flex;
		align-items: center;
	    justify-content: space-between;
		font-size: 13px;
		margin: 0 10px 12px 12px;
	}

	.b-filters__range-input {
		display: flex;
		align-items: center;

		.b-input {
			padding: 0;
			width: unset;
			margin-left: 5px;
		}
		.b-input__value {
			background: rgba(255, 255, 255, 0.2);
			border: 1px solid #FFFFFF;
			border-radius: 3px;
			color: rgba(255, 255, 255, .5);
			height: unset;
			width: unset;
			max-width: 88px;

			text-align: center;
			padding: 6px 0;

			&:focus {
				color: #fff;
			}
		}
	}
}

.b-filters-transfers__class {
	.b-hotel-star {
		width: 20px;
		height: 20px;
		display: inline-block;
		vertical-align: top;
		background: url('~assets/img/hotels/icon-star-white.svg') center -1px no-repeat;
	    background-size: cover;
	    margin-right: 10px;

	    &:last-child {
	    	margin-right: 0;
	    }
	}
}

.b-filters-transfers__footer {
	padding: 28px 10px;
	border-top: 1px solid #2F3842;
}

.b-filters-transfers__reset,
.b-filters-transfers__apply {
	background: #4882BD;
	color: #fff;
	padding: 10px 0;
	margin-bottom: 5px;
	text-align: center;
	border: 0;
	cursor: pointer;
	height: unset;
	font-weight: normal;

	&.btn-disabled {
		border: none;
	}

	&.dn {
		display: none;
	}
}

.b-filters-transfers__footer-variants {
	text-align: center;
	display: none;
}

@import "style.mobile";
