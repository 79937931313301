@import (reference) "~assets/css/imports";

.b {
  &-header {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	color: #4A5767;
	height: 50px;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
	background-color: #D70000;

	&__additional-order {
	  font-weight: 500;

	  .g-icon-basket {
		font-size: 11px;
		font-style: normal;
		text-align: center;
		color: #fff;
		display: inline-block;
		width: 24px;
		height: 21px;
		vertical-align: middle;
		position: relative;
		right: 4px;
		bottom: 1px;
		padding: 6px 0 5px 5px;
		line-height: 12px;
		font-weight: normal;
	  }
	}

	&__additional-logo {
	  position: absolute;
	  left: 20px;
	  display: block;
	  height: 50%;
	  width: 5em;
	  background-size: contain;
	  background-position: center;
	  background-repeat: no-repeat;
	}

	&__additional-order-number {
	  font-weight: 500;
	  font-size: 14px;
	  color: #4882BD;
	  border-bottom: 1px dashed #4882BD;
	  vertical-align: middle;
	  cursor: pointer;
	}

	&__blocks {
	  .b-breadcrumbs {
		display: flex;
		flex-direction: row;
		align-items: center;
	  }
	}

	&__logo {
	  padding-left: 12px;
	  display: block;
	  position: relative;

	  &-img {
		background: url("~assets/img/logos/IBE.svg") no-repeat center;
		width: 100px;
		height: 24px;
		position: relative;
		z-index: 5;
	  }
	}

	&__info {
	  display: flex;
	  flex-direction: row;
	  justify-content: space-between;
	  align-items: center;
	  width: 100%;
	  height: 100%;
	  background: #fff;
	  position: relative;

	  &:before {
		content: '';
		background: #fff;
		position: absolute;
		left: -3px;
		top: 0;
		border-right: 25px solid transparent;
		border-top: 50px solid #D70000;
	  }

	  &:after {
		content: '';
		position: absolute;
		left: -115px;
		right: 0;
		top: 0;
		border-top: 5px solid #D70000;
	  }

	  &-wrapper {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-left: auto;
		height: 100%;
		padding-top: 5px;
	  }

	  &-account,
	  &-currency,
	  &-language {
		display: flex;
		flex-direction: row;
		align-items: center;
		position: relative;
		padding: 0 12px;
		margin: auto 0;

		&:after {
		  content: '';
		  border-right: 1px solid #4A5767;
		  position: absolute;
		  height: 20px;
		  top: calc(~'50% - 10px');
		  right: 0;
		}

		&.dn {
		  display: none;
		}
	  }

	  &-currency,
	  &-language {
		&:after {
		  border-color: rgba(0, 0, 0, 0.1);
		}
	  }

	  &-language {
		margin-top: 0;
		margin-bottom: 0;
		height: 100%;
	  }

	  &-account-text {
		text-transform: uppercase;
	  }

	  &-currency, &-account {
		&-text {
		  font-weight: 500;
		  margin: 0;
		  font-size: 14px;
		  position: relative;
		  top: 1px;

		  &_color {
			font-size: 13px;
		  }
		}
	  }

	  &-currency {
		flex-direction: column;
		align-items: flex-start;
	  }

	  &-language {
		padding: 0;
		margin-right: 12px;

		&-flag {
		  margin-right: 12px;

		  &-img {
			background-image: url("~assets/img/flag.svg");
			background-repeat: no-repeat;
			background-position: center;
			width: 20px;
			height: 12px;
		  }
		}

		&-select {
		  &-img {
			background-image: url("~assets/img/icon-arrow-big-down.svg");
			background-repeat: no-repeat;
			background-position: center;
			width: 10px;
			height: 5px;
		  }
		}

		.p-login__header-language {
		  height: 100%;
		  border: 0;
		  padding: 0;

		  &-wrapper {
			padding: 0 12px;
		  }

		  &-dropdown {
			top: 45px;
			width: 100%;
			z-index: 10;
		  }

		  &-select-img {
			background: #4A5767;
			-webkit-mask-image: url("~assets/img/icon-arrow-big-down.svg");
			mask-image: url("~assets/img/icon-arrow-big-down.svg");
			-webkit-mask-size: cover;
			-o-mask-size: cover;
			mask-size: cover;
			-webkit-mask-repeat: no-repeat;
			-o-mask-repeat: no-repeat;
			mask-repeat: no-repeat;
			-webkit-mask-position: center;
			-o-mask-position: center;
			mask-position: center;
		  }

		  &.opened {
			.p-login__header-language-select-img {
			  background: #fff;
			  transform: rotate(-180deg);
			}
		  }
		}
	  }

	  &-button {
		width: 1px;
	  }
	}

	&-button {
	  display: none;
	}
  }
}

@import "./style.mobile";
