@media (min-width: 1440px) {
	.b-hotel-order-info {
		.b-hotel-order__column-container {
			padding: 22px 40px 22px 0;
			margin: 0 75px 0 35px;
		}

		.b-hotel-order_slider {
			padding: 50px 60px 0 0;
		}
	}
}

@media (min-width: 1920px) {
	.b-hotel-order-info {

	}
}