@media @tablet {
	.p-cabinet-orders {
	  .b-block-container__header-label:before {
		.g-icon-clipboard-black;
		background-color: #fff;
		display: flex;
		align-items: center;
		.border-radius(50%);
		justify-content: center;
	  }
	}
}

@media (min-width: 1440px) {
	.p-cabinet-orders {
		.p-search__submit {
			width: 355px;
		}
	}
}

@media (min-width: 1920px) {
	.p-cabinet-orders {
		.b-block-container__element {
			flex: 1 1 20%;

			&:nth-child(3n) {
				margin-right: 12px;
			}
		}
	}
}
