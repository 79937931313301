@import (reference) "~assets/css/imports";

.b-remarks {
  display: flex;
  flex-flow: column;
  gap: 15px;
  width: 100%;

  &__string-section {

	&-header {
	  font-size: 14px;
	  font-weight: 500;
	  display: flex;
	  align-items: center;
	  gap: 5px;

	  & > span {
		width: 16px;
		height: 16px;
	  }
	}
	&-container {
	  display: flex;
	  flex-flow: column;
	  gap: 10px;
	}
  }

  &__datetime-section {
	&-header {

	}
	&-container {
	  display: flex;
	  flex-flow: column;
	  gap: 10px;
	  width: 50%;
	}
  }
}

@media @tablet {
  .b-remarks {

	&__datetime-section {
	  &-header {
		font-weight: 500;
		font-size: 14px;
	  }
	  &-container {
		width: 100%;
	  }
	}
  }
}
