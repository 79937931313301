@import (reference) "~assets/css/imports";

.b-fare-rules__rules-header {
  color: @field1-alert-color;
}

.b-fare-rules__rules-content {
  padding: 0 10px;
}

.b-fare-rules__rules-list {
  padding: 10px;
}

.b-fare-rules__translate-button {
  color: #fff;
  background-color: #4882BD;
}

.b-fare-rules__ticketing-before {
  color: @field1-alert-color;
}

.b-fare-rules__rule-booking {
  height: 400px;
  overflow: auto;
  margin-left: 20px;
}

.b-fare-rules__rules {
  background-color: @field1-background-color;
  border-left: 1px solid @field1-border;
  border-right: 1px solid @field1-border;
  padding: 20px 10px;
  //.rounded-bottom;
}

#goog-gt-tt {
  display: none !important;
}

.goog-text-highlight {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

@import 'style.mobile';
