@import (reference) "~assets/css/imports";

.l-content-table-title-hotels {
	color: rgba(74, 87, 103, 0.8);
	text-align: right;
	font-size: 14px;
	font-weight: 500;
  	margin-left: auto;
}

.b-hotels {

	&__search-no-results {
		margin: 0;
		padding: 5px 10px;

		&.dn {
			display: none;
		}
	}

  	&__result-counter {
	  padding: 6px;
	  font-size: 14px;
	}

	&__search {
		position: fixed;
		background: #fff;
		border: 1px solid #4882BD;
		top: 50px;
		right: 15px;
		text-align: center;
		z-index: 19999;
		padding: 15px 40px;
		border-radius: 4px;


		&.dn {
			display: none;
		}
	  &-progress-bar {
      position: sticky;
      top: 44px;
      background-color: white;
      z-index: 998;

      @media (min-width: 768px) {
        top: 36px;
      }

      &.finished {
        background-color: #A4ABB3;
        border-radius: 3px;
        margin-bottom: 29px;
      }

		&.dn {
		  display: none;
		}
	  }
	}

  	&-search-mobile {
	  display: none;
	}

	&__search-all {
		margin-top: 5px;
		border-bottom: 1px dotted;
		cursor: pointer;
		font-weight: bold;

		&.dn {
			display: none;
		}
	}

	&__search-close {
		background: url("~assets/img/icon-clear-dark.svg");
		background-repeat: no-repeat;
		background-position: center;
		background-size: 12px;
		position: absolute;

		cursor: pointer;

		right: 5px;
		top: 5px;
		height: 12px;
		width: 12px;
	}

	&.all-group-opened {
		.b-hotel-offer__additional-prices {
			display: none;
		}

		.b-hotel-offers-group:not(.b-hotel-offers-group--filtered) .b-hotel-offers-grouped {
			display: block !important;

			.b-expand {
				display: none;
			}
		}
	}

	&__results {
		margin-bottom: 15px;

	  &.dn {
		display: none;
	  }
	}

	&__results--map {
		margin-bottom: 30px;
	}

	&__map {
		width: 100%;
		height: 300px;
		background: #f8f8f8;
		margin-bottom: 20px;
		border-radius: 3px;
		overflow: hidden;
	}

  	&__map-wrapper.dn {
		display: none !important;
  	}
	&__map--fullscreen {
		position: fixed !important;
		z-index: 9999;
		left: 12px;
		right: 12px;
		width: unset;
		bottom: 0;
		top: 210px;
		height: unset !important;
	}
	&__map--fixed {
		position: fixed !important;
		z-index: 9999;
		right: 12px;
		width: unset;
		top: 239px;
	}

	&__map-wrapper {
		position: relative;

		&.hotels-map-closed {
			.b-hotels__map-open {
				display: flex;
			}
			.b-hotels__map {
				height: 67px;
			}
		}
	}

	&__map-open {
		align-items: center;
		justify-content: center;
		display: none;
		position: absolute;
		top: 0;
		width: 100%;
		height: 67px;
		text-align: center;
		background-color: rgba(255, 255, 255, 0.7);
		border-radius: 3px;
		z-index: 1;
		cursor: pointer;

		&-link {
			color: #4A5767;
			border-bottom: 1px dashed #4A5767;
			font-size: 16px;
			font-weight: 500;
		}
	}

	.b-hotel + .b-hotel {
		margin-top: 20px;
	}
}

.options-list {
	padding-left: 20px;
	margin: 5px 0;

	& > li {
		padding: 0;
	}
}

@import "./style.mobile";
