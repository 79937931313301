.w {
	&-timerange {
		position: relative;
		&.open .w-timerange__suggest {
			display: block;
		}

		&:not(.open) .w-timerange__select-img {
			background: url('~assets/img/icon-timerange.svg') no-repeat center;
		}

		&__wrapper {
			display: flex;
			flex-direction: row;
			cursor: pointer;
		}

		&__input {
			background: #4A5767;
			box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
			border: 1px solid #A4ABB3;
			box-sizing: border-box;
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			line-height: normal;
			font-size: 14px;
			text-align: center;
			color: #FFFFFF;
			padding: 6px 10;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 3px 0 0 3px;
			height: 30;
		}

		&__select {
			font-family: 'Roboto', sans-serif;
			font-size: 14px;
			color: #fff;
			background-color: #4A5767;
			border: 1px solid #A4ABB3;
			border-left: none;
			border-radius: 0 3px 3px 0;
			height: 30;
			display: flex;
			align-items: center;
			box-sizing: border-box;

			&-img {
				background: url('~assets/img/icon-clear.svg') no-repeat center;
				padding: 7px 20;
			}
		}

		&__suggest {
		  	z-index: 555;
			position: absolute;
			width: 100%;
			background-color: #4A5767;
			border: 1px solid #A4ABB3;
			border-top: none;
			border-bottom-left-radius: 3px;
			border-bottom-right-radius: 3px;
			box-sizing: border-box;
			display: none;

			&-list {
				list-style-type: none;
				margin: 0;
				padding: 0;

				&-point {
					box-sizing: border-box;
					padding: 0 12px;
					cursor: pointer;
					&:hover {
						background: #2F3842;
					}

					&:hover .w-timerange__suggest-list-daytype {
						color: #4882BD;
					}

					&:hover .w-timerange__suggest-list-daytime {
						color: #4882BD;
					}

					&-wrapper {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						width: 100%;
						padding: 6px 0;
						border-bottom: 1px solid rgba(0, 0, 0, 0.2);
					}
				}
				&-daytype {
					color: #fff;
				}
				&-daytime {
					color: #A4ABB3;
				}
			}
		}
	}
}

.w-time__remove {
	background: url("~assets/img/icon-clear-dark.svg") no-repeat center;
	background-size: 14px;
	width: 28px;
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
	cursor: pointer;

	&.dn {
		display: none;
	}
}
