.l-container {
	min-height: 100%;
	position: relative;
	//z-index: 1;
}

.l-main-container {
	padding-bottom: 100px;
	width: @content-width;
	margin: 0 auto;
}

.l-wrapper {
	width: @content-width;
	margin: 0 auto;
}

.l-content-wrapper {
	.clearfix;
}

.l-page {
	padding-top: 75px;
	.clearfix;
}

.l-page-inner .l-page {
	padding-top: 105px;
	-webkit-transition: padding 150ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
	transition:         padding 150ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.l-content {
	float: right;
    margin-bottom: 30px;
    display: block;
    width: 71%;
    margin-left: 30px;
}

.l-content-inner {

}

.l-sidebar {
    float: left;
    width: 25%;
    margin-bottom: 30px;
}

.l-grid-container {
	margin: 5px 0;
	.clearfix;
}

.l-grid-layout-33 {
	float: left;
	width: 33.33333%;
	padding-right: 10px;
}

.l-grid-layout-66 {
	float: left;
	width: 66.66666%;
	padding-right: 10px;
}

.l-grid-layout-25 {
	float: left;
	width: 25%;
	padding-right: 10px;
}

.l-grid-layout-50 {
	float: left;
	width: 50%;
	padding-right: 15px;
}

.l-grid-layout-100 {
	float: left;
	width: 100%;
}

.l-grid-layout-full {
	float: left;
	width: 100%;
	padding-right: 10px;
}

.l-grid-layout-33:not(:nth-child(2n)):last-child:not(:only-child) {
	padding-right: 0;
}

.l-grid-layout-66:not(:first-child):last-child:not(:only-child) {
	padding-right: 0;
}

.l-line {
	border-top: 1px solid @color-extra-light;
  	margin-top: 7px;
}

.l-block__back {
	.rounded;
  	.shadow;
	margin: 2px 0 0 2px;
}

.l-block__over {
	background: @field2-background-color;
	.rounded;
	position: relative;
}


@media @tablet {

	.l-main-container {
		padding-bottom: 0;
		height: auto;
		min-height: 0;
		width: 100%;
	}

	.l-line {
		margin-bottom: 7px;
	}

	.l-container {
		padding: 0 10px;
	}

	.l-wrapper {
		width: 100%;
	}

	.l-page {
		padding-top: 60px;
	}

	.l-page-inner .l-page {
		padding-top: 73px !important;
	}

	.l-sidebar {
		width: 100%;
		position: fixed;
		left: -100%;
		.transition-fast(left);
	}

	.l-content {
		padding-left: 0;
	}

	.l-grid-container {
		margin: 0;
	}

	.l-grid-layout-33, .l-grid-layout-66, .l-grid-layout-50, .l-grid-layout-25 {
		width: 100%;
		margin: 0 0;
		padding: 0;
	}
}
