@import (reference) "~assets/css/imports";

.b-standard-services {
  	font-size: @base-font-size;
	color: @__base-color-2;
  	display: flex;
	justify-content: space-between;
  	border-top: 1px solid @field2-elements-color;
}

	.b-standard-services__item {
		min-height: 18px;
		position: relative;
		margin: 10px 30px 10px 0;

		&:last-child {
			margin-right: 0;
		}
	}

		.b-standard-services__item-title {
			float: left;
			padding-right: 5px;
		  	}

			.b-standard-services__item-rules {
				overflow: hidden;
				display: block;
			}

				.b-standard-services__item-rule {
					display: block;
					margin: 0 0 3px;
				}

				.b-standard-services__item-rule-type {
					display: inline-block;
					padding-right: 2px;
				  	font-weight: normal;
				}

.b-ticket-small {
	.b-ticket-leg__segments, .b-standard-services {
		flex-direction: column;
	}

	.b-ticket-leg__short {
		.b-ticket-leg-segment {
			width: auto;
			padding: 11px 35px;
		}

		.b-ticket-leg-route-info .b-ticket-leg-route-info-description {
			width: auto;
			position: static;
		}

		.b-ticket-leg__info-line {
			display: none; /* TMP */
		}
	}
}

