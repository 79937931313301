@import (reference) "~assets/css/imports";
@import "./b-button-container";
@import "./b-error-popup";

.b-popup {
	display: inline-block;
	width: 100%;
	position: relative;
	width: 700px;
	text-align: left;
	display: none;

	.b-button-container__top{
	  	overflow-y: auto;
	  	overflow-x: hidden;
	  	max-height: 700px;
	}

	&.b-reload-popup {
		.b-popup__content {
			  background-image: url('~assets/img/icons/icon-info.svg');
		}
	}

	&--type {
		&-info {
			.b-button-container__footer {
				.b-button-container__button {
					border-color: #099C9D;

					&:nth-child(2n-1) {
						background: #fff;
						color: #4A5767;

						&:before,
						&:after {
							background: #fff;
						}
					}

					&:nth-child(2n) {
						background: #099C9D;
						color: #fff;

						&:before,
						&:after {
							background: #099C9D;
						}
					}

					&:after,
					&:before {
						border-color: #099C9D;
					}

					&:only-child {
						background: #099C9D;
						color: #fff;

						&:before,
						&:after {
							background: #099C9D;
						}
					}
				}
			}

			.b-popup__content {
				border-color: #099C9D;
			}
		}

		&-danger {
			.b-button-container__footer {
				.b-button-container__button {
					border-color: #B60A0A;
					&:after,
					&:before {
						background: #B60A0A;
						border-color: #B60A0A;
					}
					&:nth-child(2n-1) {
						background: #B60A0A;
					}
				}
			}

			.b-popup__content {
				border-color: #B60A0A;
			}
		}

		&-warning {
			.b-button-container__footer {
				.b-button-container__button {
					border-color: @__secondary-color-5;
					&:after,
					&:before {
						background: @__secondary-color-5;
						border-color: @__secondary-color-5;
					}
					&:nth-child(2n-1) {
						background: @__secondary-color-5;
					}
				}
			}

			.b-popup__content {
				border-color: @__secondary-color-5;
			}
		}

		&-simple {
			.b-popup__content {
				padding: 0;
				border: none;
				background-image: none;
			}
		}
	}

	&.without-title {
		.b-popup__header {
			display: none;
		}
	}

	&.without-bg-icon {
		.b-popup__content {
			background-image: none;
		}
	}

	&__header {
		.rounded-top;

		background: #4A5767;
		padding-bottom: 8px;
		color: #fff;
		position: relative;

		font-size: 14px;
		padding: 6px 20px;
		display: flex;
		align-items: center;
	}

	&__title {
		margin: 0;
		display: inline;
		text-align: left;
		color: @field2-H1-color;
		font-weight: normal;
	}

	&__close {
		.g-icon-w-cancel;
		width: 12px;
		height: 12px;
		display: block;
		position: absolute;
		right: 12px;
		top: 8px;
		cursor: pointer;

		&:hover {
			opacity: 0.7;
		}

		&:active {
			opacity: 1;
		}
	}

	&__content {
		margin-top: -2px;
		background: #fff;
		border: 1px solid #A9A9A9;
		padding: 30px 20px 30px 60px;
		text-align: center;
		// display: flex;
		border-radius: 3px;
		background: #fff url('~assets/img/icons/icon-notification.svg') 16px 16px no-repeat;
		background-size: 24px;
	}

	&-mask {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		z-index: -1;

		&.visible {
			z-index: 99999;
			opacity: 1;
		}

		&__table {
			display: table;
			width: 100%;
			height: 100%;
		}

		&__cell {
			display: table-cell;
			text-align: center;
			vertical-align: middle;
		}

		&-overlay {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.8);
			opacity: 0;
			z-index: -1;
			.transition(all 400ms cubic-bezier(0.455, 0.03, 0.515, 0.955));

			&.visible {
				opacity: 1;
			}
		}
	}

	&-successful__content {
		color: @field1-active-color;
		margin: 15px 0;
		padding: 0 40px;
		.g-icon-ok;
		background-position: 10px 50%;
		background-size: 20px 20px;
		display: inline-block;
	}

	&-failed__content {
		color: @field1-alert-color;
		margin: 15px 0;
		padding: 0 40px;
		.g-icon-cancel;
		background-position: 10px 50%;
		background-size: 20px 20px;
		display: inline-block;
	}

	&__print {
		display: block;
		position: absolute;
		right: 12px;
		background-image: url('~assets/img/icon-print.svg');
		width: 18px;
		height: 19px;
		top: 10px;
		cursor: pointer;

		&.b-popup__print-wc {
			right: 40px;
		}

		&:hover {
			opacity: 0.7;
		}

		&:active {
			opacity: 1;
		}
	}

	&.visible {
		display: inline-block;
	}
}

.b-dialog__popup-confirm-quastion {
	font-size: 18px;
	margin-bottom: 10px;
}

@media print {
    body.b-popup-print * {
        visibility: hidden;
    }
    .b-popup {
        visibility: visible;
		position: absolute;
		left: 0;
		top: 0;
    }
}

@import 'style.mobile';
