@media @tablet {
  .b-hotel {
	&__wrapper {
	  flex-flow: column;
	}

	&__photo {

	}

	&__photo-container {
	  order: 1;
	  margin: 20px 0;
	  max-height: unset;
	  max-width: unset;
	}

	&__photo-not-available {
	}

	&__slider {
	  width: 100% !important;
	  height: 100% !important;
	}

	&__slider-control {
	  &--type-prev {
	  }

	  &--type-next {
	  }
	}

	&__slider-list {
	  width: 100% !important;
	  height: 170px;
	  display: flex;
	}

	&__slider-slide {
	  width: 100% !important;
	  min-width: 100%;
	  height: 100%;
	  overflow: hidden;

	  & > img {
		height: auto;
		width: max-content;
	  }
	}

	&__description-wrapper {
	  order: 0;
	  padding: 10px;
	}

	&__description {
	  margin-bottom: 10px;
	}

	&__description-stars {
	  margin-bottom: 10px;
	}

	&__description-rating {
	}

	&__description-name {
	  font-size: 16px;
	  font-weight: 500;
	  color: #4882BD;
	}

	&__description-border {
	  display: none;
	}

	&__description-address-container {
	  flex-flow: row !important;
	  gap: 10px;
	}

	&__description-distance {
	  font-size: 12px;
	}

	&__description-distance-hotelType {
	}

	&__description-address {
	  color: #4882BD;
	  font-size: 12px;
	  text-decoration: underline;
	}

	&__description-contacts {
	}

	&__description-show-map {
	  display: none;
	}

	&__description-show-map-button {
	}

	&__description-border-second {
	  display: none;
	}

	&__description-hotel-text {
	}

	&__providers {
	  order: 3;
	  margin: 0;
	  border: none;
	  padding: 5px 10px;
	  align-items: flex-start;
	  flex-direction: column;
	  justify-content: flex-start;
	  max-width: 65%;
	}

	&__provider-wrapper {
	  align-items: flex-start !important;
	}

	&__provider-price-wrapper {
	  display: flex;
	  align-items: center;
	  flex-wrap: wrap;
	  gap: 10px;
	}

	&__provider-taxation {
	  font-size: 12px;
	}

	&__provider-arrow {
	  display: none !important;
	}

	&__provider-price {
	  font-size: 16px !important;
	}

	&__travel-policy {
	  right: 10px;
	  top: 10px;
	}

	&__expand {
	  &--with-description {
		display: none;
	  }
	}

	&-extended {
	  background: transparent;
	  padding-bottom: 50px;
	}

	&__select-offer {
	  padding: 0 28px;
	  height: 28px !important;
	  font-size: 14px;
	}

	&-offers {
	  background-color: #D6E2EE;

	  &__check-date-container {
		display: flex;
		flex-direction: column;
		color: #58636f;
		margin: 0;
		border-bottom: none;
		font-size: 12px;
	  }


	  .b-hotel-offers__check-date + .b-hotel-offers__check-date {
		margin-left: 0;
		position: unset;
	  }

	  &__summary {
		flex-flow: row wrap;

		&-amount {
		  width: 100%;
		  margin-bottom: 10px;
		  font-size: 14px;

		  &-sum {
			font-size: 16px !important;
		  }
		}

		.b-hotel__summary-select, .b-hotel__summary-select--offer {
		  width: 50%;
		  margin-left: 0;
		  font-size: 14px;
		}

		.b-hotel__summary-select--offer:before {
		  width: 16px;
		  height: 16px;
		  top: 10px;
		  font-size: 12px;
		}
	  }
	}

	&-offers-offer {
	  background: #fff;
	  .border-radius(4px);
	}

	&-offers-group {
	  margin: 10px 10px 60px;
	}

	&-offers-grouped {
	  padding-top: 60px;
	  background: #b7c0cb;
	  border-bottom-color: #D6E2EE;
	}

	&-offers-grouped__inner {
	  background: #b7c0cb;

	  & > .b-hotel-offer:not(:first-of-type) {
		margin-top: 60px;
	  }
	}

	&-offer:not(&-offer__info-icons) {
	  padding: 10px;
	  flex-direction: column;
	  background: #fff;
	}

	&__wrapper {
	  .box-shadow(~'0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12)');
	  .border-radius(4px);
	  border: none !important;
	}

	&-offer__room-wrapper {
	  width: 100%;
	  max-width: unset;
	  text-align: unset;
	  padding-left: unset;
	  padding-right: unset;
	  margin-bottom: 20px;
	}

	&-offer__mobile-provider {
	  display: flex;
	}

	&-offer__provider-wrapper {
	  display: none;
	}

	&-offer__select {
	  display: flex;
	  align-items: center;
	  position: absolute !important;
	  margin-left: 0 !important;
	  margin-top: 0;
	  z-index: 2;
	  left: 0;
	  bottom: -36px;
	  width: 100%;
	  pointer-events: none;

	  .b-hotel-offer__select-container {
		  pointer-events: all;
      background:#fff;

      .b-hotel-offer__select-button,
      .b-hotel-offer__select-button--offer {
        margin-bottom: 0px;
      }
	  }

	  &.many-rooms {
		position: static !important;
		flex-flow: row wrap;
		justify-content: flex-start !important;
		gap: 10px;
		pointer-events: all;

		.b-numerable-picker-hotels {
		  padding: 0;
		  margin: 0 0 0 auto;
		  order: 2;
		}

		.b-hotel-offer__select-container {
		  width: unset;
		  flex-wrap: wrap;
		}
	  }
	}

	&-offer__select-button-price {

	  &.many-rooms {
		flex-direction: unset !important;
		flex-flow: row wrap;
		align-items: center;
	  }
	}

	&-offer__daily-average-price {
	  margin-top: 10px;

	  &:not(.many-rooms) {
		margin: 0 0 10px auto;
	  }
	}

	&-offer__select-container {
	  display: flex;
	  align-items: center;
	  width: 100%;
	}

	&-offer__vat {
	  margin-bottom: 10px;
	}

	.b-hotel-offers:not(.b-hotel-offers--selection-room) .b-hotel-offer__select-button--offer {
	  width: 50%;
	  order: 1;
	  color: #fff;
	  background: #4882BD;
	  .border-radius(~'0px 0px 0px 4px');
	  margin-bottom: 0 !important;
	}

	.b-hotel-offers:not(.b-hotel-offers--selection-room) .b-hotel-offer__select-button--offer.b-ticket-offer__cancel {
	  background: #D70000;
	}

	.b-hotel-offers:not(.b-hotel-offers--selection-room) .b-hotel-offer__select-button {
	  width: 50%;
	  order: 2;
	  background: #099C9D;
	  .border-radius(~'0px 0px 4px 0px');
	  margin-bottom: 0 !important;
	}

	&-offer__line {
	  display: none;
	}

	&-offer__important.b-hotel-offer__info-tag {
	  background: rgba(215, 0, 0, 0.15);
	  color: #D70000;
	}

	&-offer__info-tag {
	  display: flex;
	  padding: 5px;
	  text-transform: lowercase;
	  .border-radius(20px);
	  white-space: nowrap;
	  background: #EDEEF0;
	}

	&-offer__info-container {
	  order: 3;
	  flex-direction: row;
	  flex-wrap: wrap;
	  align-items: center;
	  gap: 10px;
	  margin-bottom: 10px;

	  & > div:first-of-type {
		display: flex;
	  }
	}

	&-offer__name {
	  font-size: 16px;
	  font-weight: 500;
	  order: 2;
	  color: #4882BD;
	  margin-bottom: 10px;

	  & > div {
		max-width: unset;
		min-width: unset;
		white-space: unset;
		overflow: unset;
		text-overflow: unset;
	  }
	}

	&-offer__additional-prices {
	  order: 3 !important;
	}

	&-offer__info-icons {
	  padding: 0;
	  order: 1;
	  flex-direction: row;

	  .b-hotel-offer {

		&__information,
		&__passport,
		&__agencyInfo {
		  background-size: 100%;
		  width: 24px;
		  height: 24px;
		}
	  }
	}

	&-offer__common-wrapper {
	  margin-top: unset !important;
	  margin-bottom: 10px !important;
	  max-width: unset !important;
	  min-width: unset !important;
	  width: 100% !important;
	}

	&-offer__essential-wrapper {
	  width: 100%;
	  background: #FAFAFA;
	  margin-bottom: 10px;
	  padding: 12px 16px;
	}

	&-offer__checked-wrapper,
    &-offer__refresh-wrapper,
    &-offer__cancel-wrapper,
    &-offer__info-wrapper,
    &-offer__request-info-wrapper {
	  display: flex;
	  align-items: center;
	  margin: 0 0 10px;
	}

	&-offer__request-info-wrapper {
      justify-content: center;
	}

	&-offer__checked-label,
	&-offer__refresh-label,
	&-offer__cancel-label,
    &-offer__info-label {
	  padding: 0;
	  font-size: 14px;
	  max-width: unset;
	}

    &-offer__checked-label {
      color: #099C9D;
    }

    &-offer__refresh-label,
	&-offer__cancel-label,
    &-offer__info-label {
      color: #4A5767;
    }

    &-offer__info,
	&-offer__refresh,
	&-offer__cancel,
	&-offer__checked,
	&-offer__agencyInfo {
	  width: 25px;
	  height: 25px;
	  min-width: unset;
	  background-size: 100%;
	}
  }

	.b-hotel-offer__terms-link {
		width: auto;
	}

  .l-layout.show-hotel-offer-preview {
	.b-hotel:not(.show-mobile-view) {
	  display: none;
	}
  }

  .show-mobile-view {
	position: absolute;
	z-index: 9999;
	top: 0;
	margin-top: 0 !important;
	left: 0;
	width: 100%;
	min-height: 100vh;
	background: #D6E2EE;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;

	.b-hotel__providers {
	  display: none;
	}

	.b-hotel__select-offer {
	  display: none;
	}

	.b-hotel__mobile-close-btn {
	  color: #4882BD;
	  position: relative;
	  display: flex;
	  height: 40px;
	  align-items: center;
	  background: #fff;
	  border: none;
	  width: 100%;

	  &:before {
		content: " ";
		display: block;
		width: 50px;
		height: 18px;
		.g-icon-arrow-left-blue;
	  }
	}

	.b-hotel__mobile-description-container {
	  order: 4;
	  display: flex;
	  flex-direction: column;
	  width: 100%;
	  padding: 0 10px;
	  margin-bottom: 10px;
	}
	.b-hotel__mobile-description-show-btn {
	  font-size: 14px;
	  color: #4882BD;

	  &.open {
		color: #9b9b9b;
	  }
	}
	.b-hotel__mobile-description-text {
	  font-size: 14px;
	  height: 46px;
	  overflow: hidden;
	  text-overflow: ellipsis;

	  &.open {
		height: fit-content !important;
	  }
	}
  }

  .b-hotel-offer__select-button-price.price-only {
	text-align: center;
  }
}

@media (min-width: 769px) and (max-width: 930px) {
  .b-hotel {
	&-offer {
	  padding: 5px 10px;

	  &__line {
		display: none;
	  }
	  &__info-icons {
		padding: 5px;
	  }

	  &__row {
		.b-hotel-offer__common-wrapper {
		  width: 130px;
		}
	  }
	  &__essential-wrapper {
		width: unset;
		min-width: 170px;
	  }
	  &__room-wrapper {
		min-width: 86px;
		max-width: unset;
	  }
	  &__provider-wrapper {
		min-width: 110px;
	  }
	  &__auto-width {
		padding-right: 5px;
		padding-left: 5px;
	  }
	  &__select-button {
		min-width: 168px;
	  }
	}
  }
}

@media (max-width: 1439px) {
  .b-hotel {
	&-offer__services-list {
	  .hotel-service__img {
		display: block;

		&:nth-child(n + 4) {
		  display: none;
		}

		&:nth-last-child(2):not(:nth-child(-n + 3)) + .b-hotel-offer__services-more {
		  display: block;
		}
	  }
	}

	&-offer--without-row {
	  .b-hotel-offer__row.b-hotel-offer__services-wrapper {
		flex-grow: 2;

		.b-hotel-offer__services-list {
		  max-width: 325px;
		  min-width: 325px;

		  .hotel-service__img + .b-hotel-offer__services-more {
			display: none;
		  }

		  .hotel-service__img {
			display: block;

			&:nth-child(n + 8) {
			  display: none;
			}

			&:nth-last-child(2):not(:nth-child(-n + 7)) + .b-hotel-offer__services-more {
			  display: block;
			}
		  }
		}
	  }
	}
  }

  .b-hotel-offers:not(.b-hotel-offers--selection-room) .b-hotel-offer__select-button--offer::before {
	content: " ";
	border: none;
  }

  .b-hotel__provider-arrow {
	right: 85px;
  }
}

@media (min-width: 1440px) and (max-width: 1919px) {
  .b-hotel {
	&-offer__services-list {
	  max-width: 292px;
	  min-width: 292px;

	  .hotel-service__img {
		display: block;

		&:nth-child(n + 7) {
		  display: none;
		}

		&:nth-last-child(2):not(:nth-child(-n + 6)) + .b-hotel-offer__services-more {
		  display: block;
		}
	  }
	}

	&-offer--without-row {
	  .b-hotel-offer__row.b-hotel-offer__services-wrapper {
		flex-grow: 2;

		.b-hotel-offer__services-list {
		  max-width: 396px;
		  min-width: 396px;

		  .hotel-service__img + .b-hotel-offer__services-more {
			display: none;
		  }

		  .hotel-service__img {
			display: block;

			&:nth-child(n + 10) {
			  display: none;
			}

			&:nth-last-child(2):not(:nth-child(-n + 8)) + .b-hotel-offer__services-more {
			  display: block;
			}
		  }
		}
	  }
	}

	&__description-border-second {
	  display: none;
	}

	.b-hotel__provider-arrow {
	  right: 75px;
	}

	.b-hotel__description:not(.b-hotel__description-hotel-text) {
	  max-width: 800px;
	}
  }
}

@media (min-width: 1440px) {
  .b-hotel-offers:not(.b-hotel-offers--selection-room) .b-hotel-offer__select-button--offer,
  .b-hotel-offers:not(.b-hotel-offers--selection-room) .b-hotel-offer__select-button {
	width: 175px;
  }

  .b-hotel-offer__auto-width {
	padding: 0 15px;
  }

  .b-hotel-offer__row.b-hotel-offer__common-wrapper,
  .b-hotel-offer__name > * {
	max-width: 180px;
	min-width: 180px;
  }

  .b-hotel-offer__name > * {
	font-size: 18px;
  }

  .b-hotel-offer__essential-wrapper {
	width: 275px;
	min-width: 265px;
  }

  .b-hotel-offer__room-wrapper {
	width: 15vw;
	max-width: 15vw;
	min-width: 15vw;
  }

  .b-hotel-offer__provider {
	padding: 0 15px;
	min-width: 170px;
  }

  .b-hotel {
	font-size: 14px;

	&-offers.b-hotel-offers--selection-room {
	  .b-hotel-offer__select {
		justify-content: center;
	  }
	}

	&__photo-description {
	  font-size: 13px;
	  text-align: center;
	  margin: 5px 0 0;
	}

	&__photo-container {
	  margin: 13px 25px 13px 13px;
	}

	&__description-wrapper {
	  display: flex;
	  flex-flow: row nowrap;
	  align-items: center;

	  padding: 33px 0;
	  flex-direction: row;

	  .b-hotel__description-address {
		max-width: unset;
	  }

	  .b-hotel__description:not(.b-hotel__description-hotel-text) {
		height: 100%;
		display: flex;
		flex-flow: column;
		justify-content: center;

		&:nth-child(2) {
		  min-width: 300px;
		}
	  }

	  .b-hotel__description + .b-hotel__description {
		position: relative;
		padding-right: 25px;

		&:before {
		  content: '';
		  display: block;
		  width: 0;
		  border-left: 1px dashed #4A5767;
		  position: absolute;
		  left: -25px;
		  height: 100%;
		}
	  }

	  .b-hotel__description:last-child {
		margin: auto 0;
		padding-right: 25px;
	  }

	  .b-hotel__description {
		flex: 1;

		&:first-child {
		  max-width: 250px;
		  padding-right: 10px;
		}

		&-border {
		  display: block;
		  width: 0;
		  border-left: 1px dashed #4A5767;
		  height: 100%;
		  margin-right: 24px;
		}
	  }

	  .b-hotel__description-stars {
		.b-hotel-star {
		  height: 19px;
		  width: 19px;
		}
	  }
	}

	&__description-name {
	  font-size: 30px;
	  white-space: normal;
	  display: -webkit-box;
	  -webkit-box-orient: vertical;
	  -webkit-line-clamp: 2;
	  max-height: 72px;
	  max-width: 100%;
	}

	&__description-address,
	&__description-contacts,
	&__description-distance {
	  font-size: 16px;
	}

	&__description-show-map {
	  font-size: 14px;
	}

	&__description-distance {
	  margin-bottom: 10px;
	  margin-top: auto;
	}

	&__providers {
	  max-width: 300px;
	  margin: 33px 0;
	  padding-right: 95px;
	}

	&__travel-policy {
	  top: 6px;
	  right: 6px;

	  .b-travel-policy-rule {
		&.g-icon-travel-policy-rules-RECOMMENDED,
		&.g-icon-travel-policy-rules-VIOLATION,
		&.g-icon-travel-policy-rules-STRICT_VIOLATION,
		&.g-icon-travel-policy-rules-AUTHORIZATION_REQUIRED {
		  width: 20px;
		  height: 24px;
		}
	  }
	}

	&__provider-wrapper {
	  flex-direction: column;
	}

	&__provider:first-child + .b-hotel__provider {
	  &:after {
		right: -35px;
	  }

	  &:before {
		left: -35px;
	  }
	}
  }

  .b-hotel-extended {
	padding: 50px 0 0;

	&__policy .b-policy-list {
	  font-size: 18px;
	}

	&.b-hotel-extended-offers {
	  padding: 5px 0 0;
	}

	&__description {
	  padding-left: 32px;
	  padding-right: 0;
	  padding-top: 0;
	}

	&__description-text {
	  padding: 25px 0 0;
	}

	&__description-no-text {
	  font-size: 16px;
	}

	&__description-top {
	  margin-right: 30px;
	  max-width: 60%;
	  flex: 2;
	}

	&__description-bottom {
	  padding: 0;
	  flex-flow: column nowrap;
	  flex: 1;
	}

	&__description-bottom-column {
	  &:first-child {
		padding: 25px 0 20px 0;
		margin-left: 15px;
		flex: 0;
	  }
	}

	&__description-bottom-item {
	  font-size: 16px;
	  margin-right: 0;
	  padding: 12px 0;
	  flex: 0;
	}

	&__description-bottom-item > div {
	  margin-bottom: 10px;

	  &:last-child,
	  &.b-hotel-extended__description-address,
	  &.b-hotel-extended__description-phone {
		margin-bottom: 0;
	  }
	}

	&__description-bottom-map {
	  min-height: 202px;
	}

	&__description-text,
	&__description-address,
	&__description-phone {
	  font-size: 18px;
	}

	&__footer {
	  margin: 0 36px;
	  padding: 18px 0;
	  width: unset;
	}

	.b-toggle-extended-description {
	  display: flex;
	  flex-flow: row nowrap;
	}
  }

  .b-hotel {
	&-offers__caption {
	  font-size: 20px;
	}

	&-offers__caption-text {
	  padding: 0 10px;
	}

	&-offer {
	  padding: 25px 10px 25px 20px;
	  font-size: 16px;

	  &:after {
		right: 20px;
	  }
	}

	&-offer__simple {
	  .b-hotel-offer__fines {
		margin-left: 10px;
	  }
	}
  }

  .b-hotel-offers__summary {
	margin: 0 10px 0 40px;
	padding: 20px 0 20px 18px;
  }

  .b-hotel-extended__policy {
	margin: 20px 0 0;
  }
}

@media (min-width: 1920px) {
  .b-hotel-offer__row.b-hotel-offer__select:not(.b-hotel-offer__select--offer) {
	display: flex;
  }

  .b-hotel-offer__select-info {
	max-height: 46px;
	margin: auto;
	margin-left: 20px;
	text-align: center;
	min-width: 50px;
  }

  .b-hotel-offers:not(.b-hotel-offers--selection-room) .b-hotel-offer__select-button {
	width: 100%;
	font-size: 20px;
  }

  .b-hotel-offers:not(.b-hotel-offers--selection-room) .b-hotel-offer__select--offer .b-hotel-offer__select-button--offer {
	width: 260px;
	font-size: 18px;
	white-space: nowrap;
  }

  .b-hotel-offer__select--offer {
	.b-hotel-offer__select-button--offer-price {
	  font-size: 18px;
	}

	.b-hotel-offer__travel-policy.b-travel-policy .b-travel-policy-rule {
	  height: 12px;
	  width: 12px;
	}
  }

  .b-hotel-offer__RP {
	display: block;
	white-space: nowrap;
  }

  .b-hotel-offer__row.b-hotel-offer__common-wrapper,
  .b-hotel-offer__name > * {
	max-width: 305px;
	min-width: 305px;
  }

  .b-hotel-offer__room-wrapper {
	width: 15vw;
	max-width: 15vw;
	min-width: 15vw;
  }

  .b-hotel-offer__auto-width {
	padding-left: 35px;
	padding-right: 35px;
  }

  .b-hotel-offer__provider {
	padding: 0 15px;
  }

  .b-hotel-offer__travel-policy.b-travel-policy .b-travel-policy-rule {
	height: 26px;
	width: 20px;
  }

  .b-hotel {
	font-size: 16px;

	.lg-visible-block {
	  display: block;
	}

	&__wrapper {
	  padding: 0;
	  min-height: unset;
	}

	&__photo-container {
	  margin: 13px 30px 13px 13px;
	}

	&__description-wrapper {
	  display: flex;
	  flex-flow: row nowrap;
	  align-items: center;

	  .b-hotel__description {
		flex: 1;

		&-border-second {
		  display: block;
		  width: 0;
		  border-left: 1px dashed #4A5767;
		  height: 100%;
		  margin-right: 24px;
		}

		&:first-child {
		  max-width: 195px;
		  padding-top: 25px;
		  justify-content: flex-start;
		}
	  }
	}

	&__description-name {
	  font-size: 30px;
	  white-space: normal;
	  display: -webkit-box;
	  -webkit-box-orient: vertical;
	  -webkit-line-clamp: 2;
	  max-height: 72px;
	  max-width: 100%;
	}

	&__description-border {
	  display: block;
	  width: 0;
	  border-left: 1px dashed #4A5767;
	  height: 100%;
	  margin-right: 24px;
	}

	&__photo-description {
	  margin-left: 0;
	  text-align: center;
	}

	&__description-wrapper {
	  padding: 33px 0;
	  flex-direction: row;

	  .b-hotel__description-address {
		max-width: 280px;
	  }

	  .b-hotel__description {
		height: 100%;
		display: flex;
		flex-flow: column;
		justify-content: center;

		&:nth-child(2) {
		  min-width: 300px;
		  max-width: 300px;
		}
	  }

	  .b-hotel__description + .b-hotel__description {
		position: relative;
		padding-right: 25px;

		&:before {
		  content: '';
		  display: block;
		  width: 0;
		  border-left: 1px dashed #4A5767;
		  position: absolute;
		  left: -25px;
		  height: 100%;
		}
	  }

	  .b-hotel__description:last-child {
		margin: auto 0;
	  }
	}

	&__description-address {
	  max-width: 300px;
	}

	&__description-address,
	&__description-contacts,
	&__description-distance {
	  font-size: 18px;
	}

	&__description-distance {
	  margin-bottom: 8px;
	  margin-top: 0;
	}

	&__description .b-hotel__description-stars {
	  margin-bottom: 5px;
	}

	&__description-show-map {
	  margin-top: auto;
	}

	&__description-hotel-text > div {
	  white-space: normal;
	  -webkit-box-orient: vertical;
	  -webkit-line-clamp: 6;
	  text-overflow: ellipsis;
	  overflow: hidden;
	  min-width: 400px;
	  max-height: 140px;
	  display: -webkit-box;
	}

	&__travel-policy {
	  top: 12px;
	  right: 12px;
	}

	&__providers {
	  padding-right: 80px;
	}

	&__provider:only-child {
	  .b-hotel__provider-name {
		margin-right: 20px;
	  }
	}

	&__provider-price {
	  min-width: unset;
	}

	&__provider-name {
	  margin-bottom: 3px;
	}

	&__provider-wrapper {
	  flex-direction: column;
	}

	&__provider-arrow {
	  right: 60px;
	}

	&__provider:only-child,
	&__provider--best-price {
	  .b-hotel__provider-price {
		font-size: 34px;
	  }
	}
  }

  .b-hotel-extended {
	padding: 60px 0 0;
	margin: 0 55px;

	&__description {
	  padding-left: 65px;
	  padding-right: 25px;
	}

	&__description-title {
	  font-size: 30px;
	}

	&__description-title.collapse--with-anchor:after {
	  right: 0;
	}

	&__description-text {
	  font-size: 20px;
	}

	&__description-no-text {
	  font-size: 18px;
	}

	&__description-bottom {
	  border-left: 1px dashed #4A5767;
	  margin-top: 25px;
	}

	&__description-bottom-column {
	  &:first-child {
		padding: 0 0 15px 15px;
		flex-direction: row;
		max-width: unset;
		margin-right: 0;

		.b-hotel-extended__description-bottom-item {
		  flex: 1;
		  padding: 0 0 0 20px;
		  border: none;
		  border-right: 1px dashed #4A5767;

		  &:first-child {
			padding-left: 10px;
		  }

		  &:last-child {
			border: none;
		  }
		}
	  }
	}

	&__description-bottom-map {
	  margin-left: 15px;
	  min-height: 310px;
	}

	&__description-top {
	  margin-right: 25px;
	  max-width: 52%;
	}
  }

  .b-hotel {
	&-offer__line {
	  min-width: 50px;

	  &--short {
		display: none;
	  }
	}

	&-offers:not(.b-hotel-offers--selection-room) {
	  .b-hotel-offer__room-wrapper + .b-hotel-offer__line--short {
		flex-basis: 227px;
	  }

	  .b-hotel-offer__common-wrapper {
		max-width: 305px;
		min-width: 305px;
	  }

	  .b-hotel-offer__select-button-price {
		font-size: 20px;
	  }
	}

	&-offers.b-hotel-offers--selection-room {
	  .b-hotel-offer__line {
		min-width: 27px;
	  }

	  .b-hotel-offer__select {
		min-width: unset;
		max-width: unset;
		flex: 0;
		white-space: nowrap;
		padding-left: 44px;
	  }

	  .b-hotel-offer__room-wrapper,
	  .b-hotel-offer__important-info-wrapper {
		padding-left: 44px;
		padding-right: 44px;
	  }

	  .b-hotel-offer__select-button-price {
		font-size: 20px;
	  }
	}

	&-offer__services-wrapper .b-hotel-offer__services-list {
	  max-width: 312px;
	  min-width: 312px;
	  padding: 0 30px;

	  .b-hotel-offers--selection-room & {
		max-width: 340px;
		min-width: 340px;
		padding: 0 44px;
	  }

	  .hotel-service__img {
		display: block;

		&:nth-child(n + 7) {
		  display: none;
		}

		&:nth-last-child(2):not(:nth-child(-n + 6)) + .b-hotel-offer__services-more {
		  display: block;
		}
	  }
	}

	&-offers__caption {
	  font-size: 30px;
	  width: unset;
	}

	&-offer,
	&-offers__caption {
	  margin: 0 23px 0 65px;
	}

	&-extended__footer {
	  margin: 0 44px;
	}

	&-offer {
	  padding: 30px 10px 30px 20px;
	  margin: 0;

	  .hotel-service__img {
		width: 30px;
		height: 30px;
	  }

	  &--without-row {
		.b-hotel-offer__row.b-hotel-offer__services-wrapper {
		  &.b-hotel-offer__services-wrapper {
			flex-grow: 0;

			.b-hotel-offer__services-list {
			  max-width: 312px;
			  min-width: 312px;

			  .b-hotel-offers--selection-room & {
				max-width: 340px;
				min-width: 340px;
			  }

			  .hotel-service__img {
				display: block;

				&:nth-child(n + 7) {
				  display: none;
				}

				&:nth-last-child(2):not(:nth-child(-n + 6)) + .b-hotel-offer__services-more {
				  display: block;
				}
			  }
			}
		  }
		}
	  }
	}
  }

  .b-hotel-offers__summary {
	margin: 0 23px 0 65px;
	padding: 20px 24px 20px 0;
  }

  .b-hotel-offer__name {
	font-size: 20px;
  }

  .b-hotel-offer__select-button-description {
	font-size: 14px;
  }

  .b-hotel-extended {
	&__footer-sum {
	  margin: 0 40px 0 auto;
	}
  }

  .b-hotel__description:not(.b-hotel__description-hotel-text) {
	max-width: 275px;
  }
}

