@import (reference) "~assets/css/imports";

.l-layout__top .b-exchange__traveller-service {
  justify-content: flex-start;
}

.l-layout__top .b-exchange-step2__header {
  padding: 10px;
  background-color: #4A5767;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 16px;
}

.b-exchange {

  &__traveller-service {
	justify-content: flex-start !important;
	border-bottom: 1px solid #EDEEF0;
  }

  &__route {

	&-title {
	  margin-bottom: 20px;
	  font-weight: 500;
	  font-size: 16px;
	  color: #4A5767;
	}
  }

  &__new-route {

	.b-exchange {
	  &__flight-leg {
		padding: 10px 26px;

		& + & {
		  border-bottom: 1px solid #4A5767;
		}
	  }

	  &__flight-header {
		border-bottom: 1px solid #4A5767;
	  }

	  &__flight {
		border-color: #4A5767;
		.box-shadow(~'0 4px 4px rgba(51, 153, 204, 0.2)');
	  }
	}


	&-title {
	  margin-top: 20px;
	  color: #4882BD;
	}
  }

  &__footer {
	border-top: 1px solid #EDEEF0;
	flex-direction: column;
	margin-top: 20px;
  }

  &__exchange-warning {
	font-weight: normal;
	font-size: 14px;
	display: flex;
	gap: 20px;
	padding: 20px 0;
	border-bottom: 1px solid #EDEEF0;
	width: 100%;
	margin-bottom: 20px;

	&:before {
	  content: " ";
	  display: block;
	  width: 20px;
	  height: 20px;
	  .g-icon-attention-orange;
	}
  }

  &__price-details {
	border-bottom: 1px solid #EDEEF0;
	width: 100%;

	&-row {
	  width: 100%;
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	  padding: 10px 0;
	}

	&-price {
	  font-weight: 500;

	  & .currency {
		font-weight: 500;
	  }
	}

	&-title {

	}
  }

  &__total-price {
	display: flex;
	justify-content: space-between;
	align-items: center;

  }

}
