@media @tablet {
  .b-block-container {
	.b-block-container__footer {
	  .b-block-table-footer {
		.b-footer-show {
		  .b-footer-show-more {
			display: flex;
		  }
		}
	  }
	}
  }
  .b-block-table__cell--clickable {
	text-decoration: unset;
  }
}
