@import (reference) "~assets/css/imports";

.b-cabinet-finance {

  .b-block-container {
	.b-block-container__top-controls, .b-block-container__content, .b-block-container__footer {
	  background: #FFFFFF;
	  border: 3px solid #F0F0F0;
	}

	.b-block-container__content {
	  padding-bottom: 0;
	  margin-top: 40px;

	  .b-block-container__tab-group{
		margin-top: 40px;
		max-width: 600px;
		font-size: 16px;
		text-transform: uppercase;

		.b-block-container__tab-btn {
		  position: relative;
		  bottom: -3px;
		  width: auto;
		  padding: 0 20px 0 20px;
		  white-space: nowrap;
		  cursor: pointer;

		  &_active {
			border-bottom: 3px solid #4882BD;
			color: #4882BD;
		  }
		}
	  }
	}

	.b-block-container__footer {
	  padding: 9px 13px;
	  border-top: none;

	  .b-block-container__input {
		margin-left: 20px;

		.pos-rel {
		  display: flex;
		  align-items: center;
		  height: 100%;

		  .b-finance-select {
			top: 2px;
		  }
		}
	  }
	}

  }


  .b-block-container__group {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;

	&_column {
	  display: flex;
	  flex-direction: column;

	  .l-grid-layout-25 {
		float: right;
	  }

	  .p-search__submit {
		background: #4882BD;
		color: #FFFFFF;
		border-radius: 5px;
		height: 30px;
		line-height: 30px;
		padding: 0;
		margin-top: 9px;
		width: 100%;
		cursor: pointer;
	  }

	  .b-block-container__text {
		padding: 5px 0;

		.b-block-container__date-balance {
		  font-weight: 600;
		  margin-right: 10px;
		}
	  }


	}

	.b-block-container__submit-btn {
	  white-space: nowrap;
	  display: block;
	  float: right;
	}

	.b-block-container__element {
	  margin-right: 12px;
	  width: 100%;

	  .payed-bill-filter {
		margin-top: 5px;
		white-space: nowrap;
		display: flex;
		align-items: center;
		height: 100%;
		width: 100%;

	  }

	  .p-search__input {
		background: none;
		color: #2F3842;
	  }

	  .p-search__options-date-with-label {
		position: relative;
		padding-top: 8px;

		.p-search__options-date-label {
		  display: block;
		  position: absolute;
		  top:-2px;
		  background: #FFFFFF;
		  margin-left: 4px;
		  margin-right: 4px;
		  padding-left: 4px;
		  padding-right: 4px;
		  z-index: 1;
		}

		.p-search__direction-date-calendar {
		  background-color: unset;

		  .p-search__direction-date-calendar-img {
			  background: url("~assets/img/icon-calendar-black-1.svg") no-repeat center;
		  }
		}
	  }

	  .b-input {
		min-width: 160px;

		.b-input__label {
		  background: #FFFFFF;
		  padding-bottom: 0;
		}
		.b-input__value {
		  height: 30px;
		  border: 1px solid #A4ABB3;
		}
		.b-input__select-value-label {
		  line-height: 30px;
		}
		.b-input__select-value:before {
		  background-color: unset;
		  background-size: 10px;
		}

	  }

	}

	.b-block-container__element:last-of-type {
	  margin-right: 0;
	}

	.b-block-container__element-dates {
	  display: flex;
	  justify-content: center;
	  width: 345px;

	  .p-search__options-date {
		.p-search__bridge {
		  left: 160px;
		}
	  }
	}

  }

  .b-block-container__period-balance {
	margin-left: 20px;
  }

  .b-block-container__debt {
	margin-top: 20px;
  }

  .b-block-container__finance-summary {
	flex-grow: .3;
	margin-top: 20px;

	.b-block-container__text {
	  padding: 5px 0;
	  font-weight: 600;

	  & > span {
		margin-left: 10px;
	  }
	}
  }

 .b-documents-select {

	.b-cabinet-finance-document-select-popup {
	  display: flex;
	  justify-content: flex-start;
	}
  }




  .p-search__input {
	.placeholder(@input-placeholder-color);
  }

  .b-block-table {
	.b-block-table__row:nth-child(even) {
	  background: #F0F0F0;
	}
  }

  .download-doc-link {
	cursor: pointer;
  }
}

@import 'style.mobile';
