.b-destination {
	height: 42px;
	border: 1px solid @field2-object-color;
	position: relative;
	.rounded();
	width: 100%;
}

.b-destination__from {
	float: left;
	padding-right: 20px;
	width: 50%;
}

.b-destination__to {
	float: right;
	padding-left: 2px;
	padding-right: 15px;
}

.b-destination__swap {
	background: url("~assets/img/icon-change.svg") no-repeat 50% 50%;
	background-size: 7px 20px;
	height: 40px;
	width: 20px;
	cursor: pointer;
	display: block;
	position: absolute;
	left: 50%;
	margin-left: -10px;
	top: 0;
	z-index: 5;
}

@media @tablet {
	.b-destination__from, .b-destination__to {
		width: 100%;
		margin-top: 10px;
		border: 1px solid @field2-object-color;
		height: 42px;
		.rounded;
		float: none;
	}

	.b-destination__swap {
		left: auto;
		right: 2px;
		margin: 0;
		top: 1px;
	}

	.b-destination {
		border: 0;
		height: auto;
	}
}
