@media @tablet {
	.b-payment {
		background: @field2-background-color;
	}

	.b-payment__block {
		margin: 3px;
	}
}

@media (max-width: 600px) {
	.b-payment__item-cell > .b-radiobutton {
		max-width: 250px;
	}

	.b-payment__item-cell > .b-radiobutton .b-radiobutton__label  {
		max-width: 200px;
	}
}
