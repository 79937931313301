@import (reference) "~assets/css/imports";

.b-customer {
	width: 100%;

	&__attention {
		height: 16px;
		width: 16px;
		display: inline-block;
		vertical-align: middle;
		margin: 16px 0 12px 5px;
	}
}

.b-customer__header {
	background: @field2-background-color;
	padding: 12px 15px;
	height: auto;
	line-height: 22px;
	color: @field2-H1-color;
	font-size: @base-H1-size;
	border-left: @field2-border;
	border-right: @field2-border;
	border-top: @field2-border;
	text-transform: uppercase;
	.shadow;
}

.b-customer__info {
	background: @field1-background-color;
	border-left: @field1-border;
	border-right: @field1-border;
	padding: 0 10px;
	margin-top: -2px;
	.clearfix;
	.rounded-top;
	.shadow;
}

.b-customer--disabled {
	.l-grid-layout-33 {
		position: relative;
	}

	.b-customer__allow-edit {
		width: 24px;
		height: 20px;
		display: block;
		position: absolute;
		top: calc(~'50% - 8px');
		right: 10px;
		cursor: pointer;
		z-index: 2;
	}
}

.b-customer-train {
	.b-customer__info-message {
		margin: 15px 0;
	}
}

@media @tablet {
  .b-customer:not(.b-payment-types__customer) .b-customer__header {
	display: flex;
	align-items: center;

	&:before {
	  width: 22px;
	  height: 22px;
	  content: ' ';
	  display: block;
	  margin-right: 10px;
	  .g-icon-contact-info-black;
	}
  }
}
