@import (reference) "~assets/css/imports";

@legButtonsHeight: 70px;
@segmentButtonsHeight: 70px;
@modalHeaderHeight: 40px;
@modalMaxHeight: 500px;

.b-ancillary-fees {
  width: 70%;
  height: fit-content;
  max-height: @modalMaxHeight;

  .flex-container {
	display: flex;
	align-items: center;
	width: 100%;
  }

  &__modal {
	position: fixed;
	top: 0;
	bottom: 0;
	z-index: 9999;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.5);
	min-height: 100vh;
  }

  &__modal-content-container {
	height: auto;
	width: 100%;
	background: #fff;
  }

  &__modal-header {
	background: #4A5767;
	width: 100%;
	color: #fff;
	position: relative;
	font-size: 14px;
	height: @modalHeaderHeight;
	padding: 6px 20px;
	display: flex;
	align-items: center;
  }

  &__modal-content {
	display: flex;
	flex-direction: column;
	width: 100%;
  }

  &__legs-buttons {
	float: left;
	width: 100%;
	display: flex;
	height: @legButtonsHeight;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
	border-bottom: 1px solid #EDEEF0;
	padding: 10px 22px 10px 16px;
  }

  &__segments-buttons {
	width: 100%;
	display: flex;
	align-items: center;
	height: @segmentButtonsHeight;
	justify-content: flex-start;
	gap: 10px;
	padding: 10px 22px 10px 16px;
  }

  &__leg-button, &__segment-button {
	background: #FFFFFF;
	color: #4882BD;
	padding: 10px 5px;
	display: flex;
	align-items: center;
	font-size: 12px;
	height: 30px;
	.border-radius(4px);
	border: 1px solid #4882BD;
	cursor: pointer;
	gap: 10px;
	font-weight: 500;
	line-height: 14px;
	font-family: "Roboto", "Segoe UI", "Arial", sans-serif;

	& > span {
	  font-weight: 500;
	  line-height: 14px;
	  font-family: "Roboto", "Segoe UI", "Arial", sans-serif;
	}

	&.active, &:hover {
	  color: #FFFFFF;
	  background: #4882BD;

	  & .b-ancillary-fees__segment-button-tooltip {
		border-color: #FFFFFF;
	  }

	  & .b-ancillary-fees__segment-button-icon {
		display: block;
		width: 14px;
		height: 14px;
		.g-icon-segment-aircraft--white;
	  }
	}
  }

  &__segment-button-icon {
	display: block;
	width: 20px;
	height: 20px;
	.g-icon-segment-aircraft;
  }

  &__segment-button-tooltip {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	background-color: #4A5767;
	color: #FFFFFF;
	border: 1px solid #4A5767;
	width: 15px;
	height: 15px;
	font-size: 9px;
	border-radius: 50%;
	padding: 5px;
  }

  .segment-tooltip {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	& > div {
	  white-space: nowrap;
	  width: min-content;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	}

	& > div + & > div {
	  margin-right: 5px;
	}
  }

  &__traveller-views {
	flex: 1 1 100%;
  }

  &__traveller-view {
	width: 100%;
	height: 100%;
  }

  &__service-list {
	padding: 10px 0;
	display: flex;
	align-self: flex-start;
	flex: 1 1 45%;
	flex-direction: column;
	min-width: 200px;
	height: ~'calc(@{modalMaxHeight} - @{modalHeaderHeight} - @{legButtonsHeight} - @{segmentButtonsHeight})';
	gap: 8px;
	justify-content: flex-start;
	overflow-y: auto;
	border-top: 1px solid #EDEEF0;
	border-right: 1px solid #EDEEF0;
	margin-right: 10px;
  }

  &__service-item {
	display: flex;
	padding: 0 16px;
	cursor: pointer;
	color: #4A5767;
	align-items: center;
	height: 40px;
	gap: 10px;

	&:before {
	  content: ' ';
	  display: block;
	  width: 20px;
	  height: 20px;
	}

	&[data-service-type="BAGGAGE"]:before {
	  .g-icon-anc-fees-BAGGAGE;
	}

	&[data-service-type="MEALS"]:before {
	  .g-icon-anc-fees-MEALS;
	}

	&[data-service-type="OTHER"]:before {
	  .g-icon-anc-fees-TRAVEL_SERVICES;
	}

	&.active, &:hover {
	  background-color: #EDEEF0;
	}
  }

  &__service-content-list {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex: 1 1 100%;
	padding: 20px 10px;
	width: 100%;
	height: ~'calc(@{modalMaxHeight} - @{modalHeaderHeight} - @{legButtonsHeight} - @{segmentButtonsHeight})';
	overflow-y: auto;
	gap: 10px;
	color: #4A5767;
	margin-right: 10px;
	border-top: 1px dashed rgba(0, 0, 0, .3);
  }

  &__service-content-item {
	border-bottom: 1px dashed rgba(0, 0, 0, .3);
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;

  }

  .b-service {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	min-height: 50px;

	&__price-block {
	  color: #4A5767;
	  display: flex;
	  align-items: center;
	  min-width: 32%;
	  justify-content: space-evenly;

	  .b-numerable-picker__input {
		color: #4A5767;
	  }
	}

	&__price {
	  min-width: 50px;
	}
  }

  &__traveller-prices {
	background: #EDEEF0;
	flex: 1 1 60%;
	min-width: 200px;
	height: ~'calc(@{modalMaxHeight} - @{modalHeaderHeight} - @{legButtonsHeight})';
	display: flex;
	padding: 0 10px;
	gap: 20px;
	flex-direction: column;
	align-self: flex-start;

	&-total {
	  display: flex;
	  justify-content: flex-end;
	  gap: 10px;
	}
  }

  .b-travellers-prices {
	display: table;
	width: 100%;

	&__row-head {
	  border-bottom: 1px solid #A4ABB3;
	}

	&__cell-head {
	  text-transform: uppercase;
	}

	&__row {
	  border-bottom: 1px solid #A4ABB3;
	  cursor: pointer;
	  padding: 24px 6px;

	  border-left: 2px solid transparent;
	}

	&__row[data-action] {

	  &.active, &:hover {
		background: #FFFFFF;
		border-left-color: #4882BD;
		box-shadow: inset 4px 0 0 #4882BD;
	  }
	}

	&__cell {
	  padding: 10px;
	  text-align: left;
	}

	&__fullName {
	  font-size: 14px;
	  text-transform: capitalize;
	}

	&__service-count {
	  text-align: center;
	}

	&__sum {
	  text-align: right;
	}

	&__service-count, &__sum {
	  font-weight: 600;
	  font-size: 16px;
	}

	&__total-caption {
	  text-align: center;
	}

	&__total-price {
	  text-align: right;
	}

	&__total-caption, &__total-price {
	  font-weight: 600;
	  font-size: 16px;
	}
  }

  &__modal-buttons {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 40px;

	& > button {
	  border: none;
	  padding: 10px 40px;
	  position: relative;
	  cursor: pointer;


	  &:after,
	  &:before {
		content: ' ';
		position: absolute;
		width: 50px;
		top: 0;
		height: 100%;
		z-index: -1;
		cursor: pointer;
	  }

	  &:before {
		transform-origin: right bottom;
	  }

	  &:after {
		transform-origin: left bottom;
	  }
	}
  }

  &__back {
	background: #fff;
	color: #4A5767;

	&:after,
	&:before {
	  border-color: #fff;
	  background: #fff;
	}

	&:after {
	  right: 0;
	  transform: skew(320deg);
	}

	&:before {
	  left: 0;
	  transform: skew(-320deg);
	}
  }

  &__save {
	background: #4882BD;
	color: #fff;

	&:after {
	  right: 0;
	  transform: skew(320deg);
	}

	&:before {
	  left: -40px;
	  transform: skew(320deg);
	}

	&:after,
	&:before {
	  background: #4882BD;
	  border-color: #4882BD;
	}
  }
}
