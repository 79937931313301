@import (reference) "~assets/css/imports";

/* OLD STYLES */
.b-search-form__params {
	.clearfix;
	display: flex;
	align-items: center;
	width: 100%;
}

.b-search-form__param-direct {
  	display: flex;
	min-width: 160px;
	margin-right: 15px;
}

.b-search-form__param-toggles,
.b-search-form__param-checkboxs,
.b-search-form__param-multiticket,
.b-search-form__param-classes,
.b-search-form__param-complex,
.b-search-form__param-buttons {
  	display: inline-flex;
  	margin-right: 21px;

	.b-toggle__label:last-of-type {
		margin-right: 0;

	}
}

.b-search-form__param-buttons {
	display: none;
	margin-right: 0;
	position: absolute;
	right: 0;

	.b-search-form__param-button {
		width: unset;
		& + .b-search-form__param-button {
			margin-left: 10px;
		}
	}
}

.b-search-form__param-airlines {
  	display: flex;
	min-width: 300px;
	padding-right: 12px;
}

.b-search-form__param-flight-number {
	display: flex;
}

.b-search-form__param-flight-number .b-input {
	padding-bottom: 0;
}

.b-search-form__param-flight-number .b-input__value {
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	color: #fff;
	background-color: #4A5767;
	border: 1px solid #A4ABB3;
	border-radius: 3px;
	padding: 11px;
	outline: none;
	box-sizing: border-box;

	&[disabled] {
		color: #aaa;
		&::placeholder {
			color: #aaa;
		}
	}
	&::placeholder {
		color: #fff;
	}
}

 /* NEW STYLES */
 .p-search-form__wrapper {
 	&.is-hotels-search {
 		.p-search-form {
 			.p-search-form__row .p-search-form__group .p-search-form__element__dates .p-search__options-date .p-search__options-date-element {
				width: 205px;
 			}

			.p-search__direction-city {
				flex-grow: 10;

				&-input {
					width: 100%;
					height: 33px;
					border-radius: 3px;
					background: #FFFFFF;
					color: #4A5767;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 14px;

					@media @desktop-min-width {
						height: 41px;
						font-size: 16px;
						line-height: 16px;
					}
				}

				&-input::placeholder {
					color: #4A5767;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					opacity: 0.5;

					@media @desktop-min-width {
						font-size: 16px;
					}
				}
			}

 			.p-search__direction-city-input,
 			.p-search__direction-date-input {
 				height: 41px;
				border-radius: 3px 3px 0 0;
				border: 1px solid #fff;
 			}

 			.b-company-employees-service__wrapper {
 				.b-passengers,
 				.b-passengers__wrapper,
 				.b-numerable-picker,
 				.b-numerable-picker__wrapper,
 				.b-search-form__params {
					width: 205px;
					min-width: 205px;
					max-width: 205px;
					margin-right: 20px;
 				}

	 			.b-search-form__employees {
		 			.b-company-employees__company {
		 				margin-left: 20px;
						margin-top: 9px;
		 			}

		 			.l-grid-layout-50 {
		 				width: 100%;
		 			}

		 			.b-company-employees__retail {
		 				.b-passengers-hotels,
		 				.b-numerable-picker-hotels {
		 					display: inline-block;
		 					vertical-align: middle;
		 				}
		 			}

		 			.b-search-room-row .b-search-room-employees .b-employees-collection {
						width: 245px;

						.b-search-employee-row {
							margin-top: 8px;
							&:not(.b-employees-collection__content) {
								width: 205px;
							}

							& + .b-search-employee-row {
								margin-top: 20px;

							}

							.b-search-employee {
								width: 205px;
							}
						}
		 			}

					.b-search-room-row .b-search-room-employees .validations-errors__container {
						max-height: 40px;
						overflow-y: auto;
						display: flex;
						flex-direction: column;
						gap: 5px;
						scrollbar-width: thin;
  					scrollbar-color: #c4c4c4;
					}

					.b-search-room-row .b-search-room-employees .validations-errors__container::-webkit-scrollbar {
						-webkit-appearance: none;
						width: 14px;
						height: 14px;
						background: #F6F9FB;
					}
					
					.b-search-room-row .b-search-room-employees .validations-errors__container::-webkit-scrollbar-thumb {
						background-color: #c4c4c4;
						border-radius: 20px;
						border: 2px solid #F6F9FB;
						transition: background-color .2s ease-out;
					}
					
					.b-search-room-row .b-search-room-employees .validations-errors__container::-webkit-scrollbar-thumb:hover { 
						background-color: #A4ABB3;
					}
					
					.b-search-room-row .b-search-room-employees .validations-errors__container::-webkit-scrollbar-thumb:active { 
						background-color: #4A5767;
					}

	 			}
 			}
 		}
 		.p-search__direction {
 			margin-bottom: 10px;
 		}
 		.p-search-form__element__service-params {
 			margin-top: 0 !important;
 		}
 	}

   	&.is-transfer-search {
	 .p-search-form {

	   &__element__service-params {
		 position: relative;
	   }

	   &__row-with-border {
		 margin-bottom: 20px;
	   }

	   &__transfer-row {
	     display: flex;
	     justify-content: flex-start;
	     width: 100%;
	   }
	 }

	  .p-search__direction {

		.b-geocoder-input__value {

		}
	  }
	  .p-search__direction-swap {
		height: 33px;

		@media @desktop-min-width {
		  height: 41px;
		}
	  }
   }
 }


.p-search-form__wrapper {
	&.single-person {
		.b-rooms-collection__content,
		.b-employees-collection__add {
			display: none;
		}
	}
}

.p {
	&-search-form {
		padding: 24px 12px 28px 12px;
		background-color: #4A5767;
		border-radius: 3px;
		width: 100%;
		box-sizing: border-box;

		.b-checkbox {
			color: #fff;
			height: unset;

			&__view {
				&:after,
				&:before {
					color: #fff;
					border-color: #fff;
				}
				&:after {
					background: #fff;
				}
			}

			.b-checkbox__input:checked + .b-checkbox__view {
				color: #fff;
				&:after,
				&:before {
					color: #fff;
					border-color: #fff;
				}

				&:after {
					background: #fff;
				}
			}
		}

		&__additional-orders {
			border: 1px solid #2F3842;
			border-top: 0;
			position: relative;
			bottom: 2px;
			padding-top: 2px;

			&-title {
				font-size: 18px;
				text-align: center;
				margin: 5px 0;
				cursor: pointer;
			}

			&-container {
				display: none;
				position: relative;
				margin-top: 30px;
				padding-bottom: 45px;
			}

			.p-search-form__additional-orders-expand {
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: 2;
				background-color: #fff;
				border-radius: 0 3px;
				border-width: 0 0 1px 1px;
			}
		}

		&__additional-order {
		  	z-index: 10;
			position: absolute;
			left: 0;
			top: -5px;
			color: #fff;
			text-transform: uppercase;

			.g-icon-w-cancel {
				height: 10px;
				width: 10px;
				display: inline-block;
				margin-right: 8px;
				cursor: pointer;
			}

		  .back-to-order-link {
			cursor: pointer;
		  }
		}

		.b-search-form__param-flight-number,
		.b-search-form__params,
		.b-search-employee {
			.validation-error .b-input__value {
				background-image: linear-gradient(#e50000, #e50000), linear-gradient(#e50000, #e50000), linear-gradient(#e50000, #e50000) !important;
			}
		}

		.p-search-form__group-button {

			justify-content: flex-end;

			.p-search-form__submit,
			.p-search-form__add {
				border-radius: 3px;
				height: 35px;
				cursor: pointer;
				width: 414px;
				max-width: 414px;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;

				&.disabled {
					opacity: .7;
					cursor: default;
				}

				a {
					font-size: 14px;
                	line-height: normal;
                	color: #fff;

                	@media @desktop-min-width {
						font-size: 16px;
					}
                }
			}

		  	.p-search-form__submit {
			  background: #4882BD;
			}

			.p-search-form__add:after {
			  content: ' ';
			  display: block;
			  width: 18px;
			  height: 18px;
			  margin-left: 10px;
			  .g-icon-add-orange;
			}

		  	.disabled.p-search-form__add:after {
			  display: none;
			}
		}

		.b-company-employees-service__wrapper {

			align-items: start;

			.b-search-form__employees {

				.b-employees-collection {
					display: flex;
					flex-direction: column;
					position: relative;
					width: 340px;

					.l-grid-layout-33:nth-of-type(1) {
						margin-top: 0;
					}

					.l-grid-layout-33 {

						width: 300px;
						margin-top: 10px;


						.b-search-employee {
							width: 300px;

							.b-input__value {
								border-radius: 3px;
								background: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
								background-size: 1px 50%, 1px 50%;
								background-position: bottom left, bottom right;
								background-repeat: no-repeat;
								border: 0;
							  	border-bottom: 1px solid #fff;
								@media @desktop-min-width {
									font-size: 16px;
								}
							}

							.b-input__remove {
								border: 0;
								background-image: url("~assets/img/icon-collection-delete-white.svg");
							}

						}
					}

					.b-employees-collection__content {
						width: 40px;
						position: absolute;
						bottom: 0;
						align-self: flex-end;

						.b-employees-collection__add {
							border: 0;
							background-image: url("~assets/img/icon-collection-add-white.svg");
						}
					}

				}

			}

			.b-passengers__child-age__item {
				border-top: 1px solid rgba(255,255,255, 0.4);
			}

			.b-search-form__employees {
				margin-left: 0;
				min-height: 35px;
			}

			.b-numerable-picker,
			.b-passengers {
				width: 300px;
			}

			.b-numerable-picker__popup,
			.b-passengers__popup {
				border: 0;
				.shadow;
			}

			.b-numerable-picker__info,
			.b-passengers__info {
				background: #4A5767;
				color: rgba(255,255,255, 0.8);
				position: initial;
				margin-top: 0;
			}

			.b-numerable-picker__list,
			.b-passengers__list {
				padding: 0;
				margin: 0;

				.b-numerable-picker__item,
				.b-passengers__item {
					padding-left: 25px;
					color: #fff;

					.b-numerable-picker__item-minus,
					.b-passengers__item-minus {
						background: none;
						top:7px;
					}

					.b-numerable-picker__item-plus,
					.b-passengers__item-plus {
						background: none;
						top:7px;
					}
				}
			}

			.b-passengers.b-passengers-avia .b-passengers__list {
				margin-bottom: 10px;
			}

			.b-numerable-picker-complex:after,
			.b-passengers-complex:after {
				content: none;
			}

			.b-numerable-picker__wrapper,
			.b-passengers__wrapper {
				width: 300px;

				border-radius: 3px;
				background: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
				background-size: 1px 50%, 1px 50%;
				background-position: bottom left, bottom right;
				background-repeat: no-repeat;
			  	border-bottom: 1px solid #fff;

				.b-numerable-picker__input,
				.b-passengers__input {
					border: none;

					@media @desktop-min-width {
						font-size: 16px;
					}

					&::placeholder {
						color: rgba(255, 255, 255, 0.4);
						@media @desktop-min-width {
							font-size: 16px;
						}
					}
				}

				.b-numerable-picker__select,
				.b-passengers__select {
					border: 0;
					background: none;

					.b-numerable-picker__select-img,
					.b-passengers__select-img {
						.g-icon-arrow-white-down;
						background-size: 12px;
						background-position: 50% 55%;
					}
				}

				.b-numerable-picker__input:after,
				.b-passengers__input:after {
					display: none;
				}

			}

			.b-search-form__params {

				width: 300px;
				max-width: 300px;
				margin-right: 28px;

				.b-input__value {

					height: 30px;
					padding: 10px;

					background: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
					background-size: 1px 50%, 1px 50%;
					background-position: bottom left, bottom right;
					background-repeat: no-repeat;

					border: none;
				  	border-bottom: 1px solid #fff;

					&:disabled {
						opacity: .5;
					}

					@media @desktop-min-width {
						font-size: 16px;
					}

					&::placeholder {
						color: rgba(255, 255, 255, 0.4);
						@media @desktop-min-width {
							font-size: 16px;
						}
					}
				}

			  .b-input__remove {
				border: 0;
				background-image: url("~assets/img/icon-collection-delete-white.svg");
			  }

			}

			.b-numerable-picker__popup {
				right: 0;
			}

			.b-passengers-hotels .b-passengers__popup {
				right: calc(~'50% - 14px');
			}

			.b-numerable-picker__popup,
			.b-passengers-hotels .b-passengers__popup {
				box-shadow: -3px 4px 10px rgba(0, 0, 0, 0.25), -1px 0 1px rgba(0, 0, 0, 0.25);
				top: 23px;
				border-radius: 3px;
				position: relative;
				box-sizing: border-box;

				&:before {
					content: "";
					position: absolute;
					width: 0;
					height: 0;
					top: -16px;
					right: -6px;
					box-sizing: border-box;

					border: 12px solid black;
					border-color: #4A5767;

					transform-origin: 0 0;
					transform: rotate(45deg);

					box-shadow: -3px 4px 10px rgba(0, 0, 0, 0.25), -1px 0 1px rgba(0, 0, 0, 0.25);
				}
			}
		}

		.b-search-form__param-flight-number {

			width: 300px;

			.b-input__value {

				width: 100%;

				height: 30px;
				padding: 10px;

				background: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
				background-size: 1px 50%, 1px 50%;
				background-position: bottom left, bottom right;
				background-repeat: no-repeat;

				border: none;
			  	border-bottom: 1px solid #fff;

				@media @desktop-min-width {
					font-size: 16px;
				}

				&::placeholder {
					color: rgba(255, 255, 255, 0.4);

					@media @desktop-min-width {
						font-size: 16px;
					}
				}
			}

		}

        .b-search-form__param-airlines {

        	width: 300px;
        	padding-right: 0;
        	margin-right: 28px;

        	.b-airlines.active {
        		color: #fff;

				> .b-airlines__wrapper {
					.b-airlines__label {
						color: #fff;
						overflow: hidden;
						text-overflow: ellipsis;

						@media @desktop-min-width {
							font-size: 16px;
						}

					}
				}
        	}

			.b-airlines {

				> .b-airlines__wrapper {
					padding: 1px;

					background: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
					background-size: 1px 50%, 1px 50%;
					background-position: bottom left, bottom right;
					background-repeat: no-repeat;
					border-radius: 3px;
					border: none;
				  	border-bottom: 1px solid #fff;

					.b-airlines__label {
						color: rgba(255, 255, 255, 0.4);
						text-transform: capitalize;
					}

					.b-airlines__label,
					.b-airlines__select {
						border: none;
						height: 28px;

						@media @desktop-min-width {
							font-size: 16px;
						}
					}

					.b-airlines__select-img {
						.g-icon-arrow-white-down;
						background-size: 12px;
						background-position: 50% 55%;
					}
				}

				.b-airlines__item-city {
				    color: #fff;
                    font-size: 12px;

                    @media @desktop-min-width {
						font-size: 14px;
					}
				}

				.b-airlines__item_selected {
					height: 35px;
					font-size: 12px;

					@media @desktop-min-width {
						font-size: 14px;
					}
				}

				.b-airlines__input {
					border-radius: 0;
				}

				.b-airlines__input::placeholder {
					color: rgba(255, 255, 255, 0.4);
					@media @desktop-min-width {
						font-size: 16px;
					}
				}

				.b-airlines__item-code {
                	.g-icon-w-cancel;
					height: 7px;
                }

			}
        }

        .p-search-form__container {
        	min-width: 930px;
			max-width: 1280px;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: space-between;
        }

		.p-search-form__row {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			position: relative;

			&-routes {
				margin-top: 24px;
			}

			&-with-border.empty-row {
				margin-bottom: 17px;

				.b-attachment {
					display: none;
				}

			}
			&-with-border {
				border-top: 1px dashed #fff;
				margin-top: 20px;
			}

			&-footer {
				align-items: self-start;
			}

			.p-search-form__group:last-of-type {
				margin-right: 0;
			}


			.p-search-form__group {
				display: flex;
				flex-direction: row;
				flex-grow: 1;
				margin-right: 10px;

				&.p-search-form__group-button {
					flex-grow: 0;
				}

				&__routes {
					flex-grow: 100;
				}

				.p-search-form__element__routes {
					width: 100%;
				}


				.p-search-form__element__service-params {
					display: flex;
					margin-top: 20px;

					.is-avia-search & {
						align-items: center;
					}
				}


				.p-search-form__element__service-types {
					margin: 0 auto;
				}
				.p-search-form__options-type-list {
					.active {
						.service-type-img {
							background-color: #4882BD;
						}
					}
				}

				.p-search-form__element__dates {


					.p-search__options-date {
						display: flex;

						.p-search__options-date-element {
							width: 200px;
						}


						&-element:last-of-type {
							margin-right: 0;
						}

						&-element {
							margin-right: 10px;
						}

						.w-timerange:not(.open) .w-timerange__select-img {
						  background: url('~assets/img/icon-timerange-dark.svg') no-repeat center;
						}

						.w-timerange__suggest-list-point {
							padding: 0;

							&:hover {
								background: #ffffff;
							}

							&:hover .w-timerange__suggest-list-daytype {
								color: #4A5767;
							}

							&:hover .w-timerange__suggest-list-daytime {
								color: #4A5767;
							}
						}

						.w-timerange__suggest-list-point-wrapper {
							justify-content: center;
                            border-bottom: 1px solid rgba(255,255,255,0.3);
						}

						.w-timerange__wrapper {
							position: relative;

							.w-timerange__select {
								height: 33px;
								position: absolute;
								right: 0;
								background: none;
								border: 0;

								@media @desktop-min-width {
									height: 41px;
								}

								&-img {
								  background: url('~assets/img/icon-clear-dark.svg') no-repeat center;
								}

							}

							.w-timerange__input {
								width: 100%;
								height: 33px;
								background: #fff;
								border-color: #fff;
								border-top-left-radius: 0;
								border-top-right-radius: 0;
								text-align: center;
								color: #4A5767;
								font-style: normal;
								font-weight: normal;
								font-size: 14px;
								line-height: 14px;

								@media @desktop-min-width {
									height: 41px;
									font-size: 16px;
									line-height: 16px;
								}

							}

						}

						.w-timerange__suggest-list-daytime {
							color: #ffffff;

							@media @desktop-min-width {
								font-size: 14px;
							}
						}

					  	.w-time .b-input__value {
							width: 100%;
							height: 33px;
							background: #fff;
							border-color: #fff;
							border-top-left-radius: 0;
							border-top-right-radius: 0;
							text-align: center;
							color: #4A5767;
							font-style: normal;
							font-weight: normal;
							font-size: 14px;
							line-height: 14px;

							@media @desktop-min-width {
							  height: 41px;
							  font-size: 16px;
							  line-height: 16px;
							}
						}


					}
				}
			}

			.p-search-form__group__routes {
				flex-direction: column;
			}
		  	.p-search-form__element__routes, .p-search-form__element__dates-container, .p-search-form__element__additional-options {
			  & .validations-errors__container {
					bottom: 41px;
			  }
			}
		}

		.p-search {

			&__bridge {
				width: 13px;
				height: 1px;
				margin-top: 20px;
				background-color: #A4ABB3;
			}

			&-wrapper {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 100%;
			}

			&__input {
				font-family: 'Roboto', sans-serif;
				font-size: 14px;
				color: #fff;
				background-color: #4A5767;
				border: 1px solid #A4ABB3;
				border-radius: 3px;
				padding: 8px;
				outline: none;
			  	margin-top: unset;
				box-sizing: border-box;

				&[disabled] {
					color: #aaa;
					&::placeholder {
						color: #aaa;
					}
				}
				&::placeholder {
					color: #fff;
				}
			}

			&__direction {
				display: flex;
				flex-direction: row;
				width: 100%;
				margin-bottom: 18px;
				justify-content: space-between;

				&-block {
					position: relative;
				}

				&-suggest {
					text-transform: uppercase;
					line-height: 40px;
					position: absolute;
					right: 0;
					top: 0;
				}

				&-wrapper-dates {
				  display: flex;
				  flex-direction: column;
				  max-width: 355px;
				}

				&-wrapper {
					display: flex;
					flex-direction: row;
					flex-basis: 100%;
					height: 40px;

					&_no-margin {
						margin: 0;
					}

					&_width-100 {
						width: 100%;
					}

					&_width-70 {
						width: 70%;
					}

					&_width-30 {
						width: 30%;
					}
				}

				&-departure {

					flex-grow: 10;

					&-input {
						width: 100%;
						height: 33px;
						border-radius: 3px;
						background: #FFFFFF;
						color: #4A5767;
						font-style: normal;
						font-weight: normal;
						font-size: 14px;
						line-height: 14px;

						@media @desktop-min-width {
							height: 41px;
							font-size: 16px;
							line-height: 16px;
						}
					}

					&-input::placeholder {
						color: #4A5767;
						font-style: normal;
						font-weight: normal;
						font-size: 14px;
						opacity: 0.5;

						@media @desktop-min-width {
							font-size: 16px;
						}
					}
				}

				&-arrival {

					flex-grow: 10;

					&-input {
						width: 100%;
						height: 33px;
						border-radius: 3px;
						background: #FFFFFF;
						color: #4A5767;
						font-style: normal;
						font-weight: normal;
						font-size: 14px;
						line-height: 14px;
						padding-left: 16px;

						@media @desktop-min-width {
							height: 41px;
							font-size: 16px;
							line-height: 16px;
						}
					}

					&-input::placeholder {
						color: #4A5767;
						font-style: normal;
						font-weight: normal;
						font-size: 14px;
						opacity: 0.5;

						@media @desktop-min-width {
							line-height: 16px;
						}
					}
				}

				&-swap {
					position: relative;
					display: flex;
					align-items: center;
					width: 10px;

					&-img {
					  	background: url("~assets/img/icon-swap-n.svg") no-repeat center;
					  	height: 24px;
						width: 24px;
						color: #fff;
						position: absolute;
						z-index: 10;
						cursor: pointer;
						left: -7px;
					}
				}

				&-date {
					display: flex;
					flex-direction: row;
					align-items: center;
					position: relative;
					margin-bottom: 4px;

					@media @desktop-min-width {
						margin-bottom: 14px;
					}

					&-calendar {
						font-family: 'Roboto', sans-serif;
						font-size: 14px;
						color: #fff;
						background-color: unset;
						border: 0;
						width: 34px;
						height: 34px;
						position: absolute;
						right: 0;

						&-img {
						  background: url("~assets/img/icon-calendar-black.svg") no-repeat center;
						  width: 100%;
							height: 100%;

						}

						&-img.b-datepicker__remove {
						  background: url("~assets/img/icon-clear-dark.svg") no-repeat center;
						}
					}

					&-input::placeholder {
						color: #4A5767;
						opacity: 0.5;
						font-style: normal;
						font-weight: normal;
						font-size: 14px;

						@media @desktop-min-width {
							font-size: 16px;
						}
					}
					&-input {
						width: 100%;
						height: 33px;
						border-bottom-left-radius: 0;
						border-bottom-right-radius: 0;
						border-right: 0;
						border-color: #fff;
						background: #fff;
						color: #4A5767;
						font-weight: normal;
                        font-size: 14px;
						text-align: center;

						@media @desktop-min-width {
							height: 41px;
							font-size: 16px;
							line-height: 16px;
						}

					}

				}

			}

		}

		&__options-type {

			&-list {
				display: flex;
				flex-direction: row;
				align-items: center;

				.service-type:last-of-type .service-type-img {
					border-radius: 12px;
					border-bottom-left-radius: 0;
					border-top-left-radius: 0;
				}

				.service-type:first-of-type {
					margin-left: 0;
				}

				.service-type:first-of-type .service-type-img {
					border-radius: 12px;
					border-bottom-right-radius: 0;
					border-top-right-radius: 0;
				}

				.service-type.disabled {
					cursor:initial;
				}

				.service-type {
					width: 61px;
					height: 27px;
					margin-left: 1px;
					cursor: pointer;

					&-img {
						width: 100%;
						height: 100%;
						background-position: center;
						background-repeat: no-repeat;
						background-color: #ffffff;
					}

					&-avia {
						&-img {
							background-image: url("~assets/img/icons/icon-avia.svg");
						}
					}
					&-avia.active {
						.service-type-avia-img {
							background-image: url("~assets/img/icons/icon-avia-active.svg");
						}

					}
					&-avia.disabled {
						.service-type-avia-img {
							background-image: url("~assets/img/icons/icon-avia-disabled.svg");
						}
					}

					&-train {
						&-img {
							background-image: url("~assets/img/icons/icon-train.svg");
						}
					}
					&-train.active {
						.service-type-train-img {
							background-image: url("~assets/img/icons/icon-train-active.svg");
						}

					}
					&-train.disabled {
						.service-type-train-img {
							background-image: url("~assets/img/icons/icon-train-disabled.svg");
						}
					}
					&-hotel {
						&-img {
							background-image: url("~assets/img/icons/icon-hotel.svg");
						}
					}
					&-hotel.active {
						.service-type-hotel-img {
							background-image: url("~assets/img/icons/icon-hotel-active.svg");
						}

					}
					&-hotel.disabled {
						.service-type-hotel-img {
							background-image: url("~assets/img/icons/icon-hotel-disabled.svg");
						}
					}
					&-auto {
						&-img {
							background-image: url("~assets/img/icons/icon-auto.svg");
						}
					}
					&-auto.active {
						.service-type-auto-img {
							background-image: url("~assets/img/icons/icon-auto-active.svg");
						}

					}
					&-auto.disabled {
						.service-type-auto-img {
							background-image: url("~assets/img/icons/icon-auto-disabled.svg");
						}
					}
					&-relax {
						&-img {
							background-image: url("~assets/img/icons/icon-relax.svg");
						}
					}
					&-relax.active {
						.service-type-relax-img {
							background-image: url("~assets/img/icons/icon-relax-active.svg");
						}

					}
					&-relax.disabled {
						.service-type-relax-img {
							background-image: url("~assets/img/icons/icon-relax-disabled.svg");
						}
					}
				  	&-transfer {
					  	.service-type-transfer-img {
							background-image: url("~assets/img/icons/icon-transfer.svg");
					  	}
					}
				  	&-transfer.active {
					  	.service-type-transfer-img {
							background-image: url("~assets/img/icons/icon-transfer-active.svg");
					  	}
				  	}
				  	&-transfer.disabled {
					  	.service-type-transfer-img {
							background-image: url("~assets/img/icons/icon-transfer-disabled.svg");
					  	}
				  	}

					&-text {
						color: #fff;
						font-size: 14px;
						font-family: 'Roboto', sans-serif;
						margin-right: 9px;
						white-space: nowrap;
					}

					&-list {
						display: flex;
						flex-direction: row;
						align-items: center;

						&-point {
							width: 30px;
							height: 30px;
							margin-left: 14px;
							cursor: pointer;
							&_no-margin {
								margin-left: 0;
							}
						}
					}

				}

			}
		}

	}
}

@media (max-width: 1440px) {
	.p-search-form {
		.p-search-form__group-button {
			.p-search-form__submit,
			.p-search-form__add {
				width: 272px;
			}
		}
	}
}

@media @desktop-1440-width {

	.p-search-form {

		.p-search-form__group-button {
			.p-search-form__submit,
			.p-search-form__add {
				width: 312px;
			}
		}

		.p-search-form__row {
			.p-search-form__group {
				.p-search-form__element__dates {

					.p-search__options-date {

						.p-search__options-date-element {
							width: 150px;
						}
					}
				}
			}
		}
	}
}

@media @desktop-1280-width {

	.p-search-form {

		.p-search-form__group-button {
			.p-search-form__submit,
			.p-search-form__add {
				width: 272px;
			}
		}

		.p-search-form__row {
			.p-search-form__group {
				.p-search-form__element__dates {

					.p-search__options-date {

						.p-search__options-date-element {
							width: 130px;
						}

						.w-timerange__wrapper {

							.w-timerange__input {
								font-size: 12px;
							}

							.w-timerange__select {
								width: 20px;
							}

							.w-timerange__select-img {
								padding: 7px 7px 10px;
							}

						}

						.w-timerange:not(.open) .w-timerange__select-img {
							width: 10px;
							background-size: 10px;
						}
					}
				}
			}
		}


	}
}

@media (min-width: 1920px) {
	.p-search-form__additional-orders {
		margin: 0 40px;
	}
}


@import './style_mobile';
