.b-ticket-pricing {
	font-size: 14px;
	color: #fff;
	.rounded-bottom;
	display: flex;

    &.no-max-width {
	    max-width: 500px;
    }
}
.inherit-color .b-ticket-pricing {
  color: inherit;
}

.b-ticket-pricing__direction {
	display: block;
	color: @field2-H2-color;
	text-transform: uppercase;
}
.inherit-color .b-ticket-pricing__direction {
  color: inherit;
}
.b-ticket-pricing__header {
	border-bottom: 1px dashed #fff;
	padding-bottom: 15px;
	margin-bottom: 10px;
	display: inline-block;
	width: auto;
	min-width: 120px;
}

.b-ticket-pricing__bordered-wrapper {
	border-top: 1px dashed #fff;
	padding-top: 6px;
}

.b-ticket-pricing__bordered-wrapper--with-bb {
	border-bottom: 1px dashed #fff;
}

.b-ticket-pricing__content {
	margin-top: 10px;
	margin-bottom: 25px;
}

.b-ticket-pricing__section {
	display: inline-block;
	line-height: 20px;
	width: 100%;
}

.b-ticket-pricing__vat-section {
  	display: flex;
  	flex-direction: column;
  	align-items: center;
  	color: #fff;

  	&-info {
		zoom: 1;
  	}
}
.inherit-color .b-ticket-pricing__vat-section {
  color: inherit;
}

.b-hotel-pricing {
	.b-ticket-pricing__section {
		& + .b-ticket-pricing__section {
			border-top: 1px dashed #fff;
			padding-top: 5px;
			margin-top: 10px;
		}
	}
}

.b-ticket-pricing_commission {
	display: none;
}

.b-ticket-pricing__section-info {
	color: rgba(255, 255, 255, .8);
	.clearfix
}
.inherit-color .b-ticket-pricing__section-info {
  color: inherit;
}

.b-ticket-pricing__section-title {
	display: block;
	color: #fff;
	font-size: 15px;
	font-weight: 500;
	line-height: 15px;
}
.inherit-color .b-ticket-pricing__section-title {
  color: inherit;
}
.b-ticket-pricing__section-passenger {
	float: left;
}

.b-ticket-pricing__section-price {
	float: right;
	padding-left: 20px;
}

.b-ticket-pricing__total-section {
	color: @field2-active-color;
	height: 36px;
	line-height: 32px;
}
.inherit-color .b-ticket-pricing__total-section {
  color: inherit;
}

.b-ticket-pricing__section-total-title {
	float: left;
}

.b-ticket-pricing__section-total-price {
	float: right;
}

.b-ticket-pricing__content {
	& > .b-ticket-pricing__section {
		& + .b-ticket-pricing__section {
			margin-top: 5px;
		}
		&:last-child {
			border-bottom: 0;
		}
	}
}

.b-ticket-pricing__item {
	flex: 1;
	display: flex;
	flex-flow: column;

	.b-ticket-pricing__direction {
		margin-bottom: 10px;
		text-align: center;
		position: relative;
		text-transform: capitalize;

		& > span {
			position: relative;
			z-index: 2;
			background: #4A5767;
			padding: 0 15px;
		}

		&:after {
			content: '';
			height: 0;
			border-top: 1px solid rgba(255, 255, 255, .8);
			left: 0;
			right: 0;
			position: absolute;
			z-index: 0;
			top: 50%;
		}
	}

	.b-pricing__total-section {
		line-height: unset;
		height: unset;
		padding-top: 10px;
		margin: auto 0 15px;
		font-size: 14px;
		color: #fff;
		border-top: 1px dashed #fff;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.b-pricing__section-total-title {
			font-size: 15px;
			font-weight: 500;
			margin-bottom: 5px;
		}
	}
  .inherit-color .b-pricing__total-section {
	color: inherit !important;
  }

	.b-ticket-pricing__section-title {
		line-height: unset;
	}
}

.b-ticket-pricing__item + .b-ticket-pricing__item {
	margin-left: 10px;
	padding-left: 10px;
	border-left: 1px solid rgba(255, 255, 255, .8);
}

.b-ticket-pricing.with-taxs {
	font-size: 16px;
	line-height: 19px;

	.b-ticket-pricing__content {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.b-ticket-pricing__section {
		line-height: 19px;
		margin-bottom: 3px;
	}

	.b-ticket-pricing__section-info {
		color: #fff;
	}

	.b-ticket-pricing__content {
		&+.b-ticket-pricing__content {
			margin-top: 20px;
		}

		&>.b-ticket-pricing__section {
			&+.b-ticket-pricing__section {
				margin-top: 0;
			}
		}

		&.table-view {
			margin-bottom: 15px;
		}
	}

	.b-ticket-pricing__item {
		.b-pricing__total-section {
			border-top: 1px dashed #fff;
			border-bottom: 1px dashed #fff;

			padding: 15px 0;
			font-size: 16px;
			line-height: 19px;

			margin-bottom: 15px;
			.b-pricing__section-total-title {
				font-weight: bold;
				margin-bottom: 2px;
			}
		}
	}
}

.b-ticket-pricing__title {
	font-weight: bold;
	font-size: 18px;
	line-height: 21px;
	margin-bottom: 10px;

	&.mb-0 {
		margin-bottom: 0;
	}
}
