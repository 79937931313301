@media @tablet {
  .b-block-container,
  .b-booking {

	&__footer {
	  justify-content: unset !important;
	  height: 36px;
	  padding: 0 12px !important;
	  background: none !important;
	}

	&__footer-leftbar,
	&__footer-rightbar {
	  height: 100%;
	  width: 100%;
	}

	&__price-info {
	  .b-pricing__header {
		display: none;
	  }

	  .b-pricing {
		margin: 20px 0;
	  }
	}

	.b-booking__submit {
	  background-color: #099C9D;
	  border-radius: 0 4px 4px 0;
	  height: 100%;
	  width: 100%;
	  border: none;
	}

	.b-booking__back {
	  background-color: #EDEEF0;
	  border-radius: 4px 0 0 4px;
	  height: 100%;
	  width: 100%;
	  color: #A4ABB3;
	  display: flex;
	  border: none;
	  justify-content: center;
	  align-items: center;
	  margin: 0 auto;

	  &:before {
		width: 1em;
		height: 1em;
		margin-right: 10px;
		display: inline-block;
		content: ' ';
		.g-icon-arrow-left;
	  }
	}
  }
}
