@import (reference) "~assets/css/imports";

.b-passengers-hotels {
	.b-passengers__input {
		justify-content: center;
	}

	.b-passengers__list {
		border-radius: 3px;
	}

	.b-passengers__popup {
		overflow: visible;
	  	background-color: #4A5767;
	}

	.b-passengers__select-img {
		&--type-cancel {
			background-image: url('~assets/img/icon-cancel-w.svg') !important;
			transform: none !important;
			cursor: pointer;
		}
	}

	.b-passengers__item {
		padding: 7px 22px 7px 20px;
		margin-right: 3px;
		border-bottom: 1px solid #2F3842;

		&:first-child {
			padding-top: 27px;
		}
		&:last-child {
			border-bottom: 1px solid #2F3842;
			margin-bottom: 20px;
		}

		&-minus,
		&-value,
		&-plus {
			position: static;
			width: unset;
			height: unset;
		}

		&-value {
			width: 25px;
		}
	}

	&__child-age {
		display: flex;
		justify-content: space-between;
		align-items: center;
	  	flex-flow: row wrap;
	  	gap: 20px;
		margin-top: 20px;

		&.dni {
			display: none;
		}

		&__content {
			display: flex;
		}

		&-title {
			margin-right: 20px;
		}

		&__item {
			position: relative;

			&:after {
				content: "";
				display: block;
				position: absolute;
				bottom: -1px;
				left: -5px;
				right: 13px;
				height: 0;
				border-top: 1px solid #fff;
			}

			&.b-passengers-hotels__child-age__item {
				margin-left: 10px;
			}
		}

		&__item-input {
			font-family: "Roboto", "Segoe UI", "Arial";
			color: #fff;
			.g-icon-arrow-white-down;
			background-size: 12px;
			background: none 23px 5px;
			position: relative;

			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;

			border: 0;
			border-radius: 0;
			-webkit-border-radius: 0;

			padding-right: 12px;

			&::-ms-expand {
				display: none;
			}

			option {
				color: black;
			}
		}
	}

	.b-passengers__item-controls,
	.b-passengers__item-content {
		display: flex;
		justify-content: space-between;
	}

	.b-passengers__item-content {
		align-items: center;
	}
}

.b-passengers__info-extra-age--hotel {
	padding: 15px;
}
