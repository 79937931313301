.r-datepicker-range {
	.b-block-container__element-dates {
		display: flex;
		flex-flow: row nowrap;
		position: relative;
		padding-top: 8px;
		max-width: 350px;

		.p-search__options-date-element {
			&:first-child {
				margin-right: 15px;

				.p-search__direction-date {
					position: relative;
					&:after {
						content: "";
						display: block;
						height: 0;
						width: 15px;
						top: 50%;
						right: -15px;
						position: absolute;
						border-top: 1px solid #a4abb3;
					}
				}
			}
		}

		.p-search__options-date-label {
			position: absolute;
			padding: 0 4px;
			background: #fff;
			top: 0;
			left: 4px;
			z-index: 2;
		}

		.p-search__direction-date-input {
			height: 30px;
			padding: 0 11px;
		}

		.p-search__direction-date-calendar {
			background-color: unset;
			height: 30px;
			width: 30px;
			min-width: 30px;
		}

		.b-datepicker__icon {
			background-image: url("~assets/img/icon-calendar-black-1.svg");
		}
	}
}
