@import (reference) "~assets/css/imports";
@import "./b-destination";

.b-search-form-mobile .b-search-route__row.b-search-route__passengers {
  display: none;
}

@media (max-width: 768px) {
  .b-search-route .b-search-route__passengers {
    display: none;
  }
}

.p-search__direction {
  position: relative;
}

.b-search-route {
  margin: 10px 0;
  .clearfix;
  display: table;
  width: 100%;

  &__transfer-type {
	margin-top: 20px;
	width: 400px;
	display: flex;

	.b-input {

	  &__value {
		height: 32px;
		background-color: #FFFFFF;
	  }

	  &__label {
		color: #FFFFFF;
		display: block;
		height: 24px;
		min-width: 100px;
		width: unset;
		margin: 10px 0;
		padding: 0;

		&::after {
		  display: none;
		}
	  }
	}
  }
}


.b-search-route-address {

  &__collection {
	width: 100%;

	&__add {
	  position: relative;
	  left: 50%;
	  cursor: pointer;
	  display: flex;
	  align-items: center;
	  justify-content: flex-start;

	  &-text {
		color: #fff;
	  }

	  &-icon {
		width: 18px;
		height: 18px;
		margin-left: 10px;
	  }
	}
  }

  &__collection-element {
	position: relative;
	width: 100%;

	&:not(:last-of-type) {
	  margin-bottom: 5px;
	}

	.b-geocoder-autocomplete__value {
	  padding-left: 16px;
	}

  }

  &__swap {
	position: relative;
	display: flex;
	align-items: center;
	width: 10px;
	left: 50%;
	margin-bottom: 8px;
  }

  &__swap-img {
	background: url("~assets/img/icons/icon-swap-straight.svg") no-repeat center;
	height: 24px;
	width: 24px;
	color: #fff;
	position: absolute;
	z-index: 999;
	cursor: pointer;
	left: -7px;
  }

  &__remove {
	background: url("~assets/img/icon-cancel-black.svg") no-repeat 50%;
	background-size: 10px;
	display: none;
	z-index: 10;
	height: 30px;
	width: 30px;
	right: 5px;
	top: 11px;
	left: unset;
	cursor: pointer;
	opacity: 1;
	position: absolute;

	.b-search-route-address__collection-element:not(:only-child) & {
	  display: block;
	}
  }
}


.b-search-route__row {
  display: table-cell;
  padding: 0 0 0 10px;
  position: relative;
  clear: both;

  &:first-child {
    padding-left: 0;
  }
}

.b-search-route__row-add {
  padding: 0;
}

.b-search-route__row-date-short {
  width: 118px;
}

.b-search-route__row-date {
  width: 167px;
}

.b-search-route__remove {
  background: url("~assets/img/icon-cancel-w.svg") no-repeat 50%;
  background-size: 11px 11px;
  width: 40px;
  height: 33px;
  cursor: pointer;
  opacity: 1;
  position: absolute;
  left: -40px;
  top: 0;
  display: none;

  .p-search__direction:first-child & {
    display: none;
  }
}

.p-search-route__add {
  display: inline-block;
  width: 15px;
  min-height: 1.22em;
  background: url('~assets/img/icons/icon-add-white.svg') center no-repeat;
  background-size: 15px;
  cursor: pointer;
  vertical-align: middle;
  background-color: inherit;
  padding: 0 20px;
  position: relative;
  z-index: 3;
  pointer-events: none;
  visibility: hidden;

  .p-search__direction:last-child & {
    pointer-events: all;
    visibility: visible;
  }
}

.b-search-form__complex {
  .p-search__options-date-element {
    width: 200px !important;
    display: flex;
    margin-right: 0 !important;
  }

	.p-search-form__row-footer {
		margin-top: 20px;
	}

	.p-search-form__additional-options-container {
		display: flex;
		gap: 10px;
		margin-bottom: 18px;
	}

	.p-search-form__additional-options-element {
		&:first-of-type {
			max-width: 250px;
		}
		&:nth-of-type(2) {
			max-width: 100px;
		}

		.b-input__label {
			display: none;
		}

		.b-airlines-input {
			margin-bottom: unset;
		}


		.b-airlines-input__wrapper , .b-flight-number-input {
			margin-bottom: -4px;

			& > input {
				margin-top: unset;
				width: 100%;
				height: 33px;
				border-radius: 3px;
				border-right: 0;
				border-color: #fff;
				background: #fff;
				color: #4A5767;
				font-weight: normal;
				font-size: 14px;
				padding: 8px;

				@media @desktop-min-width {
					height: 41px;
					font-size: 16px;
					line-height: 16px;
				}
			}
		}


		.b-flight-number-input > input {

		}
	}

  .p-search-form .p-search__direction-date {
    margin-bottom: 18px;
    width: 100%;
  }

  .p-search-form__element__dates {
    margin-left: auto;
  }

  .p-search__input {
    border-radius: 3px;
  }

  .b-search-form__param-buttons {
    display: inline-flex;
  }

  .b-search-form__param-direct,
  .p-search__options-time-from,
  .date-from-datepicker {
    display: none;
  }

  .b-search-route__remove {
    display: block;
  }
}

.b-search-route__oneway {
  .b-search-route__date-back {
    opacity: 0.5;
  }
}

@media (max-width: 1600px) {
  .b-search-form__complex {
    .p-search-form__element__routes {
      padding-left: 40px;
    }
  }
}

@media @tablet {
  .b-search-route__row.b-search-route__row-date-short {
    width: 48%;
    float: left;
    margin-top: 0;
  }

  .b-search-route__row.b-search-route__date-back {
    float: right;
    clear: none;
  }
}
