@import (reference) "~assets/css/imports";

@media @tablet {
  .l-layout.show-filters {
	.b-sorting__label {
	  color: #4a5767;
	}
	.b-sorting__drop-down-item,
	.b-sorting__selected {
	  color: #099C9D;
	}
  }
}

.b-sorting {
	display: flex;
	position: relative;
	align-items: flex-start;
	justify-content: space-between;
	.clearfix;
}

.b-sorting__label {
	float: left;
	padding: 11px 10px 5px 0;
	white-space: nowrap;
  	color: rgba(255, 255, 255, 0.8);
}

.b-sorting__selected {
	float: right;
	padding: 11px 20px 5px 0;
	text-align: right;
	cursor: pointer;
	flex-grow: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	position: relative;
	white-space: nowrap;
  	color: white;

	&:before {
		content: "";
		display: block;
		width: 10px;
		height: 6px;
		background: url("~assets/img/icon-arrow-down-2.svg") no-repeat;
		background-size: 10px;
		position: absolute;
		right: 0;
		top: 17px;
	}

}

.b-sorting__open {
	.b-sorting__selected {
		&:before {
			transform: rotate(180deg);
		}
	}
}

.b-sorting__drop-down {
	position: absolute;
	top: 100%;
	right: -12px;
	float: left;
	.rounded-bottom;
	.shadow;
	background: @background-white;
	color: @color-text-dark;
	z-index: 100;
}

	.b-sorting__drop-down-item {
		float: left;
		width: 100%;
		padding: 10px 15px;
		cursor: pointer;
		border-bottom: 1px solid @color-light;

		&:last-child {
			border: 0;
		}

		&:hover {
			color: @color-active;
		}
	}
