@import (reference) "~assets/css/imports";

.b-attachment {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
   
    &-wrapper {
        background-color: #4A5767;
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 3px;
        margin-top: 2px;
    }
    &-avia {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px 0 16px 0;

        &__wrapper {
            width: 100%;
            align-items: center;
            display: flex;
            flex-direction: row;
        }
        &__group {
            display: flex;
            align-items: start;
        }
        &__additional-params-header {
            color: #ECECEC;
            padding: 12px 10px;
        }
        &__group-airlines {
            background-color: #434D5B;
            border-radius: 3px;
            margin-right: 10px;
            color: #FFFFFF;
            width: 65%;

            &--dn {
                display: none;
            } 
            &--container {
                display: flex;
                padding: 12px 10px;
                align-items: flex-start;

                .b-input__label {
                        display: none;
                }

                .b-input__select__remove {
                    background: #434D5B url("~assets/img/icon-clear.svg") no-repeat center;
                    height: 21px;
                    right: 1px;
                }

                .b-airlines-input__input {
                    margin-right: 10px;
                    height: 30px;
                    padding: 10px;
                    background: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
                    background-size: 1px 50%, 1px 50%;
                    background-position: bottom left, bottom right;
                    background-repeat: no-repeat;
                    border: none;
                    border-bottom: 1px solid #fff;
                    
                }

                .b-search-form__param-airlines {
                    margin-right: 10px;
                    .b-airlines__label, .b-airlines__select, .b-airlines__popup {
                        background-color: #434D5B;
                        color: #fff;
                    }

                    .b-airlines-input.b-input {
                        margin-bottom: 0;
                        margin-top: 8px;
                    }
                  
                }
                .b-search-form__param-flight-number {
                    width: 150px;
                    margin-right: 10px;
                }

                .w-timerange__input {
                    width: 100%;
                    height: 30px;
                    padding: 10px;
                    background: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
                    background-size: 1px 50%, 1px 50%;
                    background-position: bottom left, bottom right;
                    background-repeat: no-repeat;
                    border: none;
                    border-bottom: 1px solid #fff;
                }
                .w-timerange__select {
                    width: 20px;
                    margin-left: -21px;
                    border: none;
                    border-bottom: 1px solid #fff;
                    background-color: #434D5B;
                    .w-timerange__select-img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
           
        }
        &__group-checkboxes {
            background-color: #434D5B;
            border-radius: 3px;
            padding: 18px 16px;
            width: 35%;
            display: flex;
            flex-direction: column;
           
            & .b-toggle__label {
                 color: #FFFFFF;
            }
            &--container {
                display: flex;
                & > span {
                    width: 50%;
                    margin: 0;
                }
            }
        }
    }

	&__logo {
		width: 30px;
		height: 30px;
        cursor: pointer;
        margin-right: 10px;

		&-img {
            width: 100%;
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 3px;
			&-avia {
                background-image: url("~assets/img/icon-avia.svg");
            }
		}
	}
}

.b-attachment-avia__group--complex .b-attachment-avia__group-airlines {
    visibility: hidden;
}

@import './style_mobile';