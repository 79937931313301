@import (reference) "~assets/css/imports";

.b-visa-info {
	width: 100%;
}

.b-visa-info__header {
	background: @field2-background-color;
	padding: 12px 15px;
	height: auto;
	line-height: 22px;
	color: @field2-active-color;
	font-size: @base-H1-size;
	text-transform: uppercase;
	.shadow;
}

.b-visa-info__passengers-container {
	position: relative;

	.l-grid-container-overlay {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 999;
		.rounded;
	}
}

.b-visa-info__passengers-container.inactive {
	.b-visa-info__passengers {
		opacity: 0.5;
	}

}

.b-visa-info__passengers-container.active {
	.l-grid-container-overlay {
		display:none;
	}
}


.l-grid-layout-100 {
	position: relative;

	.l-grid-container-overlay {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 999;
		.rounded;
	}
}

.l-grid-container.active {
	.l-grid-container-overlay {
		display: none;
	}
}

.l-grid-container.inactive {
	.l-grid-container-overlay {
		display: block;
	}	
}

.l-grid-container.inactive .l-grid-opacity-area {
	opacity: 0.5;
}

.b-visa-info__info {
	background: @field1-background-color;
	padding: 0 1px;
	margin-top: -2px;
	.clearfix;
	.rounded-top
}

.b-visa-info__info-header {
	padding: 13px 0 0 16px;
}

.b-visa-info__info-header-warning {
	color: @field1-alert-color;
	padding: 10px;
}

.b-visa-info__info-header-filled {
	color: @field1-alert-color;
	font-size: @base-H2-size;
	padding-left:25px;
	padding-top:50px;
	padding-bottom:50px;
	text-align:center;
}

.b-visa-info__passengers {
	padding: 0 10px;
	.clearfix;
}

.b-visa-info__passenger {
	margin: 10px 0;
	.rounded;

	.b-visa-info__passenger-title {
		font-size: @base-H1-size;
		display: block;
		background: #F0F0F0;
		margin: 0 -10px 10px;
		padding: 10px;
	    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
	}

	.b-visa-info__passenger-header {
		padding-bottom: 5px;

		span {
			color: @field1-H1-color;
		}
	}

	.b-visa-info__passenger-header-description {
		padding: 5px;
	}

	.l-line {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	&.filled {
		position: relative;
		pointer-events: none;
		cursor: default;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;


		&:after {
			content: '';
			position: absolute;
			left: 0; right: 0;
			top: 0; bottom: 0;
			background: #fff;
			opacity: .25;
			z-index: 3;
		}
	}
}

.b-visa-info__container {
	&.filled {
		position: relative;
		pointer-events: none;
		cursor: default;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		&:after {
			content: '';
			position: absolute;
			left: 0; right: 0;
			top: 0; bottom: 0;
			background: #fff;
			opacity: .25;
			z-index: 3;
		}
	}
}

.b-radiobutton {
	margin-bottom: 10px;
}

.b-radiobutton__label {
	top: -3px;
	left: 9px;
	position: relative;
}
