@media @tablet {
  .l-layout.show-filters {

	.b-filters-transfers {

	  .b-filters__range-input {

		.b-input__value {
		  border: 1px solid #099C9D;
		  color: #4a5767;

		  &:focus {
			color: #099C9D;
		  }
		}
	  }

	  .b-selection__value {

		.b-checkbox__view:before {
		  border-color: #099C9D;
		}


		.b-checkbox .b-checkbox__input:disabled {
		  + .b-checkbox__view {
			&:before {
			  background: none;
			  border-color: #099C9D;
			}
			&:after {
			  background: #099C9D;
			}
		  }
		}
	  }

	}
  }

  .b-filters__mobile-buttons {
	order: 7;
	width: 100%;
	margin-top: 20px;
	display: flex;
	.box-shadow(unset);

	&.fixed {
	  margin-top: unset;
	  position: fixed;
	  bottom: 0;
	  left: 0;
	  z-index: 9999;
	  .box-shadow(1px 2px 6px #4a5767);
	}

	> span {
	  width: 100%;
	  display: flex;
	  padding: 20px 0;
	  justify-content: center;
	  align-items: center;

	  &.disabled {
		color: #A4ABB3;
		pointer-events: none;
		opacity: .5;
	  }
	}
	.b-filters__mobile-apply {
	  background: #099C9D;
	  color: #FFFFFF;
	  .border-radius('0px 4px 4px 0px');

	}
	.b-filters__mobile-reset {
	  background: #EDEEF0;
	  color: #D70000;
	  .border-radius('4px 0px 0px 4px');

	  &:after {
		display: none;
	  }
	}
  }
}
