@media @tablet {
  .b-fare-rules__rule-booking {
	margin-right: 20px;
	height: 90vh;
  }
  .b-fare-rules {
	&__rules {
	}

	&__rules-list {
	  padding: 0;

	  & > div {
		margin-bottom: 10px;
	  }
	}

	&__rules-header {
	  display: none;

	  &--mobile {
		display: block;
		color: #FF8D2E;
		padding: 10px;
		background-color: #FCEFE0;

		&:before {
		  width: 22px;
		  height: 22px;
		  display: inline-block;
		  vertical-align: bottom;
		  margin-right: 10px;
		  content: ' ';
		  .g-icon-attention-orange;
		}
	  }
	}

	&__rules-content {
	  padding: 0;

	  & > p {
		background-color: #FCEFE0;
		padding: 10px 37px;
		margin-bottom: 30px;
		margin-top: 0;
	  }
	}

	&__ticketing-before {
	}
  }
}
