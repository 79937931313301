.b-ticket-stop {
	position: relative;
	padding: 10px 0;

	&:before {
		content: " ";
		position: absolute;
		width: 1px;
		display: block;
		top: -12px;
		bottom: -14px;
		left: -18px;
		border-left: 1px dashed;
	}
}

	.b-ticket-stop-short {
		color: @layover-color-little;

		.b-ticket-stop__info {
			border-color: @layover-color-little;
			background: fade(@layover-color-little, 10%);
		}

		.b-ticket-stop__time {
			color: @layover-color-little;
			.g-icon-clock-green;
			background-position: 0 50%;
			background-size: 14px 14px;
		}

		&:before {
			border-color:  @layover-color-little;
		}
	}

	.b-ticket-stop-medium {
		color: @layover-color-medium;


		.b-ticket-stop__info {
			border-color: @layover-color-medium;
			background: fade(@layover-color-medium, 10%);
		}

		.b-ticket-stop__time {
			color: @layover-color-medium;
			.g-icon-clock-yellow;
			background-position: 0 50%;
			background-size: 14px 14px;
		}

		&:before {
			border-color:  @layover-color-medium;
		}
	}

	.b-ticket-stop-long {
		color: @layover-color-long;


		.b-ticket-stop__info {
			border-color: @layover-color-long;
			background: fade(@layover-color-long, 10%);
		}

		.b-ticket-stop__time {
			color: @layover-color-long;
			.g-icon-clock-red;
			background-position: 0 50%;
			background-size: 14px 14px;
		}

		&:before {
			border-color:  @layover-color-long;
		}
	}

	.b-ticket-stop__info {
		padding: 10px 20px;
		border-top: 1px solid;
		border-bottom: 1px solid;
		display: block;
	}
