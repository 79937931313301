@import (reference) "~assets/css/imports";


.train-service__img {
	width: 24px;
	height: 24px;
	margin-right: 12px;
	background-repeat: no-repeat;
	background-position: center;
}

/* train services icons */

.train-service__MEAL {
	background-image: url(~assets/img/train/services/MEAL.svg);
}

.train-service__AIR_CONDITIONING {
	background-image: url(~assets/img/train/services/AIR_CONDITIONING.svg);
}

.train-service__PETS_CARRIAGE {
	background-image: url(~assets/img/train/services/PETS_CARRIAGE.svg);
}

.train-service__HYGIENIC_KIT {
	background-image: url(~assets/img/train/services/HYGIENIC_KIT.svg);
}

.train-service__PLAID {
	background-image: url(~assets/img/train/services/PLAID.svg);
}

.train-service__PRESS {
	background-image: url(~assets/img/train/services/PRESS.svg);
}

.train-service__BIO_TOILET {
	background-image: url(~assets/img/train/services/BIO_TOILET.svg);
}

.train-service__MULTIMEDIA_PORTAL {
	background-image: url(~assets/img/train/services/MULTIMEDIA_PORTAL.svg);
}

.train-service__BEDCLOTHES {
	background-image: url(~assets/img/train/services/BEDCLOTHES.svg);
}

.train-service__WIFI {
	background-image: url(~assets/img/train/services/WIFI.svg);
}

.train-service__TV {
	background-image: url(~assets/img/train/services/TV.svg);
}

.train-service__WASHBASIN_IN_COMPARTMENT {
	background-image: url(~assets/img/train/services/WASHBASIN_IN_COMPARTMENT.svg);
}

.train-service__SHOWER_IN_COMPARTMENT {
	background-image: url(~assets/img/train/services/SHOWER_IN_COMPARTMENT.svg);
}

.train-service__HYGIENIC_SHOWER {
	background-image: url(~assets/img/train/services/HYGIENIC_SHOWER.svg);
}

.train-service__SOCKET220V {
	background-image: url(~assets/img/train/services/SOCKET220V.svg);
}

.train-service__SLIPPERS {
	background-image: url(~assets/img/train/services/SLIPPERS.svg);
}

.train-service__TRANSFER {
	background-image: url(~assets/img/train/services/TRANSFER.svg);
}

.train-service__NO_BIO_TOILET {
	background-image: url(~assets/img/train/services/NO_BIO_TOILET.svg);
}

.train-service__NO_AIR_CONDITIONING {
	background-image: url(~assets/img/train/services/NO_AIR_CONDITIONING.svg);
}

.train-service__PETS_FORBIDDEN {
	background-image: url(~assets/img/train/services/PETS_FORBIDDEN.svg);
}

.train-service__RESTAURANT_CAR {
	background-image: url(~assets/img/train/services/RESTAURANT_CAR.svg);
}

.train-service__LUGGAGE_COMPARTMENT{
	background-image: url(~assets/img/train/services/LUGGAGE_COMPARTMENT.svg);
}

.train-service__SHOWER_IN_TRAIN {
	background-image: url(~assets/img/train/services/SHOWER_IN_TRAIN.svg);
}

.train-service__SAFE {
	background-image: url(~assets/img/train/services/SAFE.svg);
}

.train-service__IMPROVED_SERVICES {
	background-image: url(~assets/img/train/services/IMPROVED_SERVICES.svg);
}

.train-service__HIGH_COMFORT_WAITING_ROOM {
	background-image: url(~assets/img/train/services/HIGH_COMFORT_WAITING_ROOM.svg);
}

.train-service__INFOTAINMENT_SERVICES {
	background-image: url(~assets/img/train/services/INFOTAINMENT_SERVICES.svg);
}

.train-service__HOT_DRINK {
	background-image: url(~assets/img/train/services/HOT_DRINK.svg);
}

.train-service__USB_SOCKET {
	background-image: url(~assets/img/train/services/USB_SOCKET.svg);
}

/* gray icons */
.train-services-gray {
	.train-service__MEAL {
		background-image: url(~assets/img/train/services/gray/MEAL.svg);
	}

	.train-service__AIR_CONDITIONING {
		background-image: url(~assets/img/train/services/gray/AIR_CONDITIONING.svg);
	}

	.train-service__PETS_CARRIAGE {
		background-image: url(~assets/img/train/services/gray/PETS_CARRIAGE.svg);
	}

	.train-service__HYGIENIC_KIT {
		background-image: url(~assets/img/train/services/gray/HYGIENIC_KIT.svg);
	}

	.train-service__PLAID {
		background-image: url(~assets/img/train/services/gray/PLAID.svg);
	}

	.train-service__PRESS {
		background-image: url(~assets/img/train/services/gray/PRESS.svg);
	}

	.train-service__BIO_TOILET {
		background-image: url(~assets/img/train/services/gray/BIO_TOILET.svg);
	}

	.train-service__MULTIMEDIA_PORTAL {
		background-image: url(~assets/img/train/services/gray/MULTIMEDIA_PORTAL.svg);
	}

	.train-service__BEDCLOTHES {
		background-image: url(~assets/img/train/services/gray/BEDCLOTHES.svg);
	}

	.train-service__WIFI {
		background-image: url(~assets/img/train/services/gray/WIFI.svg);
	}

	.train-service__TV {
		background-image: url(~assets/img/train/services/gray/TV.svg);
	}

	.train-service__WASHBASIN_IN_COMPARTMENT {
		background-image: url(~assets/img/train/services/gray/WASHBASIN_IN_COMPARTMENT.svg);
	}

	.train-service__SHOWER_IN_COMPARTMENT {
		background-image: url(~assets/img/train/services/gray/SHOWER_IN_COMPARTMENT.svg);
	}

	.train-service__HYGIENIC_SHOWER {
		background-image: url(~assets/img/train/services/gray/HYGIENIC_SHOWER.svg);
	}

	.train-service__SOCKET220V {
		background-image: url(~assets/img/train/services/gray/SOCKET220V.svg);
	}

	.train-service__SLIPPERS {
		background-image: url(~assets/img/train/services/gray/SLIPPERS.svg);
	}

	.train-service__TRANSFER {
		background-image: url(~assets/img/train/services/gray/TRANSFER.svg);
	}

	.train-service__NO_BIO_TOILET {
		background-image: url(~assets/img/train/services/gray/NO_BIO_TOILET.svg);
	}

	.train-service__NO_AIR_CONDITIONING {
		background-image: url(~assets/img/train/services/gray/NO_AIR_CONDITIONING.svg);
	}

	.train-service__PETS_FORBIDDEN {
		background-image: url(~assets/img/train/services/gray/PETS_FORBIDDEN.svg);
	}

	.train-service__RESTAURANT_CAR {
		background-image: url(~assets/img/train/services/gray/RESTAURANT_CAR.svg);
	}

	.train-service__LUGGAGE_COMPARTMENT{
		background-image: url(~assets/img/train/services/gray/LUGGAGE_COMPARTMENT.svg);
	}

	.train-service__SHOWER_IN_TRAIN {
		background-image: url(~assets/img/train/services/gray/SHOWER_IN_TRAIN.svg);
	}

	.train-service__SAFE {
		background-image: url(~assets/img/train/services/gray/SAFE.svg);
	}

	.train-service__IMPROVED_SERVICES {
		background-image: url(~assets/img/train/services/gray/IMPROVED_SERVICES.svg);
	}

	.train-service__HIGH_COMFORT_WAITING_ROOM {
		background-image: url(~assets/img/train/services/gray/HIGH_COMFORT_WAITING_ROOM.svg);
	}

	.train-service__INFOTAINMENT_SERVICES {
		background-image: url(~assets/img/train/services/gray/INFOTAINMENT_SERVICES.svg);
	}

	.train-service__HOT_DRINK {
		background-image: url(~assets/img/train/services/gray/HOT_DRINK.svg);
	}

	.train-service__USB_SOCKET {
		background-image: url(~assets/img/train/services/gray/USB_SOCKET.svg);
	}
}


.b-train__visible {
	display: block;
}

.b-train__header {
	color: #ffffff;
	padding: 0 15px;
	height: 39px;
	line-height: 39px;
	.rounded-top;
	overflow: hidden;
	background-color: @field2-background-color;
	border-top: @field2-border;
	border-left: @field2-border;
	border-right: @field2-border;
	&:extend(.clearfix all);
}

	.b-train-ticket__header .b-train-ticket__info-disabled {
		flex: 0;
		z-index: 99;
	}

	.b-train-ticket__info-disabled {
		margin: 0 10px 0 25px;
	}

	.b-train-ticket__info-disabled-icon {
		display: inline-block;
		background: url('~assets/img/icons/icon-carriage-disabled.svg') no-repeat center;
		background-size: cover;
		width: 24px;
		height: 24px;
		vertical-align: middle;
		pointer-events: all;
	}

	.b-train__info-services {
		display: flex;
		flex-direction: row;
		padding-top: 7px;
		padding-left: 12px;
	}


	/* EOF train services icons */


	.b-train__header-route {
		float: left;
	}

	.b-train__header-price {
		float: right;
	}

.b-train__info {
	color: #ffffff;
	&:extend(.clearfix all);
}

	.b-train__info-number {
		float: left;
	}

	.b-train__info-type {
		float: left;
	}


.b-train__route {
	display: flex;
	flex-direction: row;
	position: relative;
	justify-content: space-between;
	background: #fff;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 0 0 3px 3px;
}

	.b-train__route-departure {
		flex-grow: 1;
		width: 250px;
		padding: 12px;
	}

	.b-train__route-info {
		flex-grow: 5;
	}

	.b-train__route-arrival {
		flex-grow: 1;
		width: 250px;
		padding: 12px;
	}

	.train__route-datetime {
		margin-top: 5px;
		font-weight: bold;

	}

	.b-train-route__link,
	.b-train-route__link:hover {
		margin-left: 20px;
		color: #4A5767;
		border-bottom: 1px dashed #4A5767;
	}

	.b-train__route-info {
		.b-train__route-info-description {
			width: 300px;
			text-align: center;
			position: absolute;
			left: 0;
			right: 0;
			top: 15px;
			margin-left: auto;
			margin-right: auto;

			.travel-duration {
				font-weight: bold;
			}
		}
		.b-train__info-line {
			height: 2px;
			background: @field1-object-color;
			top: 65%;
			position: relative;
		}

		.b-train__info-line:after, .b-train__info-line:before {
			content: " ";
			display: block;
			border: 2px solid @field1-object-color;
			background: @field1-background-color;
			border-radius: 50%;
			width: 15px;
			height: 15px;
			position: absolute;
			top: 50%;
			margin-top: -8px;
			left: 0;
		}

			.b-train__info-line:after {
				right: 0;
				left: auto;
			}

			.b-train__stops-info {
			  font-weight: bold;
			  color: #f8902c;
			}

			.b-train__transplants {
				background: @field1-background-color;
				display: block;
				height: 10px;
				line-height: 18px;
				font-size: @base-H1-size;
				padding: 0 5px;
				float: left;
				position: absolute;
				left: 50%;
				color: @field1-active-color;
				margin-top: -6px;
				top: 50%;
			}

			.b-train__transplants:before {
				content: " ";
				width: 8px;
				height: 8px;
				display: block;
				position: absolute;
				.border-radius(50%);
				left: 3px;
				top: 50%;
				margin-top: -3px;
				background: @field1-object-color;
			}
	}

	.b-train__carriages-type:last-of-type {
		margin-right: 0;
	}

/* NEW STYLES */

.b-tickets-container-rail {
	padding-top: 9px;
	position: relative;

	&.g-process2 {
		opacity: 0.5;
		pointer-events: none;
	}
}

.l-content-table-title-rail {
	color: rgba(74, 87, 103, 0.8);
	text-align: right;
	font-size: 14px;
	font-weight: 500;
	right: 0;
  	margin-left: auto;
}

.b-train__container {
	margin-bottom: 20px;
	display: none;

	.b-train__travel-policy-rule {
		display: inline-block;
		margin-left: 10px;

		&:first-child {
			margin-left: 0;
		}

		&.g-icon-travel-policy-rules-RECOMMENDED,
		&.g-icon-features-3D-contract {
  			width: 18px;
  			height: 24px;
  			top: 0;
		}

  		&.g-icon-travel-policy-rules-VIOLATION,
  		&.g-icon-travel-policy-rules-STRICT_VIOLATION,
  		&.g-icon-travel-policy-rules-AUTHORIZATION_REQUIRED {
  			width: 18px;
  			height: 24px;
  			top: 8px;
  		}
	}
}

.b-train-ticket__wrapper {
	.train-service__img {
		width: 18px;
		height: 18px;
		margin: 0 12px 10px 0;
		opacity: .8;
		background-size: contain;
	}
}

.b-carriage__selector-wrapper {
	min-width: 220px;
	margin-left: 15px;
}

.b-carriage__selector-seats {
	.b-seats {
		width: 100%;
	}
}

.b-carriage-maps__wrapper {
	.b-train__carriage-map svg {
		max-width: 921px;
	}

	.b-toggle {
		margin-bottom: 15px;
	}

	.b-toggle__view {
		background: #A9A9A9;

		&::before {
			background: rgba(255, 255, 255, 1);
		}
	}

	.b-toggle .b-toggle__input:checked + .b-toggle__label {
		color: #4A5767;
	}

	.b-toggle .b-toggle__input:checked + .b-toggle__label {
		.b-toggle__view {
			background: #4A5767;

			&::before {
				background: rgba(255, 255, 255, 1);
			}
		}
	}
}

.b-train-ticket {
	background: #fff;
	border: 1px solid #A9A9A9;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
	border-radius: 3px;
	padding: 17px 22px 30px 17px;
	position: relative;
	min-width: 924px;

	&__wrapper {
		position: relative;
		z-index: 1;
		display: none;

		&.selected {
			z-index: 2;
		}

		.b-train__carriage {

		  	&-type-tp-container {
			  display: none;
		  	}

			&-seats {
				border: 1px solid #A9A9A9;
				box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
				border-radius: 0 0 3px 3px;
				position: relative;
				padding-top: 5px;
				margin-top: -5px;
				z-index: -2;
			}
		}
	}

	&__visible {
		display: block;

		&.disabled {
			position: relative;
			pointer-events: none;

			&:before {
				content: "";
				position: absolute;
				display: block;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				background: rgba(255, 255, 255, .35);
				z-index: 10;
			}
		}

		& ~ .b-train-ticket__visible {
			margin: 27px 0;
		}
	}

	&__header {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;

		& > div {
			flex: 1 1 100%;

			&.b-train-ticket__info-common,
			&.b-train-ticket__info-train {
				flex: 0;
				white-space: nowrap;
			}

			&.b-train-ticket__info-common {
				margin-left: auto;
			}
		}
	}

	&__info-services {
		display: flex;
		flex-flow: row wrap;
		margin: 0 15px -10px;
	}

	&__info-common {
		text-align: right;
	}

	&__tags {
		margin-left: 15px;
		& > span {
			border-radius: 10px;
			padding: 0 7px;
			height: 18px;
			line-height: 18px;
			font-weight: 700;
			font-size: 14px;
			color: #aaaaa9;
			background: #e2e2de;
			margin: auto 5px auto 0;
			white-space: nowrap;

			&:last-child {
				margin-right: 0;
			}

			&.red-tag {
				color: #f15f6c;
				background: #ffcece;
			}
		}
	}

	&__route {
		margin: 30px 0 0 30px;

		& > .b-train-ticket__info-services,
		& > .b-train-ticket__info-common {
			display: none;
		}
	}

	&__map {
		margin-top: 20px;
		text-align: center;

		svg {
			max-width: 840px;
			height: auto;
		}

		.b-train__carriage-map {
			display: inline-block;
			width: auto;
    		display: inline-block;
	    	margin: 0 auto;

			&__car-number {
				display: none;
			}
		}
	}

	&__carriages {
		border: 1px solid #A9A9A9;
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
		border-radius: 0 0 3px 3px;
		position: relative;
		padding-top: 5px;
		margin-top: -5px;
		z-index: -1;
	}

	&:after {
		content: '';
		display: block;
		width: calc(~'100% + 2px'); /* + border witdh 2px */
		position: absolute;
		height: 10px;
		background: #4A5767;
		border-radius: 3px;
		z-index: -1;
		left: -1px;
		top: -8px;
	}
}

.b-info-train {
	font-size: 20px;


  	&__element {
	  white-space: nowrap;
	}

	&__number {
		font-weight: 500;
		letter-spacing: 0.02em;
	}

	&__brand {
		font-weight: bold;
		color: rgba(74, 87, 103, 0.8);
	}

	&__description {
		font-size: 16px;
		color: rgba(74, 87, 103, 0.8);
	}
}

.b-route {
	font-size: 14px;
	display: flex;
	flex-flow: row nowrap;

	&__date {
		display: flex;
		align-items: center;
		white-space: nowrap;

		&-time {
			color: #4A5767;
			font-size: 20px;
			margin-right: 5px;
			font-weight: bold;
		}

		&-info {
			color: rgba(74, 87, 103, 0.8);
			text-transform: capitalize;
			font-weight: normal;
		}
	}

	&__terminal {
		line-height: 1.3em;
	}

	&__departure,
	&__arrival {
		width: 190px;
	}

	&__arrival {
		position: relative;
	}

	&__arrival-notification {
		height: 15px;
		width: 15px;
		margin-left: 10px;
		background: url('~assets/img/icons/icon-notification-alert.svg') no-repeat center;
		background-size: 100%;
	}

	&__departure,
	&__info {
		margin-right: 90px;
	}

	&__info {
		&-duration {
			font-size: 20px;
			white-space: nowrap;
		}

		&-route {
			text-align: center;
			&-link {
				cursor: pointer;
				color: #4882BD;
				border-bottom: 1px dashed #4882BD;
			}
		}
	}

	&__carriage {
		border-left: 1px dashed rgba(0, 0, 0, .3);
		display: flex;
		flex-flow: row nowrap;
		white-space: nowrap;

		&-row {
			display: flex;
			flex-flow: column nowrap;
			margin: 0 10px 0 25px;
			justify-content: space-between;

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.b-train__carriages {
	display: flex;
	flex-direction: row;
	background: #fff;

	&-type {
		padding: 10px 10px 5px 10px;
		min-height: 95px;
		cursor: pointer;
		flex: 1;
	    flex-direction: column;
	    justify-content: space-between;
		border-right: 1px solid rgba(0, 0, 0, .3);
		cursor: pointer;
		display: flex;
		position: relative;

		&.sold_out {
			cursor: default;
		}

		.b-train__carriage-tp {
			display: flex;
			flex-direction: row;

			.b-train__travel-policy-rule {
				margin-left: 5px;
				display: block;

				&:first-child {
					margin-left: 0;
				}

				&.g-icon-travel-policy-rules-RECOMMENDED,
				&.g-icon-features-3D-contract {
		  			width: 16px;
		  			height: 20px;
		  			top: 0;
				}

		  		&.g-icon-travel-policy-rules-VIOLATION,
		  		&.g-icon-travel-policy-rules-STRICT_VIOLATION,
		  		&.g-icon-travel-policy-rules-AUTHORIZATION_REQUIRED {
		  			width: 16px;
		  			height: 20px;
		  		}
			}
		}

		&.open {
			box-shadow: -4px 5px 20px rgba(0, 0, 0, 0.25);

			&:after {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				border-bottom: 4px solid #4882BD;
			}

			.b-train__carriage-tp {
				visibility: hidden;
			}
		}

		&:last-child {
			border-right: 0;
		}
	}
}

.b-train__carriage {
	font-size: 14px;

	&-type {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: baseline;

		& > .b-train__carriage-quantity {
			display: none;
		}
	}

	&-caption {
		padding-right: 3px;
	}

	&-quantity {
		font-size: 12px;
		white-space: nowrap;
		font-weight: bold;
	    color: #4A5767;
	}

	&-seats-tp {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: center;
	}

	&-price {
		font-weight: bold;
		font-size: 16px;
		color: #4882BD;
		width: 100%;
		text-align: center;
		margin-top: 5px;
	}

	&-seats {
		display: none;

		&.open {
			display: block;
		}

		.b-train__carriage + .b-train__carriage,
		.b-carriage-maps__wrapper + .b-train__carriage {
			border-top: 1px dashed rgba(0, 0, 0, .3);
		}


		.b-train__carriage {
			padding: 22px 5px 22px 18px;
			display: flex;
			cursor: pointer;
			position: relative;
		    align-items: flex-start;


			.b-train__carriage-tp {
				display: flex;
				flex-direction: row;
				min-width: 50px;
			    justify-content: flex-end;
			    position: relative;
			    bottom: 2px;

				.b-train__travel-policy-rule {
					margin-left: 5px;
					display: block;

					&.g-icon-travel-policy-rules-RECOMMENDED,
					&.g-icon-features-3D-contract {
			  			width: 16px;
			  			height: 20px;
					}

			  		&.g-icon-travel-policy-rules-VIOLATION,
			  		&.g-icon-travel-policy-rules-STRICT_VIOLATION,
			  		&.g-icon-travel-policy-rules-AUTHORIZATION_REQUIRED {
			  			width: 16px;
			  			height: 20px;
			  		}
				}
			}

			&.even {
				background: #fff;
				&.open {
					&:after {
						content: '';
						display: block;
						position: absolute;
						left: 18px;
						right: 5px;
						bottom: 0;
						border-bottom: 1px solid #4A5767;
						opacity: .4;
					}
				}
			}

			&.odd {
				background: #EDEEF0;
			}

			&-number {
				width: 20%;
				font-weight: 500;
				padding-right: 30px;
				line-height: 150%;
			}

			&-service-class {
				width: 9%;
				&-caption {
					color: #4882BD;
				}
			}

			&-genders {
				white-space: nowrap;
				margin-top: 1px;
			}

			&-services {
				max-width: 50%;
			}

			&-info {
				margin-left: auto;
				display: flex;

				& > div {
					margin: auto 0;
				}

				.b-train-carriage-border-text {
					font-weight: 500;
					font-size: 13px;
					color: #4A5767;
					padding: 0;
				}
			}

			&-price {
				width: unset;
				margin: 0;
				min-width: 25%;
				text-align: right;
				color: #4A5767;
			}
		}
	}
}

.b-carriage__selected-variant {
	display: none;
	background: #F0F0F0;
	padding: 14px 18px;

	.b-selected-variant {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: center;

		&__controls {
			margin-right: auto;
			.b-selected__gender {
				margin-top: 6px;
				.b-input {
					padding: 0;
				}
				.b-input__value {
					height: 20px;
					padding: 0;

					color: #4A5767;
					background: linear-gradient(#4A5767, #4A5767), linear-gradient(#4A5767, #4A5767), linear-gradient(#4A5767, #4A5767);
					background-size: 1px 50%, 100% 1px, 1px 50%;
					background-position: bottom left, bottom center, bottom right;
					background-repeat: no-repeat;

					border: none;

					&:before {
						background-color: transparent;
						border: none;
						width: 26px;
					}

					@media @desktop-min-width {
						font-size: 16px;
					}

					&::placeholder {
						color: #4A5767;
						@media @desktop-min-width {
							font-size: 16px;
						}
					}
				}
				.b-input__select-value-label {
					right: 30px;
					line-height: 20px;
					padding-left: 5px;
				}
			}
		}

		&__cancel {
			& > .btn {
				font-weight: bold;
				padding: 0 32px;
			}
		}

		&__booking {
			& > .btn {
				font-weight: bold;
				padding: 0 22px;
				margin-right: 15px;
			}

			& .btn-disabled {
				margin-right: 15px;
			}
		}

		&__offer {
			& > .btn {
				font-weight: bold;
				color: #4A5767;
				border: 1px solid #4A5767;
				background: none;
				padding: 0 22px;
			}

			& > .b-ticket-offer__cancel {
				border: 1px solid #D70000;
				color: #D70000;
			}

			&:hover > .btn {
				background: #ffffff;
			}
		}

		.b-selected {
			&__gender {
				min-width: 200px;
				margin-top: -9px;
				margin-right: 15px;

				.b-input__label:after {
					background: #F0F0F0;
				}
			}

			&__places {
				text-align: right;
				color: rgba(74, 87, 103, 0.8);
				margin-right: auto;

				& > .carriage-number,
				& > strong {
					color: #4A5767;
					font-weight: 500;
				}
			}

			&__price-value {
				color: #E50000;
				font-weight: 500;
				font-size: 20px;
			}

			&__price {
				margin-left: auto;
				text-align: right;
				padding-right: 25px;
			}
		}
    }
}

.b-train-ticket {
	.b-train-ticket__gds-info {
		padding-left: 5px;
		padding-top: 5px;
    	position: absolute;
    	left: 0;
    	bottom: 0;
    	width: 300px;
    	height: 25px;
    	z-index: 3;
		font-size: 13px;
		color: #4A5767;
	}
}

@import "./style.mobile";
