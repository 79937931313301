@import (reference) "~assets/css/imports";

.b-hotel-info__wrapper {
	border-radius: 3px 3px 0 0;
	margin-bottom: 40px;
	background: #4A5767;
	.shadow;

	.b-hotel-info {
		&__footer-row {
			padding: 16px 0;
			border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
		}
	}

	.b-hotel-info__header,
	.b-hotel-info__footer,
	.b-hotel-info__pricing {
		padding-left: 13px;
		padding-right: 13px;
	}

	.b-hotel-info__header {
		color: #fff;

		display: flex;
		flex-flow: column;

		padding-top: 10px;
		padding-bottom: 13px;

		& > div:not(:first-child) {
			border-top: 1px solid #2F3842;
		}
	}

	.b-hotel-info__provider,
	.b-hotel-info__name {
		font-size: 20px;
	}
  	.b-hotel-info__tripartite {
	  width: 18px;
	  height: 18px;
	  float: right;
	  top: 3px;
	  position: relative;
	}

	.b-hotel-info__name {
		padding: 0 0 7px;

		.b-travel-policy {
			float: right;
		}
	}

	.b-hotel-info__provider {
		padding: 10px 0 0;
	}

	.b-hotel-info__footer {
		background: #fff;
		color: #4A5767;
		font-size: 18px;

		padding-top: 10px;
		padding-bottom: 28px;
	}

}
