.r-service-icon {
  &.g-icon-service {
    display: inline-block;
    vertical-align: middle;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 17px;
    width: 20px;
    min-width: 20px;
    height: 20px;
  
    &.large {
      width: 30px;
      height: 30px;
    }
  
    &--type-INSURANCE_ACCIDENT {
      mask-image: url("~assets/img/icons/icon-med-insurance-white.svg");
    }
    &--type-TAXI {
      mask-image: url("~assets/img/icons/icon-taxi-white.svg");
    }
    &--type-OTHER {
      mask-image: url("~assets/img/icons/icon-universal-white.svg");
    }
    &--type-AEROEXPRESS {
      mask-image: url("~assets/img/icons/icon-air-express-white.svg");
    }
    &--type-INSURANCE_TOURISTIC {
      mask-image: url("~assets/img/icons/icon-tour-insurance-white.svg");
    }
    &--type-CAR_RENTAL {
      mask-image: url("~assets/img/icons/icon-rent-white.svg");
    }
    &--type-VISA {
      mask-image: url("~assets/img/icons/icon-visa-white.svg");
    }
    &--type-BUS {
      mask-image: url("~assets/img/icons/icon-bus-white.svg");
    }
    &--type-AIR {
      mask-image: url("~assets/img/icons/bc-avia.svg");
    }
  
    &--type-RAILWAY {
      mask-image: url("~assets/img/icons/bc-railways.svg");
    }
  
    &--type-HOTEL_RESERVATION {
      mask-image: url("~assets/img/hotels/bc-hotels.svg");
    }
  
    &--type-TRANSFER {
      mask-image: url("~assets/img/icons/bc-transfers.svg");
    }
  
    &--type-unknown {
      mask-image: url("~assets/img/icons/bc-unknown.svg");
    }
  }
}

