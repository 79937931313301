@import (reference) "~assets/css/imports";

.l {
	&-layout {
		background-color: #fff;
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		font-size: @base-font-size;

		&__top:not(:empty) {
			margin-bottom: 13px;
		}

		&__wrapper {
			padding: 12px 12px 0 12px;
		}

		&__content {
			display: flex;
			flex-direction: row;

			&-side {
				width: 285px;
				flex: 0 0 285px;

			  	&.hide-personal-cabinet {
				  width: unset;
				  flex: unset;
				}

				.sidebar__toggler {
					display: none;
				}
			}

			&-main {
				margin-left: 12px;
				width: 100%;
			}

		  &-controls {
			display: flex;
			align-items: center;
			gap: 10px;

			.show-filters-button {
			  display: none;
			  border: none;
			  outline: none;
			  align-items: center;
			  height: 50px;
			  width: 50px;
			  background-color: #099C9D;
			  .border-radius(4px);

			  &:after {
				content: " ";
				display: block;
				width: 20px;
				height: 10px;
				pointer-events: none;
				background: url('~assets/img/icon-arrow-right-white.svg') center no-repeat;
			  }

			  &:before {
				content: " ";
				display: block;
				width: 50px;
				height: 100%;
				pointer-events: none;
				background: url('~assets/img/icons/icon-filters-mobile.svg') center no-repeat;
			  }
			}
		  }
		}

	  &.show-filters {
		.show-filters-button:after {
		  background: url('~assets/img/icon-arrow-left-white.svg') center no-repeat;
		}
	  }
	}

  	&-offer-preview-container {
	  display: none;
	}
}

@import "./style.mobile";
