.b-popup{
  .react-content-holder {
    max-height: 70vh;
    overflow: auto;
  }
  
  &.r-content-popup {
    .b-popup__content {
      padding: 30px 20px 30px 20px;
      background-image: none;
    }
  }

} 