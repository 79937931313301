@import (reference) "~assets/css/imports";

.b-person-organization {
	position: relative;

	&__main-employment {
		margin: 15px 30px 0 0;
		display: inline-block;
		vertical-align: middle;
	}

	.b-checkbox__view {
		&:before,
		&:after {
			border-radius: 10px;
		}
	}

	&__checkbox {
		white-space: nowrap;
	}

	&__remove {
		.g-icon-delete-collection;
		.rounded;
		cursor: pointer;
		display: inline-block;
		vertical-align: middle;
		background-size: 10px 10px;
		background-position: 50% 50%;
		width: 30px;
		height: 30px;
		z-index: 5;
		margin-top: 5px;
		border: 1px solid #A4ABB3;		
	}
}
