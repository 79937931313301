@media @tablet {
	
	/* Airlines autocomplete */

	.b-products-page__airline-autosuggest.b-airlines-input .b-input__select__remove {
    height: 34px;
    top: 11px;
    right: 2px;
	}

	/* EOF Airlines autocomplete */

	.p-cabinet-products {
		.b-block-container {
			&__group {
				flex-wrap: wrap;

				.p-search__bridge {
					display: none;
				}
				.b-block-container__element {
					width: 100%;
					margin: 0;
					.p-search {
						&__options-date {
							.p-search__options-date-element {
								width: 100%;
								margin-bottom: 12px;

								&:last-child {
									position: relative;
								}

								.p-search__bridge {
									display: block;
									margin: 0;
									width: 2px;
									height: 12px;
									top: -12px;
									background-color: #a4abb3;
									position: absolute;
									left: 50%;
								}
							}

						}

						&__input {
							max-width: 100%;
							border-width: 2px;
							height: 48px;
							font-size: 18px;
						}

						&__direction-date-calendar {
							border-width: 2px;
							width: 56px;
							height: 48px;
						}
					}

					&-dates {
						.p-search__options-date {
							flex-wrap: wrap;

							&-element {
								width: 100%;
							}
						}
					}

					.b-input {
						.b-input__value, .b-input__select-value, .b-input__select-value-label {
							height: 48px;
							line-height: 48px;
						}
					}

					.p-search__submit {
						width: 100%;
						height: 54px;
						border-radius: 0;
						padding: 0;
						line-height: 54px;
						font-size: 18px;
						position: absolute;
						bottom: -54px;
						left: 0;
					}
				}
			}

		  	&__header-label:before {
				background: url("~assets/img/icons/icon-products-circle.svg") no-repeat center;
				background-size: 100% 100%;
		  	}

			&__top-controls {
				position: relative;
			}

			&__content-products {
				position: relative;
			}

		  .b-block-table {

			&__cell {
			  font-size: 14px;
			  border-bottom: unset;
			  order: 10;

			  &-isOnlineBooking, &-status {
				background-color: #EDEEF0;
				.border-radius(20px);
				color: #A4ABB3;
				padding: 5px 10px;
				width: fit-content;
				margin-left: 12px;
			  }
			  &-isOnlineBooking {
				position: absolute;
				bottom: 0;
				right: 12px;
				color: #099C9D;
				background: rgba(9, 156, 157, 0.2);
			  }
			  &-income {
				position: absolute;
				top: 5px;
				right: 0;
				font-weight: bold;
			  }
			  &-amount, &-subagency, &-ticketNumber {
				display: none;
			  }
			  &-status {

			  }
			  &-departureDate {

			  }
			  &-route {

			  }
			  &-travellers {

			  }
			  &-pnr {
				position: absolute;
				top: 5px;
				left: 82px;

				&:before {
				  content: ' ';
				  position: absolute;
				  left: 0;
				  width: 1px;
				  height: 20px;
				  background-color: #4A5767;
				}
			  }
			  &-bookingNumber {
				order: 0;
				background-color: #DAE6F2;
				color: #4882BD;
				border-bottom: 1px solid #4882BD;
				padding: 5px 12px !important;
			  }
			  &-income, &-pnr, &-bookingNumber, &-isOnlineBooking, &-status {
				.b-block-table__cell-icon {
				  display: none;
				}
			  }
			  &-isTripartiteDiscount {
				position: absolute;
				top: 38px;
				right: 0;
				width: 100px;

				& > .b-block-table__cell-value {
				  display: flex;
				  justify-content: flex-end;
				  flex-flow: row wrap;
				  width: 100%;
				  margin-left: auto;

				  & > span {
					flex: 0 0 33.333333%;
					margin-bottom: 5px;
				  }
				}
			  }

			  &-icon {
				&--AIR_TICKET {
				  background: url("~assets/img/icons/bc-avia.svg") no-repeat center #4A5767;
				}
				&--UNKNOWN {
				  background: url("~assets/img/icons/bc-unknown.svg") no-repeat center #4A5767;
				}
				&--HOTEL_RESERVATION {
				  background: url("~assets/img/hotels/bc-hotels.svg") no-repeat center #4A5767;
				}
				&--TRANSFER {
				  background: url("~assets/img/icons/bc-transfers.svg") no-repeat center #4A5767;
				}
				&--RAILWAY_PRODUCT {
				  background: url("~assets/img/icons/bc-railways.svg") no-repeat center #4A5767;
				}
				&--VISA_PRODUCT {
				  background: url("~assets/img/icon-visa.svg") no-repeat center #4A5767;
				}
				&--departureDate {
				  .g-icon-schedule-blue;
				}
				&--travellers {
				  .g-icon-user-blue;
				}
			  }
			}
		  }
		}
	}
}
