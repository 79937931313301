@import (reference) "~assets/css/imports";

.b-ticket-available-seats {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;

  &__ticket-icon {
    width: 20px;
    height: 20px;
    background: url("~assets/img/icons/icon-ticket.svg") no-repeat;
  }

  &__text {
    font-size: 14px;
    line-height: 16px;
    color: @field1-object-color;
  }
}
