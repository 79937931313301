@media @tablet {

	.b-cabinet-person {

		.b-datepicker__field {
			height: 48px;
			line-height: 48px;
			font-size: 16px;
		}

		.p-search__options-date-with-label {
			.p-search__direction-date-calendar {
				height: 48px;
				width: 48px;
			}
		}

		& > .b-cabinet-block {
			margin-bottom: 15px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		.b-person-bonus-card {
			margin-bottom: 40px;
		}

		.b-cabinet-person__bonus-card {
			.b-cabinet-collection__add {
				margin-left: 0;
			}
		}
	}

}