@import (reference) "~assets/css/imports";

.b-matrix-controls {
  color: @button1-font-color;
  .clearfix;
	margin-bottom: 15px;
}

.b-matrix-controls__control {
  .rounded;
  .shadow;
  cursor: pointer;
  background: @button1-color;
  width: 49%;
  height: 29px;
  line-height: 29px;
  text-align: center;
  font-size: 14px;
}

.b-matrix-controls__control-full {
	width: 100%;
}

.b-matrix-controls__control.active {
  background: @field1-background-color;
  color: @field1-H2-color;
  .border-top-left-radius(0px);
  .border-top-right-radius(0px);
}

.b-matrix-controls__current-date {
	float: left;
	.transition-standard;
}

	.b-matrix-controls__current-date:hover {
	  background: @button1-color-act;
	  color: @button1-font-color-act;
	}

	.b-matrix-controls__current-date:active {
	  background: @button1-color-act;
	  color: @button1-font-color-act;
	}

.b-matrix-controls__nearest-dates {
	float: right;
	.transition-standard;
}

	.b-matrix-controls__nearest-dates:hover {
	  background: @button1-color-act;
	  color: @button1-font-color-act;
	}


	.b-matrix-controls__nearest-dates:active {
	  background: @button1-color-act;
	  color: @button1-font-color-act;
	}


@media @tablet {
	.b-matrix-controls {
		display: none;
	}
}
