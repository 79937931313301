@import (reference) "~assets/css/imports";

.b-hotel {
	position: relative;
    display: none;
    flex-flow: nowrap column;
	font-size: 13px;

  .b-hotel-travellers-info__wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;

    @media (max-width: 769px) {
      flex-direction: column;
      gap: 8px;
      align-items: end;
    }


    &.compact-view {
      margin-top: 8px;
      margin-bottom: 0px;
    }
  }

  .b-hotel-travellers-info {
    background: #EDEEF0;
    border-radius: 6px;
    padding: 4px 8px;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    max-width: fit-content;
    color: #4A5767;
    gap: 6px;

    white-space: nowrap;
    height: 24px;

    .b-travell-icon {
      background-image: url("~assets/img/hotels/icon-traveller.svg");
      width: 8px;
      height: 16px;
    }
  }

  .b-hotel-travellers-rooms-info {
    background: #D9E1F2;
    border-radius: 6px;
    padding: 4px 8px;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    max-width: fit-content;
    color: #4A5767;
    white-space: nowrap;
    height: 24px;
  }


	&.all-group-opened-filter {
		.b-hotel-offer__additional-prices {
			display: none;
		}

		.b-hotel-offers-grouped {
			display: block !important;
			border-bottom: 0;

			.b-expand {
				display: none;
			}
		}
	}

  	&__mobile-close-btn {
	  display: none;
	}

  	&__mobile-description-container {
	  display: none;
	}

  	&-offer__info-container {
	  display: flex;
	  flex-direction: column;
	}

	&__unknown-price {
		font-weight: bold;
		position: relative;
		left: 10px;
		top: 10px;
		border-bottom: 1px dotted;
	}

	&-offer-pricing {
		max-width: 210px;
		&.with-taxs {
			max-width: 330px;
			padding: 5px 2px;
		}
		.b-ticket-pricing__item .b-pricing__total-section {
			margin-bottom: 0;
		}
	}

	&-offers-group {
		.b-hotel-offer {
			transition: box-shadow .5s;
		}
		@media (min-width: 769px) {
		  &.opened-group .b-hotel-offers-offer__wrapper .b-hotel-offer {
			border-bottom-color: transparent;
			position: relative;
			box-shadow: 0 4px 8px rgba(0, 0, 0, 0.18);
			border-radius: 0 0 3px 3px;
		  }
		}
	}

	&-offers-grouped {
		position: relative;
		border-bottom: 1px solid rgb(201, 205, 209);

		.b-expand {
			display: block;
			width: 21px;
			height: 21px;
			border: none;
			border-radius: 0;
			box-shadow: 1px 0 3px rgba(0, 0, 0, 0.25);
			position: absolute;
			bottom: 0;
			right: 0;
			background-color: #2F3842;
			background-image: url("~assets/img/hotels/icon-arrow-down-white.svg");
			background-size: 50%;
		}
	}

	&-offers__check-date-wrapper {
		display: flex;
		flex-flow: column;
	}

	/* first group of a class */
	&-offers__check-date-wrapper + .b-hotel-offers-group--filtered:not(:only-child) {
		border-bottom: 0;
	}
		
	&-offers__check-date-container {
		display: inline-flex;
		margin: 10px auto 25px;
		padding: 10px;
		border-bottom: 1px solid #A4ABB3;
		color: #58636F;
		font-size: 20px;
		line-height: 23px;
	}

	&-offers__check-date + .b-hotel-offers__check-date {
		margin-left: 32px;
		position: relative;
		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: -16px;
			height: 100%;
			width: 0;
			border-left: 1px solid #A4ABB3;
		}
	}

	.lg-visible-block {
		display: none;
	}

	&.b-hotel__visible {
		display: flex;
	}

	&--description-is-open {
		.b-hotel__wrapper {
			.b-hotel__expand {
				display: none;
			}
		}
		.b-hotel__extended-wrapper {
			.b-hotel__expand {
				display: block;
			}
		}
	}

	&-offer__additional-prices {
		position: relative;
		display: inline-flex;
		align-items: center;
		gap: 8px;
		margin-top: 5px;
		margin-right: auto;
		color: #4882bd;
		font-size: 14px;
		cursor: pointer;

		&:after {
			content: "";
			width: 22px;
			height: 18px;
			background: url("../../../../../assets/img/icons/icon-chevron-down-light-blue-24x18.svg") no-repeat center;
			transition: all .15s ease;
		}

	  &.close-group {
			&:after {
				transform: rotate(180deg);
			}
	  }
	}

  	&-offer&-offer__info-icons {
		padding: 5px;
	  	display: flex;
	  	flex-flow: row wrap;
	  	gap: 10px;

		& > span {
		  width: 30px;
		  height: 30px;
		}

	  	& > span:first-of-type {
		  margin-left: 0;
		}
  	}

  	&-offer__anti-covid {
	  background: url("../../../../../assets/img/icons/icon-antiCovid_30_30.svg") no-repeat center;
	}

  	&-offer__passport {
	  background: url("../../../../../assets/img/icons/icon-hotel-pass_30_30.svg") no-repeat center;
	}

  	&-offer__information {
	  background: url("../../../../../assets/img/icons/icon-hotel-information_30_30.svg") no-repeat center;
	}

  	&-offer__checked {
	  background: url("../../../../../assets/img/icons/icon-hotel-checked_25_25.svg") no-repeat center;
	  height: 25px;
	  width: 25px;
	  margin: 5px 5px ;
	  text-indent: 30px;
	  min-width: 25px;
	}

  	&-offer__checked-wrapper,
    &-offer__refresh-wrapper,
    &-offer__cancel-wrapper,
    &-offer__info-wrapper,
    &-offer__request-info-wrapper {
	  display: flex;
	  align-items: center;
	  margin: 7px 0;
	}

  	&-offer__checked-label,
	&-offer__refresh-label,
	&-offer__cancel-label,
    &-offer__info-label {
	  padding: 0 0 0 15px;
	  max-width: 165px;
	  white-space: normal!important;
	  text-align: left!important;
	}

  	&-offer__checked-label {
	  color: #099C9D;
	}

  	&-offer__refresh-label {
	  color: #FF8D2E;
	}

  	&-offer__cancel-label {
	  max-width: 265px;
	}

  	&-offer__refresh,
  	&-offer__cancel,
    &-offer__info {
	  height: 25px;
	  width: 25px;
	  margin: 5px 5px;
	  text-indent: 30px;
	  min-width: 25px;
	}

    &-offer__info {
      background: url("../../../../../assets/img/icons/icon-info-blue.svg"), no-repeat;
      color: #4882BD;
    }

  	&-offer__refresh {
	  background: url("../../../../../assets/img/icons/icon-hotel-refresh_25_25.svg"), no-repeat;
	  color: #FF8D2E;
	}

  	&-offer__cancel {
	  background: url("../../../../../assets/img/icons/icon-cancel_25_25.svg"), no-repeat;
	}

  	&-offer__agencyInfo{
	  background: url("../../../../../assets/img/icons/icon-agency-info_30_30.svg"), no-repeat;
	  height: 30px;
	  width: 30px;
	}

	&__wrapper {
		background: #fff;
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
		border: 1px solid #A9A9A9;
		border-radius: 3px;
		display: flex;
		flex-flow: nowrap row;
		padding: 0;
		position: relative;
		z-index: 5;
		overflow: hidden;
	}

	&__photo,
	&__description-wrapper,
	&__providers {
		flex: 1;
		display: flex;
		flex-flow: column;
	}
	&__photo:not(.no_image) {
		cursor: zoom-in;
		cursor: -webkit-zoom-in;
	}

  	&__photo-container {
	  max-width: 170px;
	  max-height: 170px;
	  margin: 13px 15px 13px 13px;
	}

  	&__photo-outside-container {

	  &.open {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100vw;
		z-index: 10000;
		background-color: #0a0a0e;
		margin: 0 !important;

	  	.b-hotel__photo {
			cursor: unset;
		}

		.b-hotel__slider {
		  margin: auto;
		  max-width: 80%;
		}

		.b-hotel__mobile-slider-controls {
		  display: flex;
		  position: relative;
		  align-items: center;
		  justify-content: center;
		  padding-top: 20px;
		}

		.b-hotel__mobile-slide-indicator {
		  display: block;
		  font-size: 14px;
		  color: #fff;
		}

		.b-hotel__mobile-close-slider-btn {
		  position: absolute;
		  right: 40px;
		  cursor: pointer;
		  top: 10px;
		  width: 40px;
		  height: 40px;
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  transition: all .3s ease-out;

		  &:before {
			content: ' ';
			display: block;
			width: 18px;
			height: 18px;
			.g-icon-w-cancel;
		  }

		  &:hover {
			background-color: #ffffff;
			border-radius: 50%;
		  }
		  &:hover:before {
			.g-icon-b-cancel;
		  }
		}

		.b-hotel__slider {
		  display: flex;
		  align-items: center;

		  &-slide > img {
			max-height: 100%;
		  	width: unset;
		  }
		}
	  }
	}

	&__photo {
		width: 100%;
	  	height: 100%;

		&-not-available {
			background: url('~assets/img/hotels/icon-no-photo.svg') center no-repeat;
			background-size: contain;
			width: 112px;
			height: 112px;
			opacity: .3;
			margin: auto;
		}

		&-description {
			margin: 0 0 5px 5px;
		}
	}

	&__slider {
		position: relative;
		height: 100%;
		overflow: hidden;
		border-radius: 3px;
	}

	&__slider-dots {
		position: absolute;
		top: 34px;
		right: 4px;
		display: flex;
		z-index: 2;
		min-width: 60px;
		justify-content: center;
		align-items: center;
	}

	&__slider-dot {
		width: 3px;
		height: 3px;
		background: #2F3842;
		border-radius: 100%;
		opacity: 0.5;
		cursor: pointer;
		margin-left: 6px;

		&.active {
			opacity: 1;
		}

		&:first-child {
			margin-left: 0;
		}
	}

	&__slider-controls {
		position: absolute;
		top: 4px;
		right: 4px;
		background: #2F3842;
		border-radius: 0 3px;
		display: flex;
		z-index: 2;
	}

  	&__mobile-slider-controls {
	  display: none;
	}

	&__slider-control {
		cursor: pointer;
		height: 50px;
		width: 22px;
		position: absolute;
		top: calc(~'50% - 25px');
		opacity: .5;
		z-index: 2;

		background: #2F3842;
		background-repeat: no-repeat;
		background-position: center;

		border: 1px solid rgba(255, 255, 255, 0.5);

		&:hover {
			opacity: 1;
		}

		&--type-prev {
			background-image: url("~assets/img/hotels/icon-arrow-left-white.svg");
			left: 0;
		}

		&--type-next {
			background-image: url("~assets/img/hotels/icon-arrow-right-white.svg");
			right: 0;
		}
	}

	&__slider-list {
		height: 100%;
		position: relative;
	}

	&__slider-slide {
		position: relative;
		float: left;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;

		.lds-ring {
			display: inline-block;
			position: relative;
			width: 64px;
			height: 64px;
			opacity: .1;
		}
		.lds-ring div {
			box-sizing: border-box;
			display: block;
			position: absolute;
			width: 51px;
			height: 51px;
			margin: 6px;
			border: 6px solid #2F3842;
			border-radius: 50%;
			animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
			border-color: #2F3842 transparent transparent transparent;
		}
		.lds-ring div:nth-child(1) {
			animation-delay: -0.45s;
		}
		.lds-ring div:nth-child(2) {
			animation-delay: -0.3s;
		}
		.lds-ring div:nth-child(3) {
			animation-delay: -0.15s;
		}
		@keyframes lds-ring {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}

		& > img {
			width: 100%;
		}
	}

	&__description-wrapper {
		padding: 13px 10px 13px 0;
		justify-content: left;

		.b-hotel__description-stars {
			margin-bottom: 5px;
			.b-hotel-star {
				display: inline-block;
				vertical-align: middle;
				height: 17px;
				width: 17px;
				background: url('~assets/img/hotels/icon-star.svg') center no-repeat;
			    background-size: contain;
			}
		}

	  	.b-hotel__description-rating {
			margin-bottom: 5px;

			.b-hotel-rating {
		  		font-weight: 600;
		  		color: #4882bd;
			}
	  	}

		.b-hotel__description {
			&:last-child {
				margin-top: auto;
			}
		}

	  	.b-hotel__description-distance-hotelType{
		  font-weight: bold;
		}

		.b-hotel__description-address-container {
			display: flex;
			flex-flow: column;
			flex: 1;
		}

		.b-hotel__description-address {
			max-width: 220px;
		}

		.b-hotel__description-check-date-container {
			display: flex;
			flex-flow: column;
			align-items: flex-start;
			justify-content: space-around;
			margin: auto 0;
		}
	}

	&__providers {
		max-width: 330px;
		margin: 33px 0;
		border-left: 1px dashed #4A5767;
		flex: 2;
		justify-content: flex-end;
		display: flex;
		flex-direction: row;
		align-items: center;
		overflow: hidden;
		padding-bottom: 20px;
		padding-right: 105px;
	}

	&__provider {
		cursor: pointer;
		white-space: nowrap;

		&:first-child + .b-hotel__provider {
			position: relative;
			&:before,
			&:after {
				content: '';
				display: block;
				border-left: 1px dashed #4A5767;
				height: 50px;
				width: 0;
				position: absolute;
				top: 5px;
			}

			&:before {
				left: -23px;
			}

			&:after  {
				right: -23px;
			}
		}

		&--best-price + .b-hotel__provider {
			margin-left: 25px;
		}

		&:only-child {
			.b-hotel__provider-name {
				margin-right: 15px;
				flex: 0;
			}

			.b-hotel__provider-wrapper {
			    justify-content: center;
			    flex-direction: column;
			    align-items: flex-end;
			}

			.b-hotel__provider-price {
				min-width: unset;
			}

			.b-hotel__provider-taxation {
			  	position: static;
				text-align: right;
			}
		}

		&:only-child,
		&--best-price {

			.b-hotel__provider-taxation,
			.b-hotel__provider-price,
			.b-hotel__provider-daily-average-price {
				color: #099C9D;
			}
			.b-hotel__provider-price {
				font-size: 30px;
				font-weight: bold;
			}

		  	.b-hotel__provider-arrow {
			  	display: block;
			  	width: 10px;
			  	height: 20px;
			  	background: #099C9D;
			  	-webkit-mask-repeat: no-repeat;
			  	-o-mask-repeat: no-repeat;
			  	mask-repeat: no-repeat;
			  	-webkit-mask-position: center;
			  	-o-mask-position: center;
			  	mask-position: center;

			  	-webkit-mask-size: contain;
			  	mask-size: contain;

			  	position: absolute;
			  	top: 79px;

			  	-webkit-mask-image: url("~assets/img/icon-arrow-right.svg");
			  	mask-image: url("~assets/img/icon-arrow-right.svg");
			}
		}

		&-wrapper {
			display: flex;
			flex-flow: column nowrap;
			align-items: center;
		}

		&-price {
			font-size: 24px;
			text-align: right;
		}

	  	&-daily-average-price {
		  	font-size: 14px;
		  	text-align: right;
		  	white-space: normal;

		  &-amount {
			white-space: nowrap;
		  }
		}

		&-name {
			flex: 1;
			text-align: right;
		}

		&-taxation {
			color: rgba(74, 87, 103, 0.6);
			text-align: right;
			position: absolute;
			right: 0;
		}
	}

	&__description-name {
		font-size: 28px;
		margin-top: 5px;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 320px;
		white-space: nowrap;
	}

	&__description-distance {
		margin-bottom: auto;
	}

	&__description-contacts {
		margin-top: 4px;
	}

	&__description-show-map {
		font-size: 12px;
		color: #4882BD;
		margin-top: auto;
	}

	&__description-show-map-button {
		cursor: pointer;
	}

	&__travel-policy {
		position: absolute;
		right: 5px;
		top: 5px;

		.b-travel-policy-rule {
			&.g-icon-travel-policy-rules-RECOMMENDED,
			&.g-icon-travel-policy-rules-VIOLATION,
			&.g-icon-travel-policy-rules-STRICT_VIOLATION,
			&.g-icon-travel-policy-rules-AUTHORIZATION_REQUIRED {
				background-position: center top;
				width: 18px;
				height: 20px;
				margin-left: 5px;
			}
		}
	}

	&__select-offer {
		color: #fff;
		font-size: 20px;
		height: 48px;
		padding: 0 45px;
		margin-left: auto;
		display: inline-flex;
		align-items: center;

		background: #4882BD;
		border-radius: 3px;
		cursor: pointer;

		position: absolute;
		z-index: 2;
		box-sizing: content-box;

		bottom: -1px;
		right: 0;

		&:before {
			content: '';
			display: block;
			border: 24px solid;
			border-color: transparent #4882BD #4882BD transparent;
			position: absolute;
			left: -46px;
			top: 0;
		}
	}

	&__expand {
		width: 33px;
		height: 33px;
		border: none;
		border-radius: 0;
		box-shadow: 1px 0 3px rgba(0, 0, 0, 0.25);
		position: absolute;
		left: 0;
		bottom: 0;
		background-color: #2F3842;
		background-size: auto;
		background-image: url("~assets/img/hotels/icon-arrow-down-white.svg");

		&.show {
			display: block !important;
		}
		&.collapsed {
			border-radius: 0;
		}
	}
}

.b-hotel-extended {
	border-radius: 3px;
	position: relative;
	background: #FFFFFF;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.18);
	overflow: hidden;

	&-description {
		z-index: 2;
		top: -5px;
		margin-bottom: -5px;
	}

	&-offers {
		.b-hotel--description-is-open & {
			top: 10px;
			margin-bottom: 0;
			padding-top: 0;
		}

		top: -5px;
		margin-bottom: -5px;
		padding: 5px 0 0;
	}

	.b-hotel__expand {
		display: none;
		position: static;
	}

	&__description {
		padding-left: 28px;
		padding-right: 18px;
		padding-bottom: 12px;
		padding-top: 12px;
	}

	&__description-title {
		font-size: 24px;
		display: inline-block;
		position: relative;

		&:before {
			content: '';
			display: block;
			position: absolute;
			bottom: -5px;
			left: -1px;
			right: -1px;
			border-top: 2px solid #4A5767;
		}
	}

	&__description-title.collapse--with-anchor:after {
		right: 8px;
	}

	&__description-phone,
	&__description-address {
		font-size: 16px;
	}

	&__description-text {
		padding: 25px 160px 25px 40px;
		line-height: 1.5em;
		font-size: 14px;
	}

	&__description-no-text {
		opacity: .6;
	}

	&__description-bottom {
		display: flex;
		flex-flow: row nowrap;
	    padding: 0 40px;
	}

	&__description-bottom-item {
		border-bottom: 1px dashed #4A5767;
		padding: 14px 0;
		margin-right: 5px;
		display: flex;
		flex-flow: column;
		justify-content: space-between;
		flex: 1;

		&:first-child {
			padding-top: 0;
		}
	}

	&__description-bottom-map {
		max-width: unset;
		min-height: 176px;
		background: #f8f8f8;
		border-radius: 3px;
		max-height: 250px;
	}

	&__description-bottom-column {
		display: flex;
		flex-flow: column nowrap;
		flex: 1;
	    justify-content: space-between;

	    &:first-child {
			max-width: 285px;
			margin-right: 20px;
	    }
	}

	&__footer {
		width: 100%;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		padding: 16px 20px 10px;
		border-top: 1px solid rgba(74, 87, 103, .3);

		& + .b-hotel__expand {
			position: absolute;
		}
	}

	&__footer-sum {
		margin: 0 25px 0 auto;
		font-size: 16px;
	}

	&__footer-amount {
		font-size: 22px;
		color: #E50000;
		font-weight: 500;
	}

	&__footer-button {
		display: flex;
		align-items: center;
		cursor: pointer;
	}
}

.b-hotel__offers {
	display: flex;
	flex-flow: column nowrap
}

.b-hotel-offers__caption {
	width: 100%;
	font-size: 18px;
	position: relative;
    padding-left: 50px;

	&-text {
		background: #fff;
		padding: 0 25px;
		position: relative;
		z-index: 2;
	}

	&:before {
		content: '';
		display: block;
		height: 0;
		left: 0;
		right: 0;
		top: 50%;
		border-top: 1px solid rgba(74, 87, 103, 0.8);
		position: absolute;
	}
}

.b-hotel {

	&__show-filtered {
		.b-hotel-offer--filtered {
			display: flex;
		}

		.b-hotel-offers-group--filtered,
		.b-hotel-offers-group--filtered:only-child {
			border: 1px solid #4A5767;
		}

		.b-hotel-offers-group:not(.b-hotel-offers-group--filtered) + .b-hotel-offers-group--filtered:not(:last-child) {
			border-bottom: 0;
			border-top-left-radius: 3px;
			border-top-right-radius: 3px;
		}

		.b-hotel-offers-group--filtered + .b-hotel-offers-group--filtered {
			border-left: 1px solid #4A5767;
			border-right: 1px solid #4A5767;
			border-top: 0;
			border-bottom: 0;
		}

		.b-hotel-offers-group--filtered:last-child {
			border-bottom: 1px solid #4A5767;
			border-bottom-left-radius: 3px;
			border-bottom-right-radius: 3px;
		}
	}

	&__filtered-link {
		height: 47px;
		line-height: 47px;
		text-align: center;
		color: #4A5767;
		font-size: 16px;
		font-weight: 500;
		cursor: pointer;
		border-top: 1px solid rgba(74, 87, 103, 0.3);
		position: relative;
		// bottom: 2px;
		background: #fff;

		& > span {
			border-bottom: 1px dashed #4A5767;
		}
	}

	&-offer {
		display: flex;
		flex-flow: row nowrap;
		padding: 15px 20px;
		border-bottom: 1px solid rgba(201, 205, 209, .2);
		position: relative;
		font-size: 14px;

		&--filtered {
			display: none;
		}

		&--offset-top {
			.b-hotel-offer__row,
			.b-hotel__line,
			.b-hotel-offer__line {
				padding-top: 25px;
			}
		}

	  &__vat {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			font-size: 14px;
			width: 100%;
			color: #099C9D;
	  }
	}

	&-offer__online {
		margin-right: 10px;
	}

	&-offer__line {
    	min-width: 15px;
		min-height: 100%;
		display: flex;

		&--long {
			flex: 1;
		}

		&--dlong {
			flex: 2;
		}

		&--short {
			display: none;
		}

		&:before {
	    	content: '';
	    	display: block;
	    	border-top: 1px dashed #4A5767;
	    	height: 0;
	    	width: 100%;
	    	margin: auto;
		}
	}

	&-offer__row {
		flex: 1;
		display: flex;
		flex-flow: column nowrap;

		&.b-hotel-offer__common-wrapper {
			width: 160px;
		  	margin-top: auto;
		  	margin-bottom: auto;
		}

		&.b-hotel-offer__select {
			position: relative;
			margin-left: 10px;
		}

		.additional-services-link {
		  font-size: 14px;
		}
	}

	&-offer__name {
		margin-bottom: 2px;

		& > * {
			max-width: 160px;
			min-width: 160px;
      /*
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;*/
		}
	}

	&-offer__essential-list {
		.b-hotel-offer__essential {
			padding: 8px 0;
			&:not(:last-child) {
				border-bottom: 1px solid rgba(255, 255, 255, .5);
			}
		}
	}

	&-offer__fines {
		font-size: 13px;
		width: 17px;
		height: 17px;
		border: 1px solid #4A5767;
		display: inline-block;
		margin: 3px -23px 0 5px;
		text-align: center;
		line-height: 15px;
		border-radius: 100%;

		&-important {
			border-color: #D70000;
			color: #D70000;
		}
	}

	&-offer__important {
		color: #D70000;

		&.bb-important {
			border-bottom: 1px dotted #D70000;
		}
	}

	&-offer__auto-width {
		text-align: center;
		white-space: nowrap;
		padding-left: 20px;
		padding-right: 20px;
		flex: 0;
		margin: auto;
	}

	&-offer__essential-wrapper {
		width: 200px;
		min-width: 200px;

		& > div {
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

  	&-offer__terms-link {
      display: inline-block;
      width: 100%;
	  font-size: 14px;
	  color: #4882bd;
      border: 1px solid #4882bd;
      border-radius: 3px;
      padding: 4px 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
	}

	&-offer__room-wrapper {
		width: 10vw;
		min-width: 120px;

		& > div {
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&-offer__important-info-wrapper {
		text-align: center;
		padding-left: 20px;
		padding-right: 20px;
		flex: 0;
		align-items: center;
		justify-content: center;
	}

	&-offer__provider-wrapper {
		position: relative;
		white-space: nowrap;
		padding-left: 20px;
		padding-right: 20px;
		min-width: 200px;
	}

  	&-offer__mobile-provider {
	  display: none;
	}

	&-offer__services-wrapper {
	    justify-content: center;
	    align-items: center;
	    position: relative;
	}

	&-offer__provider {
		margin: auto;
	}
	
	&-offer__sub-provider {
		margin-top: 10px;
		display: block;
		text-align: center;
		font-size: 12px;
	}

	&-offer__RP {
		margin-bottom: 5px;
		font-size: 14px;
		line-height: 20px;
		min-height: 20px;
	}

	&-offer__additional-services {
		color: #4882BD;
	}

	&-offer__travel-policy.b-travel-policy {
		margin-left: auto;

		.b-travel-policy-rule {
			background-position: center top;
			margin-left: 5px;
		}
	}

	.b-numerable-picker__wrapper {
		border-radius: 3px;
		background: linear-gradient(#4A5767,#4A5767),linear-gradient(#4A5767,#4A5767),linear-gradient(#4A5767,#4A5767);
		background-size: 1px 50%,100% 1px,1px 50%;
		background-position: 0 100%,bottom,100% 100%;
		background-repeat: no-repeat;
	}

	.b-numerable-picker {
		margin: 0 auto 5px;
		width: 160px;
		padding: 0 15px;
	}

	.b-numerable-picker__input {
		border: none;
		color: #4A5767;
	}

	.b-numerable-picker__select {
		border: none;
		background: none;
	}

	.b-numerable-picker__select-img {
		background: #4A5767;
		cursor: pointer;

		-webkit-mask-repeat: no-repeat;
		-o-mask-repeat: no-repeat;
		mask-repeat: no-repeat;
		-webkit-mask-position: center;
		-o-mask-position: center;
		mask-position: center;

		-webkit-mask-size: 13px auto;
				mask-size: 13px auto;

		-webkit-mask-image: url("~assets/img/icon-arrow-down-2.svg");
		mask-image: url("~assets/img/icon-arrow-down-2.svg");
	}

	&-offers {
		.b-numerable-picker__popup {
			border: none;
			margin: 5px auto 0;
			width: 100%;
			padding: 0 15px;
		}

    .small-rooms-count {
      font-size: 16px;
      font-weight: 400;
    }

		&.b-hotel-offers--selection-room {
            .b-hotel-offer.b-hotel-offer--selection-room {
                .b-hotel-offer__select-button-price {
                    margin-top: 10px;
                    color: #099C9D;
                }
            }

			.b-hotel-offer__select {
			    justify-content: center;
				text-align: center;

				.b-hotel-offer__select {
					min-width: unset;
					max-width: unset;
					flex: 0;
					white-space: nowrap;
					padding-left: 44px;
				}

                .b-hotel-offer__select-button-price {
                    &.many-rooms {
                        display: flex;
                        flex-direction: column;
                        font-size: 12px;
    
                        & > span {
                            text-align: right;
                            padding-right: 20px;
                        }
    
                        & > span:first-of-type {
                            font-size: 20px;
                        }
                    }
                }

			    .b-hotel-offer__select-button-description {
			    	margin-bottom: 2px;
			    }

				.b-hotel-offer__RP {
					line-height: unset;
					margin: 0;
					order: 1;
					position: relative;
					top: 20px;
				}
			    
                .container-test {
					margin-left: 20px;
					margin-right: 10px;
			    }
			}
		}

        .b-hotel-offer__select-button--offer,
        .b-hotel-offer__select-button {
            background: #4882BD;
            width: 100%;
            min-height: 36px;
            padding: 4px 0;
            border-radius: 3px;
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            color: #fff;
            cursor: pointer;
            position: relative;
            margin-bottom: 15px;
            margin-right: 0;


            .select-button-price__roomsNumber {
              font-size: 16px;
              font-weight: 400;
              color: #B4CBFF;
            }

        }

        .b-hotel-offer__select-button--offer {
            position: relative;
            background: none;
            color: #4882BD;
            height: 36px;
        }

        &:not(.b-hotel-offers--selection-room) {
            .b-hotel-offer__select-button--offer {
                &::before {
                    content: "+";
                    z-index: 100;
    
                    display: block;
                    width: 20px;
                    height: 20px;
    
                    text-align: center;
                    font-size: 14px;
                    border: 2px solid #4882BD;
                    border-radius: 50%;
                    margin-right: 5px;
    
                    transition: transform 0.2s;
                }
    
                &:hover {
                    color: #D70000;
                    background: none;
    
                    &::before {
                        border-color: #D70000;
                    }
    
                }
    
                &.b-ticket-offer__cancel {
                    color: #D70000;
    
                    &::before {
                        border-color: #D70000;
                        transform: rotate(45deg);
                    }
                }
            }
        }
	}

	&-offer__select {
		margin-left: auto;
	  	margin-top: 19px;
	}

	&-offer__select-info {
		text-align: right;
	  	position: absolute;
	    min-width: 50px;
	  	top: 7px;
	  	right: 7px;
	}

	&-offer__select-button-description {
		font-weight: 300;
	}

	&-offer__services-more {
		width: 24px;
		height: 24px;
		background: #4882BD;
		margin: auto -6px 12px 12px;
		cursor: pointer;

		-webkit-mask-repeat: no-repeat;
		-o-mask-repeat: no-repeat;
		mask-repeat: no-repeat;
		-webkit-mask-position: center;
		-o-mask-position: center;
		mask-position: center;

		-webkit-mask-size: 13px auto;
				mask-size: 13px auto;

		-webkit-mask-image: url("~assets/img/icon-arrow-down-2.svg");
		mask-image: url("~assets/img/icon-arrow-down-2.svg");
	}

	&-offer__services-list {
		display: flex;
		flex-direction: row;
		margin: 0 0 -12px -12px;
		padding: 0 18px;

		min-width: 180px;
		.b-hotel-offer--without-row & {
			min-width: 325px;
		}

		.b-hotel-offer__services-more {
			display: none;
		}

		.hotel-service__img {
			min-width: 24px;
			min-height: 24px;
			margin: 0 0 12px 12px;
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	&-offer__services-list--full {
		flex-wrap: wrap;

		.b-hotel-offer__services-more {
			display: none !important;
		}

		.hotel-service__img {
			display: block !important;
		}
	}
}

.mobile-travellers-info-single-room {
  align-self: end;
  margin-right: 6px;
}

.b-hotel-offers__summary {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	padding: 15px 20px;

  background: #fff;
  border-radius: 4px;

  .b-hotel-offers__summary-amount-sum-wrapper {
    @media (max-width: 769px) {
      color: #099C9D;
    }
  }

	.b-hotel-offers__summary-amount {
		margin-left: auto;
		font-size: 16px;
    display: flex;
    align-items: center;
    gap: 15px;

    flex-direction: row;
    @media (max-width: 769px) {
      flex-direction: row-reverse;
      justify-content: space-between;
      background: #fff;
    }

    .b-hotel-offers__summary-amount-travellersInfo-wrapper {
      display: flex;
      gap: 15px;

      
    }
	}



	.b-hotel-offers__summary-amount-sum {
		font-weight: 500;
		font-size: 22px;
		color: #E50000;
	}

	.b-hotel__summary-select {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 25px;
		cursor: pointer;
		font-size: 16px;
	  	background: #4882BD;
	  	width: 195px;
	  	height: 36px;
	  	padding: 10px 15px;
	  	border-radius: 3px;
	  	flex-flow: column nowrap;
	  	color: #fff;
	  	position: relative;
	  	margin-right: 0;

		&--offer {
		  	border: none;
		  	font-size: 18px;
		  	color: #4882BD;
			background: none;

		  &::before {
			content: "+";
			position: absolute;
			left: 15px;
			top: 8px;
			z-index: 100;

			display: block;
			width: 20px;
			height: 20px;

			text-align: center;
			font-size: 14px;
			border: 2px solid #4882BD;
			border-radius: 50%;

			transition: transform 0.2s;
		  }

			&:hover{
				color: #D70000;

			  &:before {
				border-color: #D70000;
			  }
			}

		  &--disabled, &--disabled:hover {
			cursor: default;
			background: transparent;
			color: #9ca0a7;

			&:before {
			  border-color: #9ca0a7;
			}
		  }
		}

	  &--disabled {
		background: #9ca0a7;
		cursor: default;
	  }
	}
}

.hotel-service__WIFI {
	background-image: url(~assets/img/hotels/services/WIFI.svg);
}

.hotel-service__PETS {
	background-image: url(~assets/img/hotels/services/PETS.svg);
}

.hotel-service__MEAL {
	background-image: url(~assets/img/hotels/services/MEAL.svg);
}

.hotel-services-gray {
	.hotel-service__WIFI {
		background-image: url(~assets/img/hotels/services/gray/WIFI.svg);
	}
	.hotel-service__PETS {
		background-image: url(~assets/img/hotels/services/gray/PETS.svg);
	}
	.hotel-service__MEAL {
		background-image: url(~assets/img/hotels/services/gray/MEAL.svg);
	}
}

.b-hotel-offer__select-button--offer-container {
	display: flex;
	align-items: center;
	margin-bottom: 5px;

  &.many-rooms {
	padding-right: 20px;
  }
}

.b-hotel-offer__select-button--offer-price {
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	color: #4882BD;
	flex: 1;
}

.b-hotel-offer__select-button-price.price-only {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #4882BD;
  text-align: right;
  flex: 1;
}

.b-hotel-offer__daily-average-price {
  color: #099C9D;
  font-size: 14px;
  text-align: right;

  &-amount {
	white-space: nowrap;
  }
}

.b-hotel__hotel-offer-provider-list {
  color: #4882bd;
}

.b-hotel-offer__select-button--offer-RP {
	font-size: 14px;
	line-height: 16px;
	margin-left: 5px;
}

.b-hotel-extended__policy {
	margin-bottom: 20px;
}

.b-hotel-extended__policy-title {
	font-size: 20px;
	margin-bottom: 20px;
	display: inline-block;
	position: relative;

	&:before {
		content: '';
		display: block;
		position: absolute;
		bottom: -5px;
		left: -1px;
		right: -1px;
		border-top: 2px solid #4A5767;
	}
}

.b-policy-list {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-items: center;
	font-size: 14px;
}

.b-policy-list-item {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: flex-start;
	flex: 1;
	width: 100%;
	margin-bottom: 15px;
}

.b-policy-list-item__title {
	font-weight: 500;
	text-align: left;
	flex: 1;
	padding-right: 15px;
}

.b-policy-list-item__description {
	flex: 2;
	line-height: 1.5em;
}

.summary-tooltip-total {
  max-width: unset;
  & > .prices-container {
	display: flex;
	gap: 5px;
  }
  & .prices-separator {
	border-left: 1px dashed;
	width: 1px;
  }
  & > .prices-total {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top: 1px dashed;
	margin-top: 10px;
	padding-top: 5px;
	font-weight: 600;
  }
}

.b-hotel-offers-grouped .b-hotel-offers-offer {
	background: #f4f4f4;
}

@import "./style.mobile";
