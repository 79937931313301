@import (reference) "~assets/css/imports";

.validations-errors__container {
	background: @background-styling;
	color: @color-text-white;
	padding: 5px 5px 10px 5px;
	bottom: 30px;
	position: absolute;
	display: block;
	.rounded-top;
	z-index: 100;
	width: 100%;
	margin-bottom: -5px;
	overflow: hidden;
	text-overflow: ellipsis;
	height: auto;
	box-sizing: border-box;
}

.validation-error {
	position: relative;
	& input {
		border-color: @background-styling !important;
	}
	&:hover > .validations-errors__container {
		opacity: 1;
	}
}

@media @tablet {
  .validations-errors__container {
	bottom: 38px;
  }
}
