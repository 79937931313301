@import (reference) "~assets/css/imports";

.p-cabinet-news {
	margin-bottom: 30px;

	.b-block-container__header {
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
		position: relative;
		z-index: 2;
	}

  .b-block-container__content,
  .b-block-container__footer {
    border: 1px solid #A9A9A9;
    box-sizing: border-box;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
    border-top:0px;
    border-radius: 2px;
  }

  .b-block-container__content {
    border-bottom: 0px;
  }

	.b-block-container .b-block-container__top-controls {
		background: #ffffff;
		border: 1px solid #a9a9a9;
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
		border-radius: 0 0 3px 3px;
		border-top: none;
		padding: 10px 10px 12px 12px;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		position: relative;
		z-index: 2;
    gap: 12px;

    .b-block-container__element {
      display: flex;
    }

	}

  .b-block-container__content-news {
		background: #FFFFFF;
		border: 1px solid #A9A9A9;
		box-sizing: border-box;
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
		border-radius: 3px;
		margin-top: -5px;
		padding-top: 5px;
		position: relative;
		overflow: hidden;
		z-index: 0;

		& > .b-block-container__content {
			padding: 0;
			border-top: 0;
		}


	}

}
