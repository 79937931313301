@import (reference) "~assets/css/imports";

.b-passport {
	position: relative;
	border-bottom: 3px dotted rgba(0, 0, 0, 0.1);

  .b-passport__bonus-cards-info {
    color: rgba(215, 0, 0, 0.8);
  }
}

.b-passport__remove {
	.g-icon-delete-collection;
	.rounded;
	cursor: pointer;
	display: block;
	background-size: 10px 10px;
	background-position: 50% 50%;
	width: 30px;
	height: 30px;
	border: 1px solid #A4ABB3;
	margin-top: 9px;
}

@import "./style.mobile";