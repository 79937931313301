@media @tablet {

	.b-middle-name__value {
		height: 48px;
	}

	.b-middle-name__label, .b-middle-name__value {
		font-size: 14px;
	}

  	.b-input__label-container .b-middle-name__without {
	  color: #099C9D !important;
	  border-bottom: none !important;
	}
}
