@import (reference) "~assets/css/imports";

.tooltip-hotels-locations-error.tooltip {
	z-index: 99;

	.tooltip-inner {
		box-shadow: -3px 1px 12px rgba(0, 0, 0, 0.25);
		max-width: 250px;
	}
}

.b-hotels-locations {
	font-size: 16px;
	height: 40px;
	padding: 0 50px 0 0;
	position: relative;
	display: block;
}

.b-hotels-locations__wrapper {
	width: 100%;
}

.b-hotels-locations input.b-hotels-locations__input {
	border: 0;
	background: none;
	width: 100%;
	height: 40px;
	line-height: 40px;
	padding: 0 0 0 15px;
	color: #FFFFFF;
	overflow: hidden;
	float: left;
	text-overflow: ellipsis;
	.placeholder(@__base-color-2-dark);
	font-size: @font-size-big;
	outline: none;
}

.b-hotels-locations__hint {
	color: @color-text;
	opacity: 0.5 !important;
}

.b-hotels-locations__code {
	text-transform: uppercase;
	color: @color-text;
	line-height: 40px;
	position: absolute;
	right: 0;
	top: 0;
}

.b-hotels-locations__items {
	background: #4A5767;
	padding: 0 0;
	margin-top: 1px;
	.rounded-bottom;
	.shadow;
	min-width: 265px;
	max-height: 210px;
	overflow-y: scroll;
}

.b-hotels-locations__item {
	height: 30px;
	padding: 6px 10px;
	text-align: left;
	position: relative;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	box-sizing: border-box;

	&:hover {
		background-color: #2F3842;
		& .b-hotels-locations__item-city {
			color: #4882BD;
			& span {
				color: #4882BD;
			}
		}
		& .b-hotels-locations__item-code {
			color: #4882BD;
		}

	}
}

.b-hotels-locations__cursor {
	background: @background-active;
	color: @color-text-white;
}

.b-hotels-locations__cursor .b-hotels-locations__item-code,
.b-hotels-locations__cursor .b-hotels-locations__item-city {
	color: @color-text-white;
}

.b-hotels-locations__cursor.b-hotels-locations__item:after {
	display: none;
}

.b-hotels-locations__item-city {
	color: #fff;
	overflow: hidden;
	display: block;
	text-overflow: ellipsis;
	white-space: nowrap;

	span {
		color: @color-text;
	}
}

.b-hotels-locations__item-code {
	float: right;
	color: @color-text;
	text-transform: uppercase;
	font-size: @font-size-big;
	margin-right: 10px;
	line-height: 40px;
	padding-left: 15px;
	margin-left: -100%;
}

.b-hotels-locations.validation-error {
	.b-hotels-locations__input {
		.placeholder(@color-text-styling);
		color: @color-text-styling;
	}
}

.b-hotels-locations__items {
	width: 100%;
	border: 1px solid #A4ABB3;
	border-top: 0;
	box-sizing: border-box;
}

.p-places .validations-errors__container {
	opacity: 0;
	overflow: hidden;
	.transition-fast;
}

.b-hotels-locations__items {
	padding-bottom: 14px;
}

.b-hotels-locations__dataset {
	.b-hotels-locations__item {
		margin: 0 13px 0 18px;
		padding: 13px 0 13px 20px;
		border-bottom: 1px dashed #fff;
		height: unset;
		width: unset;
		font-size: 16px;
		cursor: pointer;

		&.b-hotels-locations__cursor {
			background: none;
		}
	}

	.league-name {
		color: rgba(255, 255, 255, .5);
		margin: 18px 13px 0 18px;
		font-size: 16px;
		display: flex;
		align-items: center;
		font-weight: normal;

		&:after {
			content: '';
			border-top: 1px solid #fff;
			flex: 1;
			margin-left: 10px;
		}
	}
}

@media @tablet {
	.b-hotels-locations__items {
		max-width: 110%;
	}

}
