@import (reference) "~assets/css/imports";


.b-block-table {
	width: 100%;
	display: table;
	table-layout: fixed;

	&__row {
	  display: table-row;
	  clear: both;

	  &.collapse:not(.show) {
	  	display: none;
	  }

	  &:last-child {
	  	.b-block-table__cell {
	  		border: 0;
	  	}
	  }
	}

	&__row.clickable:hover {
		background: rgba(72, 130, 189, 0.2);
		cursor: pointer;
	}

	&__row-header {

		.b-block-table__cell {
			border-bottom: 1px solid #4A5767;
			color: #4A5767;
		}
	}

	&__cell {
	  display: table-cell;
	  vertical-align: middle;
	  padding: 12px;
	  border-bottom: 1px solid #e5e5e5;
	  color: #6f7884;
	  overflow: hidden;
	  text-overflow: ellipsis;
	  position: relative;

	  &-caption {
	  	display: none;
	  	font-weight: bold;
	  }

	  &--clickable {
		display: inherit;
		gap: inherit;
		width: 100%;
		color: #4882BD;
		text-decoration: underline #4882BD;
	  }
	}

	&__cell-empty {
		width: 12px;
		padding: 0;
		border-bottom: 0;
	}

	&__cell--width-auto {
		width: 1px;
		overflow: unset;
		white-space: nowrap;
	}

	&__cell:after {
	  content: '';
	  display:block;
	  height: 10px;
	  width: 1px;
	  background: rgba(0, 0, 0, 0.1);
	  position: absolute;
	  right: 0;
	  top: calc(~'50% - 5px');
	}

	&__cell-no-border:after {
		content: unset;
	}

	&__cell-empty:after {
		content: unset;
	}

  }

@import "./style.mobile";
