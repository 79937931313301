@media (min-width: 1440px) {
	.b-carriage-maps__wrapper {
		.b-train__carriage-map svg {
			max-width: unset;
		}
	}

	.b-train-ticket {
		padding: 10px 14px 20px 14px;

		&__route {
			margin: 14px 0 0 30px;
		}

		&__wrapper {
			.b-map-description__messages,
			.b-carriage-map__description {
				max-width: unset;
				margin: 20px 0 0;
				padding: 0 85px 20px 14px;
			}
		}
	}
	.b-train__carriages {
		&-type {
			padding: 7px 6px 10px 10px;
			min-height: 75px;
		}
	}
	.b-train__carriage-type {
		flex-flow: column;
	}
	.b-train__carriage-quantity {
		margin-left: auto;
	}

	.b-train__seats {
		.b-train__carriage {
			padding: 22px 14px 22px 14px;

			&.even.open {
				&:after {
					left: 14px;
					right: 14px;
				}
			}
		}
	}
	.b-train__carriage {
		&-type {
			& > .b-train__carriage-quantity {
				display: block;
			}
		}

		&-seats-tp {
			flex-wrap: nowrap;

			& > .b-train__carriage-quantity {
				display: none;
			}
		}

		&-seats {
			.b-train__carriage {
				&-price {
					min-width: 20%;
				}
			}
		}

		&-price {
			width: auto;
			margin-top: 0;
		}
	}
	.b-route {
		&__departure,
		&__info {
			margin-right: 100px;
		}
	}
	.b-carriage-maps__container .b-carriage-maps__controls {
		padding-left: 14px;
	}

	.b-carriage__selector-wrapper {
		margin-left: 0;
	}

}

@media (min-width: 1600px) {
	.b-train__carriage-seats,
	.b-train-ticket__carriages {
		margin-left: 30px;
		margin-right: 30px;
	}

	.b-train-ticket {
		padding: 12px 10px 20px 18px;

		&__wrapper {
			.b-map-description__messages,
			.b-carriage-map__description {
				padding: 0 50px 20px 135px;
			}
		}

		&__header {
			& > .b-train-ticket__info-services {
				display: none;
			}
		}

		&__route {
			margin: 7px 0 0 50px;

			& > .b-train-ticket__info-services {
				min-width: 30%;
				display: flex;
				margin-top: 10px;
			}
		}
	}
	.b-train__carriages {
		&-type {
			padding: 4px 12px 5px 12px;
			min-height: 70px;
		}
	}
	.b-train__seats {
		.b-train__carriage {
			padding: 22px 17px 22px 19px;

			&.even.open {
				&:after {
					left: 19px;
					right: 17px;
				}
			}
		}
	}
	.b-route {
		&__arrival,
		&__departure,
		&__info {
			margin-right: 75px;
		}
	}
	.b-carriage__selected-variant {
		padding: 14px 50px 14px 60px;
	}
	.b-carriage-maps__container {
		.b-carriage-maps {
			&__controls {
				display: none !important;
			}
			&__arrow {
				display: flex;
			}
			&__slide-right {
				margin: 30px auto 0 auto !important;
			}
			&__slide-left {
				margin: 30px auto 0 auto !important;
			}
		}
		.b-train__carriage-map__car-number {
			padding: 0;
		}
	}
}

@media (min-width: 1920px) {
	.b-train__carriage-seats,
	.b-train-ticket__carriages {
		margin-left: 60px;
		margin-right: 60px;
	}

	.b-train-ticket {
		padding: 7px 50px 20px 14px;

		&__header {
			& > .b-train-ticket__info-common {
				display: none;
			}
		}

		&__wrapper {
			.b-map-description__messages,
			.b-carriage-map__description {
				max-width: unset;
				margin: 20px 0 0;
				padding: 0 35px 20px 60px;
			}
		}

		&__route {
			margin-left: 96px;

			& > .b-train-ticket__info-common {
				display: block;
				margin: 10px 0 0 auto;
			}
		}
	}
	.b-train__carriages {
		&-type {
			padding: 7px 14px 11px 14px;
		}
	}
	.b-train__carriage-type {
		flex-flow: row;
	}
	.b-train__seats {
		.b-train__carriage {
			padding: 22px 20px 22px 14px;

			&.even.open {
				&:after {
					left: 14px;
					right: 20px;
				}
			}
		}
	}
	.b-carriage__selected-variant {
		padding: 14px 35px 14px 60px;

		.b-selected-variant {
			flex-wrap: nowrap;

			&__info {
				flex-grow: unset;
			}
		}

		.b-carriage-maps {
			&__slide-right {
				margin: 30px auto 0 40px !important;
			}
			&__slide-left {
				margin: 30px 40px 0 auto !important;
			}
		}
	}
	.b-route {
		&__departure,
		&__info {
			margin-right: 145px;
		}

		&__arrival {
			margin-right: 100px;
		}
	}
}

@media (min-width: 769px) and (max-width: 955px) {
  .b-train-ticket {
	min-width: unset;

	&__header {
	  .b-train-ticket__info-train {
		white-space: normal !important;
	  }
	}

	&__route {

	  .b-route__departure, .b-route__info {
		margin-right: 40px;
	  }
	}
  }

  .b-train__carriages {

	.b-train__carriages-type {
	  max-width: 140px;
	  font-size: 12px;
	  padding: 10px 5px;

	  .b-train__carriage-price {
		text-align: left;
		font-size: 14px;
	  }

	  &.sold_out {
		display: none;
	  }
	}
  }

  .b-carriage-maps__area {
	overflow-x: auto;
  }

  .b-carriage-map__description {
	padding: 0 12px;
  }
}

@media @tablet {
  .b-train-ticket {
	min-width: unset;
	padding: 10px 6px;

	&__wrapper {
	}

	&__visible {
	}

	&__gds-info {
	  width: unset;
	}

	&__header {
	  flex-flow: row wrap;
	}

	&__info-train {
	}

	&__info-disabled {
	}

	&__info-disabled-icon {
	}

	&__info-services {
	  margin: 20px 0 0 0;
	}

	&__info-common {
	}

	&__info-common-tags {
	}

	&__tags {
	}

	&__route {
	  display: none;
	}

	&__mobile {
	  margin-top: 20px;

	  & > div:not(:last-child) {
		margin-bottom: 10px;
	  }
	}

	&__mobile-duration {
	  font-size: 12px;
	  display: flex;
	  align-items: center;
	}

	&__mobile-route-location {
	  text-transform: capitalize;
	  font-size: 12px;
	}

	&__mobile-route-line {

	  &:after,
	  &:before {
		width: 8px;
		height: 8px;
		top: -3px;
	  }
	}
  }

  .b-info-train {
	display: flex;
	align-items: center;
	font-size: 14px;

	&__element {
	  display: flex;
	  align-items: center;
	  gap: 5px;
	}

	&__number:before {
	  .border-radius(50%);
	  content: ' ';
	  width: 1.2em;
	  height: 1.2em;
	  margin-right: 10px;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  background: url("~assets/img/icons/bc-railways-red.svg") no-repeat center #EDEEF0;
	  background-size: 65% 65%;
	  padding: 1em;
	}

	&__number {
	  white-space: nowrap;
	}

	&__number,
	&__brand {
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  font-size: 14px;
	  font-weight: 600;
	}

	&__description {
	  display: none;
	}
  }

  .b-route {

	&__info-route {
	  display: flex;
	  align-items: center;
	  margin-left: 10px;
	}

	&__info-route-link {
	  border-bottom: none;
	}
  }

  .train-services-gray {
  }

  .red-tag {
  }

  .b-carriage {
	&__selected-variant {
	}
  }

  .b-train {
	&__carriages {
	  flex-flow: column;
	}

	&__carriage-seats {
	  display: none !important;
	}

	&__carriages-type {
	  overflow: hidden;
	  text-overflow: ellipsis;
	  flex-direction: row;
	  min-height: 50px;
	  align-items: center;

	  &.open {
		box-shadow: unset;

		&:after {
		  content: unset;
		}
	  }

	  &:not(:last-of-type) {
		border-bottom: 1px solid #EDEEF0;
	  }

	  &.sold_out {
		display: none;
	  }

	  .b-train__carriage-type-tp-container {
		order: 1;
		flex-grow: 1;
		display: flex;
		flex-flow: row wrap;
		width: 40%;

		& > .b-train__carriage-tp,
		& > .b-train__carriage-type {
		  display: flex !important;
		}
	  }
	}

	&__carriages-select {
	}

	&__carriage {

	}

	&__carriage-type {
	  flex-grow: 1;
	  order: 2;
	  display: none;
	}

	&__carriage-caption {
	}

	&__carriage-quantity {
	  width: 20%;

	  & > span {
		display: none;
	  }
	}

	&__carriage-tp {
	  order: 1;
	  margin-right: 10px;
	  display: none !important;
	}

	&__carriage-seats-tp {
	  width: 40%;
	  order: 3;
	  flex-grow: 1;
	  flex-flow: row;
	}

	&__carriage-price {
	  min-width: 140px;
	  margin-top: unset;
	  display: flex;
	  gap: 3px;
	  justify-content: flex-end;
	  align-items: center;
	}
  }
}

@media (max-width: 450px) {
  .b-train-ticket__header {
	& > div {
	  flex: unset !important;
	}
	div.b-train-ticket__info-train {
	  flex-flow: row wrap;
	  white-space: normal;
	}
  }
}
