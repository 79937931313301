@import (reference) '~assets/css/imports';

.b-transliteration-popup {
  position: absolute;
  z-index: 10;
  background-color: #4a5767;
  .border-radius(4px);
  padding-right: 22px;

  &__text {
	color: #fff;
	font-size: 14px;
  }

  &__value {
	color: #4882bd;
	cursor: pointer;
	border-bottom: 1px dashed;
  }

  &__close {
	.g-icon-w-cancel;
	width: 10px;
	height: 10px;
	display: block;
	position: absolute;
	right: 7px;
	top: 7px;
	cursor: pointer;
  }

  &__content {
	padding: 10px;
  }

  & .arrow {
	display: block;
	position: absolute;
	width: 10px;
	height: 10px;
	background: #4a5767;
	transform: rotate(45deg);
	bottom: -4px;
	z-index: 0;
  }
}
