@media @tablet {
  .b-addition-orders {
	padding: 0 12px;

	&__item-number {
	  border-bottom: unset;
	  font-weight: 500;
	}

	&__item-add-caption {
	  display: inline-block !important;
	  border-bottom: 1px dashed;
	  font-size: 14px;
	  color: #4882BD;
	}

	&__item-add {
	  display: none;
	}

	.b-block-table__cell {

	  &-label {
		min-width: 100% !important;
		justify-content: unset;
		font-size: 14px;
		flex-flow: row nowrap;

		.overflow-field {
		  min-width: 70% !important;
		  white-space: nowrap;

		  & > div {
			text-overflow: ellipsis;
			overflow: hidden;
		  }
		}
	  }

	  &-client,
	  &-agents,
	  &-subagent {
		display: none !important;
	  }

	  &-icon-service {
		background-color: #4A5767;
	  }

	  &-icon-service-colored {
		background-color: #099C9D;
	  }

	  &-issue-date {
		background: #EDEEF0;
		padding-top: 3px !important;
		padding-bottom: 3px !important;
		margin-bottom: unset !important;
		border-top: 1px solid #EDEEF0;
		font-size: 14px;
		width: 100%;
		order: 8;
	  }

	  &-item-number {
		background-color: #DAE6F2;
		border: 1px dashed #4882BD !important;
		padding-top: 5px !important;
		padding-bottom: 5px !important;
	  }

	  &-price {
		position: absolute;
		top: 5px;
		right: 0;
		font-weight: 500;
		color: #4882BD;
	  }
	}

	.b-block-table__row {
	  margin-bottom: 20px;
	  border: 1px solid #f3f3f3;
	  .border-radius(4px);
	}
  }

}

@media (min-width: 769px) {
  .b-addition-orders {
	.b-block-table__row {
	  @media (max-width: 1630px) {
		.b-block-table__label, .b-block-table__cell, .b-block-table__cell-label {
		  font-size: 12px;
		}
		.b-addition-orders__item-add {
		  padding: 0 5px;
		  background-size: 10px;
		  width: 10px;
		}
	  }
	  @media (max-width: 806px) {
		.b-block-table__label, .b-block-table__cell, .b-block-table__cell-label {
		  font-size: 11px;
		}
	  }
	}
	@media (max-width: 1870px) {
	  .b-block-table__row .b-block-table__cell {
		padding: 3px;
	  }
	  .b-block-table tbody .b-block-table__cell .b-block-table__cell-label {
		padding: 0 3px;
	  }
	}
  }
}

@media (min-width: 1440px) {
	.b-addition-orders {
		.b-block-table {
			&__cell {
				&-empty {
					&:last-child {
					    display: table-cell;
					}
				}
			}
		}
	}
}

@media (min-width: 1920px) {
	.b-addition-orders {
		.b-block-table {
			&__cell {
				&-empty {
				    display: table-cell;
				    width: 3%;
				}
				&-controls {
					min-width: 90px;
					&:after {
						right: 50%;
					}
				}
			}
		}
	}
}
