.b-attachment {
	&__logo {
		&-img {
			&-train {
                background-image: url("~assets/img/icon-train.svg");
            }
		}

	}
}

.b-railways-custom-toggle {
	.b-toggle {
		.b-toggle__label:first-of-type {
		  display: none;
		}
	}
}
