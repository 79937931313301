@import (reference) "~assets/css/imports";

.b-payment {
	.rounded;

	&__header {
		background: @field2-background-color;
		padding: 0 15px;
		height: 45px;
		line-height: 45px;
		color: @field2-H2-color;
		font-size: @base-H1-size;
		text-transform: uppercase;
		.rounded-top;
		.shadow;
	}

	&__block {
		background: white;
		margin: 0;
		position: relative;
		display: inline-block;
		max-width: 75%;
		.rounded;

		&-header {
			.clearfix;
			height: 40px;
			line-height: 40px;

			&-type {
				float: left;
				position: relative;
				top: 3px;

				.b-radiobutton__view {
					top: 2px;
				}
			}

			&-comission {
				float: right;
				padding-right: 15px;
				color: @field1-H1-color;
			}

			&-status {
				&-ok {
					.clearfix;
					font-size: 18px;
					line-height: 1.75em;
					display: inline-block;

					&:before {
						content: "";
						background-image: url("~assets/img/icon-ok.svg");
						border: 1px solid #4A5767;
						border-radius: 50%;
						.g-icon;
						background-size: 70%;
						width: 22px;
						height: 22px;
						display: inline-block;
						position: absolute;
						left: -35px;
						top: calc(~'50% - 11px');
					}
				}

				&-text {
					color: @field1-font-color;
					font-size: 12px;
					display: block;
				}

				&-fail {
					.clearfix;
					font-size: 18px;
					line-height: 1.75em;
					display: inline-block;

					&:before {
						content: "";
						background-image: url("~assets/img/icon-cancel.svg");
						border: 1px solid #B60A0A;
						border-radius: 50%;
						.g-icon;
						background-size: 50%;
						width: 22px;
						height: 22px;
						display: inline-block;
						position: absolute;
						left: -35px;
						top: calc(~'50% - 11px');
					}
				}
			}
		}

		&-content {
			display: none;
			padding: 0 15px 20px;
		}
	}

	&__office {
		.rounded;
		border: 1px solid @field1-elements-color;
		padding: 5px 10px;
		.clearfix;

		&-title {
			color: @field1-H1-color;
			font-weight: normal;
			font-size: @base-H1-size;
		  	margin: 0;
		}

		&-variant {
			padding: 10px 0 10px 10px;
			border-bottom: 1px solid @field1-elements-color;
			width: 50%;
			float: left;
			clear: left;

			&-info {
				padding-left: 22px;
				font-size: @base-H1-size;
				line-height: 1.4;
			}

			&:last-child {
				border-bottom: 0;
			}

			.b-radiobutton__label {
				font-weight: normal;
				color: @field1-H1-color;
				font-size: @base-H1-size;
				overflow: hidden;
				display: block;
				padding-left: 10px;
			}

			.b-radiobutton__view {
				top: 3px;
				float: left;
			}
		}
	}

	&__rules {
		margin-top: 2px;
	}

	&__new_search {
		.rounded-bottom;
		background: @button1-color;
		height: 35px;
		line-height: 35px;
		text-align: center;
		text-transform: uppercase;
		color: @button1-font-color;
		display: block;
		text-decoration: none;
	}

	&__gates {
		padding: 0 0 0 10px;

		p {
			margin: 10px 0;
		}

		&-list {
			.clearfix;
		}
	}

	&__gate {
		float: left;
		margin: 15px;
		padding-left: 20px;
		position: relative;
		cursor: pointer;
		height: 80px;
		background-position: 100% 0;
		background-repeat: no-repeat;
		background-size: 100px 80px;

		&-input {
			display: none;

			&:checked + .b-payment__gate-visual {
				border: 1px solid @field1-active-color;
			}

			&:checked + .b-payment__gate-visual:before {
				width: 14px;
				height: 14px;
				top: 1px;
				left: 1px;
				opacity: 1;
			}
		}

		&-visual {
			.border-radius(50%);
			border: 1px solid @field1-object-color;
			display: inline-block;
			width: 18px;
			height: 18px;
			position: absolute;
			left: 0;
			top: 3px;

			&:before {
				content: "";
				display: block;
				position: absolute;
				width: 0;
				height: 0;
				top: 8px;
				left: 8px;
				.border-radius(50%);
				background: @field1-active-color;
				.transition-fast;
				opacity: 0;
			}
		}
	}

	&__footer {
		display: table;
		width: 100%;
		table-layout: fixed;

		.b-payment__button {
			display: table-cell;
			.rounded-bottom;
			text-align: center;
			height: 40px;
			color: @button1-font-color;
			line-height: 40px;
			width: auto;
			white-space: nowrap;
			.transition-standard;
			text-transform: uppercase;
			font-size: @base-font-size;
			cursor: pointer;

			&:hover {
				background: @button1-color-act;
				color: @button1-font-color-act;
			}

			&:active {
				background: @button1-color-act;
				color: @button1-font-color-act;
			}

			&.inactive {
				cursor: default;
				background: fade(@button1-color, 10%);
			}

		}
	}

	&__button {
		.b-payment__pay {
			background: @button1-color;
			color: @button1-font-color;

			&:hover {
				background: @button1-color-act;
				color: @button1-font-color-act;
			}

			&:active {
				background: @button1-color-act;
				color: @button1-font-color-act;
			}

			&.inactive {
				cursor: default;
				background: fade(@button1-color, 10%);
			}
		}

		.b-payment__cancel {
			background: @button2-color;
			color: @button2-font-color;

			&:hover {
				background: @button2-color-act;
				color: @button2-font-color-act;
			}

			&:active {
				background: @button2-color-act;
				color: @button2-font-color-act;
			}
		}
	}


	/* NEW STYLES */
	&__item {
		display: flex;
		flex-direction: column;

		& + .b-payment__item {
			margin-top: 12px;
		}

		.b-payment__item-cell-select {
			padding-top: 8px;
			.b-input {
				min-width: 300px;
			}
		}

		&-row {
			display: flex;
			flex-flow: row nowrap;
			white-space: nowrap;
			align-items: center;
			width: 100%;

			&--with-border {
				.b-payment__item-cell {
					flex-grow: 0;

					&--width-full {
						flex-grow: 1;
					}
				}
			}
		}

		&-cell {
			flex: 1;

			&--type-title {
				.b-radiobutton {
					margin: 0;
				}
			}

			&--type-amount {
				font-weight: 500;
				font-size: 18px;
			}
		}

		&-border {
			display: block;
			border-top: 1px dashed #000;
			opacity: .3;
			margin: 0 20px;
		}
	}
}

.b-order__payment-in-hotel-container {
	margin-bottom: 20px;
	.b-payment__item-cell--type-title {
		font-size: 18px;
		line-height: 18px;
	}
}

.b-cabinet-order-payment__title {
	color: #4A5767;
	font-size: 18px;
	line-height: 21px;
	//margin: 0 0 15px;
	font-weight: normal;
}

.b-payment__item-cell > .b-radiobutton {
	max-width: 465px;
	overflow: hidden;
	text-overflow: ellipsis;
	box-sizing: border-box;
}

.b-payment__item-cell > .b-radiobutton .b-radiobutton__label  {
	max-width: 410px;
	overflow: hidden;
	text-overflow: ellipsis;
	box-sizing: border-box;
	left: 0 !important;
	margin-left: 9px;
}

@import "./style.mobile";
