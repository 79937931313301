.w {
  &-time {
    position: relative;

    &.b-input {

      &__wrapper {
        position: relative;
      }

      &__value {
        border: 1px solid rgba(74, 87, 103, 0.4);
        font-size: 14px;
        color: #2F3842;
        padding: 0 10px;
        height: 30px;
        width: 100%;
        -webkit-border-radius: 3px;
        -webkit-background-clip: padding-box;
        -moz-border-radius: 3px;
        -moz-background-clip: padding;
        border-radius: 3px;
        z-index: 2;
        background: none;
        background-clip: padding-box;
        position: relative;
        display: block;
      }

      &__label {
        color: #4A5767;
        margin-bottom: 2px;
        display: inline;
        font-size: 14px;
        width: min-content;
        position: relative;
        margin-left: 4px;
        margin-right: 4px;
        padding: 0 4px;
        z-index: 3;
      }
    }
  }
}

.w-time__remove {
  background: url("~assets/img/icon-clear-dark.svg") no-repeat center;
  width: 28px;
  height: 100%;
  background-size: 10px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
  cursor: pointer;

  &.dn {
    display: none;
  }
}
