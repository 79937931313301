.b-search-bar-sorter {
  padding: 6px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  &__text {
    text-align: left;
  }

  &__button {
    width: auto;
    background: #4882BD;
    margin: 0 16px;
    padding: 4px 16px;
    color: #fff;
    cursor: pointer;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    @media (min-width: 768px) {
      width: 194px;
    }
  }
}