@import (reference) "~assets/css/imports";

.image-checkbox__input {	
	&:hover + .image-checkbox__icon {
		color: #099C9D;
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), inset 1px 1px #099C9D, inset -1px -1px #099C9D;

		&--0-SPEQ-SNOW { 
			background-image: url('~assets/img/icons/icon-additional-baggage-weight-23-hover.svg');
		}
	}

	&:hover + .image-checkbox__icon .image-checkbox__add-block {
		display: block;
	}
}

.image-checkbox__input {	
	&:checked + .image-checkbox__icon {
		color: #000000;
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), inset 1px 1px #099C9D, inset -1px -1px #099C9D;

		&--0-SPEQ-SNOW { 
			background-image: url('~assets/img/icons/icon-additional-baggage-weight-23.svg');
		}
	}

	&:checked + .image-checkbox__icon .image-checkbox__add-block {
		display: none;
	}
}

.image-checkbox__label {
	margin-right: 15px;
	margin-bottom: 20px;
	cursor: pointer;
}

.image-checkbox__icon {
	position: relative;
	width: 90px;
	height: 90px;
	padding-top: 55px;
	border: 0;

	border-radius: 3px;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), inset 1px 1px #F0F0F0, inset -1px -1px #F0F0F0;

	background-repeat: no-repeat;
	background-position: 50% 50%;

	text-align: center;
	font-size: 12px;
	font-weight: 500;
	line-height: 17px;
	color: #4A5767;

	&:last-child {
		margin-right: 0;
	}

	&--baggage-not-incl {
		border: 0;
		box-shadow: none;
		background-image: url('~assets/img/icons/icon-additional-baggage-not-incl.svg');
	}

	&--0-SPEQ-SNOW {
		background-position: 50% 8px;
		background-image: url('~assets/img/icons/icon-additional-baggage-weight-23.svg');
	}
}

.image-checkbox__add-block {
	display: none;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;

	background-color: #099C9D;

	font-size: 10px;
	font-weight: 700;
	color: #ffffff;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
}
