@import (reference) "~assets/css/imports";

.w {
    &-sections {
        &__section {
            width: 262px;
            padding: 8px 0;
            background-color: #A4ABB3;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 4px;
            text-decoration: none;
            color: #fff;
            cursor: pointer;
            &_current {
				background: white;
                color: @field1-font-color;
			  	font-weight: 500;
                &:hover {
                    color: @field1-font-color;
                }
			}
		  	&_disable {
                background-color: #4A5767;
                opacity: 0.15;
                cursor: auto;
            }
        }
    }
}
