@import (reference) "~assets/css/imports";

.b-input-phone {
	&.b-input-phone-disabled {
		.b-input__value {
			background-color: rgba(74, 87, 103, 0.1);
		}
	}

	.b-input__value {
		z-index: 2;
		position: relative;
		background-color: @field1-input-background;
	}
	.validations-errors__container {
		bottom: -25px;
		border-radius: 0;
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;
	}
}

.b-input-phone__remove {
	.g-icon-delete-collection;
	cursor: pointer;
	display: block;
	background-size: 10px 10px;
	background-position: 50% 50%;
	width: 30px;
	top: 0;
	bottom: 0;
	height: 100%;
	position: absolute;
	right: 0;
	z-index: 5;
	border-left: 1px solid #a4abb3;
}

.b-input-phone__country-visible,
.b-input-phone__value {
	width: 45px;
	float: left;
	height: 28px;
	line-height: 28px;
	padding: 0 0 0 0;
	border: 0;
	position: relative;
	z-index: 5;
	left: 0;
	top: 0;
	background: none;
	text-align: center;
	color: @field1-font-color;
}

.b-input-phone__country option {
	padding: 3px 0;
}

.b-input-phone__country {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 45px;
	float: left;
	height: 38px;
	z-index: 100;
}

.b-input-phone__value {
	position: absolute;
	z-index: 4;
	left: 10px;
	width: 100%;
	padding-left: 45px;
	text-align: left;
	color: @field1-font-color;
}

.b-input-phone__hint {
	color: @field1-font-color;
	opacity: 0.5 !important;
}

.b-input-phone__code {
	text-transform: uppercase;
	color: @field2-link-color;
	right: 0;
	top: 0;
}

.b-input-phone__items {
	background: @field1-background-color;
	padding: 0 0;
	margin-top: 1px;
	min-width: 60px;
	z-index: 10;
	border: 1px solid @field1-object-color;
}

.b-input-phone__item {
	.clearfix;
	height: 42px;
	line-height: 40px;
	padding: 0 10px;
	text-align: left;
	position: relative;
	cursor: pointer;
	white-space: nowrap;
}

.b-input-phone__item:after {
	content: " ";
	background: @field1-object-color;
	height: 1px;
	left: 10px;
	right: 10px;
	bottom: -1px;
	display: block;
	position: absolute;
}

.b-input-phone__cursor {
	background: @field2-active-color;
	color: @field2-link-color;
}

.b-input-phone__cursor .b-input-phone__item-code,
.b-input-phone__cursor .b-input-phone__item-city {
	color: @field2-link-color;
}

.b-input-phone__cursor.b-input-phone__item:after {
	display: none;
}

.b-input-phone__item-city {
	color: @field1-active-color;
	overflow: hidden;
	display: block;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.b-input-phone__item-code {
	float: right;
	color: @field1-font-color;
	text-transform: uppercase;
	font-size: @base-H1-size;
	margin-right: 10px;
	line-height: 40px;
	padding-left: 15px;
}

.b-input-phone.validation-error {
	.b-input-phone__input {
		.placeholder(@field1-warning-color);
		color: @field1-warning-color;
	}
}

.autocomplete-suggestions {
	background: @field1-background-color;
	padding: 0 0;
	margin: 1px 0 0 -11px;
	.rounded-bottom;
	.shadow;
	min-width: 190px;
	width: auto !important;
	font-size: @base-H1-size;
	color: @field1-active-color;
}

.autocomplete-suggestion {
	.clearfix;
	height: 39px;
	line-height: 40px;
	padding: 0 10px;
	text-align: left;
	position: relative;
	cursor: pointer;

	&:after {
		content: " ";
		background: @field1-object-color;
		height: 1px;
		left: 10px;
		right: 10px;
		bottom: -1px;
		display: block;
		position: absolute;
	}

	&:last-child:after {
		display: none;
	}
}

.autocomplete-selected {
	background: @field2-active-color;
	color: @field2-link-color;
}

@import "./style.mobile";
