@import (reference) "~assets/css/imports";
@import '../styles';


.b-booking-transfers {

  	&__block-header {
	  background: #4a5767;
	  border-top-left-radius: 3px;
	  -webkit-border-top-right-radius: 3px;
	  -webkit-background-clip: padding-box;
	  -moz-border-radius-topright: 3px;
	  -moz-background-clip: padding;
	  border-top-right-radius: 3px;
	  background-clip: padding-box;
	  display: flex;
	  padding: 12px;
	  font-weight: 500;
	  font-size: 14px;
	  color: #fff;
	  text-transform: uppercase;
	}

  	&__additional-services {
	  	&-wrapper {
		  background: #FFFFFF;
		  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
		  border-radius: 0 3px;
		  margin-bottom: 20px;
		}
	}

  	&__passengers {

	}

  &__notes {
	padding: 0;
  }
}

@import './style.mobile';
