@import (reference) "~assets/css/imports";

.flex-grid-container {
	margin-bottom: -18px;
	display: flex;
	flex-flow: row wrap;
}

.flex-grid-layout {
	width: 33.33333%;
	margin-bottom: 18px;
	padding-right: 30px;
	&:nth-child(3n) {
		padding-right: 0;
	}
}

@import "./style.mobile";
